@file:UseSerializers(UrlSerializer::class)

package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.PollConfig
import io.ktor.http.Url
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.time.Duration
import kotlin.time.Duration.Companion.minutes

@Serializable
sealed class BrokerClientConfig {
    abstract val createdAt: Long
    abstract val commissionPercent: Double
    abstract val exchanges: Set<Exchange>
    abstract val autoStart: Boolean

    abstract fun withCreatedAt(createdAt: Long): BrokerClientConfig

    @Serializable
    @SerialName("Tinkoff")
    data class Tinkoff(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val sandbox: Boolean,
        val token: String,
        val portfolioPollConfig: PollConfig,
        val ordersPollConfig: PollConfig,
        val operationsPollConfig: PollConfig,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("Alor")
    data class Alor(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val sandbox: Boolean,
        val token: String,
        val profile: String,
        val liquidityPollConfig: PollConfig,
        val debug: Boolean,
        val riskCategory: String,
        val usdTradesPollConfig: PollConfig,
        val subscribeOperations: Boolean,
        val subscribePortfolioSummary: Boolean,
        val subscribeRisk: Boolean,
        val subscribeStopOrders: Boolean,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("InteractiveBrokers")
    data class InteractiveBrokers(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val port: Int,
        val exchangeCurrencies: Map<Exchange, Currency>,
        val currencyPairs: List<CurrencyPair>,
        val directlyRoutedExchanges: Set<Exchange>,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        val clientId: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val orderIdReceiveTimeout: Duration,
        val correctPriceByStep: Boolean = false,
        val skipEmptyQuote: Boolean = false,
        val genericTicks: Set<Int> = setOf(46),
        val caching: Boolean = true,
        val errorCodesPush: Set<Int> = setOf(101),
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("Simulator")
    data class Simulator(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val balance: Double,
        val portfolio: Map<TypedTicker, PortfolioPosition>,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val executionInterval: Duration?,
        val executionStepVolumeCap: Double?,
        val processOrdersInBackground: Boolean,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("Relay")
    data class Relay(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val endpoint: Url,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val connectionPingTimeout: Duration,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("NoOp")
    data class NoOp(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val failOnStart: Boolean,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("Exante")
    data class Exante(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val accountId: String,
        val token: String,
        val portfolioPollConfig: PollConfig,
        val ordersPollConfig: PollConfig,
        val sandbox: Boolean,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("Finam")
    data class Finam(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val token: String,
        val clientId: String,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val portfolioPollInterval: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val pingInterval: Duration,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("FutureBinance")
    data class FutureBinance(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val apiKey: String,
        val secretKey: String,
        val market: BinanceFutureMarket,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("Mexc")
    data class Mexc(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val accessKey: String,
        val secretKey: String,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val listenKeyExtendInterval: Duration = 10.minutes,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }

    @Serializable
    @SerialName("MexcFut")
    data class MexcFut(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val exchanges: Set<Exchange>,
        override val commissionPercent: Double,
        val accessKey: String,
        val secretKey: String,
    ) : BrokerClientConfig() {
        override fun withCreatedAt(createdAt: Long): BrokerClientConfig {
            return copy(createdAt = createdAt)
        }
    }
}
