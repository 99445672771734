package com.edvorg.trade.common.model.market2

import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.OperationType
import com.edvorg.trade.common.model.OrderType
import kotlinx.serialization.Serializable

@Serializable
data class Market2PreparedOrder(
    val localId: String,
    val brokerId: BrokerId?,
    val operationType: OperationType,
    val orderType: OrderType,
    val volume: String,
    val price: String,
    val smart: Boolean,
    val hidden: Boolean,
)
