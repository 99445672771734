package com.edvorg.trade.common.model.config

import kotlinx.serialization.Serializable

@Serializable
data class Version(val number: Int) {
    override fun toString(): String {
        return "v$number"
    }
}
