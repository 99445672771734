package com.edvorg.trade.common.model.groupwatchlist

import com.edvorg.trade.common.model.SortType
import kotlinx.serialization.Serializable

@Serializable
data class GroupWatchlistSubscription(
    val listName: String,
    val columns: Set<GroupWatchlistColumn>,
    val columnSort: GroupWatchlistColumn,
    val sortType: SortType,
    val count: Int,
)
