package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.MarketDataId
import react.ChildrenBuilder
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.span
import react.useEffectOnce
import react.useState
import web.cssom.ClassName

fun ChildrenBuilder.renderStatus(status: ConnectorStatus?) {
    when {
        status == null -> span {
            +"unknown"
        }

        status.isConnecting() -> span {
            className = ClassName("lightgreen")
            +"connecting (${status.connecting}/${status.total})"
        }

        status.isFailed() -> span {
            className = ClassName("red")
            +"failed (${status.failed}/${status.total})"
        }

        status.isDisconnecting() -> span {
            +"disconnecting (${status.disconnecting}/${status.total})"
        }

        status.isPartiallyConnected() -> span {
            className = ClassName("green")
            +"connected (${status.connected}/${status.total})"
        }

        else -> span {
            +"disconnected"
        }
    }
}

external interface BrokerStatusIndicatorProps : PropsWithChildren {
    var exchange: Exchange
}

val brokerStatusIndicator = FC<BrokerStatusIndicatorProps> { props ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val connectionManager = connectorServersManager.getServerConnection("local")?.client?.connectionManager

    var connectorStatus: Pair<BrokerId, ConnectorStatus>? by useState(null)

    useEffectOnce {
        val brokerClientsHandle = connectionManager?.subscribeToBrokerClientsUpdate { update ->
            connectorStatus = update.priorityBrokers[props.exchange]?.let { brokerId ->
                update.brokerClients[brokerId]?.connectorStatus?.let {
                    Pair(brokerId, it)
                }
            }
        }

        cleanup {
            brokerClientsHandle?.unsubscribe()
        }

        span {
            className = ClassName("connection-indicator")
            label { +"${connectorStatus?.first?.id} (${props.exchange.id}): " }
            renderStatus(connectorStatus?.second)
        }
    }
}

external interface MarketDataStatusIndicatorProps : PropsWithChildren {
    var exchange: Exchange
}

val marketDataStatusIndicator = FC<MarketDataStatusIndicatorProps> { props ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val connectionManager = connectorServersManager.getServerConnection("local")?.client?.connectionManager

    var connectorStatus: Pair<MarketDataId, ConnectorStatus>? by useState(null)

    useEffectOnce {
        val marketDataClientsHandle =
            connectionManager?.subscribeToMarketDataClientsUpdate { update ->
                connectorStatus = update.marketDataClients.entries.find {
                    val level1Exchanges = it.value.marketDataClientConfig.level1Exchanges
                    !it.value.connectorStatus.isDisconnected() && level1Exchanges.contains(props.exchange)
                }?.let {
                    Pair(it.key, it.value.connectorStatus)
                }
            }

        cleanup {
            marketDataClientsHandle?.unsubscribe()
        }
    }

    span {
        className = ClassName("connection-indicator")
        label { +"${connectorStatus?.first?.id} (${props.exchange.id}): " }
        renderStatus(connectorStatus?.second)
    }
}
