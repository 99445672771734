package com.edvorg.trade.common.frontend.services

import com.edvorg.trade.common.model.SubscriptionHandle
import com.edvorg.trade.common.utils.concurrency.compute
import com.edvorg.trade.common.utils.concurrency.concurrentMapOf
import kotlinx.browser.window
import kotlin.random.Random

class GlobalKeyEventManager : AbstractGlobalKeyEventManager {
    init {
        window.addEventListener("keyup", { event ->
            val keyCode = event.asDynamic().keyCode as Int
            keyUpSubscribers[keyCode]?.values?.forEach {
                it.invoke()
            }
        },)
        window.addEventListener("keydown", { event ->
            val repeat = event.asDynamic().repeat as Boolean
            if (!repeat) {
                val keyCode = event.asDynamic().keyCode as Int
                keyDownSubscribers[keyCode]?.values?.forEach {
                    it.invoke()
                }
            }
        },)
    }

    private val keyUpSubscribers = concurrentMapOf<Int, Map<Int, (() -> Unit)>>(mapOf())
    private val keyDownSubscribers = concurrentMapOf<Int, Map<Int, (() -> Unit)>>(mapOf())

    override fun subscribeUp(keyCode: Int, callback: () -> Unit): SubscriptionHandle<Int> {
        val id = Random.nextInt()
        keyUpSubscribers.compute(keyCode) { _, oldSubscribers ->
            (oldSubscribers ?: mapOf()) + Pair(id, callback)
        }
        return object : SubscriptionHandle<Int>(id) {
            override fun unsubscribe() {
                keyUpSubscribers.compute(keyCode) { _, oldSubscribers ->
                    oldSubscribers?.minus(id)
                }
            }
        }
    }

    override fun subscribeDown(keyCode: Int, callback: () -> Unit): SubscriptionHandle<Int> {
        val id = Random.nextInt()
        keyDownSubscribers.compute(keyCode) { _, oldSubscribers ->
            (oldSubscribers ?: mapOf()) + Pair(id, callback)
        }
        return object : SubscriptionHandle<Int>(id) {
            override fun unsubscribe() {
                keyDownSubscribers.compute(keyCode) { _, oldSubscribers ->
                    oldSubscribers?.minus(id)
                }
            }
        }
    }
}
