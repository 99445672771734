package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class SamuraiTick {
    @Serializable
    @SerialName("OrderBook")
    data object OrderBook : SamuraiTick()

    @Serializable
    @SerialName("Stat")
    data class Stat(
        val priceStatType: PriceStatType,
    ) : SamuraiTick()

    @Serializable
    @SerialName("Indicator")
    data class IndicatorTick(
        val indicator: Indicator,
    ) : SamuraiTick()
}
