package com.edvorg.trade.common.model

abstract class SubscriptionHandle<T>(val id: T) {
    abstract fun unsubscribe()

    fun <U> map(block: (T) -> U): SubscriptionHandle<U> {
        return object : SubscriptionHandle<U>(block(id)) {
            override fun unsubscribe() {
                this@SubscriptionHandle.unsubscribe()
            }
        }
    }
}
