package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class TickerPair(
    val ticker1: TypedTicker,
    val ticker2: TypedTicker,
) {
    fun reversed(): TickerPair {
        return TickerPair(ticker2, ticker1)
    }

    fun mapExchange(
        mapFunction: (Exchange) -> Exchange,
    ): TickerPair {
        return TickerPair(
            ticker1.copy(exchange = mapFunction(ticker1.exchange)),
            ticker2.copy(exchange = mapFunction(ticker2.exchange)),
        )
    }
}
