package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(TickerSerializer::class)
data class Ticker(val id: String) {
    override fun toString(): String {
        return id
    }
}

object TickerSerializer : KSerializer<Ticker> {
    override fun deserialize(decoder: Decoder): Ticker {
        return Ticker(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: Ticker) {
        encoder.encodeString(value.id)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "TickerIdentifier",
        PrimitiveKind.STRING,
    )
}
