package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.model.BridgeTradeConfig
import com.edvorg.trade.common.model.OrderType
import com.edvorg.trade.common.model.ScannerExecutorConfig
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerExecutorStatus
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import kotlinx.coroutines.launch
import mui.icons.material.AddCircle
import mui.material.Button
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.responsive
import mui.system.sx
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.h3
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.Globals.Companion.unset
import web.cssom.px
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

private val scannerExecutorOptions = mapOf(
    "Pair" to ScannerExecutorConfig.Pair(
        0,
    ),
    "Bridge" to ScannerExecutorConfig.Bridge(
        0,
        BridgeTradeConfig(
            10.seconds,
            15.seconds,
            OrderType.IOC,
            1.seconds,
        ),
    ),
    "VWAP" to ScannerExecutorConfig.Vwap(
        0,
        5.seconds,
    ),
    "Timeout" to ScannerExecutorConfig.Timeout(
        0,
        5.seconds,
        100.0,
    ),
    "PairCap" to ScannerExecutorConfig.PairCap(
        0,
        0.5,
        2.minutes,
    ),
)

val scannerExecutorConnectorsPanel = fcWithScope<Props> { _, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val connectionManager = connectorServersManager.getServerConnection("local")?.client?.connectionManager
    val clickWatcher = getContext<CommonContext>().clickWatcher
    val toaster = getContext<CommonContext>().toaster

    var scannerExecutorTypeToCreate: String by useState(scannerExecutorOptions.keys.first())
    val (shownScannerExecutors, setShownScannerExecutors) = useState(emptySet<ScannerExecutorId>())
    var scannerExecutors: Map<ScannerExecutorId, ScannerExecutorStatus> by useState(emptyMap())
    val (confirmDialog, setConfirmDialog) = useState<Confirm?>(null)
    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)

    useEffectOnce {
        val handle = connectionManager?.subscribeToScannerExecutorsUpdate { update ->
            scannerExecutors = update.executors
        }
        cleanup {
            handle?.unsubscribe()
        }
    }

    fun resetScannerExecutorSettings(
        settings: MutableSettings,
        config: ScannerExecutorConfig,
    ) {
        when (config) {
            is ScannerExecutorConfig.Pair -> {
            }

            is ScannerExecutorConfig.Bridge -> {
                settings.setDuration(
                    "openPositionTimeout",
                    config.bridgeConfig.openPositionTimeout,
                    false,
                    null,
                )
                settings.setDuration(
                    "closePositionTimeout",
                    config.bridgeConfig.closePositionTimeout,
                    false,
                    null,
                )
                settings.setOrderType("openPositionOrderType", config.bridgeConfig.openPositionOrderType, true)
                settings.setDuration("placeOrderDelay", config.bridgeConfig.placeOrderDelay, false, null)
            }

            is ScannerExecutorConfig.Vwap -> {
                settings.setDuration("orderTimeout", config.orderTimeout, false, null)
            }

            is ScannerExecutorConfig.Timeout -> {
                settings.setDuration("orderTimeout", config.orderTimeout, false, null)
                settings.setDouble("minHedgeBudget", config.minHedgeBudget, false, null)
            }

            is ScannerExecutorConfig.PairCap -> {
                settings.setDouble("price2PercentCap", config.price2PercentCap, false, null)
                settings.setDuration("orderTimeout", config.orderTimeout, false, null)
            }
        }
    }

    fun saveScannerExecutorSettings(
        executorId: ScannerExecutorId,
        settings: Settings,
        prevConfig: ScannerExecutorConfig,
    ) {
        val newScannerClientSettings = when (prevConfig) {
            is ScannerExecutorConfig.Pair -> {
                ScannerExecutorConfig.Pair(
                    prevConfig.createdAt,
                )
            }

            is ScannerExecutorConfig.Bridge -> {
                ScannerExecutorConfig.Bridge(
                    prevConfig.createdAt,
                    BridgeTradeConfig(
                        settings.getDuration("openPositionTimeout"),
                        settings.getDuration("closePositionTimeout"),
                        settings.getOrderType("openPositionOrderType"),
                        settings.getDuration("placeOrderDelay"),
                    ),
                )
            }

            is ScannerExecutorConfig.Vwap -> {
                ScannerExecutorConfig.Vwap(
                    prevConfig.createdAt,
                    settings.getDuration("orderTimeout"),
                )
            }

            is ScannerExecutorConfig.Timeout -> {
                ScannerExecutorConfig.Timeout(
                    prevConfig.createdAt,
                    settings.getDuration("orderTimeout"),
                    settings.getDouble("minHedgeBudget"),
                )
            }

            is ScannerExecutorConfig.PairCap -> {
                ScannerExecutorConfig.PairCap(
                    prevConfig.createdAt,
                    settings.getDouble("price2PercentCap"),
                    settings.getDuration("orderTimeout"),
                )
            }
        }
        scope.launch {
            connectionManager?.sendUpdateScannerExecutor(executorId, newScannerClientSettings)
        }
    }

    Stack {
        spacing = responsive(10.px)

        h3 {
            +"Executor"
        }
        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(5.px)
            justifyContent = "flex-start"

            autocomplete(
                { null },
                false,
                "executor type",
                150,
                null,
                { value ->
                    value?.let { newTypeToCreate ->
                        scannerExecutorTypeToCreate = newTypeToCreate
                    }
                },
                null,
                {
                },
                StringOption(scannerExecutorTypeToCreate),
                scannerExecutorOptions.map { StringOption(it.key) }.toTypedArray(),
                true,
            )
            Tooltip {
                title = ReactNode("Create")

                Button {
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        clickWatcher.click()
                        event.stopPropagation()
                        setPromptDialog(
                            Prompt(
                                "",
                                "Create executor",
                                "Executor name",
                            ) { executorId ->
                                scannerExecutorOptions[scannerExecutorTypeToCreate]?.let { config ->
                                    scope.launch {
                                        connectionManager?.sendCreateScannerExecutor(
                                            ScannerExecutorId(executorId),
                                            config,
                                        )
                                    }
                                }
                            },
                        )
                    }
                    AddCircle {
                    }
                }
            }
        }
        TableContainer {
            Table {
                sx {
                    width = unset
                }
                size = Size.small

                TableHead {
                    TableRow {
                        TableCell {
                            +"ExecutorId"
                        }
                        TableCell {
                            +"Status"
                        }
                        TableCell {
                            +"Actions"
                        }
                    }
                }
                val entries = scannerExecutors
                if (entries.isNotEmpty()) {
                    val sortedEntries = entries.entries.sortedBy { it.key.id }
                    for ((executorId, status) in sortedEntries) {
                        TableBody {
                            key = "executor:${executorId.id}"

                            scannerExecutorConnector {
                                this.executorId = executorId
                                this.status = status
                                this.resetSettings = { it ->
                                    resetScannerExecutorSettings(it, status.executorConfig)
                                }
                                this.saveSettings = {
                                    saveScannerExecutorSettings(
                                        executorId,
                                        it,
                                        status.executorConfig,
                                    )
                                    toaster.info(
                                        "Executor ${executorId.id} saved",
                                        1900,
                                        ToastPosition.BOTTOM_RIGHT,
                                    )
                                }
                                this.showSettings = shownScannerExecutors.contains(executorId)
                                this.toggleShowSettings = {
                                    setShownScannerExecutors { shownScannerExecutors ->
                                        if (shownScannerExecutors.contains(executorId)) {
                                            shownScannerExecutors - executorId
                                        } else {
                                            shownScannerExecutors + executorId
                                        }
                                    }
                                }
                                this.removeConnector = {
                                    setConfirmDialog(
                                        Confirm(
                                            "Remove executor",
                                            "Please confirm connector removal",
                                        ) {
                                            scope.launch {
                                                connectionManager?.sendRemoveScannerExecutor(executorId)
                                            }
                                        },
                                    )
                                }
                                this.duplicateConnector = {
                                    setPromptDialog(
                                        Prompt(
                                            "",
                                            "Duplicate executor",
                                            "Connector name",
                                        ) { newExecutorId ->
                                            scope.launch {
                                                connectionManager?.sendDuplicateScannerExecutor(
                                                    executorId,
                                                    ScannerExecutorId(newExecutorId),
                                                )
                                            }
                                        },
                                    )
                                }
                            }
                        }
                    }
                } else {
                    TableBody {
                        TableRow {
                            TableCell {
                                colSpan = 4
                                +"No connectors"
                            }
                        }
                    }
                }
            }
        }
    }

    confirm {
        this.dialog = confirmDialog
        this.setDialog = setConfirmDialog
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }
}
