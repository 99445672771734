package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class TickerPricePrefill {
    @Serializable
    @SerialName("OrderBookPrefill")
    data class OrderBookPrefill(val orderBook: OrderBook) : TickerPricePrefill()

    @Serializable
    @SerialName("MidPointPrefill")
    data class MidPointPrefill(
        val midpoint: Double = 100.0,
        val spread: Double = 20.0,
    ) : TickerPricePrefill()
}
