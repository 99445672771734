package com.edvorg.trade.common.model

enum class ScannerDealSelection(val id: String) {
    ALL("All"),
    SHORT("Short"),
    LONG("Long"),
    SHORT_AND_LONG("Short & Long"),
    ;

    fun matches(operationType: OperationType): Boolean {
        return when (this) {
            ALL -> true
            SHORT -> operationType == OperationType.SELL
            LONG -> operationType == OperationType.BUY
            SHORT_AND_LONG -> operationType == OperationType.SELL || operationType == OperationType.BUY
        }
    }
}
