package com.edvorg.trade.backend.frontend.utils

import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.TypedTicker

class TickerInputHelper(
    private val tickers: Map<Exchange, Set<Ticker>>,
) {
    val allExchanges = tickers.keys.toList()

    fun processInput(tickerStr: String, masterExchange: Exchange): TypedTicker {
        val components = tickerStr.split(":")
        if (components.size == 1) {
            val ticker = Ticker(components[0])
            tickers.entries.sortedBy {
                if (it.key == masterExchange) {
                    0
                } else {
                    1
                }
            }.firstOrNull {
                it.value.contains(ticker)
            }?.key?.let {
                return TypedTicker(TickerType.STOCK, it, ticker)
            } ?: return TypedTicker(TickerType.STOCK, masterExchange, ticker)
        } else {
            return TypedTicker.parse(tickerStr, masterExchange, TickerType.STOCK) ?: TypedTicker(
                TickerType.STOCK,
                masterExchange,
                Ticker(tickerStr),
            )
        }
    }
}
