package com.edvorg.trade.common.model.config

import kotlinx.serialization.Serializable

@Serializable
data class SamuraiPauseState(
    val time: Long = 0,
    val limSell: Double,
    val limBuy: Double,
    val pos1: Double,
    val pos2: Double,
)
