package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class WidgetGeometry(
    val left: Double,
    val top: Double,
    val width: Double,
    val height: Double,
    val index: Int,
) {
    companion object {
        fun makeDefaultGeometry(index: Int): WidgetGeometry {
            return WidgetGeometry(0.0, 0.0, 600.0, 500.0, index)
        }
    }
}
