package com.edvorg.trade.common.model.chartscript

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class ChartScriptManagementRequest {

    @Serializable
    @SerialName("CreateChartScript")
    data class CreateChartScript(
        val scriptId: ScriptId,
        val script: ChartScriptConfig? = null,
    ) : ChartScriptManagementRequest()

    @Serializable
    @SerialName("UpdateChartScript")
    data class UpdateChartScript(
        val scriptId: ScriptId,
        val script: ChartScriptConfig,
    ) : ChartScriptManagementRequest()

    @Serializable
    @SerialName("DeleteChartScript")
    data class RemoveChartScript(
        val scriptId: ScriptId,
    ) : ChartScriptManagementRequest()

    @Serializable
    @SerialName("DuplicateChartScript")
    data class DuplicateChartScript(
        val originalScriptId: ScriptId,
        val scriptId: ScriptId,
    ) : ChartScriptManagementRequest()

    @Serializable
    @SerialName("StartChartScript")
    data class StartChartScript(
        val scriptId: ScriptId,
    ) : ChartScriptManagementRequest()

    @Serializable
    @SerialName("StopChartScript")
    data class StopChartScript(
        val scriptId: ScriptId,
    ) : ChartScriptManagementRequest()

    @Serializable
    @SerialName("StartAll")
    object StartMarkedScripts : ChartScriptManagementRequest()
}
