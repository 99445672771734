package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.BridgeConfig
import com.edvorg.trade.common.model.config.OptionalDoubleRange
import com.edvorg.trade.common.model.config.OptionalLongRange
import kotlinx.serialization.Serializable

@Serializable
data class ArbitrageConfig(
    val includedTickersFilter: Boolean,
    val includedTickers: Set<TypedTicker>,
    val excludedTickersFilter: Boolean,
    val excludedTickers: Set<TypedTicker>,
    val market1SpreadPercentRange: OptionalDoubleRange,
    val market2SpreadPercentRange: OptionalDoubleRange,
    val autoBridge: BridgeConfig?,
    val openPriceRange: OptionalDoubleRange,
    val closeVolumeRange: OptionalLongRange,
    val allowRefill1: Boolean,
    val allowRefill2: Boolean,
    val excludeWhenUpTick: Boolean,
    val smart1: Boolean,
    val hidden1: Boolean,
    val hidden2: Boolean,
) {
    companion object {
        fun permissiveArbitrageConfig(
            includedTickersFilter: Boolean = false,
            includedTickers: Set<TypedTicker> = emptySet(),
            excludedTickersFilter: Boolean = true,
            excludedTickers: Set<TypedTicker> = emptySet(),
            market1SpreadPercentRange: OptionalDoubleRange = OptionalDoubleRange(),
            market2SpreadPercentRange: OptionalDoubleRange = OptionalDoubleRange(),
            autoBridge: BridgeConfig? = null,
            openPriceRange: OptionalDoubleRange = OptionalDoubleRange(),
            closeVolumeRange: OptionalLongRange = OptionalLongRange(),
            allowRefill1: Boolean = true,
            allowRefill2: Boolean = true,
            excludeWhenUpTick: Boolean = true,
            smart1: Boolean = true,
            hidden1: Boolean = false,
            hidden2: Boolean = false,
        ): ArbitrageConfig {
            return ArbitrageConfig(
                includedTickersFilter,
                includedTickers,
                excludedTickersFilter,
                excludedTickers,
                market1SpreadPercentRange,
                market2SpreadPercentRange,
                autoBridge,
                openPriceRange,
                closeVolumeRange,
                allowRefill1,
                allowRefill2,
                excludeWhenUpTick,
                smart1,
                hidden1,
                hidden2,
            )
        }
    }
}
