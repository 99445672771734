package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
data class Operation(
    val orderId: String,
    @Serializable(with = HumanReadableDurationSerializer::class)
    val time: Duration,
    val ticker: TypedTicker,
    val operationType: OperationType,
    val price: Double,
    val volume: Double,
    val commission: Double,
) {
    constructor(
        orderId: String,
        time: Duration,
        ticker: TypedTicker,
        operationType: OperationType,
        price: Double,
        volume: Long,
        commission: Double,
    ) : this(orderId, time, ticker, operationType, price, volume.toDouble(), commission)
}
