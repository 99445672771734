package com.edvorg.trade.common.frontend.services

import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.utils.concurrency.compute
import com.edvorg.trade.common.utils.concurrency.concurrentMapOf
import io.ktor.http.Url
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch

class ConnectorServersManager {
    companion object {
        data class ServerConnection(
            val url: Url,
            val client: ConnectionManagement,
            val status: ConnectorStatus,
        )
    }

    private val servers = concurrentMapOf<String, ServerConnection>()

    fun updateServer(
        name: String,
        url: Url,
        scope: CoroutineScope,
        client: ConnectionManagement,
    ) {
        val server = servers[name]
        if (server != null) {
            if (server.url != url) {
                server.client.stopConnection()
                servers.remove(name)
                createServer(name, url, scope, client)
            }
            return
        }

        val oldNameClient = servers.entries.find { it.value.url == url }
        if (oldNameClient != null) {
            servers.remove(oldNameClient.key)
            servers[name] = oldNameClient.value
            return
        }

        createServer(name, url, scope, client)
    }

    private fun createServer(
        serverId: String,
        url: Url,
        scope: CoroutineScope,
        client: ConnectionManagement,
    ) {
        servers[serverId] = ServerConnection(
            url,
            client,
            ConnectorStatus.Disconnected,
        )
        scope.launch {
            client.start { status ->
                setServerStatus(serverId, status)
            }
        }
    }

    fun setServer(serverId: String, client: ConnectionManagement, url: Url) {
        servers[serverId] = ServerConnection(
            url,
            client,
            ConnectorStatus.Disconnected,
        )
    }

    fun getServerIds(): Set<String> {
        return servers.keys
    }

    fun getServerConnection(serverId: String): ServerConnection? {
        return servers[serverId]
    }

    fun setServerStatus(serverId: String, status: ConnectorStatus) {
        servers.compute(serverId) { _, scanner ->
            scanner?.copy(status = status)
        }
    }

    fun deleteServer(serverId: String) {
        servers[serverId]?.client?.stopConnection()
        servers.remove(serverId)
    }
}
