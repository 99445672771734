package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ScannerManagementRequest")
sealed class ScannerManagementRequest {
    @Serializable
    @SerialName("ConnectScanner")
    data class ConnectScanner(
        val scannerId: ScannerId,
    ) : ScannerManagementRequest()

    @Serializable
    @SerialName("CreateScannerClient")
    data class CreateScannerClient(
        val scannerId: ScannerId,
        val config: ScannerClientConfig,
    ) : ScannerManagementRequest()

    @Serializable
    @SerialName("RemoveScannerClient")
    data class RemoveScannerClient(
        val scannerId: ScannerId,
    ) : ScannerManagementRequest()

    @Serializable
    @SerialName("UpdateScannerClient")
    data class UpdateScannerClient(
        val scannerId: ScannerId,
        val config: ScannerClientConfig,
    ) : ScannerManagementRequest()

    @Serializable
    @SerialName("DuplicateScannerConnector")
    data class DuplicateScannerConnector(
        val scannerId: ScannerId,
        val newScannerId: ScannerId,
    ) : ScannerManagementRequest()

    @Serializable
    @SerialName("DisconnectScanner")
    data class DisconnectScanner(
        val scannerId: ScannerId,
    ) : ScannerManagementRequest()
}
