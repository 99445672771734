package com.edvorg.trade.common.model.config

import kotlinx.datetime.LocalTime
import kotlinx.serialization.Serializable

@Serializable
data class SamuraiSchedule(
    val startAt: LocalTime?,
    val stopAt: LocalTime?,
)
