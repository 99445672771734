package com.edvorg.trade.backend.frontend

import com.edvorg.trade.backend.frontend.services.BackendMainClient
import com.edvorg.trade.backend.frontend.services.ClickWatcher
import com.edvorg.trade.backend.frontend.services.makeWsBackendUrl
import com.edvorg.trade.backend.frontend.views.app
import com.edvorg.trade.common.frontend.services.AbstractClickWatcher
import com.edvorg.trade.common.frontend.services.AbstractGlobalKeyEventManager
import com.edvorg.trade.common.frontend.services.AbstractNotificationService
import com.edvorg.trade.common.frontend.services.ChannelBus
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.ConnectionManager
import com.edvorg.trade.common.frontend.services.ConnectorServersManager
import com.edvorg.trade.common.frontend.services.FlowBus
import com.edvorg.trade.common.frontend.services.GlobalKeyEventManager
import com.edvorg.trade.common.frontend.services.NotificationService
import com.edvorg.trade.common.frontend.services.SnackbarToaster
import com.edvorg.trade.common.frontend.services.TickersInfoProvider
import com.edvorg.trade.common.frontend.services.TradingClient
import com.edvorg.trade.common.frontend.services.WidgetBusAction
import com.edvorg.trade.common.frontend.services.setContext
import com.edvorg.trade.common.frontend.services.toast.AbstractToaster
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.utils.sound.BackendSoundPlayer
import com.edvorg.trade.common.utils.sound.JsSoundPlayer
import io.ktor.http.Url
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import mui.material.CssBaseline
import mui.material.styles.ThemeProvider
import org.w3c.dom.get
import org.w3c.dom.set
import react.Fragment
import react.create
import react.dom.client.createRoot
import web.dom.document

class FrontendContext(
    override val tickersInfoProvider: TickersInfoProvider,
    override val clickWatcher: AbstractClickWatcher,
    override val toaster: AbstractToaster,
    override val connectorServersManager: ConnectorServersManager,
    override val soundPlayer: BackendSoundPlayer,
    override val globalKeyEventManager: AbstractGlobalKeyEventManager,
    override val notificationService: AbstractNotificationService,
    override val dropDownBus: FlowBus<Unit, String>,
    override val groupBus: ChannelBus<Group, WidgetBusAction>,
    override val tradingClient: TradingClient,
    override val connectionManager: ConnectionManager,
    val candlesClient: BackendMainClient,
    val tradesClient: BackendMainClient,
    val newsClient: BackendMainClient,
    val backendMainClient: BackendMainClient,
    val snackbarToaster: SnackbarToaster,
) : CommonContext

val root = createRoot(document.getElementById("root") ?: throw Error("root node not found"))

fun getUserSpaceFromCache(): String? {
    return window.localStorage["user_space"]
}

fun putUserSpaceToCache(userSpace: String) {
    window.localStorage["user_space"] = userSpace
}

fun main() {
    val scope = CoroutineScope(Dispatchers.Default)

    val soundPlayer = BackendSoundPlayer(JsSoundPlayer())
    val toaster = SnackbarToaster()
    val notificationService = NotificationService()

    val connectorServersManager = ConnectorServersManager()

    val mainClient = BackendMainClient(
        getUserSpaceFromCache(),
        soundPlayer,
        connectorServersManager,
        makeWsBackendUrl("/ws", emptyMap()),
        toaster,
    ) {
        root.render(
            Fragment.create {
                ThemeProvider {
                    theme = Themes.Light

                    CssBaseline {
                    }

                    app {
                    }
                }
            },
        )
    }

    val candlesClient = BackendMainClient(
        null,
        soundPlayer,
        connectorServersManager,
        makeWsBackendUrl("/ws/limited", emptyMap()),
        toaster,
    ) {
    }

    val tradesClient = BackendMainClient(
        null,
        soundPlayer,
        connectorServersManager,
        makeWsBackendUrl("/ws/limited", emptyMap()),
        toaster,
    ) {
    }

    val newsClient = BackendMainClient(
        null,
        soundPlayer,
        connectorServersManager,
        makeWsBackendUrl("/ws/limited", emptyMap()),
        toaster,
    ) {
    }

    val globalKeyEventManager = GlobalKeyEventManager()

    val clickWatcher = ClickWatcher(
        soundPlayer,
    )

    val groupBus = ChannelBus<Group, WidgetBusAction>(Group.entries.toTypedArray())
    val dropDownBus = FlowBus<Unit, String>(arrayOf(Unit))

    setContext(
        FrontendContext(
            mainClient,
            clickWatcher,
            toaster,
            connectorServersManager,
            soundPlayer,
            globalKeyEventManager,
            notificationService,
            dropDownBus,
            groupBus,
            mainClient,
            mainClient.connectionManager,
            candlesClient,
            tradesClient,
            newsClient,
            mainClient,
            toaster,
        ),
    )

    scope.launch {
        mainClient.run {
            startConnection({ }, { })
        }
    }
    scope.launch {
        candlesClient.run {
            startConnection({ }, { })
        }
    }
    scope.launch {
        tradesClient.run {
            startConnection({ }, { })
        }
    }
    scope.launch {
        newsClient.run {
            startConnection({ }, { })
        }
    }

    connectorServersManager.setServer("local", mainClient, Url("localhost"))
    connectorServersManager.setServerStatus("local", ConnectorStatus.Connected)
}
