package com.edvorg.trade.common.model.stats

import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.utils.CollectionUtils.updated
import kotlinx.serialization.Serializable

@Serializable
data class ImmutableTickerStats(
    val currency: Currency,
    val priceStats: Map<PriceStatType, Double>,
    val volumeStats: Map<VolumeStatType, Double>,
) {

    companion object {
        fun empty(currency: Currency): ImmutableTickerStats {
            return ImmutableTickerStats(currency, mapOf(), mapOf())
        }
    }

    fun get(statType: PriceStatType): Pair<Double, Currency>? {
        return priceStats[statType]?.let { it to currency }
    }

    fun get(statType: VolumeStatType): Double? {
        return volumeStats[statType]
    }

    fun put(statType: PriceStatType, value: Double): ImmutableTickerStats {
        return this.copy(priceStats = priceStats + (statType to value))
    }

    fun put(statType: VolumeStatType, value: Double): ImmutableTickerStats {
        return this.copy(volumeStats = volumeStats + (statType to value))
    }
}

fun Map<TypedTicker, ImmutableTickerStats>.updateStat(
    ticker: TypedTicker,
    statType: PriceStatType,
    price: Double,
    currency: Currency,
): Map<TypedTicker, ImmutableTickerStats> {
    return this.updated(ticker) { _, oldStats ->
        (
            oldStats.takeIf { it?.currency == currency }
                ?: ImmutableTickerStats(currency, emptyMap(), emptyMap())
            ).put(statType, price)
    }
}
