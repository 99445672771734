package com.edvorg.trade.backend.frontend

import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.Theme
import mui.material.styles.TypographyOptions
import mui.material.styles.TypographyVariant
import mui.material.styles.createTheme
import react.RequiredContext
import react.StateInstance
import react.StateSetter
import react.createRequiredContext
import react.useRequiredContext
import web.cssom.atrule.maxWidth
import web.cssom.integer
import web.cssom.px
import web.cssom.rem

val themeContext: RequiredContext<StateInstance<Theme>> = createRequiredContext()
fun useTheme(): Theme = useRequiredContext(themeContext).component1()
fun useSetTheme(): StateSetter<Theme> = useRequiredContext(themeContext).component2()

private val TYPOGRAPHY_OPTIONS = TypographyOptions {
    fontWeight = integer(500)

    TypographyVariant.h6 {
        fontSize = 1.5.rem

        media(maxWidth(599.px)) {
            fontSize = 1.25.rem
        }
    }
}

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = PaletteMode.light
                background = jso {
                    default = "#f5f5f5"
                }
                error = jso {
                    main = "#b30000"
                    light = "#a50000"
                    dark = "#ff3a3a"
                }
                success = jso {
                    main = "#218621"
                    light = "#1bbe1b"
                    dark = "#1a671a"
                }
            }
            typography = TYPOGRAPHY_OPTIONS
        },
    )

//    val Dark = createTheme(
//        jso {
//            palette = jso {
//                mode = PaletteMode.dark
//                background = jso {
//                    default = "#393939"
//                }
//            }
//            typography = TYPOGRAPHY_OPTIONS
//        },
//    )
}
