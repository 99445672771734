package com.edvorg.trade.common.model

import com.edvorg.trade.common.serialization.Dump
import com.edvorg.trade.common.serialization.dumpToJson
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject
import kotlin.math.max

@Serializable
data class OrderBook(
    @SerialName("b")
    val bids: List<OrderBookEntry>,
    @SerialName("a")
    val asks: List<OrderBookEntry>,
    @Serializable(CurrencyIndexSerializer::class)
    @SerialName("c")
    val currency: Currency,
    @SerialName("t")
    val timestamp: Long? = null, // in sec
) : Dump {
    fun convert(currency: Currency, rate: Double): OrderBook {
        if (currency == this.currency) {
            return this
        }

        return OrderBook(
            bids.map {
                it.copy(price = it.price / rate)
            }.toList(),
            asks.map {
                it.copy(price = it.price / rate)
            }.toList(),
            currency,
        )
    }

    fun getColorFillingBySum(): OrderBookColorFilling {
        val bidSum = mutableListOf<Pair<Double, Double>>()
        val askSum = mutableListOf<Pair<Double, Double>>()

        bids.forEach { bid ->
            val prevBidSum = bidSum.lastOrNull()?.second ?: 0.0
            bidSum.add(Pair(bid.volume, prevBidSum + bid.volume))
        }

        asks.forEach { ask ->
            val prevAskSum = askSum.lastOrNull()?.second ?: 0.0
            askSum.add(Pair(ask.volume, prevAskSum + ask.volume))
        }

        return OrderBookColorFilling(
            bidFilling = bidSum.map {
                Pair(it.second, it.second.toFloat() / max(bidSum.last().second, 1.0).toFloat())
            }.toList(),
            askFilling = askSum.map {
                Pair(it.second, it.second.toFloat() / max(askSum.last().second, 1.0).toFloat())
            }.toList(),
        )
    }

    fun getMidpoint(): Double? {
        val bid = bids.firstOrNull()?.price ?: return null
        val ask = asks.firstOrNull()?.price ?: return null
        return (bid + ask) / 2
    }

    fun takeDepth(depth: Int): OrderBook {
        return copy(
            bids = bids.take(depth),
            asks = asks.take(depth),
        )
    }

    override fun dumpToJson(): JsonElement {
        return buildJsonObject {
            put("bids", bids.dumpToJson())
            put("asks", asks.dumpToJson())
            put("currency", JsonPrimitive(currency.toString()))
            put("timestamp", JsonPrimitive(timestamp))
        }
    }
}
