package com.edvorg.trade.common.model.settings

import com.edvorg.trade.common.model.AlpacaDataApiType
import com.edvorg.trade.common.model.AlpacaEndPoint
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.ClosePriceScannerMode
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.ExanteLevel2Mode
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.OrderType
import com.edvorg.trade.common.model.PostCloseMethod
import com.edvorg.trade.common.model.ScannerDealSelection
import com.edvorg.trade.common.model.ScannerDealsSubscription
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.SpreadScannerOperatorMode
import com.edvorg.trade.common.model.Tick
import com.edvorg.trade.common.model.TickerDetailsFilter
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.datetime.LocalTime

sealed class Setting {
    abstract val showLabel: Boolean

    data class BooleanSetting(
        val value: Boolean,
        override val showLabel: Boolean,
    ) : Setting()

    data class StringSetting(
        val value: String,
        val width: Int?,
        override val showLabel: Boolean,
    ) : Setting()

    data class ScannerDealSelectionSetting(
        val value: ScannerDealSelection,
        override val showLabel: Boolean,
    ) : Setting()

    data class BarSizeSetting(
        val value: BarSize,
        val width: Int?,
        override val showLabel: Boolean,
    ) : Setting()

    data class TickSetting(
        val value: Tick,
        override val showLabel: Boolean,
    ) : Setting()

    data class ScannerTickSetting(
        val value: Map<String, Setting>,
        override val showLabel: Boolean,
    ) : Setting()

    data class PriceTickSetting(
        val value: Map<String, Setting>,
        override val showLabel: Boolean,
    ) : Setting()

    data class DealsSubscriptionSetting(
        val value: ScannerDealsSubscription,
        override val showLabel: Boolean,
    ) : Setting()

    data class OrderTypeSetting(
        val value: OrderType,
        override val showLabel: Boolean,
    ) : Setting()

    data class ScannerIdSetting(
        val value: ScannerId,
        override val showLabel: Boolean,
    ) : Setting()

    data class ScannerExecutorIdSetting(
        val value: ScannerExecutorId,
        override val showLabel: Boolean,
    ) : Setting()

    data class ScannerServerSetting(
        val value: String,
        override val showLabel: Boolean,
    ) : Setting()

    data class CurrencySetting(
        val value: Currency,
        val width: Int?,
        override val showLabel: Boolean,
    ) : Setting()

    data class ExanteLevel2ModeSetting(
        val value: ExanteLevel2Mode,
        override val showLabel: Boolean,
    ) : Setting()

    data class AlpacaEndPointSetting(
        val value: AlpacaEndPoint,
        override val showLabel: Boolean,
    ) : Setting()

    data class AlpacaDataApiTypeSetting(
        val value: AlpacaDataApiType,
        override val showLabel: Boolean,
    ) : Setting()

    data class ClosePriceScannerSetting(
        val value: ClosePriceScannerMode,
        override val showLabel: Boolean,
    ) : Setting()

    data class TickerDetailsFilterSetting(
        val value: TickerDetailsFilter,
        override val showLabel: Boolean,
    ) : Setting()

    data class SpreadScannerOperatorSetting(
        val value: SpreadScannerOperatorMode,
        override val showLabel: Boolean,
    ) : Setting()

    data class TickerTypeSetting(
        val value: TickerType,
        val width: Int?,
        override val showLabel: Boolean,
    ) : Setting()

    data class ExchangeSetting(
        val value: Exchange,
        override val showLabel: Boolean,
    ) : Setting()

    data class SamuraiTickSetting(
        val value: Map<String, Setting>,
        override val showLabel: Boolean,
    ) : Setting()

    data class PriceStatTypeSetting(
        val value: PriceStatType,
        override val showLabel: Boolean,
    ) : Setting()

    data class IndicatorSetting(
        val value: Map<String, Setting>,
        override val showLabel: Boolean,
    ) : Setting()

    data class TypedTickerSetting(
        val value: Map<String, Setting>,
        override val showLabel: Boolean,
    ) : Setting()

    data class PostCloseMethodSetting(
        val value: PostCloseMethod,
        override val showLabel: Boolean,
    ) : Setting()

    data class OptionalLocalTimeSetting(
        val value: LocalTime?,
        override val showLabel: Boolean,
    ) : Setting()

    data class ExchangesListSetting(
        val value: Collection<Exchange>,
        val width: Int?,
        override val showLabel: Boolean,
    ) : Setting()

    data class SamuraiLimitsUnitSetting(
        val value: Map<String, Setting>,
        override val showLabel: Boolean,
    ) : Setting()
}
