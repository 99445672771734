package com.edvorg.trade.common.model

import com.edvorg.trade.common.utils.average
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.math.abs

@Serializable
data class PortfolioPosition(
    val volume: Double,
    val averagePrice: Double,
    val blocked: Double,
) {
    constructor(volume: Long, averagePrice: Double, blocked: Long) : this(
        volume.toDouble(),
        averagePrice,
        blocked.toDouble(),
    )

    constructor(volume: Double, averagePrice: Double, blocked: Long) : this(
        volume,
        averagePrice,
        blocked.toDouble(),
    )

    @Transient
    val amount = volume * averagePrice

    fun plus(portfolioPosition: PortfolioPosition): PortfolioPosition {
        return PortfolioPosition(
            volume + portfolioPosition.volume,
            average(
                listOf(
                    Pair(volume, averagePrice),
                    Pair(portfolioPosition.volume, portfolioPosition.averagePrice),
                ),
            ),
            blocked + portfolioPosition.blocked,
        )
    }

    override fun equals(other: Any?): Boolean {
        if (other is PortfolioPosition) {
            return abs(other.volume - volume) < 0.00001 &&
                abs(other.averagePrice - averagePrice) < 0.00001 &&
                other.blocked == blocked
        }
        return false
    }
}
