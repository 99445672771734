package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
enum class Group(val id: String, val styleClass: String) {
    BLACK("black", "group1"),
    RED("red", "group2"),
    ORANGE("orange", "group3"),
    YELLOW("yellow", "group4"),
    GREEN("green", "group5"),
    CYAN("cyan", "group6"),
    BLUE("blue", "group7"),
    PURPLE("purple", "group8"),
}
