package com.edvorg.trade.common.model.groupwatchlist

import kotlinx.serialization.Serializable

@Serializable
enum class GroupWatchlistColumn(val displayName: String) {
    LOGO("Logo"),
    EXCHANGE("Exchange"),
    TICKER("Ticker"),
    REG("Reg"),
    POST("Post"),
    CLOSE("Reg/Close"),
    BID("Bid"),
    BID_SIZE("BidSize"),
    BID_CHANGE("BidChange"),
    ASK("Ask"),
    ASK_SIZE("AskSize"),
    ASK_CHANGE("AskChange"),
    LAST("Last"),
    LAST_CHANGE("LastChange"),
    CORRELATION("Corr"),
    INDUSTRY("Industry"),
    SECTOR("Sector"),
    TURNOVER("Turnover"),
    VOLUME("Volume"),
}
