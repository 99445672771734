package com.edvorg.trade.backend.frontend.views

import com.edvorg.trade.backend.frontend.FrontendContext
import com.edvorg.trade.backend.frontend.model.WidgetType
import com.edvorg.trade.backend.frontend.putUserSpaceToCache
import com.edvorg.trade.backend.frontend.services.makeHttpBackendUrl
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.frontend.views.Confirm
import com.edvorg.trade.common.frontend.views.Prompt
import com.edvorg.trade.common.frontend.views.StringOption
import com.edvorg.trade.common.frontend.views.autocomplete
import com.edvorg.trade.common.frontend.views.confirm
import com.edvorg.trade.common.frontend.views.fcWithScope
import com.edvorg.trade.common.frontend.views.prompt
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.LogLevel
import com.edvorg.trade.common.model.config.GuiConfig
import com.edvorg.trade.common.model.config.GuiConfigChange
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Analytics
import mui.icons.material.BugReport
import mui.icons.material.ContentCopy
import mui.icons.material.CurrencyExchange
import mui.icons.material.Delete
import mui.icons.material.FileDownload
import mui.icons.material.Info
import mui.icons.material.Input
import mui.icons.material.RestartAlt
import mui.icons.material.Widgets
import mui.material.AppBar
import mui.material.AppBarPosition
import mui.material.Avatar
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.ButtonGroupVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Divider
import mui.material.IconButton
import mui.material.ListItemIcon
import mui.material.ListItemText
import mui.material.Menu
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.ToggleButton
import mui.material.ToggleButtonGroup
import mui.material.Toolbar
import mui.material.ToolbarVariant
import mui.material.Tooltip
import mui.material.TooltipPlacement
import mui.material.Typography
import mui.system.sx
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.span
import react.useState
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.number
import web.cssom.px
import web.dom.Element
import web.html.ButtonType

external interface AppBarProps : Props {
    var tabs: List<String>
    var widgetTemplates: List<String>
    var removeWidgetTemplate: (String) -> Unit
    var selectedTab: String
    var setSelectedTab: (String) -> Unit
    var removeTab: (String) -> Unit
    var duplicateTab: (String) -> Unit
    var userSpaceList: List<String>?
    var roundingScale: Int
    var setRoundingScale: (Int) -> Unit
    var prioritySuggestExchange: Exchange?
    var setPrioritySuggestExchange: (Exchange) -> Unit
    var createWidget: (String) -> Unit
    var createWidgetFromState: (String) -> Unit
    var createWidgetFromTemplate: (String) -> Unit
    var activeSubscriptionSize: Int
}

val appBar = fcWithScope<AppBarProps> { props, scope ->
    val backendMainClient = getContext<FrontendContext>().backendMainClient
    val soundPlayer = getContext<CommonContext>().soundPlayer
    val toaster = getContext<CommonContext>().toaster

    var selectedWidgetTemplate by useState<String?>(null)
    var selectedWidget by useState(WidgetType.CHART.id)
    var createWidgetAnchor by useState<Element?>(null)
    var choosingWidgetType by useState(false)
    var choosingWidgetTemplate by useState(false)
    var userSpaceAnchor by useState<Element?>(null)
    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)
    val (confirmDialog, setConfirmDialog) = useState<Confirm?>(null)

    val allExchanges = backendMainClient.getTickerInputHelper().allExchanges
    val currentPriorityExchange = props.prioritySuggestExchange
    val currentUserSpace = backendMainClient.currentUserSpace
    val userSpaceListOrEmpty = props.userSpaceList ?: listOf()

    AppBar {
        sx {
            // this option doesn't seem to be correctly applied from the theme, so we apply it directly in css
            backgroundColor = Color("lightgrey")
        }
        position = AppBarPosition.sticky

        Toolbar {
            sx {
                minHeight = 28.px
            }
            variant = ToolbarVariant.dense

            ToggleButtonGroup {
                size = Size.small
                value = props.selectedTab

                props.tabs.forEach { tab ->
                    ToggleButton {
                        key = tab
                        value = tab
                        onClick = { _, _ ->
                            props.setSelectedTab(tab)
                            backendMainClient.sendTabsChange(GuiConfigChange.SetSelectedTab(tab))
                        }

                        +tab
                    }
                }
            }

            span {
                className = ClassName("label")
            }

            ButtonGroup {
                variant = ButtonGroupVariant.outlined

                Tooltip {
                    title = ReactNode("Add a new tab")
                    Button {
                        size = Size.small
                        type = ButtonType.button
                        onClick = { event ->
                            event.stopPropagation()
                            setPromptDialog(
                                Prompt("", "Add a new tab", "New tab name") { value ->
                                    props.setSelectedTab(value)
                                    backendMainClient.sendTabsChange(GuiConfigChange.AddNewTab(value))
                                    soundPlayer.playLetsDoSomeChemistry()
                                },
                            )
                        }
                        Add {
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("Remove current tab")
                    Button {
                        size = Size.small
                        type = ButtonType.button
                        disabled = props.selectedTab == GuiConfig.defaultTab
                        onClick = { event ->
                            event.stopPropagation()
                            setConfirmDialog(
                                Confirm(
                                    "Remove current tab",
                                    "Please confirm tab removal",
                                ) {
                                    props.removeTab(props.selectedTab)
                                    backendMainClient.sendTabsChange(GuiConfigChange.RemoveTab(props.selectedTab))
                                    soundPlayer.playWorkIsNotWolf()
                                },
                            )
                        }
                        Delete {
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("Duplicate current tab")
                    Button {
                        size = Size.small
                        type = ButtonType.button
                        onClick = { event ->
                            event.stopPropagation()
                            setPromptDialog(
                                Prompt("", "Duplicate tab", "New tab name") { tab ->
                                    props.duplicateTab(tab)
                                },
                            )
                        }
                        ContentCopy {
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("Create a widget on current tab")
                    Button {
                        size = Size.small
                        onClick = { event ->
                            event.stopPropagation()
                            createWidgetAnchor = event.target as? Element
                        }
                        Widgets {
                        }
                    }
                }
            }

            Typography {
                sx {
                    flexGrow = number(1.0)
                }
            }

            Menu {
                anchorEl = createWidgetAnchor.asDynamic()
                open = createWidgetAnchor != null
                onClose = {
                    createWidgetAnchor = null
                }
                MenuItem {
                    onClick = { _ ->
                        createWidgetAnchor = null
                        choosingWidgetType = true
                    }
                    +"Create basic"
                }
                MenuItem {
                    onClick = { _ ->
                        createWidgetAnchor = null
                        setPromptDialog(
                            Prompt(
                                "",
                                "Create widget from copied state",
                                "Copied widget",
                            ) { stateStr ->
                                props.createWidgetFromState(stateStr)
                            },
                        )
                    }
                    +"Create from state"
                }
                MenuItem {
                    onClick = { _ ->
                        createWidgetAnchor = null
                        choosingWidgetTemplate = true
                    }
                    +"Create from template"
                }
            }
            Dialog {
                open = choosingWidgetType
                disableRestoreFocus = false

                DialogTitle {
                    +"Choose widget type"
                }
                DialogContent {
                    autocomplete(
                        { null },
                        false,
                        null,
                        null,
                        true,
                        { value ->
                            value?.let { newValue ->
                                selectedWidget = newValue
                            }
                        },
                        null,
                        {
                            this.autoFocus = true
                        },
                        StringOption(selectedWidget),
                        WidgetType.entries.map {
                            StringOption(it.id)
                        }.toTypedArray(),
                        true,
                    )
                }
                DialogActions {
                    Button {
                        onClick = { _ ->
                            choosingWidgetType = false
                        }
                        +"Cancel"
                    }
                    Button {
                        type = ButtonType.submit
                        onClick = { _ ->
                            choosingWidgetType = false
                            props.createWidget(selectedWidget)
                        }
                        +"Create"
                    }
                }
            }
            Dialog {
                open = choosingWidgetTemplate

                DialogTitle {
                    +"Choose widget template"
                }
                DialogContent {
                    Select {
                        size = Size.small
                        value = selectedWidgetTemplate ?: ""
                        onMouseUp = { it.stopPropagation() }
                        onMouseDown = { it.stopPropagation() }
                        onChange = { event, _ ->
                            event.stopPropagation()
                            event.target.value.takeIf { it.isNotBlank() }?.let {
                                selectedWidgetTemplate = it
                            }
                        }

                        MenuItem {
                            key = ""
                            +""
                        }
                        props.widgetTemplates.forEach {
                            MenuItem {
                                key = it

                                value = it
                                +it
                            }
                        }
                    }
                    Button {
                        size = Size.small
                        type = ButtonType.button
                        disabled = selectedWidgetTemplate?.let {
                            !props.widgetTemplates.contains(it)
                        } ?: true
                        onClick = { event ->
                            event.stopPropagation()
                            setConfirmDialog(
                                Confirm(
                                    "Remove widget template",
                                    "Please confirm widget template removal",
                                ) {
                                    selectedWidgetTemplate?.let {
                                        props.removeWidgetTemplate(it)

                                        backendMainClient.sendTabsChange(
                                            GuiConfigChange.RemoveWidgetStateTemplate(it),
                                        )
                                    }
                                },
                            )
                        }
                        Delete {
                        }
                    }
                }
                DialogActions {
                    Button {
                        onClick = { _ ->
                            choosingWidgetTemplate = false
                        }
                        +"Cancel"
                    }
                    Button {
                        onClick = { _ ->
                            choosingWidgetTemplate = false
                            selectedWidgetTemplate?.let {
                                props.createWidgetFromTemplate(it)
                            }
                        }
                        +"Create"
                    }
                }
            }

            if (userSpaceListOrEmpty.isEmpty()) {
                span {
                    +"$currentUserSpace (loading...)"
                }
            } else {
                IconButton {
                    sx {
                        marginLeft = 20.px
                        padding = 0.px
                    }
                    size = Size.small
                    onClick = { event ->
                        userSpaceAnchor = event.target as? Element
                    }
                    Avatar {
                        +currentUserSpace
                    }
                }
            }

            Menu {
                open = userSpaceAnchor != null
                anchorEl = userSpaceAnchor.asDynamic()
                onClose = {
                    userSpaceAnchor = null
                }

                userSpaceListOrEmpty.forEach { userSpace ->
                    MenuItem {
                        key = userSpace
                        onClick = {
                            if (userSpace != currentUserSpace) {
                                putUserSpaceToCache(userSpace)
                                window.location.reload()
                                userSpaceAnchor = null
                            }
                        }

                        ListItemIcon {
                            Avatar {
                                +userSpace
                            }
                        }

                        ListItemText {
                            span {
                                className = ClassName("label")

                                +userSpace
                            }
                        }

                        ButtonGroup {
                            variant = ButtonGroupVariant.contained

                            Tooltip {
                                title = ReactNode("Remove user space")
                                Button {
                                    size = Size.small
                                    type = ButtonType.button
                                    disabled = userSpaceListOrEmpty.size <= 1
                                    onClick = { event ->
                                        userSpaceAnchor = null
                                        event.stopPropagation()
                                        if (userSpaceListOrEmpty.size > 1) {
                                            setConfirmDialog(
                                                Confirm(
                                                    "Remove user space",
                                                    "Are you sure?",
                                                ) {
                                                    scope.launch {
                                                        backendMainClient.sendRemoveUserSpace(userSpace)

                                                        if (userSpace == currentUserSpace) {
                                                            userSpaceListOrEmpty.firstOrNull { it != userSpace }
                                                                ?.let {
                                                                    delay(2000)
                                                                    putUserSpaceToCache(it)
                                                                    window.location.reload()
                                                                }
                                                        }
                                                    }
                                                },
                                            )
                                        } else {
                                            toaster.error("You cannot remove last user space!", 5000)
                                        }
                                    }
                                    Delete {
                                    }
                                }
                            }

                            Tooltip {
                                title = ReactNode("Duplicate user space")
                                Button {
                                    size = Size.small
                                    type = ButtonType.button
                                    onClick = { event ->
                                        userSpaceAnchor = null
                                        event.stopPropagation()
                                        setPromptDialog(
                                            Prompt(
                                                "",
                                                "Duplicate user space",
                                                "New user space name",
                                            ) { newUserSpace ->
                                                scope.launch {
                                                    backendMainClient.sendDuplicateUserSpace(
                                                        newUserSpace,
                                                        userSpace,
                                                    )
                                                }
                                            },
                                        )
                                    }
                                    ContentCopy {
                                    }
                                }
                            }
                        }
                    }
                }

                MenuItem {
                    onClick = {
                        userSpaceAnchor = null
                        setPromptDialog(
                            Prompt(
                                "",
                                "Add user space",
                                "New user space name",
                            ) { newUserSpace ->
                                scope.launch {
                                    backendMainClient.sendAddUserSpace(newUserSpace)

                                    delay(2000)
                                    putUserSpaceToCache(newUserSpace)
                                    window.location.reload()
                                }
                            },
                        )
                    }

                    ListItemIcon {
                        Add {
                        }
                    }

                    ListItemText {
                        span {
                            className = ClassName("label")

                            +"Add user space"
                        }
                    }
                }

                Divider {
                }

                if (allExchanges.isNotEmpty()) {
                    MenuItem {
                        ListItemIcon {
                            Input {
                            }
                        }

                        ListItemText {
                            span {
                                className = ClassName("label")
                                +"Suggest"
                            }
                        }

                        Select {
                            size = Size.small
                            value = currentPriorityExchange?.id ?: backendMainClient.config.masterExchange
                            onMouseDown = { it.stopPropagation() }
                            onMouseUp = { it.stopPropagation() }
                            onChange = { event, _ ->
                                event.stopPropagation()
                                val newExchange = allExchanges.find { ex ->
                                    ex.id == event.target.value
                                } ?: throw Error("unexpected")
                                props.setPrioritySuggestExchange(newExchange)

                                backendMainClient.sendTabsChange(
                                    GuiConfigChange.SetSuggestExchange(
                                        newExchange,
                                    ),
                                )
                            }

                            allExchanges.forEach { exchange ->
                                MenuItem {
                                    key = exchange.id
                                    value = exchange.id

                                    +exchange.id
                                }
                            }
                        }
                    }

                    MenuItem {
                        ListItemIcon {
                            CurrencyExchange {
                            }
                        }

                        ListItemText {
                            span {
                                className = ClassName("label")
                                +"Round"
                            }
                        }

                        Select {
                            size = Size.small
                            value = props.roundingScale.toString()
                            onMouseDown = { it.stopPropagation() }
                            onMouseUp = { it.stopPropagation() }
                            onChange = { event, _ ->
                                event.stopPropagation()
                                val newRoundScale = event.target.value.toInt()
                                props.setRoundingScale(newRoundScale)

                                backendMainClient.sendTabsChange(
                                    GuiConfigChange.SetPriceRoundingScale(
                                        newRoundScale,
                                    ),
                                )
                            }

                            (2..10).forEach { roundScale ->
                                MenuItem {
                                    key = roundScale.toString()
                                    value = roundScale.toString()

                                    +roundScale.toString()
                                }
                            }
                        }
                    }
                }

                Divider {
                }

                Tooltip {
                    placement = TooltipPlacement.left
                    title = ReactNode("Write a marker to log")
                    MenuItem {
                        onClick = { event ->
                            userSpaceAnchor = null
                            event.stopPropagation()
                            setPromptDialog(
                                Prompt(
                                    "",
                                    "Write a marker to log",
                                    "Marker name",
                                ) { message ->
                                    backendMainClient.sendLog(message)
                                },
                            )
                        }

                        ListItemIcon {
                            Analytics {
                            }
                        }

                        ListItemText {
                            +"Log"
                        }
                    }
                }

                Tooltip {
                    placement = TooltipPlacement.left
                    title = ReactNode("Restart backend")
                    MenuItem {
                        onClick = { event ->
                            userSpaceAnchor = null
                            event.stopPropagation()
                            scope.launch {
                                backendMainClient.sendRestart()
                                toaster.info(
                                    "server is restarting...",
                                    1900,
                                    ToastPosition.BOTTOM_RIGHT,
                                )
                            }
                        }

                        ListItemIcon {
                            RestartAlt {
                            }
                        }

                        ListItemText {
                            +"Restart"
                        }
                    }
                }

                Tooltip {
                    placement = TooltipPlacement.left
                    title = ReactNode("Dump state to json")
                    MenuItem {
                        onClick = { event ->
                            userSpaceAnchor = null
                            event.stopPropagation()
                            val url = makeHttpBackendUrl("/dump.json", emptyMap())
                            window.open(url.toString())
                        }

                        ListItemIcon {
                            FileDownload {
                            }
                        }

                        ListItemText {
                            +"Dump"
                        }
                    }
                }

                MenuItem {
                    ButtonGroup {
                        Tooltip {
                            placement = TooltipPlacement.bottom
                            title = ReactNode("Switch to info log level")
                            Button {
                                type = ButtonType.button
                                onClick = { event ->
                                    userSpaceAnchor = null
                                    event.stopPropagation()

                                    backendMainClient.sendSetLogLevel(LogLevel.INFO)
                                }

                                Info {
                                }
                            }
                        }
                        Tooltip {
                            placement = TooltipPlacement.bottom
                            title = ReactNode("Switch to debug log level")
                            Button {
                                type = ButtonType.button
                                onClick = { event ->
                                    userSpaceAnchor = null
                                    event.stopPropagation()

                                    backendMainClient.sendSetLogLevel(LogLevel.DEBUG)
                                }

                                BugReport {
                                }
                            }
                        }
                    }
                }

                Divider {
                }

                MenuItem {
                    ListItemText {
                        val info = backendMainClient.config.buildInfo
                        +"Build:${info.runId} PR:${info.pullRequest} CM:${info.commitSha}"
                    }
                }

                MenuItem {
                    ListItemText {
                        +"Count: ${props.activeSubscriptionSize}"
                    }
                }
            }
        }
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }

    confirm {
        this.dialog = confirmDialog
        this.setDialog = setConfirmDialog
    }
}
