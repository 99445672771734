package com.edvorg.trade.common.model.groupwatchlist

import com.edvorg.trade.common.model.TypedTicker
import kotlinx.serialization.Serializable

@Serializable
data class GroupWatchlistSnapshot(
    val mainTicker: Pair<TypedTicker, GroupTickerData>?,
    val data: List<Pair<TypedTicker, GroupTickerData>>,
)
