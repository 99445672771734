package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class Order(
    val id: String,
    val orderType: OrderType,
    val ticker: TypedTicker,
    val operationType: OperationType,
    val requestedLots: Double,
    val filledLots: Double,
    val price: Double?,
    val triggerPrice: Double?,
    val status: OrderStatus,
    val hidden: Boolean,
)
