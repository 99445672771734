package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("MarketDataManagementRequest")
sealed class MarketDataManagementRequest {
    @Serializable
    @SerialName("RestartMarketDataConnector")
    data class RestartMarketDataConnector(
        val marketDataId: MarketDataId,
        val mode: String,
    ) : MarketDataManagementRequest()

    @Serializable
    @SerialName("RestartServiceMarketDataConnector")
    data class RestartServiceMarketDataConnector(
        val marketDataId: MarketDataId,
    ) : MarketDataManagementRequest()

    @Serializable
    @SerialName("DisconnectMarketData")
    data class DisconnectMarketData(
        val marketDataId: MarketDataId,
    ) : MarketDataManagementRequest()

    @Serializable
    @SerialName("ConnectMarketData")
    data class ConnectMarketData(
        val marketDataId: MarketDataId,
    ) : MarketDataManagementRequest()

    @Serializable
    @SerialName("CreateMarketDataClient")
    data class CreateMarketDataClient(
        val marketDataId: MarketDataId,
        val config: MarketDataClientConfig,
    ) : MarketDataManagementRequest()

    @Serializable
    @SerialName("RemoveMarketDataClient")
    data class RemoveMarketDataClient(
        val marketDataId: MarketDataId,
    ) : MarketDataManagementRequest()

    @Serializable
    @SerialName("UpdateMarketDataClient")
    data class UpdateMarketDataClient(
        val marketDataId: MarketDataId,
        val config: MarketDataClientConfig,
    ) : MarketDataManagementRequest()

    @Serializable
    @SerialName("DuplicateMarketDataConnector")
    data class DuplicateMarketDataConnector(
        val marketDataId: MarketDataId,
        val newMarketDataId: MarketDataId,
    ) : MarketDataManagementRequest()
}
