package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.Exchange
import mui.material.FormControlLabel
import mui.material.LabelPlacement
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import react.FC
import react.Props
import react.create
import react.dom.html.ReactHTML.span
import react.useEffectOnce
import react.useState
import web.cssom.ClassName

external interface LocalBrokerSelectProps : Props {
    var exchange: Exchange
}

val localBrokerSelect = FC<LocalBrokerSelectProps> { props ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val tradingClient = getContext<CommonContext>().tradingClient
    val connectionManager = connectorServersManager.getServerConnection("local")?.client?.connectionManager

    var localBroker: BrokerId? by useState(null)
    var globalBroker: BrokerId? by useState(null)
    var brokerOptions: Set<BrokerId> by useState(emptySet())

    useEffectOnce {
        val brokerClientsHandle = connectionManager?.subscribeToBrokerClientsUpdate { update ->
            brokerOptions = update.brokerClientsByExchange[props.exchange] ?: emptySet()
            globalBroker = update.priorityBrokers[props.exchange]
                ?: update.brokerClientsByExchange[props.exchange]?.firstOrNull()
        }

        val guiConfigHandle = tradingClient.subscribeToPriorityBrokersUpdate {
            localBroker = it[props.exchange]
        }

        cleanup {
            brokerClientsHandle?.unsubscribe()
            guiConfigHandle.unsubscribe()
        }
    }

    val defaultOption = "DEFAULT(${globalBroker?.id})"
    FormControlLabel {
        labelPlacement = LabelPlacement.start
        label = span.create {
            className = ClassName("label")
            +"${props.exchange.id}:"
        }
        control = Select.create {
            size = Size.small
            value = localBroker?.id ?: defaultOption
            onMouseUp = { it.stopPropagation() }
            onMouseDown = { it.stopPropagation() }
            onChange = { event, _ ->
                event.stopPropagation()
                event.target.value.let {
                    if (it.isNotEmpty()) {
                        val brokerId = BrokerId(it)
                        if (it == defaultOption) {
                            localBroker = null
                            tradingClient.clearPriorityBroker(props.exchange)
                        } else {
                            tradingClient.setPriorityBroker(props.exchange, brokerId)
                        }
                    }
                }
            }

            (brokerOptions.map { it.id } + defaultOption).forEach {
                MenuItem {
                    key = it
                    value = it
                    +it
                }
            }
        }
    }
}
