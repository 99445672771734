package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ConnectionManagementRequest")
sealed class ConnectionManagementRequest {
    @Serializable
    @SerialName("UpdateBrokerConnectors")
    data class UpdateBrokerConnectors(
        val request: BrokerManagementRequest,
    ) : ConnectionManagementRequest()

    @Serializable
    @SerialName("UpdateMarketDataConnectors")
    data class UpdateMarketDataConnectors(
        val request: MarketDataManagementRequest,
    ) : ConnectionManagementRequest()

    @Serializable
    @SerialName("UpdateScannerConnectors")
    data class UpdateScannerConnectors(
        val request: ScannerManagementRequest,
    ) : ConnectionManagementRequest()

    @Serializable
    @SerialName("UpdateExecutorConnectors")
    data class UpdateExecutorConnectors(
        val request: ExecutorManagementRequest,
    ) : ConnectionManagementRequest()

    @Serializable
    @SerialName("UpdateSamuraiConnectors")
    data class UpdateSamuraiConnectors(
        val request: SamuraiManagementRequest,
    ) : ConnectionManagementRequest()
}
