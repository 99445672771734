package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(ExchangeAndTickerSerializer::class)
data class ExchangeAndTicker(val exchange: Exchange, val ticker: Ticker) {
    companion object {
        fun parse(value: String): ExchangeAndTicker? {
            val components = value.split(":")
            if (components.size < 2) {
                return null
            }

            val (exchangeId, ticker) = components

            val exchange = Exchange.fromId(exchangeId) ?: return null

            return ExchangeAndTicker(exchange, Ticker(ticker))
        }

        fun parseWithDefaultExchange(value: String, defaultExchange: Exchange): ExchangeAndTicker {
            val components = value.split(":")
            if (components.size < 2) {
                return ExchangeAndTicker(defaultExchange, Ticker(value))
            }

            val (exchangeId, ticker) = components

            val exchange = Exchange.fromId(exchangeId)
                ?: return ExchangeAndTicker(defaultExchange, Ticker(value))

            return ExchangeAndTicker(exchange, Ticker(ticker))
        }
    }

    override fun toString(): String {
        return "${exchange.id}:${ticker.id}"
    }
}

object ExchangeAndTickerSerializer : KSerializer<ExchangeAndTicker> {
    private const val delimiter = ":"

    override fun deserialize(decoder: Decoder): ExchangeAndTicker {
        val components = decoder.decodeString().split(delimiter)
        if (components.size < 2) {
            throw Error("ticker must be in format EXCHANGE:TICKER")
        }
        val (exchangeId, ticker) = components

        val exchange = Exchange.fromId(exchangeId)
            ?: throw Error("exchange $exchangeId is not supported")

        return ExchangeAndTicker(exchange, Ticker(ticker))
    }

    override fun serialize(encoder: Encoder, value: ExchangeAndTicker) {
        encoder.encodeString(value.exchange.id + delimiter + value.ticker.id)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "ExchangeAndTicker",
        PrimitiveKind.STRING,
    )
}
