package com.edvorg.trade.common.frontend.services

private var context: Any? = null

fun <T : Any> setContext(newContext: T) {
    context = newContext
}

fun <T> getContext(): T {
    val c = context ?: throw Error("context not found")
    return c.unsafeCast<T>()
}
