package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.PriceTick
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
sealed class Indicator {
    abstract fun copyWithNewBarInterval(barInterval: BarSize): Indicator

    @Serializable
    @SerialName("MA")
    data class MA(
        val windowDataPoints: Int,
        val barInterval: BarSize,
        val count: Int,
    ) : Indicator() {
        override fun copyWithNewBarInterval(barInterval: BarSize): Indicator {
            return copy(barInterval = barInterval)
        }
    }

    @Serializable
    @SerialName("SD")
    data class SD(
        val windowDataPoints: Int,
        val barInterval: BarSize,
    ) : Indicator() {
        override fun copyWithNewBarInterval(barInterval: BarSize): Indicator {
            return copy(barInterval = barInterval)
        }
    }

    @Serializable
    @SerialName("LookBack")
    data class LookBack(
        val tick: PriceTick,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val lookBack: Duration,
    ) : Indicator() {
        override fun copyWithNewBarInterval(barInterval: BarSize): Indicator {
            TODO("Not yet implemented")
        }
    }
}
