package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class WidgetState(
    val groupId: Group,
    val geometry: WidgetGeometry,
    val typedSettings: WidgetSettings,
    val groupId2: Group,
)
