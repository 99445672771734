package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class TradingResponse {
    @Serializable
    @SerialName("PortfolioUpdate")
    data class PositionsUpdate(
        val subscriptionIds: Set<Long>,
        val positions: Map<BrokerId, Map<TypedTicker, Pair<Currency, PortfolioPosition>>>,
    ) : TradingResponse()

    @Serializable
    @SerialName("OrdersUpdate")
    data class OrdersUpdate(
        val subscriptionIds: Set<Long>,
        val orders: Map<BrokerId, Map<String, Order>>,
    ) : TradingResponse()

    @Serializable
    @SerialName("AvailableOrderLotsUpdate")
    data class AvailableOrderLotsUpdate(
        val subscriptionIds: Set<Long>,
        val availableOrderLots: AvailableOrderLots,
    ) : TradingResponse()

    @Serializable
    @SerialName("PriceStatUpdate")
    data class PriceStatUpdate(
        val subscriptionIds: Set<Long>,
        val priceStat: Pair<Double, Currency>,
    ) : TradingResponse()

    @Serializable
    @SerialName("VolumeStatUpdate")
    data class VolumeStatUpdate(
        val subscriptionIds: Set<Long>,
        val volumeStat: Double,
    ) : TradingResponse()

    @Serializable
    data class VWAPUpdate(
        val subscriptionIds: Set<Long>,
        val vwap: Double?,
    ) : TradingResponse()

    @Serializable
    data class VWEMAUpdate(
        val subscriptionIds: Set<Long>,
        val vwema: Double?,
    ) : TradingResponse()

    @Serializable
    @SerialName("OrderPlaced")
    data class OrderPlaced(
        val order: Order,
    ) : TradingResponse()
}
