package com.edvorg.trade.common.charts

import kotlinx.browser.window

@JsName("CandlestickStyleOptions")
data class CandlestickStyleOptions(
    @JsName("upColor")
    val upColor: String,
    @JsName("borderUpColor")
    val borderUpColor: String,
    @JsName("wickUpColor")
    val wickUpColor: String,
    @JsName("downColor")
    val downColor: String,
    @JsName("borderDownColor")
    val borderDownColor: String,
    @JsName("wickDownColor")
    val wickDownColor: String,
    @JsName("title")
    val title: String,
    @JsName("priceScaleId")
    val priceScaleId: String = "right",
)

@JsName("PriceFormat")
data class PriceFormat(
    @JsName("type")
    val type: String,
    @JsName("precision")
    val precision: Double,
    @JsName("minMove")
    val minMove: Double,
)

@JsName("LineStyleOptions")
data class LineStyleOptions(
    @JsName("color")
    val color: String,
    @JsName("lineWidth")
    val lineWidth: Double,
    @JsName("title")
    val title: String,
    @JsName("priceScaleId")
    val priceScaleId: String = "right",
    @JsName("priceFormat")
    val priceFormat: PriceFormat = PriceFormat(
        "price",
        2.0,
        0.01,
    ),
)

@JsName("AreaStyleOptions")
data class AreaStyleOptions(
    @JsName("topColor")
    val topColor: String,
    @JsName("bottomColor")
    val bottomColor: String,
    @JsName("lineColor")
    val lineColor: String,
    @JsName("lineWidth")
    val lineWidth: Double,
    @JsName("title")
    val title: String,
    @JsName("priceScaleId")
    val priceScaleId: String = "right",
)

@JsName("CandlestickData")
data class CandlestickData(
    @JsName("time")
    val time: Double,
    @JsName("open")
    val open: Double,
    @JsName("high")
    val high: Double,
    @JsName("low")
    val low: Double,
    @JsName("close")
    val close: Double,
)

@JsName("LineData")
data class LineData(
    @JsName("time")
    val time: Double,
    @JsName("value")
    val value: Double,
)

@JsName("CandleSeries")
external interface CandleSeries {
    fun setData(data: Array<CandlestickData>)
}

@JsName("LineSeries")
external interface LineSeries {
    fun setData(data: Array<LineData>)
    fun update(bar: LineData)
}

@JsName("AreaSeries")
external interface AreaSeries {
    fun setData(data: Array<LineData>)
    fun update(bar: LineData)
}

@JsName("Chart")
external interface Chart {
    fun resize(width: Double, height: Double)
    fun addCandlestickSeries(options: CandlestickStyleOptions): CandleSeries
    fun addLineSeries(options: LineStyleOptions): LineSeries
    fun addAreaSeries(areaStyleOptions: AreaStyleOptions): AreaSeries
    fun removeSeries(series: CandleSeries)
    fun removeSeries(series: LineSeries)
    fun options(): ChartOptions
    fun applyOptions(options: ChartOptions)
}

@JsName("TimeScaleOptions")
data class TimeScaleOptions(
    @JsName("timeVisible")
    val timeVisible: Boolean,
)

@JsName("PriceScaleOptions")
data class PriceScaleOptions(
    @JsName("visible")
    val visible: Boolean,
)

@JsName("ChartOptions")
data class ChartOptions(
    @JsName("autoSize")
    val autoSize: Boolean,
    @JsName("width")
    val width: Double,
    @JsName("height")
    val height: Double,
    @JsName("timeScale")
    val timeScale: TimeScaleOptions,
    @JsName("leftPriceScale")
    val leftPriceScale: PriceScaleOptions,
    @JsName("rightPriceScale")
    val rightPriceScale: PriceScaleOptions,
)

@JsName("LightweightCharts")
external interface LightweightCharts {
    fun createChart(chartId: String, options: ChartOptions): Chart
}

@Suppress("UnsafeCastFromDynamic")
fun lightWeightCharts(): LightweightCharts {
    return window.asDynamic().LightweightCharts
}
