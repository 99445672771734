package com.edvorg.trade.backend.frontend.views

import com.edvorg.trade.backend.frontend.views.widgets.chartWidget
import com.edvorg.trade.backend.frontend.views.widgets.chartscript.chartScriptWidget
import com.edvorg.trade.backend.frontend.views.widgets.connectors.connectorsWidget
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.DragOperation
import com.edvorg.trade.common.frontend.services.OnBringToFrontHandler
import com.edvorg.trade.common.frontend.services.OnCloseHandler
import com.edvorg.trade.common.frontend.services.OnCopyHandler
import com.edvorg.trade.common.frontend.services.OnDragStartHandler
import com.edvorg.trade.common.frontend.services.OnDuplicateHandler
import com.edvorg.trade.common.frontend.services.OnDuplicateWidgetStateHandler
import com.edvorg.trade.common.frontend.services.OnSaveTemplateHandler
import com.edvorg.trade.common.frontend.services.OnSaveWidgetStateTemplateHandler
import com.edvorg.trade.common.frontend.services.OnUpdateGroupWatchedTickersHandler
import com.edvorg.trade.common.frontend.services.OnUpdateSettingsHandler
import com.edvorg.trade.common.frontend.services.OnUpdateWidgetStateSettingsHandler
import com.edvorg.trade.common.frontend.services.SetGroupIdHandler
import com.edvorg.trade.common.frontend.services.SetGroupPriceHandler
import com.edvorg.trade.common.frontend.services.SetGroupTickerHandler
import com.edvorg.trade.common.frontend.services.SetGroupVolumeHandler
import com.edvorg.trade.common.frontend.services.SetPriceHandler
import com.edvorg.trade.common.frontend.services.SetVolumeHandler
import com.edvorg.trade.common.frontend.services.SetWidgetGroupIdHandler
import com.edvorg.trade.common.frontend.services.UpdateWidgetGeometryHandler
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.frontend.views.WidgetProps
import com.edvorg.trade.common.frontend.views.fcWithScope
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.WidgetGeometry
import com.edvorg.trade.common.model.WidgetGeometry.Companion.makeDefaultGeometry
import com.edvorg.trade.common.model.WidgetSettings
import com.edvorg.trade.common.model.WidgetState
import com.edvorg.trade.common.serialization.Defaults.json
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import react.useState
import web.cssom.ClassName
import kotlin.time.Duration.Companion.milliseconds

external interface FrameProps : PropsWithChildren {
    var onUpdateWidgetStateSettings: OnUpdateWidgetStateSettingsHandler
    var onUpdateGroupWatchedTickers: OnUpdateGroupWatchedTickersHandler
    var onSaveWidgetStateTemplate: OnSaveWidgetStateTemplateHandler
    var onDuplicateWidgetState: OnDuplicateWidgetStateHandler
    var setWidgetGroupId: SetWidgetGroupIdHandler
    var setWidgetGroupId2: SetWidgetGroupIdHandler
    var setGroupVolume: SetGroupVolumeHandler
    var setGroupPrice: SetGroupPriceHandler
    var setTicker: SetGroupTickerHandler
    var updateWidgetGeometry: UpdateWidgetGeometryHandler
    var onRemoveWidget: (String) -> Unit
    var tab: LocalTabState
    var groups: Map<Group, LocalGroupState>
    var watchedTickers: Map<Group, Set<Ticker>>
    var roundingScale: Int
}

data class CommonWidgetState(
    val selectedTicker: TypedTicker?,
    val selectedTicker2: TypedTicker?,
    val setTicker: SetGroupTickerHandler,
    var volumeStr: String,
    var priceStr: String,
    var setVolume: SetVolumeHandler,
    var setPrice: SetPriceHandler,
    val groupId: Group,
    val groupId2: Group,
    val id: String,
    val setGroupId: SetGroupIdHandler,
    val setGroupId2: SetGroupIdHandler,
)

data class WidgetDragOperation(val widgetId: String, val dragOperation: DragOperation)

private const val snapToGrid = 20.0

private fun grid(value: Double): Double {
    val gridPreAdjustedValue = value + snapToGrid / 2.0
    return gridPreAdjustedValue - gridPreAdjustedValue % snapToGrid
}

val frame = fcWithScope<FrameProps> { props, scope ->
    var dragOperation by useState<WidgetDragOperation?>(null)
    var dragStartLeft by useState(0.0)
    var dragStartTop by useState(0.0)
    var draggedWidgetOriginalGeometry by useState(makeDefaultGeometry(0))

    fun <T : WidgetSettings> WidgetProps<T>.setWidgetState(
        widgetId: String,
        widgetState: WidgetState,
        commonWidgetState: CommonWidgetState,
        settings: T,
    ) {
        val toaster = getContext<CommonContext>().toaster

        key = widgetId

        id = widgetId
        left = widgetState.geometry.left
        top = widgetState.geometry.top
        width = widgetState.geometry.width
        height = widgetState.geometry.height
        this.settings = settings
        isResizing = dragOperation?.widgetId == widgetId
        roundingScale = props.roundingScale

        setTicker = commonWidgetState.setTicker
        setGroupId = commonWidgetState.setGroupId
        setGroupId2 = commonWidgetState.setGroupId2
        selectedTicker = commonWidgetState.selectedTicker
        selectedTicker2 = commonWidgetState.selectedTicker2
        groupId = commonWidgetState.groupId
        groupId2 = commonWidgetState.groupId2

        onDragStart = OnDragStartHandler { operation, newLeft, newTop ->
            dragOperation = WidgetDragOperation(widgetId, operation)
            dragStartLeft = newLeft
            dragStartTop = newTop
            draggedWidgetOriginalGeometry = widgetState.geometry
        }
        onBringToFront = OnBringToFrontHandler {
            scope.launch {
                delay(100.milliseconds)
                val maxIndex = props.tab.widgets.map {
                    it.value.geometry.index
                }.maxOrNull() ?: -1
                props.tab.widgets[widgetId]?.geometry?.copy(index = maxIndex + 1)?.let { newGeometry ->
                    props.updateWidgetGeometry.invoke(widgetId, newGeometry)
                }
            }
        }
        onClose = OnCloseHandler {
            props.onRemoveWidget(widgetId)
        }
        onSaveTemplate = OnSaveTemplateHandler { name ->
            props.onSaveWidgetStateTemplate.invoke(name, widgetState)
        }
        onDuplicate = OnDuplicateHandler {
            props.onDuplicateWidgetState.invoke(widgetState)
        }
        onCopy = OnCopyHandler {
            val string = json.encodeToString(
                WidgetState.serializer(),
                widgetState,
            )
            scope.launch {
                window.navigator.clipboard.writeText(string).await()

                delay(200)

                toaster.info(
                    "widget copied in clipboard",
                    1900,
                    ToastPosition.BOTTOM_RIGHT,
                )
            }
        }
        onUpdateSettings = OnUpdateSettingsHandler { updater ->
            props.onUpdateWidgetStateSettings.invoke(widgetId, updater)
        }
    }

    div {
        className = ClassName("frame")
        onMouseMove = { event ->
            dragOperation?.let { operation ->
                val draggedWidget = props.tab.widgets[operation.widgetId]
                if (draggedWidget != null) {
                    val deltaLeft = event.asDynamic().clientX as Double - dragStartLeft
                    val deltaTop = event.asDynamic().clientY as Double - dragStartTop
                    val maxIndex = props.tab.widgets.map {
                        it.value.geometry.index
                    }.maxOrNull() ?: -1
                    val newGeometry = when (operation.dragOperation) {
                        DragOperation.MoveWidget -> {
                            WidgetGeometry(
                                draggedWidgetOriginalGeometry.left + deltaLeft,
                                draggedWidgetOriginalGeometry.top + deltaTop,
                                draggedWidget.geometry.width,
                                draggedWidget.geometry.height,
                                maxIndex + 1,
                            )
                        }
                        DragOperation.FreeResizeWidget -> {
                            WidgetGeometry(
                                draggedWidget.geometry.left,
                                draggedWidget.geometry.top,
                                draggedWidgetOriginalGeometry.width + deltaLeft,
                                draggedWidgetOriginalGeometry.height + deltaTop,
                                maxIndex + 1,
                            )
                        }
                    }
                    props.updateWidgetGeometry.invoke(operation.widgetId, newGeometry)
                }
            }
        }
        onMouseUp = { _ ->
            dragOperation?.let { operation ->
                scope.launch {
                    delay(100)
                    props.tab.widgets[operation.widgetId]?.geometry?.let { geometry ->
                        props.updateWidgetGeometry.invoke(
                            operation.widgetId,
                            geometry.copy(
                                left = grid(geometry.left),
                                top = grid(geometry.top),
                                width = grid(geometry.width),
                                height = grid(geometry.height),
                            ),
                        )
                    }
                }
            }
            dragOperation = null
            dragStartLeft = 0.0
            dragStartTop = 0.0
            draggedWidgetOriginalGeometry = makeDefaultGeometry(0)
        }

        for ((widgetId, widget) in props.tab.widgets.entries.sortedBy { it.value.geometry.index }) {
            val widgetCommonState = CommonWidgetState(
                props.groups[widget.groupId]?.state?.selectedTicker,
                props.groups[widget.groupId2]?.state?.selectedTicker,
                props.setTicker,
                props.groups[widget.groupId]?.volumeStr ?: "",
                props.groups[widget.groupId]?.priceStr ?: "",
                { volumeStr ->
                    props.setGroupVolume.invoke(widget.groupId, volumeStr)
                },
                { priceStr ->
                    props.setGroupPrice.invoke(widget.groupId, priceStr)
                },
                widget.groupId,
                widget.groupId2,
                widgetId,
                { groupId ->
                    props.setWidgetGroupId.invoke(widgetId, groupId)
                },
                { groupId ->
                    props.setWidgetGroupId2.invoke(widgetId, groupId)
                },
            )

            when (val settings = widget.typedSettings) {
                is WidgetSettings.Connectors -> {
                    connectorsWidget {
                        setWidgetState(widgetId, widget, widgetCommonState, settings)
                    }
                }

                is WidgetSettings.Chart -> {
                    chartWidget {
                        setWidgetState(widgetId, widget, widgetCommonState, settings)
                    }
                }

                is WidgetSettings.ChartScript -> {
                    chartScriptWidget {
                        setWidgetState(widgetId, widget, widgetCommonState, settings)
                    }
                }
            }
        }
    }
}
