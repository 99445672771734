package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class TickerExtraInfo(
    // dividend amount paid if we have div-gap now
    val dividend: Double?,
    val daysSinceEarnings: Int?,
)
