@file:UseSerializers(UrlSerializer::class)

package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.PollConfig
import io.ktor.http.Url
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.time.Duration
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.seconds

@Serializable
sealed class MarketDataClientConfig {
    abstract val createdAt: Long
    abstract val autoStart: Boolean
    abstract val level1Exchanges: Set<Exchange>
    abstract val statExchanges: Set<Exchange>
    abstract val tradeExchanges: Set<Exchange>
    abstract val candleExchanges: Set<Exchange>

    abstract fun withCreatedAt(createdAt: Long): MarketDataClientConfig
    abstract fun displayName(): String

    @Serializable
    @SerialName("Relay")
    data class Relay(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val brokerId: BrokerId,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Relay {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Relay"
        }
    }

    @Serializable
    @SerialName("IQFeed")
    data class IQFeed(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val protocol: String,
        val level1Port: Int,
        val historyPort: Int,
        val maxHistoricalTrades: Int,
        val sockets: Int,
        val socketBuffer: Int,
        val frameConsumers: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): IQFeed {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "IQFeed"
        }
    }

    @Serializable
    @SerialName("OrderBooksSimulation")
    data class OrderBooksSimulation(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val brokerId: BrokerId,
        val tickers: Map<TypedTicker, TickerPricePrefill> = mapOf(
            Pair(
                TypedTicker(
                    TickerType.STOCK,
                    Exchange.SPBEX,
                    Ticker("TSLA"),
                ),
                TickerPricePrefill.MidPointPrefill(100.0, 20.0),
            ),
        ),
        @Serializable(with = HumanReadableDurationSerializer::class)
        val simulateLevel1Interval: Duration?,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val simulateTradesInterval: Duration?,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): OrderBooksSimulation {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "OrderBooksSimulation"
        }
    }

    @Serializable
    @SerialName("InteractiveBrokers")
    data class InteractiveBrokers(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val brokerId: BrokerId,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): InteractiveBrokers {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "InteractiveBrokers"
        }
    }

    @Serializable
    @SerialName("Alor")
    data class Alor(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val sandbox: Boolean,
        val token: String,
        val orderBookSockets: Int,
        val statSockets: Int,
        val tradeSockets: Int = 1,
        val candleSockets: Int,
        val socketBuffer: Int,
        val chartsCacheSize: Long,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val minuteChartTimeFrame: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val fiveMinutesChartTimeFrame: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val hourChartTimeFrame: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val dayChartTimeFrame: Duration,
        val countHistoricalCandles: Int,
        val orderBookDepth: Int,
        val securitiesPollConfig: PollConfig,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val socketStartInterval: Duration = 500.milliseconds,
        val caching: Boolean = true,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Alor {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Alor"
        }
    }

    @Serializable
    @SerialName("Remote")
    data class Remote(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val connectionPingTimeout: Duration,
        val endpoint: Url,
        val vwapMultiplier: Double,
        val full: Boolean,
        val disablePinger: Boolean = false,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Remote {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Remote"
        }
    }

    @Serializable
    @SerialName("Sk")
    data class Sk(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val orderBookSockets: Int,
        val frameConsumers: Int,
        val socketBuffer: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Sk {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Sk"
        }
    }

    @Serializable
    @SerialName("Bitpanda")
    data class Bitpanda(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val socketRooms: Set<String>,
        val stockTypes: Set<String>,
        val pollConfig: PollConfig,
        val frameConsumers: Int,
        val socketBuffer: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Bitpanda {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Bitpanda"
        }
    }

    @Serializable
    @SerialName("TradingView")
    data class TradingView(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val token: String,
        val sockets: Int,
        val frameConsumers: Int,
        val socketBuffer: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): TradingView {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "TradingView"
        }
    }

    @Serializable
    @SerialName("TVPython")
    data class TVPython(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val candlesInterval: String,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): MarketDataClientConfig {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "TVPython"
        }
    }

    @Serializable
    @SerialName("Nasdaq")
    data class Nasdaq(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Nasdaq {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Nasdaq"
        }
    }

    @Serializable
    @SerialName("Exante")
    data class Exante(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val applicationId: String,
        val accessKey: String,
        val sandbox: Boolean,
        val tradeConnections: Int,
        val connectionBuffer: Int,
        val frameConsumers: Int,
        val level2Mode: ExanteLevel2Mode,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): MarketDataClientConfig {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Exante"
        }
    }

    @Serializable
    @SerialName("Alpaca")
    data class Alpaca(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val keyId: String,
        val secretKey: String,
        val dataEndPoint: AlpacaEndPoint,
        val apiType: AlpacaDataApiType,
        val connections: Int,
        val connectionBuffer: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val shiftTrades: Duration,
        val tradeGroupRequests: Boolean,
        val withoutLive: Boolean,
        val postCloseMethod: PostCloseMethod,
        val wildcardSubscription: Boolean = false,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val orderBookUpdatePeriod: Duration = 0.seconds,
        val caching: Boolean = true,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Alpaca {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Alpaca"
        }
    }

    @Serializable
    @SerialName("TelegramNews")
    data class TelegramNews(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val phoneNumber: String,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): TelegramNews {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "TelegramNews"
        }
    }

    @Serializable
    @SerialName("StreetInsider")
    data class StreetInsider(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val newsPollConfig: PollConfig,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): StreetInsider {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "StreetInsider"
        }
    }

    @Serializable
    @SerialName("Okx")
    data class Okx(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Okx {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Okx"
        }
    }

    @Serializable
    @SerialName("Mexc")
    data class Mexc(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val requestDelay: Duration,
        val sockets: Int,
        val frameConsumers: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val pingInterval: Duration = 5.seconds,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Mexc {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Mexc"
        }
    }

    @Serializable
    @SerialName("Gateio")
    data class GateIO(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val sockets: Int,
        val frameConsumers: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): GateIO {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "GateIO"
        }
    }

    @Serializable
    @SerialName("Binance")
    data class Binance(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val sockets: Int,
        val frameConsumers: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Binance {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Binance"
        }
    }

    @Serializable
    @SerialName("CSV")
    data class Csv(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val path: String,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Csv {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Csv"
        }
    }

    @Serializable
    @SerialName("RobinHood")
    data class RobinHood(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val username: String,
        val password: String,
        val orderBooksPollConfig: PollConfig,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val minOrderBookPassedTime: Duration = 2.hours,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): RobinHood {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "RobinHood"
        }
    }

    @Serializable
    @SerialName("Freedom")
    data class Freedom(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val username: String,
        val password: String,
        val userId: String,
        val frameConsumers: Int,
        val tickersOnSocket: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Freedom {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Freedom"
        }
    }

    @Serializable
    @SerialName("DxFeed")
    data class DxFeed(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val dxFeed: String,
        val sockets: Int,
        val frameConsumers: Int,
        val useLastQuote: Boolean,
        val tradeSockets: Int = 1,
        val postFix: String = ":OCEA",
        @Serializable(with = HumanReadableDurationSerializer::class)
        val tradesTimeframe: Duration = 1.hours,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): DxFeed {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "DxFeed"
        }
    }

    @Serializable
    @SerialName("Iress")
    data class Iress(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val username: String,
        val password: String,
        val server: String,
        val port: Int,
        val requestSenders: Int,
        val mblLayer: Int,
        val exchangeRoutes: Map<Exchange, String> = mapOf(
            Exchange.OVERNIGHT to "OCEA",
            Exchange.TGATE to "TGAT",
            Exchange.TGATE_SK to "TGAT",
        ),
        val quotationFullMask: Boolean = true,
        val includeEmptyVolume: Boolean = true,
        val enableTrace: Boolean = false,
        val isinExchanges: Set<Exchange> = setOf(Exchange.TGATE),
        val subscribeToLevel1Exchanges: Set<Exchange> = setOf(Exchange.TGATE),
        val postfixExchanges: Map<Exchange, String> = mapOf(),
        val subscribeAllTrades: Boolean = false,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val connectionTimeout: Duration = 10.seconds,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): Iress {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Iress"
        }
    }

    @Serializable
    @SerialName("MexcFut")
    data class MexcFut(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val sockets: Int,
        val frameConsumers: Int,
        val depth: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val pingInterval: Duration = 5.seconds,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): MexcFut {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "MexcFut"
        }
    }

    @Serializable
    @SerialName("BinanceFut")
    data class BinanceFut(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val market: BinanceFutureMarket,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): BinanceFut {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "BinanceFut"
        }
    }

    @Serializable
    @SerialName("MooMoo")
    data class MooMoo(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val level1Exchanges: Set<Exchange>,
        override val statExchanges: Set<Exchange>,
        override val tradeExchanges: Set<Exchange>,
        override val candleExchanges: Set<Exchange>,
        val pollConfig: PollConfig,
        val pageSize: Int,
    ) : MarketDataClientConfig() {
        override fun withCreatedAt(createdAt: Long): MooMoo {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "MooMoo"
        }
    }
}
