package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.BridgeConfig
import com.edvorg.trade.common.model.config.BridgeMode
import com.edvorg.trade.common.model.config.OptionalDoubleRange
import com.edvorg.trade.common.utils.calcDifferencePercent
import com.edvorg.trade.common.utils.format
import com.edvorg.trade.common.utils.setScale
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import mu.KotlinLogging
import kotlin.math.abs
import kotlin.math.floor
import kotlin.math.max
import kotlin.math.min
import kotlin.math.truncate

@Serializable
sealed class ScannerEntry {
    abstract val operation1: OperationType
    abstract val tickerPair: TickerPair
    abstract val formula1: PriceFormulaComponets
    abstract val bid1: OrderBookEntryPair?
    abstract val ask1: OrderBookEntryPair?
    abstract val formula2: PriceFormulaComponets
    abstract val bid2: OrderBookEntryPair?
    abstract val ask2: OrderBookEntryPair?
    abstract val time: Long
    abstract val orderBookTime1: Long?
    abstract val orderBookTime2: Long?
    abstract val priceDiffPercent: Double
    abstract val futurePriceDiffPercent: Double?

    companion object {
        private val logger = KotlinLogging.logger {}

        fun fitVolume(
            tickerPair: TickerPair,
            openPrice: Double,
            openVolume: Double,
            closePrice: Double,
            closeVolume: Double,
            commissionPercent: Double,
            openFraction: Double,
            alreadyHave: Double,
            skipBudgetChecks: Boolean,
            skipAvailableToCloseThroughBridgeCheck: Boolean,
            maxBudgetUsd: Double,
            minProfitUsd: Double,
            maxProfitUsd: Double,
            openVolumeCapPercent: Double?,
        ): Double? {
            if (skipBudgetChecks) {
                if (skipAvailableToCloseThroughBridgeCheck) {
                    return openVolume
                }
                return min(openVolume, closeVolume)
            }
            val volume = calcRefillVolume(
                maxBudgetUsd,
                alreadyHave,
                openVolume,
                openPrice,
                if (skipAvailableToCloseThroughBridgeCheck) Double.MAX_VALUE else closeVolume,
                openVolumeCapPercent,
            ).takeIf {
                0 < it
            }?.let {
                (it * openFraction)
            }

            if (volume == null) {
                logger.debug { "$tickerPair skipped: no room for purchase" }
                return null
            }

            val profit = abs(openPrice - closePrice) * volume

            val commission = (closePrice + openPrice) * (volume * commissionPercent / 100.0)

            val finalProfit = profit - commission

            if (finalProfit < minProfitUsd) {
                logger.debug {
                    "$tickerPair skipped: profit too low ${finalProfit.format(3)} (expected min $minProfitUsd)"
                }
                return null
            }
            if (maxProfitUsd > 0.0 && finalProfit > maxProfitUsd) {
                logger.debug {
                    "$tickerPair skipped: profit too high ${finalProfit.format(3)} (expected max $maxProfitUsd)"
                }
                return null
            }

            return volume
        }

        fun calcRefillVolume(
            budget: Double,
            alreadyHave: Double,
            openVolume: Double,
            openPrice: Double,
            closeVolume: Double,
            openVolumeCapPercent: Double?,
        ): Double {
            val maxBudgetAllowance = max(
                0.0,
                floor((budget - (abs(alreadyHave) * openPrice)) / openPrice),
            )

            val maxCappedBuy = openVolumeCapPercent?.let { truncate(closeVolume * it / 100.0) }

            return listOfNotNull(
                maxBudgetAllowance,
                openVolume,
                maxCappedBuy,
            ).minOrNull() ?: throw Exception("unexpected")
        }
    }

    inline fun getOpenInfoImpl(
        entryAcceptable: (row1: OrderBookEntry, row2: OrderBookEntry) -> Boolean,
        signedVolume1: (Double) -> Double,
        signedVolume2: (Double) -> Double,
        orderBook1RowPredicate: (OrderBookEntry) -> Boolean,
        orderBookEntry1: OrderBookEntryPair,
        orderBookEntries1: List<OrderBookEntry>,
        orderBookEntries1Original: List<OrderBookEntry>?,
        orderBookEntry2: OrderBookEntryPair,
        priceDiffPercentFilterRange: OptionalDoubleRange,
        priceDiffUsdFilterRange: OptionalDoubleRange,
        commissionPercent1: Double,
        commissionPercent2: Double,
        alreadyHave1: Double,
        alreadyHave2: Double,
        pair: Boolean,
        openFraction: Double,
        skipBudgetChecks: Boolean,
        skipAvailableToCloseThroughBridgeCheck: Boolean,
        maxBudgetUsd: Double,
        minProfitUsd: Double,
        maxProfitUsd: Double,
        openVolumeCapPercent: Double?,
        spreadPrice: Double?,
    ): OpenPositionInfo? {
        val priceDiffPercentRangeMin = priceDiffPercentFilterRange.min
        val priceDiffUsdRangeMin = priceDiffUsdFilterRange.min
        return if (priceDiffPercentRangeMin != null && priceDiffUsdRangeMin != null) {
            val openPrice2 = orderBookEntry2.getTargetPrice()
            val ratioOpenPrice2 = formula2.calcPrice(orderBookEntry2.getTargetPrice())

            val extraEntries = orderBookEntries1.takeWhile { entry ->
                if (!orderBook1RowPredicate(entry)) {
                    return@takeWhile false
                }
                val diffPercent = abs(calcDifferencePercent(formula1.calcPrice(entry.price), ratioOpenPrice2))
                val diffUsd = abs(formula1.calcPrice(entry.price) - ratioOpenPrice2)
                val valid = entryAcceptable(entry, orderBookEntry2.targetEntry())
                priceDiffPercentRangeMin <= diffPercent && priceDiffUsdRangeMin <= diffUsd && valid
            }

            val extraEntryAccumulations = (1..extraEntries.size).map {
                extraEntries.take(it)
            }

            extraEntryAccumulations.mapNotNull { accumulation ->
                val openPrice1 = accumulation.lastOrNull()?.price ?: return@mapNotNull null
                val openVolume1 = accumulation.sumOf { entry -> entry.volume }
                val openVolume2 = orderBookEntry2.entry.volume.takeIf { it > 0 } ?: openVolume1

                val ratioOpenPrice1 = formula1.calcPrice(openPrice1)

                val fittedVolume1 = fitVolume(
                    tickerPair,
                    ratioOpenPrice1,
                    openVolume1,
                    ratioOpenPrice2,
                    openVolume2,
                    commissionPercent1,
                    openFraction,
                    alreadyHave1,
                    skipBudgetChecks,
                    skipAvailableToCloseThroughBridgeCheck,
                    maxBudgetUsd,
                    minProfitUsd,
                    maxProfitUsd,
                    openVolumeCapPercent,
                ) ?: return@mapNotNull null

                val volume1: Double
                val volume2: Double

                if (pair) {
                    val fittedVolume2 = fitVolume(
                        tickerPair,
                        ratioOpenPrice2,
                        openVolume2,
                        ratioOpenPrice1,
                        orderBookEntry1.entry.volume,
                        commissionPercent2,
                        openFraction,
                        alreadyHave2,
                        skipBudgetChecks,
                        skipAvailableToCloseThroughBridgeCheck,
                        maxBudgetUsd,
                        minProfitUsd,
                        maxProfitUsd,
                        openVolumeCapPercent,
                    ) ?: return@mapNotNull null

                    if (formula1.ratio <= formula2.ratio) {
                        val minFittedVolume = min(
                            fittedVolume1,
                            floor(fittedVolume2 * formula1.ratio / formula2.ratio),
                        )

                        volume1 = minFittedVolume
                        volume2 = floor(minFittedVolume / formula1.ratio * formula2.ratio)
                    } else {
                        val minFittedVolume = min(
                            floor(fittedVolume1 / formula1.ratio * formula2.ratio),
                            fittedVolume2,
                        )

                        volume1 = floor(minFittedVolume * formula1.ratio / formula2.ratio)
                        volume2 = minFittedVolume
                    }
                } else {
                    volume1 = fittedVolume1
                    volume2 = 0.0
                }

                val signed1 = signedVolume1(volume1)
                val signed2 = signedVolume2(volume2)

                var amount1 = 0.0
                var remainingOrderBook1Lots = volume1

                accumulation.forEach { entry ->
                    val price = entry.price
                    val volume = entry.volume

                    if (abs(remainingOrderBook1Lots) < 0.000001) {
                        return@forEach
                    }

                    if (volume <= remainingOrderBook1Lots) {
                        amount1 += price * volume
                        remainingOrderBook1Lots -= volume
                    } else {
                        amount1 += price * remainingOrderBook1Lots
                        remainingOrderBook1Lots = 0.0
                    }
                }

                val amount2 = openPrice2 * volume1 / formula1.ratio * formula2.ratio

                val commission = if (pair) {
                    (commissionPercent1 * amount1 + commissionPercent2 * amount2) / 100.0
                } else {
                    commissionPercent1 / 100.0 * (amount1 + amount2)
                }

                val potentialProfit = when (operation1) {
                    OperationType.SELL -> {
                        (amount1 - amount2) - commission
                    }
                    OperationType.BUY -> {
                        (amount2 - amount1) - commission
                    }
                }

                val potentialProfitSpread = spreadPrice?.let {
                    potentialProfit - it * volume1
                }

                val openPrice1Original = if (orderBookEntries1Original != null) {
                    val index = orderBookEntries1.indexOfFirst { it.price == openPrice1 }
                    if (index >= 0) {
                        orderBookEntries1Original.getOrNull(index)?.price ?: openPrice1
                    } else {
                        openPrice1
                    }
                } else {
                    openPrice1
                }

                OpenPositionInfo(
                    openPrice1Original,
                    signed1,
                    openPrice2,
                    signed2,
                    potentialProfit,
                    potentialProfitSpread,

                )
            }.lastOrNull()
        } else {
            null
        }
    }

    fun calcBridgePrice(bridgeConfig: BridgeConfig): Double? {
        val (mode, delta) = when (this) {
            is ShortEntry -> {
                bridgeConfig.intermediateRangeModes.firstOrNull {
                    (bid1.entry.price - ask2.entry.price) <= it.maxSpread
                }
            }
            is LongEntry -> {
                bridgeConfig.intermediateRangeModes.firstOrNull {
                    (bid2.entry.price - ask1.entry.price) <= it.maxSpread
                }
            }
        }?.let { Pair(it.mode, it.delta) } ?: Pair(bridgeConfig.finalMode, bridgeConfig.finalDelta)

        val bid2 = bid2
        val ask2 = ask2

        return when (mode) {
            BridgeMode.ASK -> {
                if (ask2 != null) {
                    ask2.entry.price + delta
                } else {
                    logger.debug {
                        "bridge mode is set to $mode, but scanner entry is $this, trade not possible"
                    }
                    null
                }
            }
            BridgeMode.BID -> {
                if (bid2 != null) {
                    bid2.entry.price + delta
                } else {
                    logger.debug {
                        "bridge mode is set to $mode, but scanner entry is $this, trade not possible"
                    }
                    null
                }
            }
            BridgeMode.VWAP -> {
                when (this) {
                    is ShortEntry -> {
                        // FIXME should not round price here
                        (this.ask2.entry.price * (1.0 + delta / 100.0)).setScale(2)
                    }
                    is LongEntry -> {
                        // FIXME should not round price here
                        (this.bid2.entry.price * (1.0 - delta / 100.0)).setScale(2)
                    }
                }
            }
        }
    }

    abstract fun getOpenInfo(
        commissionPercent1: Double,
        commissionPercent2: Double,
        openFraction: Double,
        alreadyHave1: Double,
        alreadyHave2: Double,
        orderBook1OrNull: OrderBook?,
        orderBook1OrNullOriginal: OrderBook?,
        skipBudgetChecks: Boolean,
        min1: Double?,
        max1: Double?,
        pair: Boolean,
        maxBudgetUsd: Double,
        minProfitUsd: Double,
        maxProfitUsd: Double,
        priceDiffPercentFilterRange: OptionalDoubleRange,
        priceDiffUsdFilterRange: OptionalDoubleRange,
        skipAvailableToCloseThroughBridgeCheck: Boolean,
        openVolumeCapPercent: Double?,
        spreadPrice: Double?,
    ): OpenPositionInfo?

    @Serializable
    @SerialName("LongEntry")
    data class LongEntry(
        override val tickerPair: TickerPair,
        override val formula1: PriceFormulaComponets,
        override val bid1: OrderBookEntryPair?,
        override val ask1: OrderBookEntryPair,
        override val formula2: PriceFormulaComponets,
        override val bid2: OrderBookEntryPair,
        override val ask2: OrderBookEntryPair?,
        override val time: Long,
        override val priceDiffPercent: Double,
        override val futurePriceDiffPercent: Double? = null,
        override val orderBookTime1: Long? = null,
        override val orderBookTime2: Long? = null,
    ) : ScannerEntry() {
        override val operation1 = OperationType.BUY

        override fun getOpenInfo(
            commissionPercent1: Double,
            commissionPercent2: Double,
            openFraction: Double,
            alreadyHave1: Double,
            alreadyHave2: Double,
            orderBook1OrNull: OrderBook?,
            orderBook1OrNullOriginal: OrderBook?,
            skipBudgetChecks: Boolean,
            min1: Double?,
            max1: Double?,
            pair: Boolean,
            maxBudgetUsd: Double,
            minProfitUsd: Double,
            maxProfitUsd: Double,
            priceDiffPercentFilterRange: OptionalDoubleRange,
            priceDiffUsdFilterRange: OptionalDoubleRange,
            skipAvailableToCloseThroughBridgeCheck: Boolean,
            openVolumeCapPercent: Double?,
            spreadPrice: Double?,
        ): OpenPositionInfo? {
            return getOpenInfoImpl(
                { e1, e2 -> formula1.calcPrice(e1.price) < formula2.calcPrice(e2.price) },
                { volume1 -> volume1 },
                { volume2 -> -volume2 },
                max1?.let { max ->
                    { entry -> entry.price <= max }
                } ?: { true },
                ask1,
                orderBook1OrNull?.asks ?: listOf(ask1.entry),
                orderBook1OrNullOriginal?.asks,
                bid2,
                priceDiffPercentFilterRange,
                priceDiffUsdFilterRange,
                commissionPercent1,
                commissionPercent2,
                alreadyHave1,
                alreadyHave2,
                pair,
                openFraction,
                skipBudgetChecks,
                skipAvailableToCloseThroughBridgeCheck,
                maxBudgetUsd,
                minProfitUsd,
                maxProfitUsd,
                openVolumeCapPercent,
                spreadPrice,
            )
        }
    }

    @Serializable
    @SerialName("ShortEntry")
    data class ShortEntry(
        override val tickerPair: TickerPair,
        override val formula1: PriceFormulaComponets,
        override val bid1: OrderBookEntryPair,
        override val ask1: OrderBookEntryPair?,
        override val formula2: PriceFormulaComponets,
        override val bid2: OrderBookEntryPair?,
        override val ask2: OrderBookEntryPair,
        override val time: Long,
        override val priceDiffPercent: Double,
        override val futurePriceDiffPercent: Double? = null,
        override val orderBookTime1: Long? = null,
        override val orderBookTime2: Long? = null,
    ) : ScannerEntry() {
        override val operation1 = OperationType.SELL

        override fun getOpenInfo(
            commissionPercent1: Double,
            commissionPercent2: Double,
            openFraction: Double,
            alreadyHave1: Double,
            alreadyHave2: Double,
            orderBook1OrNull: OrderBook?,
            orderBook1OrNullOriginal: OrderBook?,
            skipBudgetChecks: Boolean,
            min1: Double?,
            max1: Double?,
            pair: Boolean,
            maxBudgetUsd: Double,
            minProfitUsd: Double,
            maxProfitUsd: Double,
            priceDiffPercentFilterRange: OptionalDoubleRange,
            priceDiffUsdFilterRange: OptionalDoubleRange,
            skipAvailableToCloseThroughBridgeCheck: Boolean,
            openVolumeCapPercent: Double?,
            spreadPrice: Double?,
        ): OpenPositionInfo? {
            return getOpenInfoImpl(
                { e1, e2 -> formula1.calcPrice(e1.price) > formula2.calcPrice(e2.price) },
                { volume1 -> -volume1 },
                { volume2 -> volume2 },
                min1?.let { min ->
                    { entry -> min <= entry.price }
                } ?: { true },
                bid1,
                orderBook1OrNull?.bids ?: listOf(bid1.entry),
                orderBook1OrNullOriginal?.bids,
                ask2,
                priceDiffPercentFilterRange,
                priceDiffUsdFilterRange,
                commissionPercent1,
                commissionPercent2,
                alreadyHave1,
                alreadyHave2,
                pair,
                openFraction,
                skipBudgetChecks,
                skipAvailableToCloseThroughBridgeCheck,
                maxBudgetUsd,
                minProfitUsd,
                maxProfitUsd,
                openVolumeCapPercent,
                spreadPrice,
            )
        }
    }
}
