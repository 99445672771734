package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class BrokerConnectorStatus(
    val averageLatency: Long,
    val connectorStatus: ConnectorStatus,
    val brokerClientConfig: BrokerClientConfig,
    val lastError: String?,
)
