package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.AlpacaDataApiType
import com.edvorg.trade.common.model.AlpacaEndPoint
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.ClosePriceScannerMode
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.ExanteLevel2Mode
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.OrderType
import com.edvorg.trade.common.model.PostCloseMethod
import com.edvorg.trade.common.model.ScannerDealSelection
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.SpreadScannerOperatorMode
import com.edvorg.trade.common.model.Tick
import com.edvorg.trade.common.model.TickerDetailsFilter
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.config.PriceTick
import com.edvorg.trade.common.model.config.SamuraiLimitsUnit
import com.edvorg.trade.common.model.config.SamuraiTick
import com.edvorg.trade.common.model.config.ScannerTick
import com.edvorg.trade.common.model.settings.ImmutableSettings
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Setting
import com.edvorg.trade.common.model.settings.Settings
import com.edvorg.trade.common.model.stats.PriceStatType
import com.edvorg.trade.common.model.toEmojiCode
import js.core.jso
import kotlinx.datetime.LocalTime
import mui.material.Button
import mui.material.FormControlLabel
import mui.material.LabelPlacement
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Switch
import mui.material.SwitchProps
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableRow
import mui.material.TextField
import mui.material.TextFieldProps
import mui.material.Tooltip
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.span
import react.dom.onChange
import react.useEffect
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.px
import web.html.ButtonType
import web.html.HTMLInputElement
import web.html.InputType
import kotlin.time.Duration.Companion.minutes

external interface ScannerIdSettingProps : Props {
    var serverId: String
    var scannerId: ScannerId
    var onUpdateScannerId: (ScannerId) -> Unit
}

val scannerIdSettingComponent = FC<ScannerIdSettingProps> { props ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager

    var scannerIds: Set<ScannerId> by useState(emptySet())

    useEffect {
        val scannerClientsHandle = connectorServersManager.getServerConnection(
            props.serverId,
        )?.client?.connectionManager?.subscribeToScannerClientsUpdate {
            scannerIds = it.scannerClients.keys
        }

        cleanup {
            scannerClientsHandle?.unsubscribe()
        }
    }

    select {
        value = props.scannerId.id
        onMouseUp = { it.stopPropagation() }
        onMouseDown = { it.stopPropagation() }
        onChange = { event ->
            event.stopPropagation()
            event.target.value.let { selection ->
                val newValue = ScannerId(selection)
                props.onUpdateScannerId(newValue)
            }
        }

        scannerIds.forEach {
            option {
                key = it.id
                value = it.id
                +it.id
            }
        }
    }
}

external interface ScannerServerSettingsProps : Props {
    var scannerServer: String
    var onUpdateServer: (String) -> Unit
}

val scannerServerSettingsComponent = FC<ScannerServerSettingsProps> { props ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager

    select {
        value = props.scannerServer
        onMouseUp = { it.stopPropagation() }
        onMouseDown = { it.stopPropagation() }
        onChange = { event ->
            event.stopPropagation()
            event.target.value.let { selection ->
                props.onUpdateServer(selection)
            }
        }

        connectorServersManager.getServerIds().forEach {
            option {
                key = it
                value = it
                +it
            }
        }
    }
}

external interface ScannerExecutorIdSettingProps : Props {
    var executorId: ScannerExecutorId
    var onUpdateScannerExecutorId: (ScannerExecutorId) -> Unit
}

val scannerExecutorIdSettingComponent = FC<ScannerExecutorIdSettingProps> { props ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val connectionManager = connectorServersManager.getServerConnection("local")?.client?.connectionManager

    var executorIds: Set<ScannerExecutorId> by useState(emptySet())

    useEffectOnce {
        val scannerClientsHandle = connectionManager?.subscribeToScannerExecutorsUpdate {
            executorIds = it.executors.keys
        }

        cleanup {
            scannerClientsHandle?.unsubscribe()
        }
    }

    select {
        value = props.executorId.id
        onMouseUp = { it.stopPropagation() }
        onMouseDown = { it.stopPropagation() }
        onChange = { event ->
            event.stopPropagation()
            event.target.value.let { selection ->
                val newValue = ScannerExecutorId(selection)
                props.onUpdateScannerExecutorId(newValue)
            }
        }

        executorIds.forEach {
            option {
                key = it.id
                value = it.id
                +it.id
            }
        }
    }
}

external interface SettingsPanelProps : Props {
    var settings: ImmutableSettings
    var resetSettings: (MutableSettings) -> Unit
    var saveSettings: (Settings) -> Unit
    var searchEnabled: Boolean
}

data class TickerTypeSelectOption(
    override val value: TickerType,
) : SelectOption<TickerType> {
    override val label: String = value.name
}

data class BarSizeSelectOption(
    override val value: BarSize,
) : SelectOption<BarSize> {
    override val label: String = value.name
}

data class CurrencySelectOption(
    override val value: Currency,
) : SelectOption<Currency> {
    override val label: String = "${value.name} (${value.toEmojiCode()})"
}

data class StringOption(
    override val value: String,
) : SelectOption<String> {
    override val label: String = value
}

fun ChildrenBuilder.settingCell(
    settings: ImmutableSettings,
    name: String,
    setting: Setting,
    updateSetting: (Setting) -> Unit,
) {
    when (setting) {
        is Setting.BooleanSetting -> {
            fun SwitchProps.setup() {
                checked = setting.value
                onChange = { _, checked ->
                    updateSetting(
                        Setting.BooleanSetting(
                            checked,
                            setting.showLabel,
                        ),
                    )
                }
            }
            if (setting.showLabel) {
                FormControlLabel {
                    label = ReactNode(name)
                    control = Switch.create {
                        setup()
                    }
                }
            } else {
                Switch {
                    setup()
                }
            }
        }

        is Setting.StringSetting -> {
            TextField {
                if (setting.width != null) {
                    sx {
                        width = setting.width.px
                    }
                }
                size = Size.small
                label = ReactNode(name)
                value = setting.value
                onChange = { event ->
                    val newValue = (event.target as HTMLInputElement).value
                    updateSetting(Setting.StringSetting(newValue, setting.width, setting.showLabel))
                }
            }
        }

        is Setting.ScannerDealSelectionSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.id
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = ScannerDealSelection.entries.find {
                            it.id == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.ScannerDealSelectionSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                ScannerDealSelection.entries.forEach {
                    option {
                        key = it.name
                        value = it.id
                        +it.id
                    }
                }
            }
        }

        is Setting.BarSizeSetting -> {
            autocomplete(
                { null },
                false,
                name,
                setting.width,
                null,
                { value ->
                    value?.let { newValue ->
                        updateSetting(
                            Setting.BarSizeSetting(
                                newValue,
                                setting.width,
                                setting.showLabel,
                            ),
                        )
                    }
                },
                null,
                {
                },
                BarSizeSelectOption(setting.value),
                BarSize.entries.map { BarSizeSelectOption(it) }.toTypedArray(),
                true,
            )
        }

        is Setting.DealsSubscriptionSetting -> {
            +"DealsSubscriptionSetting setting not implemented"
        }

        is Setting.OrderTypeSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.name
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = OrderType.entries.find {
                            it.name == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.OrderTypeSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                OrderType.entries.forEach {
                    option {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }
        }

        is Setting.TickerTypeSetting -> {
            autocomplete(
                { null },
                false,
                name,
                setting.width,
                null,
                { value ->
                    value?.let { newValue ->
                        updateSetting(
                            Setting.TickerTypeSetting(
                                newValue,
                                setting.width,
                                setting.showLabel,
                            ),
                        )
                    }
                },
                null,
                {
                },
                TickerTypeSelectOption(setting.value),
                TickerType.entries.map { TickerTypeSelectOption(it) }.toTypedArray(),
                true,
            )
        }

        is Setting.ExchangeSetting -> {
            exchangeSelect(setting.value, false) { newValue ->
                if (newValue != null) {
                    updateSetting(
                        Setting.ExchangeSetting(
                            newValue,
                            setting.showLabel,
                        ),
                    )
                }
            }
        }

        is Setting.ScannerIdSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            scannerIdSettingComponent {
                scannerId = setting.value
                serverId = settings.getOptionalScannerServer("server") ?: "local"
                onUpdateScannerId = { newValue ->
                    updateSetting(
                        Setting.ScannerIdSetting(
                            newValue,
                            setting.showLabel,
                        ),
                    )
                }
            }
        }

        is Setting.ScannerExecutorIdSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            scannerExecutorIdSettingComponent {
                executorId = setting.value
                onUpdateScannerExecutorId = { newValue ->
                    updateSetting(
                        Setting.ScannerExecutorIdSetting(
                            newValue,
                            setting.showLabel,
                        ),
                    )
                }
            }
        }

        is Setting.ScannerServerSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            scannerServerSettingsComponent {
                scannerServer = setting.value
                onUpdateServer = { newValue ->
                    updateSetting(
                        Setting.ScannerServerSetting(
                            newValue,
                            setting.showLabel,
                        ),
                    )
                }
            }
        }

        is Setting.TickSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.name
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = Tick.entries.find {
                            it.name == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.TickSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                Tick.entries.forEach {
                    option {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }
        }

        is Setting.CurrencySetting -> {
            autocomplete(
                { null },
                false,
                name,
                setting.width,
                null,
                { value ->
                    value?.let { newValue ->
                        updateSetting(
                            Setting.CurrencySetting(
                                newValue,
                                setting.width,
                                setting.showLabel,
                            ),
                        )
                    }
                },
                null,
                {
                },
                CurrencySelectOption(setting.value),
                Currency.entries.map { CurrencySelectOption(it) }.toTypedArray(),
                true,
            )
        }

        is Setting.ExanteLevel2ModeSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.id
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = ExanteLevel2Mode.entries.find {
                            it.id == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.ExanteLevel2ModeSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                ExanteLevel2Mode.entries.forEach {
                    option {
                        key = it.id
                        value = it.id
                        +it.id
                    }
                }
            }
        }

        is Setting.AlpacaDataApiTypeSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.value
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = AlpacaDataApiType.entries.find {
                            it.value == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.AlpacaDataApiTypeSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                AlpacaDataApiType.entries.forEach {
                    option {
                        key = it.value
                        value = it.value
                        +it.value
                    }
                }
            }
        }

        is Setting.AlpacaEndPointSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.value
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = AlpacaEndPoint.entries.find {
                            it.value == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.AlpacaEndPointSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                AlpacaEndPoint.entries.forEach {
                    option {
                        key = it.value
                        value = it.value
                        +it.value
                    }
                }
            }
        }

        is Setting.ClosePriceScannerSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.name
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = ClosePriceScannerMode.entries.find {
                            it.name == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.ClosePriceScannerSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                ClosePriceScannerMode.entries.forEach {
                    option {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }
        }

        is Setting.TickerDetailsFilterSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.name
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = TickerDetailsFilter.entries.find {
                            it.name == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.TickerDetailsFilterSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                TickerDetailsFilter.entries.forEach {
                    option {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }
        }

        is Setting.SpreadScannerOperatorSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.name
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = SpreadScannerOperatorMode.entries.find {
                            it.name == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.SpreadScannerOperatorSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                SpreadScannerOperatorMode.entries.forEach {
                    option {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }
        }

        is Setting.SamuraiTickSetting -> {
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(5.px)

                val s = ImmutableSettings(setting.value)
                val type = s.getString("_type")
                autocomplete(
                    { null },
                    false,
                    name,
                    150,
                    null,
                    { value ->
                        value?.let { selection ->
                            if (type != selection) {
                                updateSetting(
                                    when (selection) {
                                        "Indicator" -> MutableSettings.makeSamuraiTick(
                                            SamuraiTick.IndicatorTick(
                                                Indicator.MA(
                                                    20,
                                                    BarSize.MINUTE,
                                                    1,
                                                ),
                                            ),
                                            setting.showLabel,
                                        )

                                        "Stat" -> MutableSettings.makeSamuraiTick(
                                            SamuraiTick.Stat(
                                                PriceStatType.REG_CLOSE,
                                            ),
                                            setting.showLabel,
                                        )

                                        "OrderBook" -> MutableSettings.makeSamuraiTick(
                                            SamuraiTick.OrderBook,
                                            setting.showLabel,
                                        )

                                        else -> {
                                            throw Error("unexpected")
                                        }
                                    },
                                )
                            }
                        }
                    },
                    null,
                    {
                    },
                    StringOption(type),
                    arrayOf(
                        StringOption("OrderBook"),
                        StringOption("Indicator"),
                        StringOption("Stat"),
                    ),
                    true,
                )

                settingsPanelInline {
                    this.settings = ImmutableSettings(setting.value)
                    this.updateSettings = {
                        updateSetting(
                            Setting.SamuraiTickSetting(
                                it.map,
                                setting.showLabel,
                            ),
                        )
                    }
                }
            }
        }

        is Setting.ScannerTickSetting -> {
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(5.px)

                val s = ImmutableSettings(setting.value)
                val type = s.getString("_type")
                autocomplete(
                    { null },
                    false,
                    name,
                    150,
                    null,
                    { value ->
                        value?.let { selection ->
                            if (type != selection) {
                                updateSetting(
                                    when (selection) {
                                        "Indicator" -> MutableSettings.makeScannerTick(
                                            ScannerTick.Indicator(
                                                Indicator.MA(
                                                    20,
                                                    BarSize.MINUTE,
                                                    1,
                                                ),
                                            ),
                                            setting.showLabel,
                                        )

                                        "Stat" -> MutableSettings.makeScannerTick(
                                            ScannerTick.Stat(
                                                PriceStatType.REG_CLOSE,
                                            ),
                                            setting.showLabel,
                                        )

                                        "Bid" -> MutableSettings.makeScannerTick(
                                            ScannerTick.Bid,
                                            setting.showLabel,
                                        )

                                        "Ask" -> MutableSettings.makeScannerTick(
                                            ScannerTick.Ask,
                                            setting.showLabel,
                                        )

                                        "Mid" -> MutableSettings.makeScannerTick(
                                            ScannerTick.Mid,
                                            setting.showLabel,
                                        )

                                        else -> {
                                            throw Error("unexpected")
                                        }
                                    },
                                )
                            }
                        }
                    },
                    null,
                    {
                    },
                    StringOption(type),
                    arrayOf(
                        StringOption("Bid"),
                        StringOption("Ask"),
                        StringOption("Mid"),
                        StringOption("Indicator"),
                        StringOption("Stat"),
                    ),
                    true,
                )

                settingsPanelInline {
                    this.settings = ImmutableSettings(setting.value)
                    this.updateSettings = {
                        updateSetting(
                            Setting.ScannerTickSetting(
                                it.map,
                                setting.showLabel,
                            ),
                        )
                    }
                }
            }
        }

        is Setting.PriceTickSetting -> {
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(5.px)

                val s = ImmutableSettings(setting.value)
                val type = s.getString("_type")
                autocomplete(
                    { null },
                    false,
                    name,
                    150,
                    null,
                    { value ->
                        value?.let { selection ->
                            if (type != selection) {
                                updateSetting(
                                    when (selection) {
                                        "Stat" -> MutableSettings.makePriceTick(
                                            PriceTick.Stat(
                                                PriceStatType.REG_CLOSE,
                                            ),
                                            setting.showLabel,
                                        )

                                        "Bid" -> MutableSettings.makePriceTick(
                                            PriceTick.Bid,
                                            setting.showLabel,
                                        )

                                        "Ask" -> MutableSettings.makePriceTick(
                                            PriceTick.Ask,
                                            setting.showLabel,
                                        )

                                        "Mid" -> MutableSettings.makePriceTick(
                                            PriceTick.Mid,
                                            setting.showLabel,
                                        )

                                        else -> {
                                            throw Error("unexpected")
                                        }
                                    },
                                )
                            }
                        }
                    },
                    null,
                    {
                    },
                    StringOption(type),
                    arrayOf(
                        StringOption("Bid"),
                        StringOption("Ask"),
                        StringOption("Mid"),
                        StringOption("Stat"),
                    ),
                    true,
                )

                settingsPanelInline {
                    this.settings = ImmutableSettings(setting.value)
                    this.updateSettings = {
                        updateSetting(
                            Setting.PriceTickSetting(
                                it.map,
                                setting.showLabel,
                            ),
                        )
                    }
                }
            }
        }

        is Setting.PriceStatTypeSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            priceStatTypeSettingView {
                value = setting.value.name
                onUpdate = { newValue ->
                    updateSetting(
                        Setting.PriceStatTypeSetting(
                            newValue,
                            setting.showLabel,
                        ),
                    )
                }
            }
        }

        is Setting.SamuraiLimitsUnitSetting -> {
            val s = ImmutableSettings(setting.value)
            val type: String = s.getString("_type")

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(5.px)

                autocomplete(
                    { null },
                    false,
                    name,
                    160,
                    null,
                    { value ->
                        value?.let { selection ->
                            if (type != selection) {
                                updateSetting(
                                    when (selection) {
                                        "Absolute" -> MutableSettings.makeSamuraiLimitsUnit(
                                            SamuraiLimitsUnit.Absolute,
                                            setting.showLabel,
                                        )

                                        "Fractional" -> MutableSettings.makeSamuraiLimitsUnit(
                                            SamuraiLimitsUnit.Fractional,
                                            setting.showLabel,
                                        )

                                        "SD" -> MutableSettings.makeSamuraiLimitsUnit(
                                            SamuraiLimitsUnit.SD(
                                                Indicator.SD(
                                                    20,
                                                    BarSize.MINUTE,
                                                ),
                                            ),
                                            setting.showLabel,
                                        )

                                        else -> {
                                            throw Error("unexpected")
                                        }
                                    },
                                )
                            }
                        }
                    },
                    null,
                    {
                    },
                    StringOption(type),
                    arrayOf(
                        StringOption("Absolute"),
                        StringOption("Fractional"),
                        StringOption("SD"),
                    ),
                    true,
                )

                settingsPanelInline {
                    this.settings = ImmutableSettings(setting.value)
                    this.updateSettings = {
                        updateSetting(
                            Setting.SamuraiLimitsUnitSetting(
                                it.map,
                                setting.showLabel,
                            ),
                        )
                    }
                }
            }
        }

        is Setting.IndicatorSetting -> {
            val s = ImmutableSettings(setting.value)
            val type = s.getString("_type")

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(5.px)

                autocomplete(
                    { null },
                    false,
                    name,
                    125,
                    null,
                    { value ->
                        value?.let { selection ->
                            if (type != selection) {
                                updateSetting(
                                    when (selection) {
                                        "MA" -> MutableSettings.makeIndicator(
                                            Indicator.MA(
                                                20,
                                                BarSize.MINUTE,
                                                1,
                                            ),
                                            setting.showLabel,
                                        )

                                        "SD" -> MutableSettings.makeIndicator(
                                            Indicator.SD(
                                                20,
                                                BarSize.MINUTE,
                                            ),
                                            setting.showLabel,
                                        )

                                        "LookBack" -> MutableSettings.makeIndicator(
                                            Indicator.LookBack(
                                                PriceTick.Stat(
                                                    PriceStatType.LAST,
                                                ),
                                                5.minutes,
                                            ),
                                            setting.showLabel,
                                        )

                                        else -> {
                                            throw Error("unexpected")
                                        }
                                    },
                                )
                            }
                        }
                    },
                    null,
                    {
                    },
                    StringOption(type),
                    arrayOf(
                        StringOption("MA"),
                        StringOption("SD"),
                        StringOption("LookBack"),
                    ),
                    true,
                )

                settingsPanelInline {
                    this.settings = ImmutableSettings(setting.value)
                    this.updateSettings = {
                        updateSetting(
                            Setting.IndicatorSetting(
                                it.map,
                                setting.showLabel,
                            ),
                        )
                    }
                }
            }
        }

        is Setting.PostCloseMethodSetting -> {
            if (setting.showLabel) {
                label {
                    +"$name: "
                }
            }
            select {
                value = setting.value.name
                onMouseUp = { it.stopPropagation() }
                onMouseDown = { it.stopPropagation() }
                onChange = { event ->
                    event.stopPropagation()
                    event.target.value.let { selection ->
                        val newValue = PostCloseMethod.entries.find {
                            it.name == selection
                        } ?: throw Error("unexpected")
                        updateSetting(
                            Setting.PostCloseMethodSetting(
                                newValue,
                                setting.showLabel,
                            ),
                        )
                    }
                }

                PostCloseMethod.entries.forEach {
                    option {
                        key = it.name
                        value = it.name
                        +it.name
                    }
                }
            }
        }

        is Setting.TypedTickerSetting -> {
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(5.px)

                settingsPanelInline {
                    this.settings = ImmutableSettings(setting.value)
                    this.updateSettings = {
                        updateSetting(
                            Setting.TypedTickerSetting(
                                it.map,
                                setting.showLabel,
                            ),
                        )
                    }
                }
            }
        }

        is Setting.OptionalLocalTimeSetting -> {
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(5.px)

                fun TextFieldProps.setup() {
                    size = Size.small
                    type = InputType.time
                    placeholder = name
                    value = setting.value?.toString() ?: ""
                    onChange = { event ->
                        updateSetting(
                            Setting.OptionalLocalTimeSetting(
                                LocalTime.parse((event.target as HTMLInputElement).value),
                                setting.showLabel,
                            ),
                        )
                    }
                }
                if (setting.showLabel) {
                    FormControlLabel {
                        labelPlacement = LabelPlacement.start
                        label = span.create {
                            style = jso {
                                margin = 5.px
                            }
                            +name
                        }
                        control = TextField.create {
                            setup()
                        }
                    }
                } else {
                    TextField {
                        setup()
                    }
                }
                Tooltip {
                    title = ReactNode("Reset")

                    Button {
                        size = Size.small
                        type = ButtonType.button
                        onClick = { event ->
                            event.stopPropagation()
                            updateSetting(
                                Setting.OptionalLocalTimeSetting(
                                    null,
                                    setting.showLabel,
                                ),
                            )
                        }

                        +"X"
                    }
                }
            }
        }

        is Setting.ExchangesListSetting -> {
            autocompleteMultiple(
                false,
                name,
                setting.width,
                { values ->
                    values?.let { newValue ->
                        updateSetting(
                            Setting.ExchangesListSetting(
                                newValue.toList(),
                                setting.width,
                                false,
                            ),
                        )
                    }
                },
                {
                    this.disableClearable = false
                },
                setting.value.map { ExchangeSelectOption(it) }.toTypedArray(),
                Exchange.entries.map { ExchangeSelectOption(it) }.toTypedArray(),
            )
        }
    }
}

external interface PriceStatTypeSettingView : Props {
    var value: String
    var onUpdate: (PriceStatType) -> Unit
}

val priceStatTypeSettingView = FC<PriceStatTypeSettingView> { props ->
    select {
        value = props.value
        onMouseUp = { it.stopPropagation() }
        onMouseDown = { it.stopPropagation() }
        onChange = { event ->
            event.stopPropagation()
            event.target.value.let { selection ->
                val newValue = PriceStatType.entries.find {
                    it.name == selection
                } ?: throw Error("unexpected")
                props.onUpdate(newValue)
            }
        }

        PriceStatType.entries.forEach {
            option {
                key = it.name
                value = it.name
                +it.name
            }
        }
    }
}

val settingsPanel = FC<SettingsPanelProps> { props ->
    val toaster = getContext<CommonContext>().toaster
    val clickWatcher = getContext<CommonContext>().clickWatcher

    var search by useState("")
    val (settings, setSettings) = useState(ImmutableSettings(props.settings.map))

    div {
        if (props.searchEnabled) {
            p {
                key = "search"
                TextField {
                    size = Size.small
                    label = ReactNode("Search settings")
                    value = search
                    onChange = { event ->
                        val newValue = (event.target as HTMLInputElement).value
                        search = newValue
                    }
                }
            }
        }

        TableContainer {
            Table {
                size = Size.small
                className = ClassName("settings-panel")

                TableBody {
                    val filteredSettings = settings.map.filter {
                        it.key.lowercase().contains(search)
                    }
                    for ((name, setting) in filteredSettings) {
                        TableRow {
                            key = name

                            TableCell {
                                settingCell(settings, name, setting) { newSetting ->
                                    setSettings { settingsValue ->
                                        settingsValue.toMutable().apply {
                                            map[name] = newSetting
                                        }.toImmutable()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        Button {
            size = Size.small
            className = ClassName("normal")
            onClick = { event ->
                clickWatcher.click()
                event.stopPropagation()
                try {
                    props.saveSettings(settings)
                } catch (e: Error) {
                    toaster.error("unable to decode parse settings: ${e.message ?: "unknown error"}", 5000)
                }
            }
            +"Apply"
        }

        Button {
            size = Size.small
            className = ClassName("normal")
            onClick = { event ->
                clickWatcher.click()
                event.stopPropagation()
                setSettings { settingsValue ->
                    settingsValue.toMutable().apply {
                        props.resetSettings(this)
                    }.toImmutable()
                }
            }
            +"Reset"
        }
    }
}

external interface SettingsPanelInlineProps : Props {
    var settings: ImmutableSettings
    var updateSettings: (ImmutableSettings) -> Unit
    var multiLine: Boolean?
}

val settingsPanelInline = FC<SettingsPanelInlineProps> { props ->
    for ((name, setting) in props.settings.map) {
        if (name != "_type") {
            fun block() {
                settingCell(props.settings, name, setting) { newSetting ->
                    props.updateSettings(
                        props.settings.toMutable().apply {
                            map[name] = newSetting
                        }.toImmutable(),
                    )
                }
            }
            if (props.multiLine == true) {
                div {
                    key = name
                    block()
                }
            } else {
                span {
                    key = name
                    block()
                }
            }
        }
    }
}
