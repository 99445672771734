package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(ScannerExecutorIdSerializer::class)
data class ScannerExecutorId(val id: String)

object ScannerExecutorIdSerializer : KSerializer<ScannerExecutorId> {
    override fun deserialize(decoder: Decoder): ScannerExecutorId {
        return ScannerExecutorId(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: ScannerExecutorId) {
        encoder.encodeString(value.id)
    }

    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("ScannerExecutorId", PrimitiveKind.STRING)
}
