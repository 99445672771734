package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.DragOperation
import com.edvorg.trade.common.frontend.services.OnBringToFrontHandler
import com.edvorg.trade.common.frontend.services.OnCloseHandler
import com.edvorg.trade.common.frontend.services.OnCopyHandler
import com.edvorg.trade.common.frontend.services.OnDragStartHandler
import com.edvorg.trade.common.frontend.services.OnDuplicateHandler
import com.edvorg.trade.common.frontend.services.OnSaveTemplateHandler
import com.edvorg.trade.common.frontend.services.OnUpdateSettingsHandler
import com.edvorg.trade.common.frontend.services.SetGroupIdHandler
import com.edvorg.trade.common.frontend.services.SetGroupTickerHandler
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.WidgetSettings
import js.core.jso
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.cancel
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.launch
import mui.material.Paper
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.span
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.px

external interface WidgetProps<T : WidgetSettings> : Props {
    var id: String
    var left: Double
    var top: Double
    var width: Double
    var height: Double
    var settings: T
    var isResizing: Boolean
    var selectedTicker: TypedTicker?
    var selectedTicker2: TypedTicker?
    var groupId: Group
    var groupId2: Group
    var roundingScale: Int

    var onDragStart: OnDragStartHandler
    var onBringToFront: OnBringToFrontHandler
    var onClose: OnCloseHandler
    var onSaveTemplate: OnSaveTemplateHandler
    var onDuplicate: OnDuplicateHandler
    var onCopy: OnCopyHandler
    var onUpdateSettings: OnUpdateSettingsHandler<T>
    var setTicker: SetGroupTickerHandler
    var setGroupId: SetGroupIdHandler
    var setGroupId2: SetGroupIdHandler
}

fun <T : Props> fcWithScope(builder: ChildrenBuilder.(T, CoroutineScope) -> Unit) = FC<T> { props ->
    val scope by useState(CoroutineScope(Dispatchers.Default))

    useEffectOnce {
        cleanup {
            scope.cancel("unmounted")
        }
    }

    builder(props, scope)
}

fun <T : WidgetProps<U>, U : WidgetSettings> widget(
    title: (T) -> String,
    isStretchable: Boolean,
    isPair: Boolean,
    contentBuilder: ChildrenBuilder.(T, Boolean, CoroutineScope, Channel<Unit>) -> Unit,
): FC<T> = fcWithScope { props, scope ->
    val clickWatcher = getContext<CommonContext>().clickWatcher
    val soundPlayer = getContext<CommonContext>().soundPlayer

    val (settingsPanel, setSettingsPanel) = useState(false)
    val reachedEndChannel by useState(Channel<Unit>(capacity = 10, onBufferOverflow = BufferOverflow.DROP_OLDEST))
    val (confirmDialog, setConfirmDialog) = useState<Confirm?>(null)
    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)

    Paper {
        id = props.id

        style = jso {
            left = props.left.px
            top = props.top.px
            width = props.width.px
            height = props.height.px
        }

        className = if (props.isResizing) {
            ClassName("grid-container widget resizing")
        } else {
            ClassName("grid-container widget")
        }

        onMouseUp = { _ ->
            props.onBringToFront.invoke()
        }
        div {
            className = ClassName("grid-item movable header")
            onMouseDown = { event ->
                val newLeft = event.asDynamic().clientX as Double
                val newTop = event.asDynamic().clientY as Double
                props.onDragStart.invoke(DragOperation.MoveWidget, newLeft, newTop)
            }
            tickerInputView {
                groupId = props.groupId
                selectedTicker = props.selectedTicker
                setTicker = props.setTicker
                setGroupId = props.setGroupId
                alignRight = false
                tickerSearchId = "${props.id}-ticker-search-left"
            }
            span {
                +title(props)
            }
            if (isPair) {
                tickerInputView {
                    groupId = props.groupId2
                    selectedTicker = props.selectedTicker2
                    setTicker = props.setTicker
                    setGroupId = props.setGroupId2
                    alignRight = true
                    tickerSearchId = "${props.id}-ticker-search-right"
                }
            }
        }

        div {
            className = ClassName("grid-item save clickable")
            onMouseDown = { event ->
                clickWatcher.click()
                event.stopPropagation()
                val shiftKey = event.asDynamic().shiftKey as Boolean
                val altKey = event.asDynamic().altKey as Boolean
                when {
                    shiftKey -> {
                        props.onDuplicate.invoke()
                    }

                    altKey -> {
                        props.onCopy.invoke()
                    }

                    else -> {
                        setPromptDialog(
                            Prompt(
                                "",
                                "Save widget template",
                                "Widget template name",
                            ) { name ->
                                props.onSaveTemplate.invoke(name)
                            },
                        )
                    }
                }
            }
            +"▼"
        }

        val settingsClasses = if (settingsPanel) {
            "grid-item settings clickable active"
        } else {
            "grid-item settings clickable"
        }

        div {
            className = ClassName(settingsClasses)
            onMouseDown = { event ->
                scope.launch {
                    clickWatcher.click()
                    event.stopPropagation()
                    setSettingsPanel { settingsPanelValue ->
                        !settingsPanelValue
                    }
                }
            }
            +"⚙"
        }

        div {
            className = ClassName("grid-item close clickable")
            onClick = { event ->
                event.stopPropagation()
                clickWatcher.click()
                setConfirmDialog(
                    Confirm(
                        "Close widget",
                        "Confirm close?",
                    ) {
                        event.stopPropagation()
                        props.onClose.invoke()
                        soundPlayer.playDeath()
                    },
                )
            }
            +"☒"
        }

        val stretchableClass = if (isStretchable) "stretchable" else ""
        div {
            className = ClassName("grid-item content $stretchableClass")
            onScroll = { event ->
                val scrollTop = event.target.asDynamic().scrollTop
                val clientHeight = event.target.asDynamic().clientHeight
                val scrollHeight = event.target.asDynamic().scrollHeight
                val reachedEnd = scrollTop + clientHeight >= scrollHeight - 5
                if (reachedEnd) {
                    if (!reachedEndChannel.trySend(Unit).isSuccess) {
                        console.log("reachedEndChannel overflow")
                    }
                }
                event.stopPropagation()
            }

            contentBuilder(props, settingsPanel, scope, reachedEndChannel)
        }
        div {
            className = ClassName("grid-item corner freely-resizable")
            onMouseDown = { event ->
                event.stopPropagation()
                val newLeft = event.asDynamic().clientX as Double
                val newTop = event.asDynamic().clientY as Double
                props.onDragStart.invoke(DragOperation.FreeResizeWidget, newLeft, newTop)
            }
            +"⇲"
        }
    }

    confirm {
        this.dialog = confirmDialog
        this.setDialog = setConfirmDialog
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }
}
