package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.OptionalDoubleRange
import com.edvorg.trade.common.model.config.ScannerColumn
import com.edvorg.trade.common.model.config.ScannerSorting
import kotlinx.serialization.Serializable

@Serializable
data class ScannerDealsSubscription(
    val scannerId: ScannerId,
    val scannerDealSelection: ScannerDealSelection,
    val sorting: ScannerSorting,
    val priceDiffPercentDisplayRange: OptionalDoubleRange,
    val priceDiffUsdDisplayRange: OptionalDoubleRange,
    val maximumScannerEntries: Int,
    val maxBudgetUsd: Double,
    val minProfitUsd: Double,
    val maxProfitUsd: Double,
    val priceDiffPercentFilterRange: OptionalDoubleRange,
    val priceDiffUsdFilterRange: OptionalDoubleRange,
    val pair: Boolean,
    val openVolumeCapPercent: Double?,
    val vwapSkipWithinUsaSpreadCheck: Boolean,
    val exchanges1: Set<Exchange>,
    val exchanges2: Set<Exchange>,
    val priorityExchanges1: List<Exchange>,
    val enabledScannerColumns: Set<ScannerColumn>,
    val compactScanner: Boolean,
    val colorScannerEntries: Boolean,
    val useFutureDiff: Boolean,
    val checkStatus: Boolean,
    val checkPriceRange: Boolean,
    val brokerId1: BrokerId?,
    val brokerId2: BrokerId?,
    val displayPriceRange: OptionalDoubleRange,
    val excludeSectors: Set<String>,
    val excludeIndustries: Set<String>,
    val excludeSubTickerTypes: Set<String>,
    val hiddenSectors: Set<String> = setOf(),
    val hiddenIndustries: Set<String> = setOf(),
    val hiddenSubTickerTypes: Set<String> = setOf(),
    val displayProfitUsdRange: OptionalDoubleRange? = null,
)
