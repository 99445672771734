package com.edvorg.trade.common.model.settings

data class ImmutableSettings(override val map: Map<String, Setting>) : Settings {
    fun toMutable(): MutableSettings {
        return MutableSettings(map.toMutableMap())
    }

    override fun plus(settings2: Settings): Settings {
        return ImmutableSettings(map + settings2.map)
    }
}
