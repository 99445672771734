package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.SamuraiPair
import com.edvorg.trade.common.model.config.SamuraiPauseState
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("SamuraiManagementRequest")
sealed class SamuraiManagementRequest {
    @Serializable
    @SerialName("StartSamurai")
    data class StartSamurai(
        val samuraiId: SamuraiId,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("CreateSamurai")
    data class CreateSamurai(
        val samuraiId: SamuraiId,
        val pair: SamuraiPair,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("RemoveSamurai")
    data class RemoveSamurai(
        val samuraiId: SamuraiId,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("UpdateSamurai")
    data class UpdateSamurai(
        val samuraiId: SamuraiId,
        val pair: SamuraiPair,
        val pauseState: SamuraiPauseState?,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("StopSamurai")
    data class StopSamurai(
        val samuraiId: SamuraiId,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("PauseSamurai")
    data class PauseSamurai(
        val samuraiId: SamuraiId,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("StartSamurais")
    data class StartSamurais(
        val samuraiIds: List<SamuraiId>,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("StopSamurais")
    data class StopSamurais(
        val samuraiIds: List<SamuraiId>,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("PauseSamurais")
    data class PauseSamurais(
        val samuraiIds: List<SamuraiId>,
    ) : SamuraiManagementRequest()

    @Serializable
    @SerialName("StopAllSamurais")
    data object StopAllSamurais : SamuraiManagementRequest()
}
