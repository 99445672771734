package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
enum class PostCloseMethod {
    VWAP,
    VWEMA,
    JURIK,
}
