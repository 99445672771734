package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.model.GroupState
import com.edvorg.trade.common.model.TabState
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.WidgetState
import com.edvorg.trade.common.utils.CollectionUtils.updated
import kotlinx.serialization.Serializable

@Serializable
data class GuiConfig(
    val version: Version,
    val selectedTab: String?,
    val tabs: Map<String, TabState>,
    val widgetTemplates: Map<String, WidgetState>,
    val watchedTickers: Map<Group, Set<Ticker>>,
    val groups: Map<Group, GroupState>,
    val prioritySuggestExchange: Exchange?,
    val priorityBrokers: Map<Exchange, BrokerId>,
    val priceRoundingScale: Int = 2,
) {
    companion object {
        const val defaultTab = "Default"
        const val fileName = "gui.yaml"
    }

    fun applyChange(change: GuiConfigChange): GuiConfig {
        return when (change) {
            is GuiConfigChange.SetWidgetGeometry -> copy(
                tabs = tabs.updated(change.tab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets.updated(change.widgetId) { _, oldWidget ->
                            oldWidget?.copy(
                                geometry = change.geometry,
                            )
                        },
                    )
                },
            )

            is GuiConfigChange.AddWidget -> copy(
                tabs = tabs.updated(change.tab) { _, oldTab ->
                    val newTabs = oldTab ?: TabState(mapOf())
                    newTabs.copy(
                        widgets = newTabs.widgets.updated(change.newWidgetId) { _, w ->
                            w ?: change.widgetState
                        },
                    )
                },
            )

            is GuiConfigChange.RemoveWidget -> copy(
                tabs = tabs.updated(change.tab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets - change.widgetId,
                    )
                },
            )

            is GuiConfigChange.SaveWidgetStateTemplate -> copy(
                widgetTemplates = widgetTemplates.updated(change.name) { _, _ ->
                    change.widgetState
                },
            )

            is GuiConfigChange.RemoveWidgetStateTemplate -> copy(
                widgetTemplates = widgetTemplates - change.name,
            )

            is GuiConfigChange.SetGroupTicker -> copy(
                groups = groups.updated(change.group) { _, oldGroup ->
                    oldGroup?.copy(
                        selectedTicker = change.ticker,
                    ) ?: GroupState(change.ticker)
                },
            )

            is GuiConfigChange.SetWidgetGroupId -> copy(
                tabs = tabs.updated(change.tab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets.updated(change.widgetId) { _, oldWidget ->
                            oldWidget?.copy(
                                groupId = change.groupId,
                            )
                        },
                    )
                },
            )

            is GuiConfigChange.SetWidgetGroupId2 -> copy(
                tabs = tabs.updated(change.tab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets.updated(change.widgetId) { _, oldWidget ->
                            oldWidget?.copy(
                                groupId2 = change.groupId,
                            )
                        },
                    )
                },
            )

            is GuiConfigChange.UpdateGroupWatchedTickers -> copy(
                watchedTickers = watchedTickers.updated(change.group) { _, _ ->
                    change.tickers
                },
            )

            is GuiConfigChange.UpdateWidgetSettings -> copy(
                tabs = tabs.updated(change.tab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets.updated(change.widgetId) { _, oldWidget ->
                            oldWidget?.copy(
                                typedSettings = change.settings,
                            )
                        },
                    )
                },
            )

            is GuiConfigChange.AddNewTab -> copy(
                selectedTab = change.newTab,
                tabs = tabs.updated(change.newTab) { _, oldWidgets ->
                    oldWidgets ?: TabState(emptyMap())
                },
            )

            is GuiConfigChange.RemoveTab -> {
                val newsTabs = tabs - change.tab
                copy(
                    tabs = newsTabs,
                    selectedTab = if (selectedTab == change.tab) {
                        newsTabs.keys.firstOrNull() ?: defaultTab
                    } else {
                        selectedTab
                    },
                )
            }

            is GuiConfigChange.DuplicateTab -> copy(
                selectedTab = change.newTab,
                tabs = tabs.updated(change.newTab) { _, oldWidgets ->
                    oldWidgets ?: tabs[change.tab] ?: TabState(emptyMap())
                },
            )

            is GuiConfigChange.SetSelectedTab -> copy(
                selectedTab = change.tab,
            )

            is GuiConfigChange.SetSuggestExchange -> copy(
                prioritySuggestExchange = change.exchange,
            )

            is GuiConfigChange.SetPriorityBroker -> copy(
                priorityBrokers = priorityBrokers.updated(change.exchange) { _, _ ->
                    change.brokerId
                },
            )

            is GuiConfigChange.ClearPriorityBroker -> copy(
                priorityBrokers = priorityBrokers - change.exchange,
            )

            is GuiConfigChange.SetPriceRoundingScale -> copy(
                priceRoundingScale = change.scale,
            )
        }
    }
}
