package com.edvorg.trade.common.model

enum class OrderType {
    LIMIT,
    MARKET,
    IOC,
    FOK,
    STOP_LIMIT,
    TAKE_LIMIT,
}
