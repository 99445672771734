package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class ScannerResponse {
    @Serializable
    @SerialName("ConnectorsUpdate")
    data class ConnectorsUpdate(
        val connectors: Connectors,
    ) : ScannerResponse()

    @Serializable
    @SerialName("DealsUpdate")
    data class DealsUpdate(
        val subscriptionIds: Set<Long>,
        val deals: Deals,
    ) : ScannerResponse()
}
