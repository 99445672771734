package com.edvorg.trade.common.frontend.services

import org.w3c.notifications.GRANTED
import org.w3c.notifications.Notification
import org.w3c.notifications.NotificationPermission
import kotlin.js.Promise

class NotificationService : AbstractNotificationService {
    override fun isGranted(): Boolean {
        return Notification.permission == NotificationPermission.GRANTED
    }

    override fun requestPermission(): Promise<Boolean> {
        return Notification.requestPermission().then {
            it == NotificationPermission.GRANTED
        }
    }

    override fun send(message: String) {
        if (!isGranted()) return

        Notification(message)
    }
}
