package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.model.Exchange
import react.ChildrenBuilder

data class ExchangeSelectOption(
    override val value: Exchange,
) : SelectOption<Exchange> {
    override val label: String = value.name
}

fun ChildrenBuilder.exchangeSelect(
    value: Exchange?,
    isClearable: Boolean,
    onChange: (Exchange?) -> Unit,
) {
    autocomplete(
        { null },
        false,
        "exchange",
        150,
        null,
        { newExchange ->
            onChange(newExchange)
        },
        null,
        {
            this.disableClearable = !isClearable
        },
        value?.let { ExchangeSelectOption(value) },
        Exchange.entries.map { ExchangeSelectOption(it) }.toTypedArray(),
        true,
    )
}
