package com.edvorg.trade.backend.frontend.views.widgets.connectors

import com.edvorg.trade.common.frontend.views.WidgetProps
import com.edvorg.trade.common.frontend.views.brokerConnectorsPanel
import com.edvorg.trade.common.frontend.views.marketDataConnectorsPanel
import com.edvorg.trade.common.frontend.views.scannerClientConnectorsPanel
import com.edvorg.trade.common.frontend.views.scannerExecutorConnectorsPanel
import com.edvorg.trade.common.frontend.views.widget
import com.edvorg.trade.common.model.WidgetSettings
import mui.material.Tab
import mui.material.Tabs
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.useState

external interface ConnectorsWidgetProps : WidgetProps<WidgetSettings.Connectors>

private enum class ConnectorsTab {
    BROKER,
    MARKET_DATA,
    SCANNER_CLIENT,
    SCANNER_EXECUTOR,
    OTHER,
}

val connectorsWidget = widget<ConnectorsWidgetProps, WidgetSettings.Connectors>(
    { "Connectors" },
    isStretchable = true,
    isPair = false,
) { _, _, _, _ ->
    var currentTab by useState(ConnectorsTab.MARKET_DATA)

    Tabs {
        value = currentTab.name
        onChange = { _, value ->
            currentTab = ConnectorsTab.valueOf(value)
        }

        centered = true

        Tab {
            label = ReactNode("Broker")
            value = ConnectorsTab.BROKER.name
        }
        Tab {
            label = ReactNode("Market Data")
            value = ConnectorsTab.MARKET_DATA.name
        }
        Tab {
            label = ReactNode("Scanner Client")
            value = ConnectorsTab.SCANNER_CLIENT.name
        }
        Tab {
            label = ReactNode("Scanner Executor")
            value = ConnectorsTab.SCANNER_EXECUTOR.name
        }
        Tab {
            label = ReactNode("Other")
            value = ConnectorsTab.OTHER.name
        }
    }

    div {
        when (currentTab) {
            ConnectorsTab.BROKER -> brokerConnectorsPanel {
            }
            ConnectorsTab.MARKET_DATA -> marketDataConnectorsPanel {
            }
            ConnectorsTab.SCANNER_CLIENT -> scannerClientConnectorsPanel {
            }
            ConnectorsTab.SCANNER_EXECUTOR -> scannerExecutorConnectorsPanel {
            }
            ConnectorsTab.OTHER -> {
                scannerServersPanel {
                }

                uploadFilePanel {
                }

                toolsPanel {
                }
            }
        }
    }
}
