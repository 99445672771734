package com.edvorg.trade.common.model.settings

import com.edvorg.trade.common.model.AlpacaDataApiType
import com.edvorg.trade.common.model.AlpacaEndPoint
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.ClosePriceScannerMode
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.ExanteLevel2Mode
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.OrderType
import com.edvorg.trade.common.model.PostCloseMethod
import com.edvorg.trade.common.model.ScannerDealSelection
import com.edvorg.trade.common.model.ScannerDealsSubscription
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.SpreadScannerOperatorMode
import com.edvorg.trade.common.model.Tick
import com.edvorg.trade.common.model.TickerDetailsFilter
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.config.BridgeConfig
import com.edvorg.trade.common.model.config.PriceTick
import com.edvorg.trade.common.model.config.SamuraiLimitsUnit
import com.edvorg.trade.common.model.config.SamuraiTick
import com.edvorg.trade.common.model.config.ScannerTick
import com.edvorg.trade.common.model.stats.PriceStatType
import com.edvorg.trade.common.model.toHumanReadableString
import com.edvorg.trade.common.utils.format
import io.ktor.http.Url
import kotlinx.datetime.LocalTime
import kotlin.time.Duration

data class MutableSettings(
    override val map: MutableMap<String, Setting>,
) : Settings {
    fun setScannerDealSelection(
        name: String,
        dealSelection: ScannerDealSelection,
        showLabel: Boolean,
    ) {
        map[name] = Setting.ScannerDealSelectionSetting(dealSelection, showLabel)
    }

    fun setBoolean(name: String, value: Boolean, showLabel: Boolean) {
        map[name] = Setting.BooleanSetting(value, showLabel)
    }

    fun setDouble(name: String, value: Double, showLabel: Boolean, width: Int?) {
        map[name] = Setting.StringSetting(value.toString(), width, showLabel)
    }

    fun setDouble(name: String, value: Double, radix: Int, showLabel: Boolean, width: Int?) {
        map[name] = Setting.StringSetting(value.format(radix), width, showLabel)
    }

    fun setOptionalString(name: String, value: String?, showLabel: Boolean, width: Int?) {
        if (value != null) {
            map[name] = Setting.StringSetting(value.trim(), width, showLabel)
        } else {
            map.remove(name)
        }
    }

    fun setString(name: String, value: String, showLabel: Boolean, width: Int?) {
        map[name] = Setting.StringSetting(value.trim(), width, showLabel)
    }

    fun setOptionalBridgeConfig(bridgeConfig: BridgeConfig?, showLabel: Boolean, width: Int?) {
        setOptionalString(
            "autoBridge",
            bridgeConfig?.let { c ->
                "${c.finalMode}:${c.finalDelta}" + c.intermediateRangeModes.joinToString("") { r ->
                    ";${r.mode}:${r.delta}:${r.maxSpread}"
                }
            },
            showLabel,
            width,
        )
    }

    fun setInt(name: String, value: Int, showLabel: Boolean, width: Int?) {
        setString(name, value.toString(), showLabel, width)
    }

    fun setLong(name: String, value: Long, showLabel: Boolean, width: Int?) {
        setString(name, value.toString(), showLabel, width)
    }

    fun setUrl(name: String, value: Url, showLabel: Boolean, width: Int?) {
        setString(name, value.toString(), showLabel, width)
    }

    fun setStringList(name: String, value: Collection<String>, showLabel: Boolean, width: Int?) {
        setString(
            name,
            value.joinToString(","),
            showLabel,
            width,
        )
    }

    fun setExchangesList(name: String, value: Collection<Exchange>, showLabel: Boolean, width: Int?) {
        map[name] = Setting.ExchangesListSetting(value, width, showLabel)
    }

    fun setStringPairsList(name: String, value: List<Pair<String, String>>, showLabel: Boolean, width: Int?) {
        setStringList(name, value.map { (first, second) -> "$first:$second" }, showLabel, width)
    }

    fun setStringTriplesList(
        name: String,
        value: List<Triple<String, String, String>>,
        showLabel: Boolean,
        width: Int?,
    ) {
        setStringList(name, value.map { (first, second, third) -> "$first:$second:$third" }, showLabel, width)
    }

    fun setDuration(name: String, value: Duration, showLabel: Boolean, width: Int?) {
        setString(name, value.toHumanReadableString(), showLabel, width)
    }

    fun setOptionalDuration(name: String, value: Duration?, showLabel: Boolean, width: Int?) {
        setOptionalString(name, value?.toHumanReadableString(), showLabel, width)
    }

    fun setOptionalLocalTime(name: String, value: LocalTime?, showLabel: Boolean) {
        map[name] = Setting.OptionalLocalTimeSetting(value, showLabel)
    }

    fun toImmutable(): ImmutableSettings {
        return ImmutableSettings(map)
    }

    fun setBarSize(name: String, barSize: BarSize, showLabel: Boolean, width: Int?) {
        map[name] = Setting.BarSizeSetting(barSize, width, showLabel)
    }

    fun setDealsSubscription(name: String, subscription: ScannerDealsSubscription, showLabel: Boolean) {
        map[name] = Setting.DealsSubscriptionSetting(subscription, showLabel)
    }

    fun setOrderType(name: String, orderType: OrderType, showLabel: Boolean) {
        map[name] = Setting.OrderTypeSetting(orderType, showLabel)
    }

    fun setScannerId(name: String, scannerId: ScannerId, showLabel: Boolean) {
        map[name] = Setting.ScannerIdSetting(scannerId, showLabel)
    }

    fun setScannerExecutorId(name: String, executorId: ScannerExecutorId, showLabel: Boolean) {
        map[name] = Setting.ScannerExecutorIdSetting(executorId, showLabel)
    }

    fun setOptionalDouble(name: String, value: Double?, showLabel: Boolean, width: Int?) {
        setOptionalString(name, value?.toString(), showLabel, width)
    }

    fun setTick(name: String, tick: Tick, showLabel: Boolean) {
        map[name] = Setting.TickSetting(tick, showLabel)
    }

    fun setScannerTick(name: String, value: ScannerTick, showLabel: Boolean) {
        map[name] = makeScannerTick(value, showLabel)
    }

    fun setPriceTick(name: String, value: PriceTick, showLabel: Boolean) {
        map[name] = makePriceTick(value, showLabel)
    }

    fun setScannerServer(name: String, serverName: String, showLabel: Boolean) {
        map[name] = Setting.ScannerServerSetting(serverName, showLabel)
    }

    fun setCurrency(name: String, currency: Currency, showLabel: Boolean, width: Int?) {
        map[name] = Setting.CurrencySetting(currency, width, showLabel)
    }

    fun setExanteLevel2Mode(name: String, mode: ExanteLevel2Mode, showLabel: Boolean) {
        map[name] = Setting.ExanteLevel2ModeSetting(mode, showLabel)
    }

    fun setAlpacaEndPoint(name: String, endPoint: AlpacaEndPoint, showLabel: Boolean) {
        map[name] = Setting.AlpacaEndPointSetting(endPoint, showLabel)
    }

    fun setAlpacaDataApiType(name: String, dataType: AlpacaDataApiType, showLabel: Boolean) {
        map[name] = Setting.AlpacaDataApiTypeSetting(dataType, showLabel)
    }

    fun setClosePriceScannerSetting(name: String, value: ClosePriceScannerMode, showLabel: Boolean) {
        map[name] = Setting.ClosePriceScannerSetting(value, showLabel)
    }

    fun setTickerDetailsFilter(name: String, value: TickerDetailsFilter, showLabel: Boolean) {
        map[name] = Setting.TickerDetailsFilterSetting(value, showLabel)
    }

    fun setSpreadScannerOperator(name: String, value: SpreadScannerOperatorMode, showLabel: Boolean) {
        map[name] = Setting.SpreadScannerOperatorSetting(value, showLabel)
    }

    override fun plus(settings2: Settings): Settings {
        return MutableSettings((map + settings2.map).toMutableMap())
    }

    fun setTickerType(name: String, value: TickerType, showLabel: Boolean, width: Int?) {
        map[name] = Setting.TickerTypeSetting(value, width, showLabel)
    }

    fun setExchange(name: String, value: Exchange, showLabel: Boolean) {
        map[name] = Setting.ExchangeSetting(value, showLabel)
    }

    fun setSamuraiTick(name: String, value: SamuraiTick, showLabel: Boolean) {
        map[name] = makeSamuraiTick(value, showLabel)
    }

    fun setPriceStatType(name: String, value: PriceStatType, showLabel: Boolean) {
        map[name] = Setting.PriceStatTypeSetting(value, showLabel)
    }

    fun setIndicator(name: String, value: Indicator, showLabel: Boolean) {
        map[name] = makeIndicator(value, showLabel)
    }

    fun setTypedTicker(name: String, value: TypedTicker, showLabel: Boolean) {
        map[name] = makeTypedTicker(value, showLabel)
    }

    fun setPostCloseMethod(name: String, value: PostCloseMethod, showLabel: Boolean) {
        map[name] = Setting.PostCloseMethodSetting(value, showLabel)
    }

    fun setSamuraiLimitsUnit(name: String, value: SamuraiLimitsUnit, showLabel: Boolean) {
        map[name] = makeSamuraiLimitsUnit(value, showLabel)
    }

    companion object {
        fun makeSamuraiLimitsUnit(value: SamuraiLimitsUnit, showLabel: Boolean): Setting.SamuraiLimitsUnitSetting {
            val s = MutableSettings(mutableMapOf())
            when (value) {
                is SamuraiLimitsUnit.Absolute -> {
                    s.setString("_type", "Absolute", false, null)
                }
                is SamuraiLimitsUnit.Fractional -> {
                    s.setString("_type", "Fractional", false, null)
                }
                is SamuraiLimitsUnit.SD -> {
                    s.setString("_type", "SD", false, null)
                    s.setBarSize("barInterval", value.indicator.barInterval, false, 150)
                    s.setInt("windowDataPoints", value.indicator.windowDataPoints, false, 135)
                }
            }
            return Setting.SamuraiLimitsUnitSetting(s.map, showLabel)
        }

        fun makeIndicator(value: Indicator, showLabel: Boolean): Setting.IndicatorSetting {
            val s = MutableSettings(mutableMapOf())
            when (value) {
                is Indicator.MA -> {
                    s.setString("_type", "MA", false, null)
                    s.setBarSize("barInterval", value.barInterval, false, 150)
                    s.setInt("count", value.count, false, 75)
                    s.setInt("windowDataPoints", value.windowDataPoints, false, 135)
                }
                is Indicator.SD -> {
                    s.setString("_type", "SD", false, null)
                    s.setBarSize("barInterval", value.barInterval, false, 150)
                    s.setInt("windowDataPoints", value.windowDataPoints, false, 135)
                }
                is Indicator.LookBack -> {
                    s.setString("_type", "LookBack", false, null)
                    s.setPriceTick("tick", value.tick, false)
                    s.setDuration("lookBack", value.lookBack, false, 150)
                }
            }
            return Setting.IndicatorSetting(s.map, showLabel)
        }

        fun makeSamuraiTick(value: SamuraiTick, showLabel: Boolean): Setting.SamuraiTickSetting {
            val s = MutableSettings(mutableMapOf())
            when (value) {
                is SamuraiTick.IndicatorTick -> {
                    s.setString("_type", "Indicator", false, null)
                    s.setIndicator("indicator", value.indicator, false)
                }

                SamuraiTick.OrderBook -> {
                    s.setString("_type", "OrderBook", false, null)
                }

                is SamuraiTick.Stat -> {
                    s.setString("_type", "Stat", false, null)
                    s.setPriceStatType("priceStatType", value.priceStatType, false)
                }
            }
            return Setting.SamuraiTickSetting(s.map, showLabel)
        }

        fun makeScannerTick(value: ScannerTick, showLabel: Boolean): Setting.ScannerTickSetting {
            val s = MutableSettings(mutableMapOf())
            when (value) {
                is ScannerTick.Indicator -> {
                    s.setString("_type", "Indicator", false, null)
                    s.setIndicator("indicator", value.indicator, false)
                }

                ScannerTick.Bid -> {
                    s.setString("_type", "Bid", false, null)
                }

                ScannerTick.Ask -> {
                    s.setString("_type", "Ask", false, null)
                }

                ScannerTick.Mid -> {
                    s.setString("_type", "Mid", false, null)
                }

                is ScannerTick.Stat -> {
                    s.setString("_type", "Stat", false, null)
                    s.setPriceStatType("priceStatType", value.priceStatType, false)
                }
            }
            return Setting.ScannerTickSetting(s.map, showLabel)
        }

        fun makePriceTick(value: PriceTick, showLabel: Boolean): Setting.PriceTickSetting {
            val s = MutableSettings(mutableMapOf())
            when (value) {
                PriceTick.Bid -> {
                    s.setString("_type", "Bid", false, null)
                }

                PriceTick.Ask -> {
                    s.setString("_type", "Ask", false, null)
                }

                PriceTick.Mid -> {
                    s.setString("_type", "Mid", false, null)
                }

                is PriceTick.Stat -> {
                    s.setString("_type", "Stat", false, null)
                    s.setPriceStatType("priceStatType", value.priceStatType, false)
                }
            }
            return Setting.PriceTickSetting(s.map, showLabel)
        }

        fun makeTypedTicker(value: TypedTicker, showLabel: Boolean): Setting.TypedTickerSetting {
            val s = MutableSettings(mutableMapOf())
            s.setTickerType("tickerType", value.tickerType, false, 125)
            s.setExchange("exchange", value.exchange, false)
            s.setString("ticker", value.ticker.id, false, 75)
            return Setting.TypedTickerSetting(s.map, showLabel)
        }
    }
}
