package com.edvorg.trade.common.model.chartscript

import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.HumanReadableDurationSerializer
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds

@Serializable
data class ChartScriptConfig(
    val createdAt: Long = 0,
    val ticks: Map<String, ChartScriptTick>,
    val script: LuaScript,
    @Serializable(with = HumanReadableDurationSerializer::class)
    val tickInterval: Duration,
    val pointsLimit: Int,
    val skipEqualTicks: Boolean,
    val startMark: Boolean = false,
    val withHistory: Boolean = true,
    val historyBarSize: BarSize = BarSize.HOUR,
) {
    companion object {
        val defaultScript = ChartScriptConfig(
            Clock.System.now().toEpochMilliseconds(),
            mapOf(
                "leg1" to ChartScriptTick.OrderBookTick(
                    Exchange.MOEX + "TATN",
                    null,
                ),
                "leg2" to ChartScriptTick.OrderBookTick(
                    Exchange.MOEX + "TATNP",
                    null,
                ),
            ),
            LuaScript(
                "local s = leg1.bid - leg2.ask\n" +
                    "plot(s)",
            ),
            500.milliseconds,
            10000,
            true,
            true,
            true,
            BarSize.HOUR,
        )
    }
}
