package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.MatchResult
import kotlinx.serialization.Serializable

@Serializable
data class OptionalDoubleRange(
    val min: Double? = null,
    val max: Double? = null,
    val enabled: Boolean = true,
) {
    fun match(value: Double): MatchResult {
        if (!enabled) {
            return MatchResult.Disabled
        }
        if (min != null && value < min) {
            return MatchResult.TooLow(value.toString(), min.toString())
        }
        if (max != null && max < value) {
            return MatchResult.TooHigh(value.toString(), max.toString())
        }
        return MatchResult.Matches
    }

    fun isPresent(): Boolean {
        return (min != null || max != null) && enabled
    }
}
