package com.edvorg.trade.common.frontend.services

import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow

class ChannelBus<T, U>(
    topics: Array<T>,
) {
    private val channels: MutableMap<T, Channel<U>> =
        topics.associateWith { Channel<U>() }.toMutableMap()

    fun send(topic: T, action: U): Boolean {
        return getChannel(topic).trySend(action).isSuccess
    }

    fun getChannel(topic: T): Channel<U> {
        val channel = channels[topic]
        return if (channel != null) {
            channel
        } else {
            val newChannel = Channel<U>()
            channels[topic] = newChannel

            newChannel
        }
    }
}

class FlowBus<T, U>(
    topics: Array<T>,
) {
    private val channels: MutableMap<T, Pair<MutableSharedFlow<U>, Flow<U>>> =
        topics.associateWith {
            val tx = MutableSharedFlow<U>(0, 10)
            val rx = tx.asSharedFlow()
            Pair(tx, rx)
        }.toMutableMap()

    fun send(topic: T, action: U): Boolean {
        return getFlowImpl(topic).first.tryEmit(action)
    }

    fun getFlow(topic: T): Flow<U> {
        return getFlowImpl(topic).second
    }

    private fun getFlowImpl(topic: T): Pair<MutableSharedFlow<U>, Flow<U>> {
        val channel = channels[topic]
        return if (channel != null) {
            channel
        } else {
            val tx = MutableSharedFlow<U>(1, 100)
            val rx = tx.asSharedFlow()
            val newFlow = Pair(tx, rx)
            channels[topic] = newFlow

            newFlow
        }
    }
}
