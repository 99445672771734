@file:UseSerializers(UrlSerializer::class)

package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.ScannerTick
import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.time.Duration

@Serializable
sealed class ScannerClientConfig {
    abstract val createdAt: Long
    abstract val autoStart: Boolean
    abstract val executorId: ScannerExecutorId?
    abstract val currency: Currency
    abstract val exchangePairs: Set<ExchangePair>
    abstract val matchByEntityId: Boolean
    abstract val tickerListName: String

    abstract fun withCreatedAt(createdAt: Long): ScannerClientConfig
    abstract fun displayName(): String

    @Serializable
    @SerialName("Level1")
    data class Level1(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val includeUnShortable: Boolean,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): Level1 {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Level1"
        }
    }

    @Serializable
    @SerialName("VWAP")
    data class VWAP(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val includeUnShortable: Boolean,
        val subscribeToTrades: Boolean,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): VWAP {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "VWAP"
        }
    }

    @Serializable
    @SerialName("VWAPPlus")
    data class VWAPPlus(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val includeUnShortable: Boolean,
        val tick1: Tick,
        val subscribeToTrades: Boolean,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): VWAPPlus {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "VWAPlus"
        }
    }

    @Serializable
    @SerialName("VWEMA")
    data class VWEMA(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val includeUnShortable: Boolean,
        val tick1: Tick,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): VWEMA {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "VWEMA"
        }
    }

    @Serializable
    @SerialName("Custom")
    data class Custom(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val includeUnShortable: Boolean,
        val tick1: ScannerTick,
        val tick2: ScannerTick,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): Custom {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Custom"
        }
    }

    @Serializable
    @SerialName("LastPriceVWAP")
    data class LastPriceVWAP(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): LastPriceVWAP {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "LastPriceVWAP"
        }
    }

    @Serializable
    @SerialName("LastPrice")
    data class LastPrice(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): LastPrice {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "LastPrice"
        }
    }

    @Serializable
    @SerialName("Bid")
    data class Bid(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): Bid {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Bid"
        }
    }

    @Serializable
    @SerialName("ClosePrice")
    data class ClosePrice(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        val closePriceMode: ClosePriceScannerMode,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val includeUnShortable: Boolean,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): ClosePrice {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "ClosePrice"
        }
    }

    @Serializable
    @SerialName("Spread")
    data class Spread(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val includeUnShortable: Boolean,
        val statExchange: Exchange,
        val statType: PriceStatType,
        val diffOperator: SpreadScannerOperatorMode,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): Spread {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Spread"
        }
    }

    @Serializable
    @SerialName("OrderBookRecorder")
    data class OrderBookRecorder(
        override val createdAt: Long,
        override val autoStart: Boolean,
        override val executorId: ScannerExecutorId?,
        override val currency: Currency,
        override val exchangePairs: Set<ExchangePair>,
        override val matchByEntityId: Boolean,
        override val tickerListName: String,
        val chunkSize: Int,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val chunkDelay: Duration,
        val historicalStorePath: String,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val subscriptionTimeout: Duration,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val timeFrame: Duration,
    ) : ScannerClientConfig() {
        override fun withCreatedAt(createdAt: Long): OrderBookRecorder {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "OrderBookRecorder"
        }
    }
}
