package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(GroupIdSerializer::class)
data class GroupId(val id: String)

object GroupIdSerializer : KSerializer<GroupId> {
    override fun deserialize(decoder: Decoder): GroupId {
        return GroupId(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: GroupId) {
        encoder.encodeString(value.id)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "GroupIdIdentifier",
        PrimitiveKind.STRING,
    )
}
