package com.edvorg.trade.common.model.portfolio

import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.PortfolioPosition
import com.edvorg.trade.common.model.TypedTicker
import kotlinx.serialization.Serializable

@Serializable
data class ExtendedPortfolioPosition(
    val brokerId: BrokerId,
    val ticker: TypedTicker,
    val position: PortfolioPosition,
    val currency: Currency,
    val lotSize: Int,
    val volumeWithLots: Double,
    val profit: Double?,
    val profitPercent: Double?,
    val available: Double,
    val lastPrice: Double?,
    val dividend: Double?,
    val daysSinceDividend: Int?,
    val daysSinceEarnings: Int?,
    val companyName: String?,
)
