package com.edvorg.trade.common.model

import com.edvorg.trade.common.utils.calcDifferencePercent
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.math.abs

@Serializable
data class Trade(
    @SerialName("p")
    val price: Double,
    @SerialName("v")
    val volume: Long,
    @SerialName("t")
    val time: Long, // in ms
    @Serializable(ExchangeIndexSerializer::class)
    @SerialName("e")
    val exchange: Exchange,
    @SerialName("c")
    val conditions: Set<String>,
    val side: OperationType?,
)

fun List<Trade>.merge(
    newEntries: List<Trade>,
    maxTrades: Int,
    historical: Boolean,
): List<Trade> {
    return if (historical) {
        (this + newEntries).take(maxTrades)
    } else {
        (newEntries + this).take(maxTrades)
    }
}

fun List<Trade>.filterByMaxDeviation(maxDeviationPercent: Double): List<Trade> {
    if (maxDeviationPercent <= 0) {
        return this
    }

    val toDelete = mutableListOf<Trade>()
    forEachIndexed { index, trade ->

        val checkLeft = getOrNull(index - 1)?.let {
            val priceDiff = calcDifferencePercent(it.price, trade.price)
            abs(priceDiff) <= maxDeviationPercent
        } ?: false

        val checkRight = getOrNull(index + 1)?.let {
            val priceDiff = calcDifferencePercent(it.price, trade.price)
            abs(priceDiff) <= maxDeviationPercent
        } ?: false

        if (!checkLeft && !checkRight) {
            toDelete.add(trade)
        }
    }

    return filter {
        it !in toDelete
    }
}
