package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.HumanReadableDurationSerializer
import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
data class PollConfig(
    @Serializable(with = HumanReadableDurationSerializer::class)
    val pollInterval: Duration,
    @Serializable(with = HumanReadableDurationSerializer::class)
    val requestTimeout: Duration,
    val enabled: Boolean,
)
