package com.edvorg.trade.common.serialization

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.cbor.Cbor
import kotlinx.serialization.json.Json

object Defaults {
    @ExperimentalSerializationApi
    val cbor = Cbor {
        ignoreUnknownKeys = true
    }

    val json = Json {
        classDiscriminator = "type"
        ignoreUnknownKeys = true
        allowStructuredMapKeys = true
        encodeDefaults = true
    }

    val jsonPretty = Json {
        classDiscriminator = "type"
        ignoreUnknownKeys = true
        allowStructuredMapKeys = true
        prettyPrint = true
        encodeDefaults = true
    }
}
