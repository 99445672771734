package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.model.BinanceFutureMarket
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.MarketDataClientConfig
import com.edvorg.trade.common.model.MarketDataConnectorStatus
import com.edvorg.trade.common.model.MarketDataId
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.TickerPricePrefill
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.config.PollConfig
import com.edvorg.trade.common.model.config.marketDataClientOptions
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import com.edvorg.trade.common.serialization.Defaults
import com.edvorg.trade.common.utils.format
import kotlinx.coroutines.launch
import mui.icons.material.AddCircle
import mui.icons.material.AddToPhotos
import mui.material.Button
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.material.Tooltip
import mui.system.responsive
import mui.system.sx
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.h3
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.ClassName
import web.cssom.Globals.Companion.unset
import web.cssom.px

private val marketDataTypes = marketDataClientOptions.values.map {
    it.displayName()
}.toSet()

enum class StatusFilter {
    CONNECTED,
    DISCONNECTED,
    CONNECTING,
    DISCONNECTING,
    FAILED,
    ;

    companion object {
        fun fromConnectorStatus(status: ConnectorStatus): StatusFilter? {
            return when {
                status.isConnected() -> CONNECTED
                status.isDisconnected() -> DISCONNECTED
                status.isConnecting() -> CONNECTING
                status.isDisconnecting() -> DISCONNECTING
                status.isFailed() -> FAILED
                else -> null
            }
        }
    }
}
val marketDataConnectorsPanel = fcWithScope<Props> { _, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val clickWatcher = getContext<CommonContext>().clickWatcher
    val toaster = getContext<CommonContext>().toaster

    var selectedMarketDataServer by useState("local")
    var marketDataClientTypeToCreate by useState(marketDataClientOptions.keys.first())
    val (shownMarketDataClients, setShownMarketDataClients) = useState(emptySet<MarketDataId>())
    var marketDataClients by useState(emptyMap<MarketDataId, MarketDataConnectorStatus>())
    val (confirmDialog, setConfirmDialog) = useState<Confirm?>(null)
    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)

    var searchInput by useState("")
    var filterMarketDataType: String? by useState(null)
    var filterConnectorStatus: StatusFilter? by useState(null)

    useEffect(selectedMarketDataServer) {
        val client = connectorServersManager.getServerConnection(
            selectedMarketDataServer,
        )?.client ?: return@useEffect
        val handle = client.connectionManager.subscribeToMarketDataClientsUpdate {
            marketDataClients = it.marketDataClients
        }
        cleanup {
            handle.unsubscribe()
        }
    }

    fun saveMarketDataClientSettings(
        marketDataId: MarketDataId,
        settings: Settings,
        prevConfig: MarketDataClientConfig,
    ) {
        val autoStart = settings.getBoolean("autoStart")
        val level1Exchanges = settings.getExchangesList("level1Exchanges").toSet()
        val statExchanges = settings.getExchangesList("statExchanges").toSet()
        val tradeExchanges = settings.getExchangesList("tradeExchanges").toSet()
        val candleExchanges = settings.getExchangesList("candleExchanges").toSet()

        val newMarketDataClientSettings = when (prevConfig) {
            is MarketDataClientConfig.Relay -> {
                MarketDataClientConfig.Relay(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    BrokerId(settings.getString("brokerId")),
                )
            }

            is MarketDataClientConfig.IQFeed -> {
                MarketDataClientConfig.IQFeed(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("protocol"),
                    settings.getInt("level1Port"),
                    settings.getInt("historyPort"),
                    settings.getInt("maxHistoricalTrades"),
                    settings.getInt("sockets"),
                    settings.getInt("socketBuffer"),
                    settings.getInt("frameConsumers"),
                )
            }

            is MarketDataClientConfig.OrderBooksSimulation -> {
                val tickers = settings.getStringTriplesList(
                    "tickers",
                ).mapNotNull { (ticker, priceStr, spreadStr) ->
                    val prefill = priceStr.toDoubleOrNull()?.let { price ->
                        spreadStr.toDoubleOrNull()?.let { spread ->
                            TickerPricePrefill.MidPointPrefill(price, spread)
                        }
                    } ?: return@mapNotNull null
                    Pair(
                        Ticker(ticker),
                        prefill,
                    )
                }.flatMap { (ticker, prefill) ->
                    level1Exchanges.map { exchange ->
                        Pair(
                            TypedTicker(TickerType.STOCK, exchange, ticker),
                            prefill,
                        )
                    }
                }.toMap()
                MarketDataClientConfig.OrderBooksSimulation(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    BrokerId(settings.getString("brokerId")),
                    tickers,
                    settings.getOptionalDuration("simulateLevel1Interval"),
                    settings.getOptionalDuration("simulateTradesInterval"),
                )
            }

            is MarketDataClientConfig.InteractiveBrokers -> {
                MarketDataClientConfig.InteractiveBrokers(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    BrokerId(settings.getString("brokerId")),
                )
            }

            is MarketDataClientConfig.Remote -> {
                MarketDataClientConfig.Remote(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getDuration("connectionPingTimeout"),
                    settings.getUrl("endpoint"),
                    settings.getDouble("vwapMultiplier"),
                    settings.getBoolean("full"),
                    settings.getBoolean("disablePinger"),
                )
            }

            is MarketDataClientConfig.Alor -> {
                MarketDataClientConfig.Alor(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getBoolean("sandbox"),
                    settings.getString("token"),
                    settings.getInt("orderBookSockets"),
                    settings.getInt("statSockets"),
                    settings.getInt("tradeSockets"),
                    settings.getInt("candleSockets"),
                    settings.getInt("socketBuffer"),
                    settings.getLong("chartsCacheSize"),
                    settings.getDuration("minuteChartTimeFrame"),
                    settings.getDuration("fiveMinuteChartTimeFrame"),
                    settings.getDuration("hourChartTimeFrame"),
                    settings.getDuration("dayChartTimeFrame"),
                    settings.getInt("countHistoricalCandles"),
                    settings.getInt("orderBookDepth"),
                    PollConfig(
                        settings.getDuration("securitiesPollConfig.pollInterval"),
                        settings.getDuration("securitiesPollConfig.requestTimeout"),
                        settings.getBoolean("securitiesPollConfig.enabled"),
                    ),
                    settings.getDuration("socketStartInterval"),
                    settings.getBoolean("caching"),
                )
            }

            is MarketDataClientConfig.Sk -> {
                MarketDataClientConfig.Sk(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getInt("orderBookSockets"),
                    settings.getInt("frameConsumers"),
                    settings.getInt("socketBuffer"),
                )
            }

            is MarketDataClientConfig.Bitpanda -> {
                MarketDataClientConfig.Bitpanda(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getStringList("socketRooms").toSet(),
                    settings.getStringList("stockTypes").toSet(),
                    PollConfig(
                        settings.getDuration("pollInterval"),
                        settings.getDuration("requestTimeout"),
                        settings.getBoolean("pollEnabled"),
                    ),
                    settings.getInt("frameConsumers"),
                    settings.getInt("socketBuffer"),
                )
            }

            is MarketDataClientConfig.TradingView -> {
                MarketDataClientConfig.TradingView(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("token"),
                    settings.getInt("sockets"),
                    settings.getInt("frameConsumers"),
                    settings.getInt("socketBuffer"),
                )
            }

            is MarketDataClientConfig.Nasdaq -> {
                MarketDataClientConfig.Nasdaq(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                )
            }

            is MarketDataClientConfig.Okx -> {
                MarketDataClientConfig.Okx(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                )
            }

            is MarketDataClientConfig.Exante -> {
                MarketDataClientConfig.Exante(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("applicationId"),
                    settings.getString("accessKey"),
                    settings.getBoolean("sandbox"),
                    settings.getInt("tradeConnections"),
                    settings.getInt("connectionBuffer"),
                    settings.getInt("frameConsumers"),
                    settings.getExanteLevel2Mode("level2Mode"),
                )
            }

            is MarketDataClientConfig.Alpaca -> {
                MarketDataClientConfig.Alpaca(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("keyId"),
                    settings.getString("secretKey"),
                    settings.getAlpacaEndPoint("endPoint"),
                    settings.getAlpacaDataApiType("apiType"),
                    settings.getInt("connections"),
                    settings.getInt("connectionBuffer"),
                    settings.getDuration("shiftTrades"),
                    settings.getBoolean("tradeGroupRequests"),
                    settings.getBoolean("withoutLive"),
                    settings.getPostCloseMethod("postCloseMethod"),
                    settings.getBoolean("wildcardSubscription"),
                    settings.getDuration("orderBookUpdatePeriod"),
                    settings.getBoolean("caching"),
                )
            }

            is MarketDataClientConfig.TelegramNews -> {
                MarketDataClientConfig.TelegramNews(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("phoneNumber"),
                )
            }

            is MarketDataClientConfig.Mexc -> {
                MarketDataClientConfig.Mexc(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getDuration("requestDelay"),
                    settings.getInt("sockets"),
                    settings.getInt("frameConsumers"),
                    settings.getDuration("pingInterval"),
                )
            }

            is MarketDataClientConfig.GateIO -> {
                MarketDataClientConfig.GateIO(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getInt("sockets"),
                    settings.getInt("frameConsumers"),
                )
            }

            is MarketDataClientConfig.TVPython -> {
                MarketDataClientConfig.TVPython(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("candlesInterval"),
                )
            }

            is MarketDataClientConfig.Csv -> {
                MarketDataClientConfig.Csv(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("path"),
                )
            }

            is MarketDataClientConfig.Binance -> {
                MarketDataClientConfig.Binance(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getInt("sockets"),
                    settings.getInt("frameConsumers"),
                )
            }

            is MarketDataClientConfig.Freedom -> {
                MarketDataClientConfig.Freedom(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("username"),
                    settings.getString("password"),
                    settings.getString("userId"),
                    settings.getInt("frameConsumers"),
                    settings.getInt("tickersOnSocket"),
                )
            }

            is MarketDataClientConfig.RobinHood -> {
                MarketDataClientConfig.RobinHood(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("username"),
                    settings.getString("password"),
                    PollConfig(
                        settings.getDuration("orderBooksPollConfig.pollInterval"),
                        settings.getDuration("orderBooksPollConfig.requestTimeout"),
                        settings.getBoolean("orderBooksPollConfig.enabled"),
                    ),
                    settings.getInt("chunkSize"),
                    settings.getDuration("minOrderBookPassedTime"),
                )
            }

            is MarketDataClientConfig.StreetInsider -> {
                MarketDataClientConfig.StreetInsider(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    PollConfig(
                        settings.getDuration("pollInterval"),
                        settings.getDuration("requestTimeout"),
                        true,
                    ),
                )
            }

            is MarketDataClientConfig.DxFeed -> {
                MarketDataClientConfig.DxFeed(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("dxFeed"),
                    settings.getInt("sockets"),
                    settings.getInt("frameConsumers"),
                    settings.getBoolean("useLastQuote"),
                    settings.getInt("tradeSockets"),
                    settings.getString("postFix"),
                    settings.getDuration("tradesTimeframe"),
                )
            }

            is MarketDataClientConfig.Iress -> {
                MarketDataClientConfig.Iress(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getString("username"),
                    settings.getString("password"),
                    settings.getString("server"),
                    settings.getInt("port"),
                    settings.getInt("requestSenders"),
                    settings.getInt("MBLLayer"),
                    settings.getStringPairsList("exchangeRoutes").mapNotNull { (exchange, route) ->
                        Exchange.fromId(exchange)?.let { ex ->
                            Pair(ex, route)
                        }
                    }.toMap(),
                    settings.getBoolean("quotationFullMask"),
                    settings.getBoolean("includeEmptyVolume"),
                    settings.getBoolean("enableTrace"),
                    settings.getExchangesList("isinExchanges").toSet(),
                    settings.getExchangesList("subscribeToLevel1Exchanges").toSet(),
                    settings.getStringPairsList("postfixExchanges").mapNotNull { (exchange, postfix) ->
                        Exchange.fromId(exchange)?.let { ex ->
                            Pair(ex, postfix)
                        }
                    }.toMap(),
                    settings.getBoolean("subscribeAllTrades"),
                    settings.getDuration("connectionTimeoutMs"),
                )
            }

            is MarketDataClientConfig.MexcFut -> {
                MarketDataClientConfig.MexcFut(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    settings.getInt("sockets"),
                    settings.getInt("frameConsumers"),
                    settings.getInt("depth"),
                    settings.getDuration("pingInterval"),
                )
            }

            is MarketDataClientConfig.BinanceFut -> {
                MarketDataClientConfig.BinanceFut(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    BinanceFutureMarket.fromName(
                        settings.getString("market").uppercase(),
                    ) ?: BinanceFutureMarket.USDT,
                )
            }

            is MarketDataClientConfig.MooMoo -> {
                MarketDataClientConfig.MooMoo(
                    prevConfig.createdAt,
                    autoStart,
                    level1Exchanges,
                    statExchanges,
                    tradeExchanges,
                    candleExchanges,
                    PollConfig(
                        settings.getDuration("pollInterval"),
                        settings.getDuration("requestTimeout"),
                        true,
                    ),
                    settings.getInt("pageSize"),
                )
            }
        }
        val client = connectorServersManager.getServerConnection(selectedMarketDataServer)?.client
        if (client != null) {
            scope.launch {
                client.connectionManager.sendUpdateMarketDataConnector(
                    marketDataId,
                    newMarketDataClientSettings,
                )
            }
        }
    }

    fun resetMarketDataClientSettings(
        settings: MutableSettings,
        config: MarketDataClientConfig,
    ) {
        settings.setBoolean("autoStart", config.autoStart, true)
        settings.setExchangesList("level1Exchanges", config.level1Exchanges, true, 500)
        settings.setExchangesList("statExchanges", config.statExchanges, true, 500)
        settings.setExchangesList("tradeExchanges", config.tradeExchanges, true, 500)
        settings.setExchangesList("candleExchanges", config.candleExchanges, true, 500)

        when (config) {
            is MarketDataClientConfig.Relay -> {
                settings.setString("brokerId", config.brokerId.id, false, null)
            }

            is MarketDataClientConfig.IQFeed -> {
                settings.setString("protocol", config.protocol, false, null)
                settings.setInt("level1Port", config.level1Port, false, null)
                settings.setInt("historyPort", config.historyPort, false, null)
                settings.setInt("maxHistoricalTrades", config.maxHistoricalTrades, false, null)
                settings.setInt("sockets", config.sockets, false, null)
                settings.setInt("socketBuffer", config.socketBuffer, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
            }

            is MarketDataClientConfig.OrderBooksSimulation -> {
                settings.setString("brokerId", config.brokerId.id, false, null)
                settings.setOptionalDuration("simulateLevel1Interval", config.simulateLevel1Interval, false, null)
                settings.setOptionalDuration("simulateTradesInterval", config.simulateTradesInterval, false, null)
                settings.setStringTriplesList(
                    "tickers",
                    config.tickers.mapKeys {
                        it.key.ticker
                    }.mapNotNull {
                        val prefill = it.value
                        if (prefill !is TickerPricePrefill.MidPointPrefill) {
                            return@mapNotNull null
                        }
                        Triple(
                            it.key.id,
                            prefill.midpoint.format(2),
                            prefill.spread.format(2),
                        )
                    },
                    false,
                    null,
                )
            }

            is MarketDataClientConfig.InteractiveBrokers -> {
                settings.setString("brokerId", config.brokerId.id, false, null)
            }

            is MarketDataClientConfig.Remote -> {
                settings.setDuration("connectionPingTimeout", config.connectionPingTimeout, false, null)
                settings.setUrl("endpoint", config.endpoint, false, null)
                settings.setDouble("vwapMultiplier", config.vwapMultiplier, false, null)
                settings.setBoolean("full", config.full, true)
                settings.setBoolean("disablePinger", config.disablePinger, true)
            }

            is MarketDataClientConfig.Alor -> {
                settings.setBoolean("sandbox", config.sandbox, true)
                settings.setString("token", config.token, false, null)
                settings.setInt("orderBookSockets", config.orderBookSockets, false, null)
                settings.setInt("statSockets", config.statSockets, false, null)
                settings.setInt("tradeSockets", config.tradeSockets, false, null)
                settings.setInt("candleSockets", config.candleSockets, false, null)
                settings.setInt("socketBuffer", config.socketBuffer, false, null)
                settings.setLong("chartsCacheSize", config.chartsCacheSize, false, null)
                settings.setDuration("minuteChartTimeFrame", config.minuteChartTimeFrame, false, null)
                settings.setDuration("fiveMinuteChartTimeFrame", config.minuteChartTimeFrame, false, null)
                settings.setDuration("hourChartTimeFrame", config.hourChartTimeFrame, false, null)
                settings.setDuration("dayChartTimeFrame", config.dayChartTimeFrame, false, null)
                settings.setInt("countHistoricalCandles", config.countHistoricalCandles, false, null)
                settings.setInt("orderBookDepth", config.orderBookDepth, false, null)
                settings.setDuration(
                    "securitiesPollConfig.pollInterval",
                    config.securitiesPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "securitiesPollConfig.requestTimeout",
                    config.securitiesPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("securitiesPollConfig.enabled", config.securitiesPollConfig.enabled, true)
                settings.setDuration("socketStartInterval", config.socketStartInterval, false, null)
                settings.setBoolean("caching", config.caching, true)
            }

            is MarketDataClientConfig.Okx -> {
            }

            is MarketDataClientConfig.Sk -> {
                settings.setInt("orderBookSockets", config.orderBookSockets, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setInt("socketBuffer", config.socketBuffer, false, null)
            }

            is MarketDataClientConfig.Bitpanda -> {
                settings.setStringList("socketRooms", config.socketRooms.toList(), false, null)
                settings.setStringList("stockTypes", config.stockTypes.toList(), false, null)
                settings.setDuration("pollInterval", config.pollConfig.pollInterval, false, null)
                settings.setDuration("requestTimeout", config.pollConfig.requestTimeout, false, null)
                settings.setBoolean("pollEnabled", config.pollConfig.enabled, true)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setInt("socketBuffer", config.socketBuffer, false, null)
            }

            is MarketDataClientConfig.TradingView -> {
                settings.setString("token", config.token, false, null)
                settings.setInt("sockets", config.sockets, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setInt("socketBuffer", config.socketBuffer, false, null)
            }

            is MarketDataClientConfig.Exante -> {
                settings.setString("applicationId", config.applicationId, false, null)
                settings.setString("accessKey", config.accessKey, false, null)
                settings.setBoolean("sandbox", config.sandbox, true)
                settings.setInt("tradeConnections", config.tradeConnections, false, null)
                settings.setInt("connectionBuffer", config.connectionBuffer, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setExanteLevel2Mode("level2Mode", config.level2Mode, true)
            }

            is MarketDataClientConfig.Nasdaq -> {
            }

            is MarketDataClientConfig.Alpaca -> {
                settings.setString("keyId", config.keyId, false, null)
                settings.setString("secretKey", config.secretKey, false, null)
                settings.setAlpacaEndPoint("endPoint", config.dataEndPoint, false)
                settings.setAlpacaDataApiType("apiType", config.apiType, true)
                settings.setInt("connections", config.connections, false, null)
                settings.setInt("connectionBuffer", config.connectionBuffer, false, null)
                settings.setDuration("shiftTrades", config.shiftTrades, false, null)
                settings.setBoolean("tradeGroupRequests", config.tradeGroupRequests, true)
                settings.setBoolean("withoutLive", config.withoutLive, true)
                settings.setPostCloseMethod("postCloseMethod", config.postCloseMethod, true)
                settings.setBoolean("wildcardSubscription", config.wildcardSubscription, true)
                settings.setDuration("orderBookUpdatePeriod", config.orderBookUpdatePeriod, false, null)
                settings.setBoolean("caching", config.caching, true)
            }

            is MarketDataClientConfig.TelegramNews -> {
                settings.setString("phoneNumber", config.phoneNumber, false, null)
            }

            is MarketDataClientConfig.Mexc -> {
                settings.setDuration("requestDelay", config.requestDelay, false, null)
                settings.setInt("sockets", config.sockets, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setDuration("pingInterval", config.pingInterval, false, null)
            }

            is MarketDataClientConfig.TVPython -> {
                settings.setString("candlesInterval", config.candlesInterval, false, null)
            }

            is MarketDataClientConfig.Csv -> {
                settings.setString("path", config.path, false, null)
            }

            is MarketDataClientConfig.GateIO -> {
                settings.setInt("sockets", config.sockets, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
            }

            is MarketDataClientConfig.Binance -> {
                settings.setInt("sockets", config.sockets, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
            }

            is MarketDataClientConfig.Freedom -> {
                settings.setString("username", config.username, false, null)
                settings.setString("password", config.password, false, null)
                settings.setString("userId", config.userId, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setInt("tickersOnSocket", config.tickersOnSocket, false, null)
            }

            is MarketDataClientConfig.RobinHood -> {
                settings.setString("username", config.username, false, null)
                settings.setString("password", config.password, false, null)
                settings.setDuration(
                    "orderBooksPollConfig.pollInterval",
                    config.orderBooksPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "orderBooksPollConfig.requestTimeout",
                    config.orderBooksPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("orderBooksPollConfig.enabled", config.orderBooksPollConfig.enabled, true)
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("minOrderBookPassedTime", config.minOrderBookPassedTime, false, null)
            }

            is MarketDataClientConfig.StreetInsider -> {
                settings.setDuration(
                    "pollInterval",
                    config.newsPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "requestTimeout",
                    config.newsPollConfig.requestTimeout,
                    false,
                    null,
                )
            }

            is MarketDataClientConfig.DxFeed -> {
                settings.setString("dxFeed", config.dxFeed, false, null)
                settings.setInt("sockets", config.sockets, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setBoolean("useLastQuote", config.useLastQuote, true)
                settings.setInt("tradeSockets", config.tradeSockets, false, null)
                settings.setString("postFix", config.postFix, false, null)
                settings.setDuration("tradesTimeframe", config.tradesTimeframe, false, null)
            }
            is MarketDataClientConfig.Iress -> {
                settings.setString("username", config.username, false, null)
                settings.setString("password", config.password, false, null)
                settings.setString("server", config.server, false, null)
                settings.setInt("port", config.port, false, null)
                settings.setInt("requestSenders", config.requestSenders, false, null)
                settings.setInt("MBLLayer", config.mblLayer, false, null)
                settings.setStringPairsList(
                    "exchangeRoutes",
                    config.exchangeRoutes.map {
                        Pair(it.key.id, it.value)
                    },
                    true,
                    null,
                )
                settings.setBoolean("quotationFullMask", config.quotationFullMask, true)
                settings.setBoolean("includeEmptyVolume", config.includeEmptyVolume, true)
                settings.setBoolean("enableTrace", config.enableTrace, true)
                settings.setExchangesList("isinExchanges", config.isinExchanges, true, 500)
                settings.setExchangesList(
                    "subscribeToLevel1Exchanges",
                    config.subscribeToLevel1Exchanges,
                    true,
                    500,
                )
                settings.setStringPairsList(
                    "postfixExchanges",
                    config.postfixExchanges.map {
                        Pair(it.key.id, it.value)
                    },
                    true,
                    null,
                )
                settings.setBoolean("subscribeAllTrades", config.subscribeAllTrades, true)
                settings.setDuration("connectionTimeoutMs", config.connectionTimeout, false, null)
            }

            is MarketDataClientConfig.MexcFut -> {
                settings.setInt("sockets", config.sockets, false, null)
                settings.setInt("frameConsumers", config.frameConsumers, false, null)
                settings.setInt("depth", config.depth, false, null)
                settings.setDuration("pingInterval", config.pingInterval, false, null)
            }

            is MarketDataClientConfig.BinanceFut -> {
                settings.setString("market", config.market.name, false, null)
            }

            is MarketDataClientConfig.MooMoo -> {
                settings.setDuration("pollInterval", config.pollConfig.pollInterval, false, null)
                settings.setDuration("requestTimeout", config.pollConfig.requestTimeout, false, null)
                settings.setInt("pageSize", config.pageSize, false, null)
            }
        }
    }

    Stack {
        spacing = responsive(10.px)

        h3 {
            +"Market Data"
        }
        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(5.px)
            justifyContent = "flex-start"

            autocomplete(
                { null },
                false,
                "server",
                150,
                null,
                { value ->
                    value?.let { newSelectedServer ->
                        selectedMarketDataServer = newSelectedServer
                        marketDataClients = emptyMap()
                    }
                },
                null,
                {
                },
                StringOption(selectedMarketDataServer),
                connectorServersManager.getServerIds().map { StringOption(it) }.toTypedArray(),
                true,
            )

            val status = connectorServersManager.getServerConnection(selectedMarketDataServer)?.status
            if (status == ConnectorStatus.Disconnected) {
                Tooltip {
                    title = ReactNode("Connect to the server")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            it.stopPropagation()

                            scope.launch {
                                connectorServersManager.getServerConnection(
                                    selectedMarketDataServer,
                                )?.client?.connect(
                                    selectedMarketDataServer,
                                )
                            }
                        }
                        +"Connect"
                    }
                }
            }
            autocomplete(
                { null },
                false,
                "Create connector",
                250,
                null,
                { value ->
                    value?.let { newTypeToCreate ->
                        marketDataClientTypeToCreate = newTypeToCreate
                    }
                },
                null,
                {
                },
                StringOption(marketDataClientTypeToCreate),
                marketDataClientOptions.map { StringOption(it.key) }.toTypedArray(),
                true,
            )

            fun createConnector(
                marketDataId: String,
                updatedConfig: MarketDataClientConfig?,
            ) {
                if (updatedConfig != null) {
                    val client = connectorServersManager.getServerConnection(
                        selectedMarketDataServer,
                    )?.client
                    if (client != null) {
                        scope.launch {
                            client.connectionManager.sendCreateMarketDataConnector(
                                MarketDataId(marketDataId),
                                updatedConfig,
                            )
                        }
                    }
                }
            }

            Tooltip {
                title = ReactNode("Create")

                Button {
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        clickWatcher.click()
                        event.stopPropagation()
                        setPromptDialog(
                            Prompt(
                                "",
                                "Create maret data connector",
                                "Connector name",
                            ) { marketDataId ->
                                marketDataClientOptions[marketDataClientTypeToCreate]?.let { config ->
                                    when (config) {
                                        is MarketDataClientConfig.Relay -> {
                                            setPromptDialog(
                                                Prompt(
                                                    "",
                                                    "Create connector",
                                                    "Broker client name",
                                                ) { brokerId ->
                                                    createConnector(
                                                        marketDataId,
                                                        config.copy(brokerId = BrokerId(brokerId)),
                                                    )
                                                },
                                            )
                                        }
                                        is MarketDataClientConfig.InteractiveBrokers -> {
                                            setPromptDialog(
                                                Prompt(
                                                    "",
                                                    "Create connector",
                                                    "Broker client name",
                                                ) { brokerId ->
                                                    createConnector(
                                                        marketDataId,
                                                        config.copy(brokerId = BrokerId(brokerId)),
                                                    )
                                                },
                                            )
                                        }
                                        is MarketDataClientConfig.OrderBooksSimulation -> {
                                            setPromptDialog(
                                                Prompt(
                                                    "",
                                                    "Create connector",
                                                    "Broker client name",
                                                ) { brokerId ->
                                                    createConnector(
                                                        marketDataId,
                                                        config.copy(brokerId = BrokerId(brokerId)),
                                                    )
                                                },
                                            )
                                        }
                                        else -> {
                                            createConnector(marketDataId, config)
                                        }
                                    }
                                }
                            },
                        )
                    }
                    AddCircle {
                    }
                }
            }
            Tooltip {
                // create connector from json text
                title = ReactNode("Create from JSON")
                Button {
                    onClick = {
                        setPromptDialog(
                            Prompt(
                                "",
                                "Create maret data connector",
                                "Connector name",
                            ) { marketDataId ->
                                setPromptDialog(
                                    Prompt(
                                        "",
                                        "Create connector",
                                        "JSON",
                                    ) { json ->
                                        val config = Defaults.json.decodeFromString(
                                            MarketDataClientConfig.serializer(),
                                            json,
                                        )
                                        createConnector(marketDataId, config)
                                    },
                                )
                            },
                        )
                    }
                    AddToPhotos {
                    }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(5.px)
            justifyContent = "flex-start"

            TextField {
                size = Size.small
                label = ReactNode("Search")
                value = searchInput
                onChange = { event ->
                    searchInput = event.target.asDynamic().value as String
                }
            }

            Select {
                size = Size.small
                value = filterMarketDataType ?: "All"
                onMouseUp = { event ->
                    event.stopPropagation()
                }
                onMouseDown = { event ->
                    event.stopPropagation()
                }
                onChange = { event, _ ->
                    event.stopPropagation()
                    val newValue = event.target.value.takeIf {
                        it.isNotEmpty() && it != "All"
                    }
                    filterMarketDataType = newValue
                }
                MenuItem {
                    value = "All"
                    +"All"
                }
                for (type in marketDataTypes) {
                    MenuItem {
                        value = type
                        +type
                    }
                }
            }

            Select {
                size = Size.small
                value = filterConnectorStatus?.name ?: "All"
                onMouseUp = { event ->
                    event.stopPropagation()
                }
                onMouseDown = { event ->
                    event.stopPropagation()
                }
                onChange = { event, _ ->
                    event.stopPropagation()
                    val newValue = event.target.value.takeIf {
                        it.isNotEmpty()
                    }
                    filterConnectorStatus = newValue?.let {
                        StatusFilter.entries.find {
                            it.name == newValue
                        }
                    }
                }

                MenuItem {
                    value = "All"
                    +"All"
                }

                StatusFilter.entries.forEach { statusType ->
                    MenuItem {
                        key = statusType.name
                        value = statusType.name
                        +statusType.name
                    }
                }
            }
        }

        TableContainer {
            Table {
                sx {
                    width = unset
                }
                size = Size.small

                TableHead {
                    TableRow {
                        TableCell {
                            +"MarketDataId"
                        }
                        TableCell {
                            +"Status"
                        }
                        TableCell {
                            +"Actions"
                        }
                    }
                }

                val entries = marketDataClients.filter {
                    it.key.id.contains(searchInput, ignoreCase = true)
                }.filter {
                    filterMarketDataType == null ||
                        it.value.marketDataClientConfig.displayName() == filterMarketDataType
                }.filter {
                    filterConnectorStatus == null ||
                        filterConnectorStatus == StatusFilter.fromConnectorStatus(it.value.connectorStatus)
                }

                if (entries.isNotEmpty()) {
                    val sortedEntries = entries.entries.sortedBy { -it.value.marketDataClientConfig.createdAt }
                    for ((marketDataId, status) in sortedEntries) {
                        TableBody {
                            key = "marketData:${marketDataId.id}"

                            marketDataClientConnector {
                                this.serverId = selectedMarketDataServer
                                this.marketDataId = marketDataId
                                this.status = status
                                this.resetSettings = { it ->
                                    resetMarketDataClientSettings(it, status.marketDataClientConfig)
                                }
                                this.saveSettings = {
                                    saveMarketDataClientSettings(
                                        marketDataId,
                                        it,
                                        status.marketDataClientConfig,
                                    )
                                    toaster.info(
                                        "MarketData ${marketDataId.id} saved",
                                        1900,
                                        ToastPosition.BOTTOM_RIGHT,
                                    )
                                }
                                this.showSettings = shownMarketDataClients.contains(marketDataId)
                                this.toggleShowSettings = {
                                    setShownMarketDataClients { shownMarketDataClients ->
                                        if (shownMarketDataClients.contains(marketDataId)) {
                                            shownMarketDataClients - marketDataId
                                        } else {
                                            shownMarketDataClients + marketDataId
                                        }
                                    }
                                }
                                this.removeConnector = {
                                    setConfirmDialog(
                                        Confirm(
                                            "Remove connector",
                                            "Please confirm connector removal",
                                        ) {
                                            val client = connectorServersManager.getServerConnection(
                                                selectedMarketDataServer,
                                            )?.client
                                            client?.connectionManager?.sendRemoveMarketDataConnector(marketDataId)
                                        },
                                    )
                                }
                                this.duplicateConnector = {
                                    setPromptDialog(
                                        Prompt(
                                            "",
                                            "Duplicate market data connector",
                                            "Connector name",
                                        ) { newMarketDataId ->
                                            scope.launch {
                                                val client = connectorServersManager.getServerConnection(
                                                    selectedMarketDataServer,
                                                )?.client
                                                client?.connectionManager?.sendDuplicateMarketDataConnector(
                                                    marketDataId,
                                                    MarketDataId(newMarketDataId),
                                                )
                                            }
                                        },
                                    )
                                }
                                this.restartConnector = {
                                    setPromptDialog(
                                        Prompt(
                                            "",
                                            "Restart market data connector",
                                            "Restart mode",
                                        ) { mode ->
                                            scope.launch {
                                                val client = connectorServersManager.getServerConnection(
                                                    selectedMarketDataServer,
                                                )?.client
                                                client?.connectionManager?.sendRestartMarketDataConnector(
                                                    marketDataId,
                                                    mode,
                                                )
                                            }
                                        },
                                    )
                                }
                            }
                        }
                    }
                } else {
                    TableBody {
                        TableRow {
                            TableCell {
                                colSpan = 4
                                +"No connectors"
                            }
                        }
                    }
                }
            }
        }
    }

    confirm {
        this.dialog = confirmDialog
        this.setDialog = setConfirmDialog
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }
}
