package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
data class BridgeTradeConfig(
    @Serializable(with = HumanReadableDurationSerializer::class)
    val openPositionTimeout: Duration,
    @Serializable(with = HumanReadableDurationSerializer::class)
    val closePositionTimeout: Duration,
    val openPositionOrderType: OrderType,
    @Serializable(with = HumanReadableDurationSerializer::class)
    val placeOrderDelay: Duration,
)
