package com.edvorg.trade.backend.frontend.views.widgets.connectors

import com.edvorg.trade.backend.frontend.services.makeHttpBackendUrl
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.frontend.views.fcWithScope
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import mui.material.Size
import mui.material.TextField
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import org.w3c.xhr.FormData
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.onChange
import react.useState
import web.html.HTMLInputElement
import web.html.InputType

val uploadFilePanel = fcWithScope<Props> { _, scope ->
    val toaster = getContext<CommonContext>().toaster

    var uploadFileName: String by useState("")

    h3 {
        +"Upload file"
    }
    div {
        label {
            +"FileName"
        }
        TextField {
            size = Size.small
            type = InputType.text
            value = uploadFileName
            onChange = { event ->
                (event.target as HTMLInputElement).value.trim().let {
                    uploadFileName = it
                }
            }
        }
        input {
            type = InputType.file
            id = "file"
            name = "file"
            accept = "text/csv"
            onChange = { event ->
                event.target.value.trim().let {
                    val url = makeHttpBackendUrl("/upload-file?name=$uploadFileName", emptyMap())
                    val file: File = event.asDynamic().target.files[0] as File
                    val data = FormData()
                    data.append("file", file)
                    scope.launch {
                        val result = window.fetch(
                            url,
                            RequestInit(
                                "POST",
                                body = data,
                            ),
                        ).await()
                        if (result.ok) {
                            toaster.info(
                                "Success!",
                                1900,
                                ToastPosition.BOTTOM_RIGHT,
                            )
                        } else {
                            toaster.error(
                                "${result.statusText}: ${result.text().await()}",
                                1900,
                                ToastPosition.BOTTOM_RIGHT,
                            )
                        }
                    }
                }
            }
        }
    }
}
