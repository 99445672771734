package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MidPoint(
    @SerialName("t")
    val time: Long,
    @SerialName("v")
    val price: Double,
)
