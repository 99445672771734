package com.edvorg.trade.common.frontend.views

import mui.material.Autocomplete
import mui.material.AutocompleteProps
import mui.material.Size
import mui.material.TextField
import mui.material.TextFieldProps
import mui.material.Tooltip
import mui.material.TooltipPlacement
import mui.system.sx
import react.ChildrenBuilder
import react.ReactNode
import react.create
import react.dom.html.LiHTMLAttributes
import react.dom.html.ReactHTML.li
import web.cssom.px
import web.html.HTMLLIElement

external interface SelectOption<out T> {
    @JsName("value")
    val value: T

    @JsName("label")
    val label: String
}

fun <T> ChildrenBuilder.autocomplete(
    hint: T.() -> String?,
    hideIndicators: Boolean,
    label: String?,
    width: Int?,
    autoFocus: Boolean?,
    onChange: (T?) -> Unit,
    createNew: ((String) -> SelectOption<T>)?,
    block: AutocompleteProps<SelectOption<T>>.() -> Unit,
    value: SelectOption<T>?,
    options: Array<SelectOption<T>>,
    disableClearable: Boolean,
) {
    @Suppress("UPPER_BOUND_VIOLATED")
    Autocomplete<AutocompleteProps<SelectOption<T>>> {
        if (width != null) {
            sx {
                this.width = width.px
            }
        }

        this.size = "small"
        this.renderInput = { props ->
            fun TextFieldProps.setup() {
                size = Size.small
                if (autoFocus != null) {
                    this.autoFocus = autoFocus
                }

                if (label != null) {
                    this.label = ReactNode(label)
                }

                +props
            }
            value?.value?.hint()?.let { title ->
                Tooltip.create {
                    this.placement = TooltipPlacement.top
                    this.title = ReactNode(title)

                    TextField {
                        setup()
                    }
                }
            } ?: TextField.create {
                setup()
            }
        }
        this.renderOption = { props, option, _, _ ->
            fun LiHTMLAttributes<HTMLLIElement>.setup() {
                +props

                +option.label
            }
            option.value?.hint()?.let { title ->
                Tooltip.create {
                    this.placement = TooltipPlacement.right
                    this.title = ReactNode(title)

                    li {
                        setup()
                    }
                }
            } ?: li.create {
                setup()
            }
        }
        this.isOptionEqualToValue = { option, value ->
            option.value == value.value
        }
        this.onChange = { event, value, _, _ ->
            event.stopPropagation()
            @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
            onChange((value as? SelectOption<T>?)?.value)
        }
        this.disableClearable = disableClearable

        block()

        if (hideIndicators) {
            this.forcePopupIcon = false
        }

        if (createNew != null) {
            this.filterOptions = { options, params ->
                val filtered = options.filter { option ->
                    option.label.contains(params.inputValue, ignoreCase = true)
                }

                if (filtered.isNotEmpty()) {
                    filtered.toTypedArray()
                } else {
                    arrayOf(createNew(params.inputValue))
                }
            }
            this.selectOnFocus = true
            this.clearOnBlur = true
            this.freeSolo = true
        }

        this.multiple = false
        this.defaultValue = value
        this.options = options
    }
}

fun <T> ChildrenBuilder.autocompleteMultiple(
    hideIndicators: Boolean,
    label: String?,
    width: Int?,
    onChange: (Array<T>?) -> Unit,
    block: AutocompleteProps<SelectOption<T>>.() -> Unit,
    value: Array<SelectOption<T>>?,
    options: Array<SelectOption<T>>,
) {
    @Suppress("UPPER_BOUND_VIOLATED")
    Autocomplete<AutocompleteProps<SelectOption<T>>> {
        if (width != null) {
            sx {
                this.width = width.px
            }
        }
        this.size = "small"
        this.renderInput = { props ->
            TextField.create {
                size = Size.small

                if (label != null) {
                    this.label = ReactNode(label)
                }

                +props
            }
        }
        this.renderOption = { props, option, _, _ ->
            li.create {
                +props

                +option.label
            }
        }
        this.isOptionEqualToValue = { option, value ->
            option.value == value.value
        }
        this.onChange = { event, value, _, _ ->
            event.stopPropagation()
            @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
            onChange((value as? Array<SelectOption<T>>?)?.map { it.value }?.toTypedArray())
        }
        this.disableClearable = true
        if (hideIndicators) {
            this.forcePopupIcon = false
        }

        block()

        this.multiple = true
        this.defaultValue = value
        this.options = options
    }
}
