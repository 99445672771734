package com.edvorg.trade.common.frontend.services

import com.edvorg.trade.common.model.BrokerClientConfig
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.BrokerManagementRequest
import com.edvorg.trade.common.model.ConnectionManagementRequest
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Connectors
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.ExecutorManagementRequest
import com.edvorg.trade.common.model.MarketDataClientConfig
import com.edvorg.trade.common.model.MarketDataId
import com.edvorg.trade.common.model.MarketDataManagementRequest
import com.edvorg.trade.common.model.SamuraiId
import com.edvorg.trade.common.model.SamuraiManagementRequest
import com.edvorg.trade.common.model.ScannerClientConfig
import com.edvorg.trade.common.model.ScannerExecutorConfig
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.ScannerManagementRequest
import com.edvorg.trade.common.model.SubscriptionHandle
import com.edvorg.trade.common.model.config.SamuraiPair
import com.edvorg.trade.common.model.config.SamuraiPauseState
import com.edvorg.trade.common.utils.Subscriber
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.launch
import kotlin.random.Random

interface ConnectionManagement {
    val connectionManager: ConnectionManager

    fun sendConnectorUpdate(message: ConnectionManagementRequest)
    val updateConnectorsFlow: Flow<Connectors>

    fun stopConnection()

    suspend fun connect(selectedServer: String)

    suspend fun start(onStarted: (ConnectorStatus) -> Unit)
}

class ConnectionManager(
    val id: String,
    val client: ConnectionManagement,
) {
    private val scope = CoroutineScope(Dispatchers.Default)

    private val brokerClientSubscribers =
        mutableMapOf<Long, Subscriber<Connectors.BrokerClients>>()
    private val marketDataClientSubscribers =
        mutableMapOf<Long, Subscriber<Connectors.MarketDataClients>>()
    private val scannerClientSubscribers =
        mutableMapOf<Long, Subscriber<Connectors.ScannerClients>>()
    private val scannerExecutorSubscribers =
        mutableMapOf<Long, Subscriber<Connectors.ScannerExecutorsUpdate>>()
    private val samuraiSubscribers =
        mutableMapOf<Long, Subscriber<Connectors.Samurais>>()

    private var brokerClients: Connectors.BrokerClients? = null
    private var marketDataClients: Connectors.MarketDataClients? = null
    private var scannerClients: Connectors.ScannerClients? = null
    private var scannerExecutors: Connectors.ScannerExecutorsUpdate? = null
    private var samurais: Connectors.Samurais? = null

    init {
        scope.launch {
            client.updateConnectorsFlow.collect { message ->
                when (message) {
                    is Connectors.BrokerClients -> {
                        this@ConnectionManager.brokerClients = message

                        for ((_, subscriber) in brokerClientSubscribers) {
                            subscriber.onUpdate(message)
                        }
                    }
                    is Connectors.MarketDataClients -> {
                        this@ConnectionManager.marketDataClients = message

                        for ((_, subscriber) in marketDataClientSubscribers) {
                            subscriber.onUpdate(message)
                        }
                    }
                    is Connectors.ScannerClients -> {
                        this@ConnectionManager.scannerClients = message

                        for ((_, subscriber) in scannerClientSubscribers) {
                            subscriber.onUpdate(message)
                        }
                    }
                    is Connectors.ScannerExecutorsUpdate -> {
                        this@ConnectionManager.scannerExecutors = message

                        for ((_, subscriber) in scannerExecutorSubscribers) {
                            subscriber.onUpdate(message)
                        }
                    }
                    is Connectors.Samurais -> {
                        this@ConnectionManager.samurais = message

                        for ((_, subscriber) in samuraiSubscribers) {
                            subscriber.onUpdate(message)
                        }
                    }
                }
            }
        }
    }

    fun activeSubscriptionSize(): Int {
        return brokerClientSubscribers.size + marketDataClientSubscribers.size + scannerClientSubscribers.size
    }

    fun subscribeToScannerExecutorsUpdate(
        subscriber: Subscriber<Connectors.ScannerExecutorsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = Random.nextLong(Long.MAX_VALUE)
        scannerExecutors?.let {
            subscriber.onUpdate(it)
        }
        scannerExecutorSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromScannerExecutors(id)
            }
        }
    }

    private fun unsubscribeFromScannerExecutors(id: Long) {
        scannerExecutorSubscribers.remove(id)
    }

    fun subscribeToBrokerClientsUpdate(
        subscriber: Subscriber<Connectors.BrokerClients>,
    ): SubscriptionHandle<Long> {
        val id = Random.nextLong(Long.MAX_VALUE)
        brokerClients?.let {
            subscriber.onUpdate(it)
        }
        brokerClientSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromBrokerClientsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromBrokerClientsUpdate(id: Long) {
        brokerClientSubscribers.remove(id)
    }

    fun sendDisconnectBroker(brokerId: BrokerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateBrokerConnectors(
                BrokerManagementRequest.DisconnectBroker(brokerId),
            ),
        )
    }

    fun sendConnectBroker(brokerId: BrokerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateBrokerConnectors(
                BrokerManagementRequest.ConnectBroker(brokerId),
            ),
        )
    }

    fun sendCreateBrokerConnector(brokerId: BrokerId, config: BrokerClientConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateBrokerConnectors(
                BrokerManagementRequest.CreateBrokerClient(brokerId, config),
            ),
        )
    }

    fun sendRemoveBrokerConnector(brokerId: BrokerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateBrokerConnectors(
                BrokerManagementRequest.RemoveBrokerClient(brokerId),
            ),
        )
    }

    fun sendUpdateBrokerConnector(brokerId: BrokerId, config: BrokerClientConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateBrokerConnectors(
                BrokerManagementRequest.UpdateBrokerClient(brokerId, config),
            ),
        )
    }

    fun sendDuplicateBrokerConnector(brokerId: BrokerId, newBrokerId: BrokerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateBrokerConnectors(
                BrokerManagementRequest.DuplicateBrokerConnector(brokerId, newBrokerId),
            ),
        )
    }

    fun subscribeToMarketDataClientsUpdate(
        subscriber: Subscriber<Connectors.MarketDataClients>,
    ): SubscriptionHandle<Long> {
        val id = Random.nextLong(Long.MAX_VALUE)
        marketDataClients?.let {
            subscriber.onUpdate(it)
        }
        marketDataClientSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromMarketDataClientsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromMarketDataClientsUpdate(id: Long) {
        marketDataClientSubscribers.remove(id)
    }

    fun sendConnectMarketData(marketDataId: MarketDataId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.ConnectMarketData(marketDataId),
            ),
        )
    }

    fun sendDisconnectMarketData(marketDataId: MarketDataId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.DisconnectMarketData(marketDataId),
            ),
        )
    }

    fun sendCreateMarketDataConnector(marketDataId: MarketDataId, config: MarketDataClientConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.CreateMarketDataClient(marketDataId, config),
            ),
        )
    }

    fun sendRemoveMarketDataConnector(marketDataId: MarketDataId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.RemoveMarketDataClient(marketDataId),
            ),
        )
    }

    fun sendUpdateMarketDataConnector(marketDataId: MarketDataId, config: MarketDataClientConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.UpdateMarketDataClient(marketDataId, config),
            ),
        )
    }

    fun sendDuplicateMarketDataConnector(marketDataId: MarketDataId, newMarketDataId: MarketDataId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.DuplicateMarketDataConnector(
                    marketDataId,
                    newMarketDataId,
                ),
            ),
        )
    }

    fun sendRestartMarketDataConnector(marketDataId: MarketDataId, mode: String) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.RestartMarketDataConnector(marketDataId, mode),
            ),
        )
    }

    fun sendRestartServiceMarketDataConnector(marketDataId: MarketDataId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateMarketDataConnectors(
                MarketDataManagementRequest.RestartServiceMarketDataConnector(marketDataId),
            ),
        )
    }

    fun subscribeToScannerClientsUpdate(
        subscriber: Subscriber<Connectors.ScannerClients>,
    ): SubscriptionHandle<Long> {
        val id = Random.nextLong(Long.MAX_VALUE)
        scannerClients?.let {
            subscriber.onUpdate(it)
        }
        scannerClientSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromScannerClientsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromScannerClientsUpdate(id: Long) {
        scannerClientSubscribers.remove(id)
    }

    fun sendDisconnectScanner(scannerId: ScannerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateScannerConnectors(
                ScannerManagementRequest.DisconnectScanner(scannerId),
            ),
        )
    }

    fun sendConnectScanner(scannerId: ScannerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateScannerConnectors(
                ScannerManagementRequest.ConnectScanner(scannerId),
            ),
        )
    }

    fun sendCreateScannerConnector(scannerId: ScannerId, config: ScannerClientConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateScannerConnectors(
                ScannerManagementRequest.CreateScannerClient(scannerId, config),
            ),
        )
    }

    fun sendRemoveScannerConnector(scannerId: ScannerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateScannerConnectors(
                ScannerManagementRequest.RemoveScannerClient(scannerId),
            ),
        )
    }

    fun sendUpdateScannerConnector(scannerId: ScannerId, config: ScannerClientConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateScannerConnectors(
                ScannerManagementRequest.UpdateScannerClient(scannerId, config),
            ),
        )
    }

    fun sendDuplicateScannerConnector(scannerId: ScannerId, newScannerId: ScannerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateScannerConnectors(
                ScannerManagementRequest.DuplicateScannerConnector(scannerId, newScannerId),
            ),
        )
    }

    fun sendSetPriorityBroker(exchange: Exchange, brokerId: BrokerId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateBrokerConnectors(
                BrokerManagementRequest.SetPriorityBroker(exchange, brokerId),
            ),
        )
    }

    fun sendStartExecutor(executorId: ScannerExecutorId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateExecutorConnectors(
                ExecutorManagementRequest.StartExecutor(executorId),
            ),
        )
    }

    fun sendStopExecutor(executorId: ScannerExecutorId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateExecutorConnectors(
                ExecutorManagementRequest.StopExecutor(executorId),
            ),
        )
    }

    fun sendCreateScannerExecutor(executorId: ScannerExecutorId, config: ScannerExecutorConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateExecutorConnectors(
                ExecutorManagementRequest.CreateScannerExecutor(executorId, config),
            ),
        )
    }

    fun sendRemoveScannerExecutor(executorId: ScannerExecutorId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateExecutorConnectors(
                ExecutorManagementRequest.RemoveScannerExecutor(executorId),
            ),
        )
    }

    fun sendUpdateScannerExecutor(executorId: ScannerExecutorId, config: ScannerExecutorConfig) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateExecutorConnectors(
                ExecutorManagementRequest.UpdateScannerExecutor(executorId, config),
            ),
        )
    }

    fun sendDuplicateScannerExecutor(executorId: ScannerExecutorId, newExecutorId: ScannerExecutorId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateExecutorConnectors(
                ExecutorManagementRequest.DuplicateScannerExecutor(executorId, newExecutorId),
            ),
        )
    }

    fun sendRestartScannerExecutor(executorId: ScannerExecutorId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateExecutorConnectors(
                ExecutorManagementRequest.RestartScannerExecutor(executorId),
            ),
        )
    }

    fun subscribeToSamuraisUpdate(
        subscriber: Subscriber<Connectors.Samurais>,
    ): SubscriptionHandle<Long> {
        val id = Random.nextLong(Long.MAX_VALUE)
        samurais?.let {
            subscriber.onUpdate(it)
        }
        samuraiSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromSamuraisUpdate(id)
            }
        }
    }

    private fun unsubscribeFromSamuraisUpdate(id: Long) {
        samuraiSubscribers.remove(id)
    }

    fun sendStopSamurai(samuraiId: SamuraiId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.StopSamurai(samuraiId),
            ),
        )
    }

    fun sendStartSamurai(samuraiId: SamuraiId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.StartSamurai(samuraiId),
            ),
        )
    }

    fun sendPauseSamurai(samuraiId: SamuraiId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.PauseSamurai(samuraiId),
            ),
        )
    }

    fun sendCreateSamuraiConnector(samuraiId: SamuraiId, pair: SamuraiPair) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.CreateSamurai(samuraiId, pair),
            ),
        )
    }

    fun sendRemoveSamuraiConnector(samuraiId: SamuraiId) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.RemoveSamurai(samuraiId),
            ),
        )
    }

    fun sendUpdateSamuraiConnector(samuraiId: SamuraiId, pair: SamuraiPair, pauseState: SamuraiPauseState?) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.UpdateSamurai(samuraiId, pair, pauseState),
            ),
        )
    }

    fun sendStartSamurais(samuraiIds: List<SamuraiId>) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.StartSamurais(samuraiIds),
            ),
        )
    }

    fun sendStopSamurais(samuraiIds: List<SamuraiId>) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.StopSamurais(samuraiIds),
            ),
        )
    }

    fun sendPauseSamurais(samuraiIds: List<SamuraiId>) {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.PauseSamurais(samuraiIds),
            ),
        )
    }

    fun sendStopAllSamurais() {
        client.sendConnectorUpdate(
            ConnectionManagementRequest.UpdateSamuraiConnectors(
                SamuraiManagementRequest.StopAllSamurais,
            ),
        )
    }
}
