package com.edvorg.trade.backend.frontend.views.widgets.chartscript

import com.edvorg.trade.backend.frontend.FrontendContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.views.BarSizeSelectOption
import com.edvorg.trade.common.frontend.views.CurrencySelectOption
import com.edvorg.trade.common.frontend.views.Prompt
import com.edvorg.trade.common.frontend.views.autocomplete
import com.edvorg.trade.common.frontend.views.prompt
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.asHumanReadableToDuration
import com.edvorg.trade.common.model.chartscript.ChartScriptConfig
import com.edvorg.trade.common.model.chartscript.ChartScriptTick
import com.edvorg.trade.common.model.chartscript.LuaScript
import com.edvorg.trade.common.model.toHumanReadableString
import mui.material.Button
import mui.material.Checkbox
import mui.material.Collapse
import mui.material.FormControlLabel
import mui.material.LabelPlacement
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.onChange
import react.useState
import web.cssom.px
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import web.html.InputType

val ticksToCreate = mapOf(
    "OrderBookTick" to ChartScriptTick.OrderBookTick(
        Exchange.MOEX + "SBER",
        null,
    ),
    "DaysToExpiration" to ChartScriptTick.DaysToExpiryTick(
        Exchange.MOEX + "SBER",
    ),
    "Indicator" to ChartScriptTick.IndicatorTick(
        Exchange.MOEX + "SBER",
        Indicator.MA(
            30,
            BarSize.MINUTE,
            1,
        ),
    ),
    "Candle" to ChartScriptTick.CandleTick(
        Exchange.MOEX + "SBER",
        BarSize.MINUTE,
    ),
//    "SpreadIndicator" to ChartScriptTick.SpreadIndicatorTick(
//        Exchange.MOEX + "SBER",
//        1.0,
//        Exchange.MOEX + "SBERP",
//        1.0,
//        Indicator.SD(
//            30,
//            BarSize.MINUTE,
//        )
//    )
)

external interface ChartScriptEditorPanelProps : Props {
    var chartScript: ChartScriptConfig
    var setChartScript: (ChartScriptConfig) -> Unit
    var collapsableScriptText: Boolean
}

val chartScriptEditorPanel = FC<ChartScriptEditorPanelProps> { props ->
    val tickerInputHelper = getContext<FrontendContext>().backendMainClient.getTickerInputHelper()
    val masterExchange = getContext<FrontendContext>().backendMainClient.getSelectedMasterExchange()

    var editableScript by useState(props.chartScript)

    var currentTickToCreate by useState(ticksToCreate.keys.first())
    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)

    var tickIntervalInputStr by useState(props.chartScript.tickInterval.toHumanReadableString())
    var scriptCollapsed by useState(true)

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(10.px)
        sx {
            margin = 10.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            FormControlLabel {
                control = Checkbox.create {
                    checked = editableScript.startMark
                    onChange = { _, checked ->
                        editableScript = editableScript.copy(
                            startMark = checked,
                        )
                    }
                }
                label = ReactNode("Start")
                labelPlacement = LabelPlacement.start
            }
        }
        Stack {
            direction = responsive(StackDirection.row)
            Select {
                this.size = Size.small
                value = currentTickToCreate
                onChange = { event, _ ->
                    currentTickToCreate = event.target.value
                }
                ticksToCreate.keys.forEach {
                    MenuItem {
                        key = it
                        value = it
                        +it
                    }
                }
            }

            Button {
                onClick = {
                    val newTick = ticksToCreate[currentTickToCreate]
                    if (newTick != null) {
                        setPromptDialog(
                            Prompt(
                                value = "",
                                title = "New tick",
                                label = "Name tick:",
                            ) { newTickName ->
                                editableScript = editableScript.copy(
                                    ticks = editableScript.ticks + (newTickName to newTick),
                                )
                            },
                        )
                    }
                }
                +"Add"
            }
        }

        TableContainer {
            Table {
                size = Size.small
                TableHead {
                    TableCell {
                        +"Name"
                    }
                    TableCell {
                        +"Type"
                    }
                    TableCell {
                        +"Ticker1"
                    }
                    TableCell {
                        +"Ticker2"
                    }
                    TableCell {
                        +"Params"
                    }
                    TableCell {
                        +"Actions"
                    }
                }
                TableBody {
                    editableScript.ticks.entries.sortedBy { it.key }.forEach { (tickName, tick) ->
                        TableRow {
                            sx {
                                maxHeight = 15.px
                            }
                            TableCell {
                                +tickName
                            }
                            when (tick) {
                                is ChartScriptTick.OrderBookTick -> {
                                    TableCell {
                                        +"OrderBookTick"
                                    }
                                    TableCell {
                                        colSpan = 2
                                        Button {
                                            size = Size.small
                                            onClick = {
                                                setPromptDialog(
                                                    Prompt(
                                                        value = tick.ticker.toString(),
                                                        title = "Change ticker",
                                                        label = "New ticker:",
                                                    ) { newTickerStr ->
                                                        val ticker = tickerInputHelper.processInput(
                                                            newTickerStr,
                                                            masterExchange,
                                                        )
                                                        val newTick = tick.copy(
                                                            ticker = ticker,
                                                        )
                                                        val newTicks =
                                                            editableScript.ticks - tickName + (tickName to newTick)
                                                        editableScript = editableScript.copy(
                                                            ticks = newTicks,
                                                        )
                                                    },
                                                )
                                            }
                                            +tick.ticker.toString()
                                        }
                                    }
                                    TableCell {
                                        autocomplete(
                                            { null },
                                            false,
                                            "",
                                            null,
                                            null,
                                            { value ->
                                                editableScript = editableScript.copy(
                                                    ticks = editableScript.ticks -
                                                        tickName + (tickName to tick.copy(currency = value)),
                                                )
                                            },
                                            null,
                                            {
                                            },
                                            tick.currency?.let {
                                                CurrencySelectOption(it)
                                            },
                                            Currency.entries.map { CurrencySelectOption(it) }.toTypedArray(),
                                            false,
                                        )
                                    }
                                }

                                is ChartScriptTick.DaysToExpiryTick -> {
                                    TableCell {
                                        +"DaysToExpiryTick"
                                    }
                                    TableCell {
                                        colSpan = 2
                                        Button {
                                            size = Size.small
                                            onClick = {
                                                setPromptDialog(
                                                    Prompt(
                                                        value = tick.ticker.toString(),
                                                        title = "Change ticker",
                                                        label = "New ticker:",
                                                    ) { newTickerStr ->
                                                        val ticker = tickerInputHelper.processInput(
                                                            newTickerStr,
                                                            masterExchange,
                                                        )
                                                        val newTick = tick.copy(
                                                            ticker = ticker,
                                                        )
                                                        val newTicks =
                                                            editableScript.ticks - tickName + (tickName to newTick)

                                                        editableScript = editableScript.copy(
                                                            ticks = newTicks,
                                                        )
                                                    },
                                                )
                                            }
                                            +tick.ticker.toString()
                                        }
                                    }
                                    TableCell {
                                        +""
                                    }
                                }

                                is ChartScriptTick.IndicatorTick -> {
                                    TableCell {
                                        +"IndicatorTick"
                                    }

                                    TableCell {
                                        colSpan = 2
                                        Button {
                                            size = Size.small
                                            onClick = {
                                                setPromptDialog(
                                                    Prompt(
                                                        value = tick.ticker.toString(),
                                                        title = "Change ticker",
                                                        label = "New ticker:",
                                                    ) { newTickerStr ->
                                                        val ticker = tickerInputHelper.processInput(
                                                            newTickerStr,
                                                            masterExchange,
                                                        )
                                                        val newTick = tick.copy(
                                                            ticker = ticker,
                                                        )
                                                        val newTicks =
                                                            editableScript.ticks - tickName + (tickName to newTick)

                                                        editableScript = editableScript.copy(
                                                            ticks = newTicks,
                                                        )
                                                    },
                                                )
                                            }
                                            +tick.ticker.toString()
                                        }
                                    }

                                    TableCell {
                                        indicatorTickEditorPanel {
                                            this.indicator = tick.indicator
                                            this.onUpdateIndicator = { newIndicator ->
                                                val newTick = tick.copy(
                                                    indicator = newIndicator,
                                                )

                                                val newTicks =
                                                    editableScript.ticks - tickName + (tickName to newTick)
                                                editableScript = editableScript.copy(
                                                    ticks = newTicks,
                                                )
                                            }
                                        }
                                    }
                                }

                                is ChartScriptTick.SpreadIndicatorTick -> {
                                    TableCell {
                                        +"SpreadIndicatorTick"
                                    }

                                    TableCell {
                                        Button {
                                            size = Size.small
                                            onClick = {
                                                setPromptDialog(
                                                    Prompt(
                                                        value = tick.ticker1.toString(),
                                                        title = "Change ticker1",
                                                        label = "New ticker1:",
                                                    ) { newTickerStr ->
                                                        val ticker1 = tickerInputHelper.processInput(
                                                            newTickerStr,
                                                            masterExchange,
                                                        )
                                                        val newTick = tick.copy(
                                                            ticker1 = ticker1,
                                                        )
                                                        val newTicks =
                                                            editableScript.ticks - tickName + (tickName to newTick)

                                                        editableScript = editableScript.copy(
                                                            ticks = newTicks,
                                                        )
                                                    },
                                                )
                                            }
                                            +tick.ticker1.toString()
                                        }
                                        TextField {
                                            sx {
                                                width = 90.px
                                            }
                                            size = Size.small
                                            value = tick.ratio1.toString()
                                            onChange = { event ->
                                                val value =
                                                    (event.target as? HTMLInputElement)?.value?.toDoubleOrNull()
                                                console.log(event.target)
                                                if (value != null) {
                                                    console.log("value: $value")
                                                    val newTick = tick.copy(
                                                        ratio1 = value,
                                                    )
                                                    val newTicks =
                                                        editableScript.ticks - tickName + (tickName to newTick)
                                                    editableScript = editableScript.copy(
                                                        ticks = newTicks,
                                                    )
                                                }
                                            }
                                        }
                                    }

                                    TableCell {
                                        Button {
                                            size = Size.small
                                            onClick = {
                                                setPromptDialog(
                                                    Prompt(
                                                        value = tick.ticker2.toString(),
                                                        title = "Change ticker2",
                                                        label = "New ticker2:",
                                                    ) { newTickerStr ->
                                                        val ticker2 = tickerInputHelper.processInput(
                                                            newTickerStr,
                                                            masterExchange,
                                                        )
                                                        val newTick = tick.copy(
                                                            ticker2 = ticker2,
                                                        )
                                                        val newTicks =
                                                            editableScript.ticks - tickName + (tickName to newTick)

                                                        editableScript = editableScript.copy(
                                                            ticks = newTicks,
                                                        )
                                                    },
                                                )
                                            }
                                            +tick.ticker2.toString()
                                        }
                                        TextField {
                                            sx {
                                                width = 90.px
                                            }
                                            size = Size.small
                                            value = tick.ratio2.toString()
                                            onChange = { event ->
                                                val value =
                                                    (event.target as? HTMLInputElement)?.value?.toDoubleOrNull()
                                                if (value != null) {
                                                    val newTick = tick.copy(
                                                        ratio2 = value,
                                                    )
                                                    val newTicks =
                                                        editableScript.ticks - tickName + (tickName to newTick)
                                                    editableScript = editableScript.copy(
                                                        ticks = newTicks,
                                                    )
                                                }
                                            }
                                        }
                                    }

                                    TableCell {
                                        indicatorTickEditorPanel {
                                            this.indicator = tick.indicator
                                            this.onUpdateIndicator = { newIndicator ->
                                                val newTick = tick.copy(
                                                    indicator = newIndicator,
                                                )
                                                val newTicks =
                                                    editableScript.ticks - tickName + (tickName to newTick)
                                                editableScript = editableScript.copy(
                                                    ticks = newTicks,
                                                )
                                            }
                                        }
                                    }
                                }

                                is ChartScriptTick.CandleTick -> {
                                    TableCell {
                                        +"CandleTick"
                                    }
                                    TableCell {
                                        colSpan = 2
                                        Button {
                                            size = Size.small
                                            onClick = {
                                                setPromptDialog(
                                                    Prompt(
                                                        value = tick.ticker.toString(),
                                                        title = "Change ticker",
                                                        label = "New ticker:",
                                                    ) { newTickerStr ->
                                                        val ticker = tickerInputHelper.processInput(
                                                            newTickerStr,
                                                            masterExchange,
                                                        )
                                                        val newTick = tick.copy(
                                                            ticker = ticker,
                                                        )
                                                        val newTicks =
                                                            editableScript.ticks - tickName + (tickName to newTick)

                                                        editableScript = editableScript.copy(
                                                            ticks = newTicks,
                                                        )
                                                    },
                                                )
                                            }
                                            +tick.ticker.toString()
                                        }
                                    }
                                    TableCell {
                                        autocomplete(
                                            { null },
                                            false,
                                            "BarSize",
                                            200,
                                            null,
                                            { value ->
                                                value?.let {
                                                    editableScript = editableScript.copy(
                                                        ticks = editableScript.ticks -
                                                            tickName +
                                                            (tickName to tick.copy(barSize = value)),
                                                    )
                                                }
                                            },
                                            null,
                                            {
                                            },
                                            BarSizeSelectOption(tick.barSize),
                                            BarSize.entries.map { BarSizeSelectOption(it) }.toTypedArray(),
                                            true,
                                        )
                                    }
                                }
                            }
                            TableCell {
                                Button {
                                    onClick = {
                                        setPromptDialog(
                                            Prompt(
                                                value = tickName,
                                                title = "Rename tick",
                                                label = "Name tick:",
                                            ) { newTickName ->
                                                val newTicks =
                                                    editableScript.ticks - tickName + (newTickName to tick)
                                                editableScript = editableScript.copy(
                                                    ticks = newTicks,
                                                )
                                            },
                                        )
                                    }
                                    +"Rename"
                                }
                                Button {
                                    onClick = {
                                        it.stopPropagation()
                                        editableScript = editableScript.copy(
                                            ticks = editableScript.ticks - tickName,
                                        )
                                    }
                                    +"Remove"
                                }
                            }
                        }
                    }
                }
            }
        }

        fun ChildrenBuilder.scriptSettings() {
            TextField {
                sx {
                    this.width = 1000.px
                }
                this.multiline = true
                this.rows = 20
                this.value = editableScript.script.chunkText
                onChange = { event ->
                    val text = (event.target as? HTMLTextAreaElement)?.value
                    if (text != null) {
                        editableScript = editableScript.copy(
                            script = LuaScript(text),
                        )
                    }
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                FormControlLabel {
                    control = TextField.create {
                        size = Size.small
                        this.type = InputType.number
                        this.value = editableScript.pointsLimit.toString()
                        onChange = { event ->
                            val value = (event.target as? HTMLInputElement)?.value?.toIntOrNull()
                            if (value != null) {
                                editableScript = editableScript.copy(
                                    pointsLimit = value,
                                )
                            }
                        }
                    }
                    label = ReactNode("Points limit")
                    labelPlacement = LabelPlacement.start
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                FormControlLabel {
                    control = TextField.create {
                        size = Size.small
                        value = tickIntervalInputStr
                        onChange = { event ->
                            val value = (event.target as? HTMLInputElement)?.value
                            if (value != null) {
                                tickIntervalInputStr = value
                            }
                        }
                    }
                    label = ReactNode("Tick interval")
                    labelPlacement = LabelPlacement.start
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                FormControlLabel {
                    control = Checkbox.create {
                        checked = editableScript.skipEqualTicks
                        onChange = { _, checked ->
                            editableScript = editableScript.copy(
                                skipEqualTicks = checked,
                            )
                        }
                    }
                    label = ReactNode("Skip equal ticks")
                    labelPlacement = LabelPlacement.start
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                FormControlLabel {
                    control = Checkbox.create {
                        checked = editableScript.withHistory
                        onChange = { _, checked ->
                            editableScript = editableScript.copy(
                                withHistory = checked,
                            )
                        }
                    }
                    label = ReactNode("History")
                    labelPlacement = LabelPlacement.start
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                autocomplete(
                    { null },
                    false,
                    "History bar size",
                    300,
                    null,
                    { value ->
                        value?.let { newValue ->
                            editableScript = editableScript.copy(
                                historyBarSize = newValue,
                            )
                        }
                    },
                    null,
                    {
                    },
                    BarSizeSelectOption(editableScript.historyBarSize),
                    BarSize.entries.map { BarSizeSelectOption(it) }.toTypedArray(),
                    true,
                )
            }
        }

        if (props.collapsableScriptText) {
            Button {
                onClick = {
                    it.stopPropagation()
                    scriptCollapsed = !scriptCollapsed
                }
                if (scriptCollapsed) {
                    +"Expand"
                } else {
                    +"Collapse"
                }
            }
            Collapse {
                this.`in` = !scriptCollapsed
                this.timeout = "auto"

                scriptSettings()
            }
        } else {
            scriptSettings()
        }

        Stack {
            direction = responsive(StackDirection.row)
            Button {
                onClick = {
                    val newScript = editableScript.copy(
                        tickInterval = tickIntervalInputStr.asHumanReadableToDuration(),
                    )
                    props.setChartScript(newScript)
                }
                +"Apply"
            }
            Button {
                onClick = {
                    editableScript = props.chartScript
                }
                +"Reset"
            }
        }
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }
}
