package com.edvorg.trade.common.frontend.services

import com.benasher44.uuid.uuid4
import com.edvorg.trade.common.frontend.services.toast.AbstractToaster
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import js.core.jso
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.coroutines.launch
import mui.material.Alert
import mui.material.AlertColor
import mui.material.Snackbar
import mui.material.SnackbarOriginHorizontal
import mui.material.SnackbarOriginVertical
import mui.material.SnackbarProps
import react.ChildrenBuilder
import react.ReactNode
import react.useEffectOnce
import react.useState

typealias SnackbarBlock = SnackbarProps.(onClosed: () -> Unit) -> Unit

data class SnackbarMessage(
    val key: String,
    val block: SnackbarBlock,
)

class SnackbarToaster : AbstractToaster {
    private val snackbarsMutableFlow = MutableSharedFlow<SnackbarMessage>(0, 10)
    private val snackbarsFlow = snackbarsMutableFlow.asSharedFlow()

    override fun info(
        message: String,
        autoCloseMs: Int,
        position: ToastPosition,
        icon: ReactNode?,
    ) {
        pushSnackbar(uuid4().toString()) { onClosed ->
            this.autoHideDuration = autoCloseMs
            this.onClose = { _, _ -> onClosed() }
            this.anchorOrigin = jso {
                this.vertical = SnackbarOriginVertical.bottom
                this.horizontal = when (position) {
                    ToastPosition.DEFAULT -> SnackbarOriginHorizontal.left
                    ToastPosition.BOTTOM_RIGHT -> SnackbarOriginHorizontal.right
                }
            }

            Alert {
                this.onClose = { _ -> onClosed() }
                this.severity = AlertColor.info

                if (icon != null) {
                    this.icon = icon
                }

                +message
            }
        }
    }

    override fun error(message: String, autoCloseMs: Int, position: ToastPosition) {
        pushSnackbar(uuid4().toString()) { onClosed ->
            this.autoHideDuration = autoCloseMs
            this.onClose = { _, _ -> onClosed() }
            this.anchorOrigin = jso {
                this.vertical = SnackbarOriginVertical.bottom
                this.horizontal = when (position) {
                    ToastPosition.DEFAULT -> SnackbarOriginHorizontal.left
                    ToastPosition.BOTTOM_RIGHT -> SnackbarOriginHorizontal.right
                }
            }

            Alert {
                this.onClose = { _ -> onClosed() }
                this.severity = AlertColor.error

                +message
            }
        }
    }

    fun pushSnackbar(key: String, block: SnackbarBlock) {
        if (!snackbarsMutableFlow.tryEmit(SnackbarMessage(key, block))) {
            console.log("unable to emit snackbars update")
        }
    }

    fun ChildrenBuilder.renderSnackbar(scope: CoroutineScope) {
        val (snackbars, setSnackbars) = useState<List<SnackbarMessage>>(listOf())

        useEffectOnce {
            val job = scope.launch {
                snackbarsFlow.collect { newSnackbar ->
                    setSnackbars { snackbars ->
                        snackbars + newSnackbar
                    }
                }
            }

            cleanup {
                job.cancel()
            }
        }

        val snackbar = snackbars.firstOrNull()

        Snackbar {
            this.key = snackbar?.key

            if (snackbar == null) {
                this.open = false
            } else {
                this.open = true

                snackbar.block.invoke(this) {
                    setSnackbars { snackbars ->
                        snackbars.drop(1)
                    }
                }
            }
        }
    }
}
