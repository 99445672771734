package com.edvorg.trade.common.model.config

enum class ScannerColumn(val displayName: String) {
    PassedTime("PassedTime"),
    Time("Time"),
    Logo("Logo"),
    Ticker("Ticker"),
    Ticker2("Ticker"),
    Ratio("Ratio"),
    Addend("Addend"),
    Ratio2("Ratio2"),
    Addend2("Addend2"),
    Bid("Bid"),
    Bid2("Bid2"),
    Ask("Ask"),
    Ask2("Ask2"),
    ShortableTick1("Short"),
    ShortableTick2("Short2"),
    Diff("Diff"),
    FutureDiff("FutureDiff"),
    Avail("Avail"),
    AverageSpread("AvgS"),
    Profit("Profit"),
    SpreadProfit("SProfit"),
    Actions("Actions"),
    Buy1000("Buy1000"),
    Buy2000("Buy2000"),
    Buy5000("Buy5000"),
    Buy10000("Buy10000"),

    ;

    companion object {
        fun nonCompactColumns() = linkedSetOf(
            Logo,
            Ticker,
            Ratio,
            Bid,
            Ask,
            ShortableTick1,
            Diff,
            Avail,
            Profit,
            SpreadProfit,
            AverageSpread,
            Actions,
        )
    }
}
