package com.edvorg.trade.backend.frontend.views.widgets.connectors

import com.edvorg.trade.backend.frontend.services.makeHttpBackendUrl
import com.edvorg.trade.common.frontend.views.fcWithScope
import kotlinx.browser.window
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.Size
import react.Props
import react.dom.html.ReactHTML.h3
import web.cssom.ClassName

val toolsPanel = fcWithScope<Props> { _, scope ->
    h3 {
        +"Tools"
    }
    Button {
        size = Size.small
        className = ClassName("normal")
        onClick = {
            scope.launch {
                val url = makeHttpBackendUrl("/tools/report-tickers.txt", emptyMap())
                window.open(url.toString())
            }
        }
        +"Load reporting tickers"
    }
}
