package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.model.BinanceFutureMarket
import com.edvorg.trade.common.model.BrokerClientConfig
import com.edvorg.trade.common.model.BrokerConnectorStatus
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.CurrencyPair
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.config.PollConfig
import com.edvorg.trade.common.model.config.brokerClientOptions
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import js.core.jso
import kotlinx.coroutines.launch
import mui.icons.material.AddCircle
import mui.material.Button
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Tooltip
import mui.system.responsive
import mui.system.sx
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.h4
import react.useEffect
import react.useState
import web.cssom.ClassName
import web.cssom.Cursor
import web.cssom.Globals.Companion.unset
import web.cssom.px

val brokerConnectorsPanel = fcWithScope<Props> { _, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val clickWatcher = getContext<CommonContext>().clickWatcher
    val toaster = getContext<CommonContext>().toaster

    var selectedBrokerServer by useState("local")
    var brokerClientTypeToCreate by useState(brokerClientOptions.keys.first())
    val (shownBrokerClients, setShownBrokerClients) = useState(emptySet<BrokerId>())
    var brokerClients by useState(emptyMap<BrokerId, BrokerConnectorStatus>())
    var exchanges by useState(emptySet<Exchange>())
    val (priorityBrokersShown, setPriorityBrokersShown) = useState(false)
    val (userSpaceBrokersShown, setUserSpaceBrokersShown) = useState(false)
    val (confirmDialog, setConfirmDialog) = useState<Confirm?>(null)
    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)

    useEffect(selectedBrokerServer) {
        val client = connectorServersManager.getServerConnection(selectedBrokerServer)?.client ?: return@useEffect
        val handle = client.connectionManager.subscribeToBrokerClientsUpdate {
            brokerClients = it.brokerClients
            exchanges = it.brokerClientsByExchange.keys
        }
        cleanup {
            handle.unsubscribe()
        }
    }

    fun saveBrokerClientSettings(
        brokerId: BrokerId,
        settings: Settings,
        prevConfig: BrokerClientConfig,
    ) {
        val autoStart = settings.getBoolean("autoStart")
        val brokerExchanges = settings.getExchangesList("exchanges").toSet()
        val commissionPercent = settings.getDouble("commissionPercent")

        val newBrokerClientSettings = when (prevConfig) {
            is BrokerClientConfig.InteractiveBrokers -> {
                val currencyPairs = settings.getStringPairsList(
                    "currencyPairs",
                ).mapNotNull { (first, second) ->
                    Currency.fromName(first.uppercase())?.let { currency1 ->
                        Currency.fromName(second.uppercase())?.let { currency2 ->
                            CurrencyPair(currency1, currency2)
                        }
                    }
                }
                val exchangeCurrencies = settings.getStringPairsList(
                    "exchangeCurrencies",
                ).mapNotNull { (exchangeId, second) ->
                    Exchange.fromId(exchangeId)?.let { exchange ->
                        Currency.fromName(second.uppercase())?.let { currency ->
                            Pair(exchange, currency)
                        }
                    }
                }.toMap()
                val directlyRoutedExchanges = settings.getExchangesList(
                    "directlyRoutedExchanges",
                ).toSet()
                val chunkSize = settings.getInt("chunkSize")
                val chunkDelay = settings.getDuration("chunkDelay")
                val clientId = settings.getInt("clientId")
                val orderIdReceiveTimeout = settings.getDuration("orderIdReceiveTimeout")
                val correctByStep = settings.getBoolean("correctPriceByStep")
                val skipEmptyQuote = settings.getBoolean("skipEmptyQuote")
                val genericTicks = settings.getStringList("genericTicks").mapNotNull { tick ->
                    tick.toIntOrNull()
                }.toSet()
                val caching = settings.getBoolean("caching")
                val errorCodesPush = settings.getStringList("errorCodesPush").mapNotNull { code ->
                    code.toIntOrNull()
                }.toSet()
                BrokerClientConfig.InteractiveBrokers(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getInt("port"),
                    exchangeCurrencies,
                    currencyPairs,
                    directlyRoutedExchanges,
                    chunkSize,
                    chunkDelay,
                    clientId,
                    orderIdReceiveTimeout,
                    correctByStep,
                    skipEmptyQuote,
                    genericTicks,
                    caching,
                    errorCodesPush,
                )
            }

            is BrokerClientConfig.Tinkoff -> {
                BrokerClientConfig.Tinkoff(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getBoolean("sandbox"),
                    settings.getString("token"),
                    PollConfig(
                        settings.getDuration("portfolioPollConfig.pollInterval"),
                        settings.getDuration("portfolioPollConfig.requestTimeout"),
                        settings.getBoolean("portfolioPollConfig.enabled"),
                    ),
                    PollConfig(
                        settings.getDuration("ordersPollConfig.pollInterval"),
                        settings.getDuration("ordersPollConfig.requestTimeout"),
                        settings.getBoolean("ordersPollConfig.enabled"),
                    ),
                    PollConfig(
                        settings.getDuration("operationsPollConfig.pollInterval"),
                        settings.getDuration("operationsPollConfig.requestTimeout"),
                        settings.getBoolean("operationsPollConfig.enabled"),
                    ),
                )
            }

            is BrokerClientConfig.Relay -> {
                BrokerClientConfig.Relay(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getUrl("endpoint"),
                    settings.getDuration("connectionPingTimeout"),
                )
            }

            is BrokerClientConfig.Simulator -> {
                BrokerClientConfig.Simulator(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getDouble("balance"),
                    emptyMap(),
                    settings.getOptionalDuration("executionInterval"),
                    settings.getOptionalDouble("executionStepVolumeCap"),
                    settings.getBoolean("processOrdersInBackground"),
                )
            }

            is BrokerClientConfig.NoOp -> {
                BrokerClientConfig.NoOp(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    prevConfig.failOnStart,
                )
            }

            is BrokerClientConfig.Alor -> {
                BrokerClientConfig.Alor(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getBoolean("sandbox"),
                    settings.getString("token"),
                    settings.getString("profile"),
                    PollConfig(
                        settings.getDuration("liquidityPollConfig.pollInterval"),
                        settings.getDuration("liquidityPollConfig.requestTimeout"),
                        settings.getBoolean("liquidityPollConfig.enabled"),
                    ),
                    settings.getBoolean("debug"),
                    settings.getString("riskCategory"),
                    PollConfig(
                        settings.getDuration("usdTradesPollConfig.pollInterval"),
                        settings.getDuration("usdTradesPollConfig.requestTimeout"),
                        settings.getBoolean("usdTradesPollConfig.enabled"),
                    ),
                    settings.getBoolean("subscribeOperations"),
                    settings.getBoolean("subscribePortfolioSummary"),
                    settings.getBoolean("subscribeRisk"),
                    settings.getBoolean("subscribeStopOrders"),
                )
            }

            is BrokerClientConfig.Exante -> {
                BrokerClientConfig.Exante(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getString("accountId"),
                    settings.getString("token"),
                    PollConfig(
                        settings.getDuration("portfolioPollConfig.pollInterval"),
                        settings.getDuration("portfolioPollConfig.requestTimeout"),
                        settings.getBoolean("portfolioPollConfig.enabled"),
                    ),
                    PollConfig(
                        settings.getDuration("ordersPollConfig.pollInterval"),
                        settings.getDuration("ordersPollConfig.requestTimeout"),
                        settings.getBoolean("ordersPollConfig.enabled"),
                    ),
                    settings.getBoolean("sandbox"),
                )
            }
            is BrokerClientConfig.Finam -> {
                BrokerClientConfig.Finam(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getString("token"),
                    settings.getString("clientId"),
                    settings.getDuration("portfolioPollInterval"),
                    settings.getDuration("pingInterval"),
                )
            }
            is BrokerClientConfig.FutureBinance -> {
                BrokerClientConfig.FutureBinance(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getString("apiKey"),
                    settings.getString("secretKey"),
                    BinanceFutureMarket.fromName(settings.getString("market")) ?: BinanceFutureMarket.USDT,
                )
            }
            is BrokerClientConfig.Mexc -> {
                BrokerClientConfig.Mexc(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getString("accessKey"),
                    settings.getString("secretKey"),
                    settings.getDuration("listenKeyExtendInterval"),
                )
            }
            is BrokerClientConfig.MexcFut -> {
                BrokerClientConfig.MexcFut(
                    prevConfig.createdAt,
                    autoStart,
                    brokerExchanges,
                    commissionPercent,
                    settings.getString("accessKey"),
                    settings.getString("secretKey"),
                )
            }
        }
        scope.launch {
            val client = connectorServersManager.getServerConnection(selectedBrokerServer)?.client
            if (client != null) {
                scope.launch {
                    client.connectionManager.sendUpdateBrokerConnector(
                        brokerId,
                        newBrokerClientSettings,
                    )
                }
            }
        }
    }

    fun resetBrokerClientSettings(
        settings: MutableSettings,
        config: BrokerClientConfig,
    ) {
        settings.setBoolean("autoStart", config.autoStart, true)
        settings.setDouble("commissionPercent", config.commissionPercent, false, null)
        settings.setExchangesList("exchanges", config.exchanges, false, 500)
        when (config) {
            is BrokerClientConfig.Tinkoff -> {
                settings.setBoolean("sandbox", config.sandbox, true)
                settings.setString("token", config.token, false, null)
                settings.setDuration(
                    "portfolioPollConfig.pollInterval",
                    config.portfolioPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "portfolioPollConfig.requestTimeout",
                    config.portfolioPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("portfolioPollConfig.enabled", config.portfolioPollConfig.enabled, true)
                settings.setDuration(
                    "ordersPollConfig.pollInterval",
                    config.ordersPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "ordersPollConfig.requestTimeout",
                    config.ordersPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("ordersPollConfig.enabled", config.ordersPollConfig.enabled, true)
                settings.setDuration(
                    "operationsPollConfig.pollInterval",
                    config.operationsPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "operationsPollConfig.requestTimeout",
                    config.operationsPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("operationsPollConfig.enabled", config.operationsPollConfig.enabled, true)
            }

            is BrokerClientConfig.InteractiveBrokers -> {
                settings.setInt("port", config.port, false, null)
                settings.setStringPairsList(
                    "exchangeCurrencies",
                    config.exchangeCurrencies.map { (first, second) ->
                        Pair(first.id, second.name)
                    },
                    false,
                    null,
                )
                settings.setStringPairsList(
                    "currencyPairs",
                    config.currencyPairs.map { (first, second) ->
                        Pair(first.name, second.name)
                    },
                    false,
                    null,
                )
                settings.setExchangesList(
                    "directlyRoutedExchanges",
                    config.directlyRoutedExchanges,
                    false,
                    300,
                )
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setInt("clientId", config.clientId, false, null)
                settings.setDuration("orderIdReceiveTimeout", config.orderIdReceiveTimeout, false, null)
                settings.setBoolean("correctPriceByStep", config.correctPriceByStep, true)
                settings.setBoolean("skipEmptyQuote", config.skipEmptyQuote, true)
                settings.setStringList(
                    "genericTicks",
                    config.genericTicks.map { it.toString() },
                    true,
                    null,
                )
                settings.setBoolean("caching", config.caching, true)
                settings.setStringList(
                    "errorCodesPush",
                    config.errorCodesPush.map { it.toString() },
                    true,
                    null,
                )
            }

            is BrokerClientConfig.Relay -> {
                settings.setUrl("endpoint", config.endpoint, false, null)
                settings.setDuration("connectionPingTimeout", config.connectionPingTimeout, false, null)
            }

            is BrokerClientConfig.Simulator -> {
                settings.setDouble("balance", config.balance, 2, false, null)
                settings.setOptionalDuration("executionInterval", config.executionInterval, false, null)
                settings.setOptionalDouble("executionStepVolumeCap", config.executionStepVolumeCap, false, null)
                settings.setBoolean("processOrdersInBackground", config.processOrdersInBackground, true)
            }

            is BrokerClientConfig.Alor -> {
                settings.setBoolean("sandbox", config.sandbox, true)
                settings.setString("token", config.token, false, null)
                settings.setString("profile", config.profile, false, null)
                settings.setDuration(
                    "liquidityPollConfig.pollInterval",
                    config.liquidityPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "liquidityPollConfig.requestTimeout",
                    config.liquidityPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("liquidityPollConfig.enabled", config.liquidityPollConfig.enabled, true)
                settings.setBoolean("debug", config.debug, true)
                settings.setString("riskCategory", config.riskCategory, false, null)
                settings.setDuration(
                    "usdTradesPollConfig.pollInterval",
                    config.usdTradesPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "usdTradesPollConfig.requestTimeout",
                    config.usdTradesPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("usdTradesPollConfig.enabled", config.usdTradesPollConfig.enabled, true)
                settings.setBoolean("subscribeOperations", config.subscribeOperations, true)
                settings.setBoolean("subscribePortfolioSummary", config.subscribePortfolioSummary, true)
                settings.setBoolean("subscribeRisk", config.subscribeRisk, true)
                settings.setBoolean("subscribeStopOrders", config.subscribeStopOrders, true)
            }

            is BrokerClientConfig.NoOp -> {
            }

            is BrokerClientConfig.Exante -> {
                settings.setString("token", config.token, false, null)
                settings.setString("accountId", config.accountId, false, null)
                settings.setDuration(
                    "portfolioPollConfig.pollInterval",
                    config.portfolioPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "portfolioPollConfig.requestTimeout",
                    config.portfolioPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("portfolioPollConfig.enabled", config.portfolioPollConfig.enabled, true)
                settings.setDuration(
                    "ordersPollConfig.pollInterval",
                    config.ordersPollConfig.pollInterval,
                    false,
                    null,
                )
                settings.setDuration(
                    "ordersPollConfig.requestTimeout",
                    config.ordersPollConfig.requestTimeout,
                    false,
                    null,
                )
                settings.setBoolean("ordersPollConfig.enabled", config.ordersPollConfig.enabled, true)
                settings.setBoolean("sandbox", config.sandbox, true)
            }
            is BrokerClientConfig.Finam -> {
                settings.setString("token", config.token, false, null)
                settings.setString("clientId", config.clientId, false, null)
                settings.setDuration("portfolioPollInterval", config.portfolioPollInterval, false, null)
                settings.setDuration("pingInterval", config.pingInterval, false, null)
            }
            is BrokerClientConfig.FutureBinance -> {
                settings.setString("apiKey", config.apiKey, false, null)
                settings.setString("secretKey", config.secretKey, false, null)
                settings.setString("market", config.market.name, false, null)
            }
            is BrokerClientConfig.Mexc -> {
                settings.setString("accessKey", config.accessKey, false, null)
                settings.setString("secretKey", config.secretKey, false, null)
                settings.setDuration("listenKeyExtendInterval", config.listenKeyExtendInterval, false, null)
            }

            is BrokerClientConfig.MexcFut -> {
                settings.setString("accessKey", config.accessKey, false, null)
                settings.setString("secretKey", config.secretKey, false, null)
            }
        }
    }

    Stack {
        spacing = responsive(10.px)

        h3 {
            +"Brokers"
        }
        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(5.px)
            justifyContent = "flex-start"

            autocomplete(
                { null },
                false,
                "server",
                150,
                null,
                { value ->
                    value?.let { newSelectedServer ->
                        selectedBrokerServer = newSelectedServer
                        brokerClients = emptyMap()
                    }
                },
                null,
                {
                },
                StringOption(selectedBrokerServer),
                connectorServersManager.getServerIds().map { StringOption(it) }.toTypedArray(),
                true,
            )

            val status = connectorServersManager.getServerConnection(selectedBrokerServer)?.status
            if (status == ConnectorStatus.Disconnected) {
                Tooltip {
                    title = ReactNode("Connect to the server")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            it.stopPropagation()

                            scope.launch {
                                connectorServersManager.getServerConnection(selectedBrokerServer)?.client?.connect(
                                    selectedBrokerServer,
                                )
                            }
                        }
                        +"Connect"
                    }
                }
            }
            autocomplete(
                { null },
                false,
                "Create connector",
                250,
                null,
                { value ->
                    value?.let { newTypeToCreate ->
                        brokerClientTypeToCreate = newTypeToCreate
                    }
                },
                null,
                {
                },
                StringOption(brokerClientTypeToCreate),
                brokerClientOptions.map { StringOption(it.key) }.toTypedArray(),
                true,
            )
            Tooltip {
                title = ReactNode("Create")

                Button {
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        clickWatcher.click()
                        event.stopPropagation()
                        setPromptDialog(
                            Prompt(
                                "",
                                "Create broker connector",
                                "Connector name",
                            ) { brokerId ->
                                brokerClientOptions[brokerClientTypeToCreate]?.let { config ->
                                    val client = connectorServersManager.getServerConnection(
                                        selectedBrokerServer,
                                    )?.client
                                    if (client != null) {
                                        scope.launch {
                                            client.connectionManager.sendCreateBrokerConnector(
                                                BrokerId(brokerId),
                                                config,
                                            )
                                        }
                                    }
                                }
                            },
                        )
                    }
                    AddCircle {
                    }
                }
            }
        }
        TableContainer {
            Table {
                sx {
                    width = unset
                }
                size = Size.small

                TableHead {
                    TableRow {
                        TableCell {
                            +"BrokerId"
                        }
                        TableCell {
                            +"Status"
                        }
                        TableCell {
                            +"Actions"
                        }
                    }
                }
                if (brokerClients.isNotEmpty()) {
                    val sortedEntries = brokerClients.entries.sortedBy { -it.value.brokerClientConfig.createdAt }
                    for ((brokerId, status) in sortedEntries) {
                        TableBody {
                            key = "broker:${brokerId.id}"

                            brokerClientConnector {
                                this.serverId = selectedBrokerServer
                                this.brokerId = brokerId
                                this.status = status
                                this.resetSettings = { it ->
                                    resetBrokerClientSettings(it, status.brokerClientConfig)
                                }
                                this.saveSettings = {
                                    saveBrokerClientSettings(brokerId, it, status.brokerClientConfig)
                                    toaster.info(
                                        "Broker ${brokerId.id} saved",
                                        1900,
                                        ToastPosition.BOTTOM_RIGHT,
                                    )
                                }
                                this.showSettings = shownBrokerClients.contains(brokerId)
                                this.toggleShowSettings = {
                                    setShownBrokerClients { oldShownBrokerClients ->
                                        if (oldShownBrokerClients.contains(brokerId)) {
                                            oldShownBrokerClients - brokerId
                                        } else {
                                            oldShownBrokerClients + brokerId
                                        }
                                    }
                                }
                                this.removeConnector = {
                                    setConfirmDialog(
                                        Confirm(
                                            "Remove broker connector",
                                            "Please confirm connector removal",
                                        ) {
                                            val client = connectorServersManager.getServerConnection(
                                                selectedBrokerServer,
                                            )?.client
                                            client?.connectionManager?.sendRemoveBrokerConnector(brokerId)
                                        },
                                    )
                                }
                                this.duplicateConnector = {
                                    setPromptDialog(
                                        Prompt(
                                            "",
                                            "Duplicate broker connector",
                                            "Connector name",
                                        ) { newBrokerId ->
                                            scope.launch {
                                                val client = connectorServersManager.getServerConnection(
                                                    selectedBrokerServer,
                                                )?.client
                                                client?.connectionManager?.sendDuplicateBrokerConnector(
                                                    brokerId,
                                                    BrokerId(newBrokerId),
                                                )
                                            }
                                        },
                                    )
                                }
                            }
                        }
                    }
                } else {
                    TableBody {
                        TableRow {
                            TableCell {
                                colSpan = 5
                                +"No connectors"
                            }
                        }
                    }
                }
            }
        }

        h4 {
            style = jso {
                cursor = Cursor.pointer
            }
            onClick = {
                it.stopPropagation()

                setPriorityBrokersShown { oldPriorityBrokersShown ->
                    !oldPriorityBrokersShown
                }
            }
            if (priorityBrokersShown) {
                +"Priority brokers"
            } else {
                +"Priority brokers >"
            }
        }

        if (priorityBrokersShown) {
            exchanges.forEach {
                priorityBrokerSelect {
                    key = it.id

                    exchange = it
                }
            }
        }

        h4 {
            style = jso {
                cursor = Cursor.pointer
            }
            onClick = {
                it.stopPropagation()

                setUserSpaceBrokersShown { oldUserSpaceBrokersShown ->
                    !oldUserSpaceBrokersShown
                }
            }
            if (userSpaceBrokersShown) {
                +"User space brokers"
            } else {
                +"User space brokers >"
            }
        }

        if (userSpaceBrokersShown) {
            exchanges.forEach {
                localBrokerSelect {
                    key = it.id

                    exchange = it
                }
            }
        }
    }

    confirm {
        this.dialog = confirmDialog
        this.setDialog = setConfirmDialog
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }
}
