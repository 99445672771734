package com.edvorg.trade.backend.frontend.views.widgets

import com.edvorg.trade.backend.frontend.FrontendContext
import com.edvorg.trade.common.charts.CandlestickData
import com.edvorg.trade.common.charts.CandlestickStyleOptions
import com.edvorg.trade.common.charts.Chart
import com.edvorg.trade.common.charts.ChartOptions
import com.edvorg.trade.common.charts.PriceScaleOptions
import com.edvorg.trade.common.charts.TimeScaleOptions
import com.edvorg.trade.common.charts.lightWeightCharts
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.views.WidgetProps
import com.edvorg.trade.common.frontend.views.settingsPanel
import com.edvorg.trade.common.frontend.views.widget
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.Candle
import com.edvorg.trade.common.model.WidgetSettings
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.browser.window
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.useEffect
import react.useState
import web.cssom.ClassName
import web.dom.document
import kotlin.js.Date
import kotlin.math.max
import kotlin.math.min

external interface ChartWidgetProps : WidgetProps<WidgetSettings.Chart>

private const val heightOffset = -25 - 22.5
private const val widthOffset = -5

val chartWidget = widget<ChartWidgetProps, WidgetSettings.Chart>(
    { "Chart" },
    isStretchable = true,
    isPair = false,
) { props, settingsPanel, _, _ ->
    val clickWatcher = getContext<CommonContext>().clickWatcher
    val candlesClient = getContext<FrontendContext>().candlesClient
    val backendMainClient = getContext<FrontendContext>().backendMainClient

    var chart by useState<Chart?>(null)

    val idStr = "${props.groupId}-${props.id}-chart"

    useEffect(props.selectedTicker, props.settings.barSize) {
        document.getElementById(idStr)?.let { chartContainer ->
            while (true) {
                val firstChild = chartContainer.firstChild ?: break
                chartContainer.removeChild(firstChild)
            }
        }

        val ticker = props.selectedTicker ?: return@useEffect

        var renderTimeout: Int? = null

        val handle = try {
            val options = ChartOptions(
                autoSize = false,
                width = props.width + widthOffset,
                height = props.height + heightOffset,
                timeScale = TimeScaleOptions(
                    timeVisible = true,
                ),
                leftPriceScale = PriceScaleOptions(
                    visible = false,
                ),
                rightPriceScale = PriceScaleOptions(
                    visible = true,
                ),
            )

            val newChart = lightWeightCharts().createChart(idStr, options)
            chart = newChart

            val candleSeries = newChart.addCandlestickSeries(
                CandlestickStyleOptions(
                    upColor = "#1bbe1b",
                    borderUpColor = "#1abd1a",
                    wickUpColor = "#1bbe1b",
                    downColor = "#b30000",
                    borderDownColor = "#b20000",
                    wickDownColor = "#b30000",
                    title = props.selectedTicker?.ticker?.id ?: "",
                ),
            )

            val candlesToRender = mutableMapOf<Long, Candle>()
            var sortedCandles: Array<CandlestickData> = arrayOf()

            val candleHandle = candlesClient.subscribeToCandles(
                ticker,
                props.settings.barSize,
            ) { update ->
                candlesToRender += update.candles.associateBy { candle -> candle.time }
                renderTimeout?.let { window.clearTimeout(it) }
                renderTimeout = window.setTimeout(
                    {
                        sortedCandles = candlesToRender.values.sortedBy { it.time }.map { candle ->
                            CandlestickData(
                                time = candle.time.toDouble() - Date().getTimezoneOffset() * 60,
                                open = candle.open,
                                high = candle.high,
                                low = candle.low,
                                close = candle.close,
                            )
                        }.toTypedArray()

                        candleSeries.setData(sortedCandles)
                    },
                    150,
                )
            }

            val lastPriceHandle = if (
                props.settings.appendLast && props.settings.barSize == BarSize.MINUTE
            ) {
                var lastRenderTimeout: Int? = null
                var currentLowHigh: Pair<CandlestickData, Pair<Double, Double>>? = null
                backendMainClient.subscribeToPriceStatUpdate(
                    ticker,
                    PriceStatType.LAST,
                    null,
                    null,
                ) {
                    lastRenderTimeout?.let { window.clearTimeout(it) }
                    lastRenderTimeout = window.setTimeout(
                        {
                            val (lastPrice, _) = it.priceStat
                            val lastCandle = sortedCandles.lastOrNull()
                            if (lastCandle != null) {
                                val lowHigh = currentLowHigh
                                val low = if (lowHigh != null && lowHigh.first == lastCandle) {
                                    min(lastPrice, lowHigh.second.first)
                                } else {
                                    lastPrice
                                }

                                val high = if (lowHigh != null && lowHigh.first == lastCandle) {
                                    max(lastPrice, lowHigh.second.second)
                                } else {
                                    lastPrice
                                }

                                currentLowHigh = lastCandle to (low to high)

                                val newCandle = CandlestickData(
                                    time = lastCandle.time + 60 * 1000,
                                    open = lastCandle.close,
                                    high = high,
                                    low = low,
                                    close = lastPrice,
                                )

                                candleSeries.setData(sortedCandles + newCandle)
                            }
                        },
                        200,
                    )
                }
            } else {
                null
            }

            candleHandle to lastPriceHandle
        } catch (e: Throwable) {
            console.error(e)
            null
        }

        cleanup {
            renderTimeout?.let { window.clearTimeout(it) }
            handle?.first?.unsubscribe()
            handle?.second?.unsubscribe()
        }
    }

    useEffect(props.width, props.height) {
        chart?.resize(
            props.width + widthOffset,
            props.height + heightOffset,
        )
    }

    if (settingsPanel) {
        settingsPanel {
            resetSettings = {
                it.setBoolean("appendLast", props.settings.appendLast, true)
            }
            settings = MutableSettings(mutableMapOf()).apply(resetSettings).toImmutable()
            searchEnabled = false
            saveSettings = {
                props.onUpdateSettings.invoke {
                    props.settings.copy(
                        appendLast = it.getBoolean("appendLast"),
                    )
                }
            }
        }
        return@widget
    }

    div {
        select {
            value = props.settings.barSize.name
            onMouseUp = { it.stopPropagation() }
            onMouseDown = { it.stopPropagation() }
            onChange = { event ->
                event.stopPropagation()
                event.target.value.let { selection ->
                    val newValue = BarSize.entries.find {
                        it.name == selection
                    } ?: throw Error("unexpected")
                    props.onUpdateSettings.invoke {
                        props.settings.copy(barSize = newValue)
                    }
                }
            }

            for (b in BarSize.entries) {
                option {
                    key = b.name
                    value = b.name

                    +b.id
                }
            }
        }
        button {
            className = ClassName("normal")
            onClick = { stop ->
                clickWatcher.click()
                stop.stopPropagation()
                window.open(
                    "https://www.tradingview.com/symbols/${props.selectedTicker?.ticker?.id}",
                )
            }

            +"TV"
        }
    }
    div {
        id = idStr
    }
}
