package com.edvorg.trade.common.model

enum class OperationType(private val index: Int) {
    BUY(1),
    SELL(2),
    ;

    companion object {
        private val indexToOperationType = entries.associateBy {
            it.index
        }.also { map ->
            map.entries.groupBy {
                it.value
            }.entries.find {
                1 < it.value.size
            }?.let { entry ->
                throw Error("2 or more operationType cases have the same index $entry")
            }
        }

        fun fromIndex(index: Int): OperationType? {
            return indexToOperationType[index]
        }
    }

    fun reverse(): OperationType {
        return when (this) {
            BUY -> SELL
            SELL -> BUY
        }
    }
}
