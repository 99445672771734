package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
enum class PortfolioGroupMode {
    NONE,
    BY_ISIN,
    BY_ENTITY_ID,
}
