package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(ScannerIdSerializer::class)
data class ScannerId(val id: String)

object ScannerIdSerializer : KSerializer<ScannerId> {
    override fun deserialize(decoder: Decoder): ScannerId {
        return ScannerId(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: ScannerId) {
        encoder.encodeString(value.id)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("ScannerId", PrimitiveKind.STRING)
}
