package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("ExecutorManagementRequest")
sealed class ExecutorManagementRequest {
    @Serializable
    @SerialName("StartExecutor")
    data class StartExecutor(
        val executorId: ScannerExecutorId,
    ) : ExecutorManagementRequest()

    @Serializable
    @SerialName("StopExecutor")
    data class StopExecutor(
        val executorId: ScannerExecutorId,
    ) : ExecutorManagementRequest()

    @Serializable
    @SerialName("CreateScannerExecutor")
    data class CreateScannerExecutor(
        val executorId: ScannerExecutorId,
        val config: ScannerExecutorConfig,
    ) : ExecutorManagementRequest()

    @Serializable
    @SerialName("RemoveScannerExecutor")
    data class RemoveScannerExecutor(
        val executorId: ScannerExecutorId,
    ) : ExecutorManagementRequest()

    @Serializable
    @SerialName("RestartScannerExecutor")
    data class RestartScannerExecutor(
        val executorId: ScannerExecutorId,
    ) : ExecutorManagementRequest()

    @Serializable
    @SerialName("UpdateScannerExecutor")
    data class UpdateScannerExecutor(
        val executorId: ScannerExecutorId,
        val config: ScannerExecutorConfig,
    ) : ExecutorManagementRequest()

    @Serializable
    @SerialName("DuplicateScannerExecutor")
    data class DuplicateScannerExecutor(
        val executorId: ScannerExecutorId,
        val newExecutorId: ScannerExecutorId,
    ) : ExecutorManagementRequest()
}
