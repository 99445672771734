package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
enum class ClosePriceScannerMode {
    CLOSE_PRICE,
    POST_CLOSE_PRICE,
    MAX_CLOSE_PRICE,
    MIN_CLOSE_PRICE,
}
