package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerExecutorStatus
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import com.edvorg.trade.common.utils.concurrency.concurrentMapOf
import kotlinx.coroutines.launch
import mui.icons.material.ContentCopy
import mui.icons.material.Delete
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.icons.material.PlayArrow
import mui.icons.material.RestartAlt
import mui.icons.material.Stop
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.Size
import mui.material.TableCell
import mui.material.TableRow
import mui.material.Tooltip
import react.Props
import react.ReactNode
import web.cssom.ClassName

external interface ScannerExecutorConnectorProps : Props {
    var executorId: ScannerExecutorId
    var status: ScannerExecutorStatus
    var resetSettings: (MutableSettings) -> Unit
    var saveSettings: (Settings) -> Unit
    var showSettings: Boolean
    var toggleShowSettings: () -> Unit
    var removeConnector: () -> Unit
    var duplicateConnector: () -> Unit
}

val scannerExecutorConnector = fcWithScope<ScannerExecutorConnectorProps> { props, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val connectionManager = connectorServersManager.getServerConnection("local")?.client?.connectionManager
    val clickWatcher = getContext<CommonContext>().clickWatcher

    val executorId = props.executorId
    val status = props.status
    val executorType = status.executorConfig.displayName()

    TableRow {
        TableCell {
            +"${executorId.id} ($executorType)"
        }
        TableCell {
            renderStatus(status.connectorStatus)
        }
        TableCell {
            ButtonGroup {
                when {
                    status.connectorStatus.isDisconnected() -> {
                        Tooltip {
                            title = ReactNode("connect")

                            Button {
                                size = Size.small
                                className = ClassName("normal")
                                onClick = {
                                    clickWatcher.click()
                                    it.stopPropagation()

                                    scope.launch {
                                        connectionManager?.sendStartExecutor(executorId)
                                    }
                                }
                                PlayArrow {
                                }
                            }
                        }
                    }
                    else -> {
                        Tooltip {
                            title = ReactNode("disconnect")

                            Button {
                                size = Size.small
                                className = ClassName("normal")
                                onClick = {
                                    clickWatcher.click()
                                    it.stopPropagation()

                                    scope.launch {
                                        connectionManager?.sendStopExecutor(executorId)
                                    }
                                }
                                Stop {
                                }
                            }
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("delete")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            clickWatcher.click()
                            it.stopPropagation()
                            props.removeConnector()
                        }
                        Delete {
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("Duplicate")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = { event ->
                            clickWatcher.click()
                            event.stopPropagation()
                            props.duplicateConnector()
                        }
                        ContentCopy {
                        }
                    }
                }
                Tooltip {
                    title = if (props.showSettings) {
                        ReactNode("Hide settings")
                    } else {
                        ReactNode("Show settings")
                    }

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            clickWatcher.click()
                            it.stopPropagation()
                            props.toggleShowSettings()
                        }
                        if (props.showSettings) {
                            ExpandLess {
                            }
                        } else {
                            ExpandMore {
                            }
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("Restart")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = { event ->
                            clickWatcher.click()
                            event.stopPropagation()

                            scope.launch {
                                connectionManager?.sendRestartScannerExecutor(executorId)
                            }
                        }
                        RestartAlt {
                        }
                    }
                }
            }
        }
    }
    if (props.showSettings) {
        TableRow {
            TableCell {
                colSpan = 4
                settingsPanel {
                    resetSettings = props.resetSettings
                    saveSettings = props.saveSettings
                    settings = MutableSettings(concurrentMapOf(emptyMap())).apply {
                        resetSettings(this)
                    }.toImmutable()
                    searchEnabled = false
                }
            }
        }
    }
}
