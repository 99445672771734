package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
enum class TickerDetailsFilter {
    INDUSTRY_AND_SECTOR,
    INDUSTRY_OR_SECTOR,
    INDUSTRY,
    SECTOR,
    NONE,
}
