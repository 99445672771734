package com.edvorg.trade.common.model.settings

import com.edvorg.trade.common.model.AlpacaDataApiType
import com.edvorg.trade.common.model.AlpacaEndPoint
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.ClosePriceScannerMode
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.ExanteLevel2Mode
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.OrderType
import com.edvorg.trade.common.model.PostCloseMethod
import com.edvorg.trade.common.model.ScannerDealSelection
import com.edvorg.trade.common.model.ScannerDealsSubscription
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.SpreadScannerOperatorMode
import com.edvorg.trade.common.model.Tick
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.TickerDetailsFilter
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.asHumanReadableToDuration
import com.edvorg.trade.common.model.config.BridgeConfig
import com.edvorg.trade.common.model.config.BridgeMode
import com.edvorg.trade.common.model.config.BridgeRange
import com.edvorg.trade.common.model.config.PriceTick
import com.edvorg.trade.common.model.config.SamuraiLimitsUnit
import com.edvorg.trade.common.model.config.SamuraiTick
import com.edvorg.trade.common.model.config.ScannerTick
import com.edvorg.trade.common.model.stats.PriceStatType
import io.ktor.http.Url
import kotlinx.datetime.LocalTime
import kotlin.time.Duration

interface Settings {
    val map: Map<String, Setting>

    fun getScannerDealSelection(name: String): ScannerDealSelection {
        return (map[name] as? Setting.ScannerDealSelectionSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getBoolean(name: String): Boolean {
        return (map[name] as? Setting.BooleanSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getOptionalBoolean(name: String): Boolean? {
        return (map[name] as? Setting.BooleanSetting)?.value
    }

    fun getOptionalString(name: String): String? {
        return (map[name] as? Setting.StringSetting)?.value?.trim()
    }

    fun getOptionalDouble(name: String): Double? {
        return getOptionalString(name)?.takeIf { it.isNotBlank() }?.let {
            it.toDoubleOrNull() ?: throw Error("param $name is invalid")
        }
    }

    fun getOptionalLong(name: String): Long? {
        return getOptionalString(name)?.takeIf { it.isNotBlank() }?.let {
            it.toLongOrNull() ?: throw Error("param $name is invalid")
        }
    }

    fun getDouble(name: String): Double {
        return getString(name).takeIf { it.isNotBlank() }?.toDoubleOrNull()
            ?: throw Error("param $name is invalid")
    }

    fun getInt(name: String): Int {
        return getString(name).takeIf { it.isNotBlank() }?.toIntOrNull()
            ?: throw Error("param $name is invalid")
    }

    fun getLong(name: String): Long {
        return getString(name).takeIf { it.isNotBlank() }?.toLongOrNull()
            ?: throw Error("param $name is invalid")
    }

    fun getString(name: String): String {
        return (map[name] as? Setting.StringSetting ?: throw Error("parameter $name not found")).value.trim()
    }

    fun getOptionalBridgeConfig(): BridgeConfig? {
        return getOptionalString("autoBridge")?.let { autoBridge ->
            val components = autoBridge.split(";")
            val final = components.firstOrNull()?.split(":")
                ?: throw Error("unable to parse auto bridge final range")
            val finalMode = BridgeMode.entries.find { c ->
                c.name == final.getOrNull(0)
            } ?: throw Error("unable to parse final mode")
            val finalDelta = final.getOrNull(1)?.toDoubleOrNull()
                ?: throw Error("unable to parse final delta")
            val ranges = components.drop(1).map { bridgeRange ->
                val range = bridgeRange.split(":")
                val mode = BridgeMode.entries.find { c ->
                    c.name == range.getOrNull(0)
                } ?: throw Error("unable to parse range mode")
                val delta = range.getOrNull(1)?.toDoubleOrNull()
                    ?: throw Error("unable to parse range delta")
                val maxSpread = range.getOrNull(2)?.toDoubleOrNull()
                    ?: throw Error("unable to parse range max spread")
                BridgeRange(maxSpread, mode, delta)
            }
            BridgeConfig(
                ranges,
                finalMode,
                finalDelta,
            )
        }
    }

    fun getUrl(name: String): Url {
        return Url(getString(name))
    }

    fun getStringList(name: String): List<String> {
        return getOptionalString(name)?.takeIf { it.isNotBlank() }?.split(",") ?: listOf()
    }

    fun getExchangesList(name: String): Collection<Exchange> {
        return (map[name] as? Setting.ExchangesListSetting)?.value ?: throw Error("parameter $name not found")
    }

    fun getStringPairsList(name: String): List<Pair<String, String>> {
        return getStringList(name).mapNotNull {
            val (first, second) = it.takeIf { it.isNotBlank() }?.split(":") ?: return@mapNotNull null
            Pair(first, second)
        }
    }

    fun getStringTriplesList(name: String): List<Triple<String, String, String>> {
        return getStringList(name).mapNotNull {
            val (first, second, third) = it.takeIf { it.isNotBlank() }?.split(":") ?: return@mapNotNull null
            Triple(first, second, third)
        }
    }

    fun getDuration(name: String): Duration {
        return getString(name).asHumanReadableToDuration()
    }

    fun getOptionalDuration(name: String): Duration? {
        return getOptionalString(name)?.asHumanReadableToDuration()
    }

    fun getOptionalLocalTime(name: String): LocalTime? {
        return (map[name] as? Setting.OptionalLocalTimeSetting)?.value
    }

    fun getBarSize(name: String): BarSize {
        return (map[name] as? Setting.BarSizeSetting)?.value ?: throw Error("parameter $name not found")
    }

    fun getDealsSubscription(name: String): ScannerDealsSubscription {
        return (map[name] as? Setting.DealsSubscriptionSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getOrderType(name: String): OrderType {
        return (map[name] as? Setting.OrderTypeSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getScannerId(name: String): ScannerId {
        return (map[name] as? Setting.ScannerIdSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getScannerExecutorId(name: String): ScannerExecutorId {
        return (map[name] as? Setting.ScannerExecutorIdSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getTick(name: String): Tick {
        return (map[name] as? Setting.TickSetting)?.value ?: throw Error("parameter $name not found")
    }

    fun getScannerTick(name: String): ScannerTick {
        val s = (map[name] as? Setting.ScannerTickSetting)?.value?.let { ImmutableSettings(it) }
            ?: throw Error("parameter $name not found")
        return when (val type = s.getString("_type")) {
            "Bid" -> {
                ScannerTick.Bid
            }
            "Ask" -> {
                ScannerTick.Ask
            }
            "Mid" -> {
                ScannerTick.Mid
            }
            "Stat" -> {
                ScannerTick.Stat(
                    s.getPriceStatType("priceStatType"),
                )
            }
            "Indicator" -> {
                ScannerTick.Indicator(
                    s.getIndicator("indicator"),
                )
            }
            else -> throw Error("unknown type $type")
        }
    }

    fun getPriceTick(name: String): PriceTick {
        val s = (map[name] as? Setting.PriceTickSetting)?.value?.let { ImmutableSettings(it) }
            ?: throw Error("parameter $name not found")
        return when (val type = s.getString("_type")) {
            "Bid" -> {
                PriceTick.Bid
            }
            "Ask" -> {
                PriceTick.Ask
            }
            "Mid" -> {
                PriceTick.Mid
            }
            "Stat" -> {
                PriceTick.Stat(
                    s.getPriceStatType("priceStatType"),
                )
            }
            else -> throw Error("unknown type $type")
        }
    }

    fun getScannerServer(name: String): String {
        return (map[name] as? Setting.ScannerServerSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getOptionalScannerServer(name: String): String? {
        return (map[name] as? Setting.ScannerServerSetting)?.value
    }

    fun getCurrency(name: String): Currency {
        return (map[name] as? Setting.CurrencySetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getExanteLevel2Mode(name: String): ExanteLevel2Mode {
        return (map[name] as? Setting.ExanteLevel2ModeSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getAlpacaEndPoint(name: String): AlpacaEndPoint {
        return (map[name] as? Setting.AlpacaEndPointSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getAlpacaDataApiType(name: String): AlpacaDataApiType {
        return (map[name] as? Setting.AlpacaDataApiTypeSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getClosePriceScannerMode(name: String): ClosePriceScannerMode {
        return (map[name] as? Setting.ClosePriceScannerSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getTickerDetailsFilter(name: String): TickerDetailsFilter {
        return (map[name] as? Setting.TickerDetailsFilterSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getSpreadScannerOperator(name: String): SpreadScannerOperatorMode {
        return (map[name] as? Setting.SpreadScannerOperatorSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    operator fun plus(settings2: Settings): Settings

    fun getTickerType(name: String): TickerType {
        return (map[name] as? Setting.TickerTypeSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getExchange(name: String): Exchange {
        return (map[name] as? Setting.ExchangeSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getPostCloseMethod(name: String): PostCloseMethod {
        return (map[name] as? Setting.PostCloseMethodSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getSamuraiTick(name: String): SamuraiTick {
        val s = (map[name] as? Setting.SamuraiTickSetting)?.value?.let { ImmutableSettings(it) }
            ?: throw Error("parameter $name not found")
        return when (val type = s.getString("_type")) {
            "OrderBook" -> {
                SamuraiTick.OrderBook
            }
            "Stat" -> {
                SamuraiTick.Stat(
                    s.getPriceStatType("priceStatType"),
                )
            }
            "Indicator" -> {
                SamuraiTick.IndicatorTick(
                    s.getIndicator("indicator"),
                )
            }
            else -> throw Error("unknown type $type")
        }
    }

    fun getPriceStatType(name: String): PriceStatType {
        return (map[name] as? Setting.PriceStatTypeSetting)?.value
            ?: throw Error("parameter $name not found")
    }

    fun getIndicator(name: String): Indicator {
        val s = (map[name] as? Setting.IndicatorSetting)?.value?.let { ImmutableSettings(it) }
            ?: throw Error("parameter $name not found")
        return when (val type = s.getString("_type")) {
            "MA" -> {
                val windowDataPoints = s.getInt("windowDataPoints")
                val barInterval = s.getBarSize("barInterval")
                val count = s.getInt("count")
                Indicator.MA(
                    windowDataPoints,
                    barInterval,
                    count,
                )
            }
            "SD" -> {
                val windowDataPoints = s.getInt("windowDataPoints")
                val barInterval = s.getBarSize("barInterval")
                Indicator.SD(
                    windowDataPoints,
                    barInterval,
                )
            }
            "LookBack" -> {
                val tick = s.getPriceTick("tick")
                val lookBack = s.getDuration("lookBack")
                Indicator.LookBack(
                    tick,
                    lookBack,
                )
            }
            else -> throw Error("unknown type $type")
        }
    }

    fun getSamuraiLimitsUnit(name: String): SamuraiLimitsUnit {
        val s = (map[name] as? Setting.SamuraiLimitsUnitSetting)?.value?.let { ImmutableSettings(it) }
            ?: throw Error("parameter $name not found")
        return when (val type = s.getString("_type")) {
            "Absolute" -> {
                SamuraiLimitsUnit.Absolute
            }
            "Fractional" -> {
                SamuraiLimitsUnit.Fractional
            }
            "SD" -> {
                val windowDataPoints = s.getInt("windowDataPoints")
                val barInterval = s.getBarSize("barInterval")
                SamuraiLimitsUnit.SD(
                    Indicator.SD(
                        windowDataPoints,
                        barInterval,
                    ),
                )
            }
            else -> throw Error("unknown type $type")
        }
    }

    fun getTypedTicker(name: String): TypedTicker {
        val s = (map[name] as? Setting.TypedTickerSetting)?.value?.let { ImmutableSettings(it) }
            ?: throw Error("parameter $name not found")
        return TypedTicker(
            s.getTickerType("tickerType"),
            s.getExchange("exchange"),
            Ticker(s.getString("ticker")),
        )
    }
}
