package com.edvorg.trade.backend.frontend.services

import com.edvorg.trade.common.client.WebSocketClient
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.ConnectionManagement
import com.edvorg.trade.common.frontend.services.ConnectionManager
import com.edvorg.trade.common.frontend.services.ScannerClient
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.BackendResponse
import com.edvorg.trade.common.model.ConnectionManagementRequest
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Connectors
import com.edvorg.trade.common.model.Deals
import com.edvorg.trade.common.model.ScannerDealsSubscription
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.ScannerRequest
import com.edvorg.trade.common.model.ScannerResponse
import com.edvorg.trade.common.model.SubscriptionHandle
import com.edvorg.trade.common.serialization.Defaults
import com.edvorg.trade.common.utils.ScannerAuthCode
import com.edvorg.trade.common.utils.Subscriber
import com.edvorg.trade.common.utils.concurrency.compute
import io.ktor.http.Url
import io.ktor.websocket.Frame
import kotlinx.atomicfu.atomic
import kotlinx.atomicfu.getAndUpdate
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.coroutines.launch
import mu.KotlinLogging
import kotlin.random.Random

class RemoteScannerClient(
    url: Url,
) : WebSocketClient("ScannerClient", url, false, ScannerAuthCode, 5, null), ScannerClient, ConnectionManagement {
    companion object {
        private val logger = KotlinLogging.logger { }
    }

    private val scope = CoroutineScope(Dispatchers.Default)
    private val activeSubscriptions = mutableMapOf<Long, ScannerDealsSubscription>()
    private val subscriptionsMutableFlow = MutableSharedFlow<Unit>(0, 1000)
    private val subscriptionsFlow = subscriptionsMutableFlow.asSharedFlow()
    private val subscriptionsHandle = atomic<Int?>(null)
    private val scannerDealSubscribers =
        mutableMapOf<ScannerDealsSubscription, Map<Long, Subscriber<Deals>>>()
    private val scannerDealSubscribersFlattened = mutableMapOf<Long, Subscriber<Deals>>()
    override val connectionManager = ConnectionManager("ScannerClient", this)

    private val updateConnectorsMutableFlow = MutableSharedFlow<Connectors>(0, 10000)
    override val updateConnectorsFlow = updateConnectorsMutableFlow.asSharedFlow()

    override fun activeSubscriptionSize(): Int =
        activeSubscriptions.size + connectionManager.activeSubscriptionSize()

    override fun subscribeToAutoTakeUpdate(
        widgetId: String,
        scannerId: ScannerId,
        subscriber: Subscriber<BackendResponse.AutoTakeUpdate>,
    ): SubscriptionHandle<Long> {
        TODO("Not yet implemented")
    }

    override suspend fun onConnect() {
        scope.launch {
            sendActiveSubscriptions()

            subscriptionsFlow.collect {
                subscriptionsHandle.getAndUpdate {
                    window.setTimeout(
                        {
                            sendActiveSubscriptions()
                        },
                        150,
                    )
                }?.let { window.clearTimeout(it) }
            }
        }
    }

    override fun sendConnectorUpdate(message: ConnectionManagementRequest) {
        send(ScannerRequest.UpdateConnectors(message))
    }

    private fun sendActiveSubscriptions() {
        send(
            ScannerRequest.SubscribeDeals(
                activeSubscriptions.toMap(),
            ),
        )
    }

    override fun onDisconnect() {
    }

    override fun processFrame(frame: Frame) {
        val m = ResponseDecoders.decodeScannerResponse(frame) ?: return

        scope.launch {
            when (m) {
                is ScannerResponse.DealsUpdate -> {
                    m.subscriptionIds.forEach {
                        scannerDealSubscribersFlattened[it]?.onUpdate(m.deals)
                    }
                }
                is ScannerResponse.ConnectorsUpdate -> {
                    if (!updateConnectorsMutableFlow.tryEmit(m.connectors)) {
                        logger.info { "Unable emit connector configs update $m" }
                    }
                }
            }
        }
    }

    private fun send(message: ScannerRequest) {
        send(
            Frame.Binary(
                true,
                Defaults.cbor.encodeToByteArray(ScannerRequest.serializer(), message),
            ),
        )
    }

    override fun subscribeToDealsUpdate(
        subscription: ScannerDealsSubscription,
        subscriber: Subscriber<Deals>,
    ): SubscriptionHandle<Long> {
        val id = Random.nextLong(Long.MAX_VALUE)
        scannerDealSubscribers.compute(subscription) { _, oldSubscribers ->
            oldSubscribers?.plus(id to subscriber)
                ?: mapOf(id to subscriber)
        }
        scannerDealSubscribersFlattened[id] = subscriber
        addSubscription(id, subscription)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromDealsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromDealsUpdate(id: Long) {
        val subscriptionKeys = scannerDealSubscribers.keys.toSet()
        subscriptionKeys.forEach {
            scannerDealSubscribers.compute(it) { _, oldSubscribers ->
                oldSubscribers?.minus(id)?.takeIf { subscribers -> subscribers.isNotEmpty() }
            }
            removeSubscription(id)
        }
        scannerDealSubscribersFlattened.remove(id)
    }

    private fun addSubscription(id: Long, subscription: ScannerDealsSubscription) {
        activeSubscriptions[id] = subscription
        if (!subscriptionsMutableFlow.tryEmit(Unit)) {
            logger.info { "unable to emit subscriptions update" }
        }
    }

    private fun removeSubscription(id: Long) {
        activeSubscriptions.remove(id)
        if (!subscriptionsMutableFlow.tryEmit(Unit)) {
            logger.info { "unable to emit subscriptions update" }
        }
    }

    override fun restartScannerServer() {
        send(ScannerRequest.Restart)
    }

    override suspend fun connect(selectedServer: String) {
        startConnection({}, { status ->
            getContext<CommonContext>().connectorServersManager.setServerStatus(
                selectedServer,
                status,
            )
        },)
    }

    override suspend fun start(onStarted: (ConnectorStatus) -> Unit) {
        startConnection(
            {},
            onStarted,
        )
    }
}
