package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class ScannerRequest {
    @Serializable
    @SerialName("UpdateConnectors")
    data class UpdateConnectors(
        val request: ConnectionManagementRequest,
    ) : ScannerRequest()

    @Serializable
    @SerialName("SubscribeDeals")
    data class SubscribeDeals(
        val subscriptions: Map<Long, ScannerDealsSubscription>,
    ) : ScannerRequest()

    @Serializable
    @SerialName("Restart")
    object Restart : ScannerRequest()
}
