package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable
enum class Exchange(val id: String, val index: Int) {
    AEB("AEB", 1),
    AEQLIT("AEQLIT", 2),
    AMEX("AMEX", 3),
    ARCA("ARCA", 4),
    ARCAEDGE("ARCAEDGE", 5),
    ASX("ASX", 6),
    ASXCEN("ASXCEN", 7),
    ATH("ATH", 8),
    BATECH("BATECH", 9),
    BATEDE("BATEDE", 10),
    BATEEN("BATEEN", 11),
    BATEES("BATEES", 12),
    BATEIT("BATEIT", 13),
    BATEUK("BATEUK", 14),
    BATS("BATS", 15),
    BEX("BEX", 16),
    BM("BM", 17),
    BSEI("BSEI", 18),
    BUX("BUX", 19),
    BVL("BVL", 20),
    BVME("BVME", 21),

    @SerialName("BVME.ETF")
    BVME_ETF("BVME.ETF", 22),
    BYX("BYX", 23),
    CBOE("CBOE", 24),

    @SerialName("CBOE.JPN")
    CBOE_JPN("CBOE.JPN", 25),
    CHINEXT("CHINEXT", 26),
    CHIXAU("CHIXAU", 27),
    CHIXCH("CHIXCH", 28),
    CHIXDE("CHIXDE", 29),
    CHIXEN("CHIXEN", 30),
    CHIXES("CHIXES", 31),
    CHIXIT("CHIXIT", 32),
    CHIXUK("CHIXUK", 33),
    CHX("CHX", 34),
    CLNTVALU("CLNTVALU", 35),
    CORPACT("CORPACT", 36),
    CPH("CPH", 37),
    DOLLR4LOT("DOLLR4LOT", 38),
    DRCTEDGE("DRCTEDGE", 39),
    DXEDE("DXEDE", 40),
    DXEEN("DXEEN", 41),
    DXEES("DXEES", 42),
    DXEIT("DXEIT", 43),
    EBS("EBS", 44),
    EDGEA("EDGEA", 45),
    EDGX("EDGX", 46),

    @SerialName("ENEXT.BE")
    ENEXT_BE("ENEXT.BE", 47),
    FOXRIVER("FOXRIVER", 48),

    FWB("FWB", 50),
    FWB2("FWB2", 51),
    GETTEX("GETTEX", 52),
    GETTEX2("GETTEX2", 53),
    HEX("HEX", 54),
    IBIS("IBIS", 55),
    IBIS2("IBIS2", 56),
    IEX("IEX", 57),
    ISE("ISE", 58),
    ISED("ISED", 59),
    JPNNEXT("JPNNEXT", 60),
    KSE("KSE", 61),
    LSE("LSE", 62),
    LSEETF("LSEETF", 63),
    LSEIOB1("LSEIOB1", 64),
    LTSE("LTSE", 65),
    MEMX("MEMX", 66),
    MEXI("MEXI", 67),
    MIAX("MIAX", 68),
    MOEX("MOEX", 69),

    @SerialName("N.RIGA")
    N_RIGA("N.RIGA", 70),

    @SerialName("N.TALLINN")
    N_TALLINN("N.TALLINN", 71),

    @SerialName("N.VILNIUS")
    N_VILNIUS("N.VILNIUS", 72),
    NASDAQ("NASDAQ", 73),
    ISLAND("ISLAND", 74),
    NMS("NMS", 75),
    NSE("NSE", 76),
    NYSE("NYSE", 77),
    NYSEFLOOR("NYSEFLOOR", 78),
    NYSENAT("NYSENAT", 79),
    OMXNO("OMXNO", 80),
    OSE("OSE", 81),
    OTCBB("OTCBB", 82),
    OTCLNKECN("OTCLNKECN", 83),
    PEARL("PEARL", 84),
    PHLX("PHLX", 85),
    PINK("PINK", 86),
    PSX("PSX", 87),
    PURE("PURE", 88),
    SBF("SBF", 89),
    SCM("SCM", 90),
    SEHK("SEHK", 91),
    SEHKNTL("SEHKNTL", 92),
    SEHKSTAR("SEHKSTAR", 93),
    SEHKSZSE("SEHKSZSE", 94),
    SFB("SFB", 95),
    SGX("SGX", 96),
    SPBEX("SPBEX", 97),
    SWB("SWB", 98),
    SWB2("SWB2", 99),
    TASE("TASE", 100),
    TGATE("TGATE", 101),
    TGHEDE("TGHEDE", 102),
    TGHEEN("TGHEEN", 103),
    TGHEES("TGHEES", 104),
    TGHEIT("TGHEIT", 105),
    TPLUS1("TPLUS1", 106),
    TRQXCH("TRQXCH", 107),
    TRQXDE("TRQXDE", 108),
    TRQXEN("TRQXEN", 109),
    TRQXES("TRQXES", 110),
    TRQXUK("TRQXUK", 111),
    TSE("TSE", 112),
    TSEJ("TSEJ", 113),
    VALUE("VALUE", 114),
    VENTURE("VENTURE", 115),
    VSE("VSE", 116),
    WSE("WSE", 117),
    NYMEX("NYMEX", 118),
    GLOBEX("GLOBEX", 119),
    TRG("TRG", 120),
    PAXOS("PAXOS", 121),

    @SerialName("MOEX.FORT")
    MOEX_FORT("MOEX.FORT", 122),
    ALPHA("ALPHA", 124),
    NITE("NITE", 125),
    ENEXT("ENEXT", 126),
    TRQX("TRQX", 127),
    N("N", 128),
    VIRTX("VIRTX", 129),
    LSEIOB("LSEIOB", 130),
    CHIXJ("CHIXJ", 131),
    HKD("HKD", 132),
    HKEX("HKEX", 133),
    XETRA("XETRA", 134),
    OKX("OKX", 135),
    MEXC("MEXC", 136),
    GATEIO("GATEIO", 137),
    HUOBI("HUOBI", 138),
    CME("CME", 139),
    COMEX("COMEX", 140),
    FINRA("FINRA", 141),
    BLUE("BLUE", 142),
    IBEOS("IBEOS", 143),
    FREEDOM("FREEDOM", 144),
    ROBINHOOD("ROBINHOOD", 145),
    BINANCE("BINANCE", 146),
    BITPANDA("BITPANDA", 147),
    OVERNIGHT("OVERNIGHT", 148),
    QUOTRIX("QUOTRIX", 149),
    LANG_SCHWARZ("LANG_SCHWARZ", 150),
    TWSE("TWSE", 151),
    TGATE_SK("TGATE_SK", 152),
    SWB_SK("SWB_SK", 153),
    REBEL_SK("REBEL_SK", 154),
    TTMZERO_SK("TTMZERO_SK", 155),
    JFD_SK("JFD_SK", 156),
    CBOE_SK("CBOE_SK", 157),
    BAADER_SK("BAADER_SK", 158),
    FWB_SK("FWB_SK", 159),
    GETTEX_SK("GETTEX_SK", 160),
    LSE_SK("LSE_SK", 161),
    XETRA_SK("XETRA_SK", 162),
    XMUN_SK("XMUN_SK", 163),
    DUS_SK("DUS_SK", 164),
    HK_SK("HK_SK", 165),
    HNR_SK("HNR_SK", 166),
    BERA_SK("BERA_SK", 167),
    JFD_AKTIE_SK("JFD_AKTIE_SK", 168),
    LSW_SK("LSW_SK", 169),
    QUOTRIX_SK("QUOTRIX_SK", 170),
    IDEALPRO("IDEALPRO", 171),
    XGEM("XGEM", 172),
    XHKG("XHKG", 173),
    EUREX("EUREX", 174),
    BLUEOCEAN("BLUEOCEAN", 175),
    APEXEN("APEXEN", 176),
    APEXIT("APEXIT", 177),
    AQEUAT("AQEUAT", 178),
    AQEUDE("AQEUDE", 179),
    AQEUDK("AQEUDK", 180),
    AQEUEN("AQEUEN", 181),
    AQEUES("AQEUES", 182),
    AQEUIT("AQEUIT", 183),
    AQXECH("AQXECH", 184),
    AQXEUK("AQXEUK", 185),
    DXEAT("DXEAT", 186),
    DXEDK("DXEDK", 187),
    EUIBSI("EUIBSI", 188),
    TGHEAT("TGHEAT", 189),
    TGHEDK("TGHEDK", 190),
    BINANCE_FUT("BINANCE_FUT", 191),
    MEXC_FUT("MEXC_FUT", 192),
    GATEIO_FUT("GATEIO_FUT", 193),
    ;

    operator fun plus(ticker: String): TypedTicker {
        return TypedTicker(TickerType.STOCK, this, Ticker(ticker))
    }

    companion object {
        private val idToExchange = entries.associateBy {
            it.id
        }

        fun fromId(id: String): Exchange? {
            return idToExchange[id]
        }

        private val indexToExchange = entries.associateBy {
            it.index
        }.also { map ->
            map.entries.groupBy {
                it.value
            }.entries.find {
                1 < it.value.size
            }?.let { entry ->
                throw Error("2 or more exchange cases have the same index $entry")
            }
        }

        fun fromIndex(index: Int): Exchange? {
            return indexToExchange[index]
        }

        val usExchanges = setOf(
            NASDAQ,
            NYSE,
            AMEX,
            NYMEX,
            ARCA,
            CME,
            COMEX,
            CHX,
            CBOE,
            ISE,
            PHLX,
        )
    }
}

object ExchangeIndexSerializer : KSerializer<Exchange> {
    override fun deserialize(decoder: Decoder): Exchange {
        val index = decoder.decodeInt()
        return Exchange.fromIndex(index) ?: throw Error("exchange not supported: $index")
    }

    override fun serialize(encoder: Encoder, value: Exchange) {
        encoder.encodeInt(value.index)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "ExchangeIndexIdentifier",
        PrimitiveKind.INT,
    )
}
