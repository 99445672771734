package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(MarketDataIdSerializer::class)
data class MarketDataId(val id: String)

object MarketDataIdSerializer : KSerializer<MarketDataId> {
    override fun deserialize(decoder: Decoder): MarketDataId {
        return MarketDataId(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: MarketDataId) {
        encoder.encodeString(value.id)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "MarketDataIdIdentifier",
        PrimitiveKind.STRING,
    )
}
