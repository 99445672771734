package com.edvorg.trade.common.frontend.views

import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Size
import react.FC
import react.Props
import react.StateSetter

private const val enterKey = 13

data class Confirm(val title: String, val label: String, val onOk: () -> Unit)
external interface ConfirmProps : Props {
    var dialog: Confirm?
    var setDialog: StateSetter<Confirm?>
}

val confirm = FC<ConfirmProps> { props ->
    Dialog {
        open = props.dialog != null
        onClose = { _, _ ->
            props.setDialog {
                null
            }
        }

        val v = props.dialog
        if (v != null) {
            onKeyUp = {
                val keyCode = it.asDynamic().keyCode as Int
                if (keyCode == enterKey) {
                    props.setDialog { _ ->
                        null
                    }
                    v.onOk()
                }
            }
            DialogTitle {
                +v.title
            }
            DialogContent {
                +v.label
            }
            DialogActions {
                Button {
                    size = Size.small
                    onClick = {
                        props.setDialog { _: Confirm? ->
                            null
                        }
                    }
                    +"Cancel"
                }
                Button {
                    size = Size.small
                    onClick = {
                        props.setDialog { _ ->
                            null
                        }
                        v.onOk()
                    }
                    +"Ok"
                }
            }
        }
    }
}
