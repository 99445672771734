package com.edvorg.trade.common.utils

import kotlinx.serialization.Serializable

@Serializable
data class BuildInfo(
    val runId: String,
    val pullRequest: String,
    val commitSha: String,
)
