@file:UseSerializers(UrlSerializer::class)

package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.chartscript.ChartScriptManagementRequest
import com.edvorg.trade.common.model.config.GuiConfigChange
import com.edvorg.trade.common.model.config.OptionalDoubleRange
import com.edvorg.trade.common.model.config.ScheduledDealsConfig
import com.edvorg.trade.common.model.market2.Market2PreparedOrder
import com.edvorg.trade.common.model.stats.PriceStatType
import io.ktor.http.Url
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.time.Duration

@Serializable
sealed class BackendRequest {
    @Serializable
    @SerialName("UpdateConnectors")
    data class UpdateConnectors(
        val request: ConnectionManagementRequest,
    ) : BackendRequest()

    @Serializable
    data class ExecuteDeals(
        val executorId: ScannerExecutorId?,
        val scannerId: ScannerId,
        val scannerEntries: List<ScannerEntry>,
        val ignoreExclusionFilter: Boolean,
        val subscription: ScannerDealsSubscription,
        val openFraction: Double,
        val limit: Boolean,
        val maxBudgetUsd: Double,
        val minProfitUsd: Double,
        val maxProfitUsd: Double,
        val priceDiffPercentFilterRange: OptionalDoubleRange,
        val priceDiffUsdFilterRange: OptionalDoubleRange,
        val pair: Boolean,
        val openVolumeCapPercent: Double?,
        val arbitrageConfig: ArbitrageConfig,
        val groupId: Group?,
        val widgetId: String?,
        val priorityBrokers1: Map<Exchange, BrokerId>,
        val priorityBrokers2: Map<Exchange, BrokerId>,
    ) : BackendRequest()

    @Serializable
    data object Restart : BackendRequest()

    @Serializable
    data class FindAndExecuteDeal(
        val tickerPair: TickerPair,
        val executorId: ScannerExecutorId?,
        val openFraction: Double,
        val limit: Boolean,
        val maxBudgetUsd: Double,
        val subscription: ScannerDealsSubscription,
        val arbitrageConfig: ArbitrageConfig,
        val groupId: Group?,
        val widgetId: String?,
    ) : BackendRequest()

    @Serializable
    data class ExcludeTickers(
        val tickers: Set<TypedTicker>,
        val scannerId: ScannerId,
    ) : BackendRequest()

    @Serializable
    data class UnExcludeTickers(
        val tickers: Set<TypedTicker>,
        val scannerId: ScannerId,
    ) : BackendRequest()

    @Serializable
    data class UnHideTickers(
        val tickers: Set<TypedTicker>,
        val scannerId: ScannerId,
    ) : BackendRequest()

    @Serializable
    data class HideTickers(
        val tickers: Set<TypedTicker>,
        val scannerId: ScannerId,
    ) : BackendRequest()

    @Serializable
    data object ExecuteScheduledOrders : BackendRequest()

    @Serializable
    data class Subscribe(
        val subscriptions: Map<Long, BackendSubscription>,
    ) : BackendRequest()

    @Serializable
    data class SubscribeDeals(
        val subscriptions: Map<String, ScannerDealsSubscription>,
    ) : BackendRequest()

    @Serializable
    data class ClosePosition(
        val brokerId: BrokerId,
        val ticker: TypedTicker,
        val closeVolume: Double?,
    ) : BackendRequest()

    @Serializable
    data class TabsChange(
        val change: GuiConfigChange,
    ) : BackendRequest()

    @Serializable
    data class GetPaginatedOperations(
        val brokerId: BrokerId,
        val continuation: String?,
        val requestId: String,
    ) : BackendRequest()

    @Serializable
    data class SetScheduledDealsConfig(
        val config: ScheduledDealsConfig,
    ) : BackendRequest()

    @Serializable
    data class Log(
        val message: String,
    ) : BackendRequest()

    @Serializable
    data class UpdateScannerServers(
        val servers: Map<String, Url>,
    ) : BackendRequest()

    @Serializable
    data class StartRecordScanner(
        val scannerSubscription: ScannerDealsSubscription,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val duration: Duration,
        val filename: String,
    ) : BackendRequest()

    @Serializable
    data class ConfirmCode(
        val phoneNumber: String,
        val code: String,
    ) : BackendRequest()

    @Serializable
    data class StartAutoTake(
        val widgetId: String,
        val scannerId: ScannerId,
        val subscription: ScannerDealsSubscription,
        val arbitrageConfig: ArbitrageConfig,
        val autoTakePriorityBrokers: AutoTakePriorityBrokers,
    ) : BackendRequest()

    @Serializable
    data class StopAutoTake(
        val widgetId: String,
        val scannerId: ScannerId,
    ) : BackendRequest()

    @Serializable
    data class SetLogLevel(
        val level: LogLevel,
    ) : BackendRequest()

    @Serializable
    data class SetUnchangeablePriceStat(
        val ticker: TypedTicker,
        val priorityExchange: Exchange?,
        val priceStatType: PriceStatType,
        val value: Double,
    ) : BackendRequest()

    @Serializable
    data class SetUnchangeableVWAP(
        val ticker: TypedTicker,
        val priorityExchange: Exchange?,
        val value: Double,
    ) : BackendRequest()

    @Serializable
    data class ForceResubscribeOrderBook(
        val ticker: TypedTicker,
        val priorityExchange: Exchange?,
    ) : BackendRequest()

    @Serializable
    data class ForceResubscribeClosePrice(
        val ticker: TypedTicker,
    ) : BackendRequest()

    @Serializable
    data class CreateNewUserSpace(
        val name: String,
    ) : BackendRequest()

    @Serializable
    data class DuplicateUserSpace(
        val name: String,
        val sourceUserSpaceName: String,
    ) : BackendRequest()

    @Serializable
    data class RemoveUserSpace(
        val name: String,
    ) : BackendRequest()

    @Serializable
    data class ExecTradingRequest(
        val request: TradingRequest,
    ) : BackendRequest()

    @Serializable
    data class UpdateChartScripts(
        val request: ChartScriptManagementRequest,
    ) : BackendRequest()

    @Serializable
    data class UpdateMarket2PreparedOrder(
        val ticker: TypedTicker,
        val localOrderId: String,
        val preparedOrder: Market2PreparedOrder?,
    ) : BackendRequest()
}
