package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.chartscript.ScriptId
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class WidgetSettings {
    @Serializable
    @SerialName("Connectors")
    data object Connectors : WidgetSettings()

    @Serializable
    @SerialName("Chart")
    data class Chart(
        val barSize: BarSize,
        val appendLast: Boolean = true,
    ) : WidgetSettings()

    @Serializable
    @SerialName("ChartScript")
    data class ChartScript(
        val scriptId: ScriptId?,
        val roundingScale: Int = 10,
    ) : WidgetSettings()
}
