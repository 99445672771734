package com.edvorg.trade.common.model

import com.edvorg.trade.common.serialization.Dump
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonPrimitive
import kotlinx.serialization.json.buildJsonObject

@Serializable
data class OrderBookEntry(
    @SerialName("v")
    val volume: Double,
    @SerialName("p")
    val price: Double,
) : Dump {
    constructor(volume: Int, price: Int) : this(
        volume.toDouble(),
        price.toDouble(),
    )

    constructor(volume: Long, price: Int) : this(
        volume.toDouble(),
        price.toDouble(),
    )

    constructor(volume: Long, price: Double) : this(
        volume.toDouble(),
        price,
    )

    override fun dumpToJson(): JsonElement {
        return buildJsonObject {
            put("v", JsonPrimitive(volume))
            put("p", JsonPrimitive(price))
        }
    }
}
