package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.ScannerClient
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.model.ClosePriceScannerMode
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.ExchangePair
import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.ScannerClientConfig
import com.edvorg.trade.common.model.ScannerConnectorStatus
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.SpreadScannerOperatorMode
import com.edvorg.trade.common.model.Tick
import com.edvorg.trade.common.model.config.PriceTick
import com.edvorg.trade.common.model.config.ScannerTick
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.coroutines.launch
import mui.icons.material.AddCircle
import mui.material.Button
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.material.Tooltip
import mui.system.responsive
import mui.system.sx
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.h3
import react.dom.onChange
import react.useEffect
import react.useState
import web.cssom.ClassName
import web.cssom.Globals.Companion.unset
import web.cssom.px
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

private val scannerClientOptions = mapOf(
    "Level1" to ScannerClientConfig.Level1(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.FWB,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.FWB2,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.SWB,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.SWB2,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.IBIS,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.IBIS2,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.GETTEX,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.GETTEX2,
            ),
            ExchangePair(
                Exchange.SPBEX,
                Exchange.TGATE,
            ),
        ),
        matchByEntityId = false,
        includeUnShortable = false,
        tickerListName = "",
    ),
    "VWAP" to ScannerClientConfig.VWAP(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        matchByEntityId = false,
        includeUnShortable = false,
        subscribeToTrades = false,
        tickerListName = "",
    ),
    "LastPriceVWAP" to ScannerClientConfig.LastPriceVWAP(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        false,
        "",
    ),
    "Bid" to ScannerClientConfig.Bid(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        false,
        "",
    ),
    "ClosePrice" to ScannerClientConfig.ClosePrice(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        ClosePriceScannerMode.CLOSE_PRICE,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        false,
        "",
        true,
    ),
    "VWAPPlus" to ScannerClientConfig.VWAPPlus(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        matchByEntityId = false,
        tickerListName = "",
        includeUnShortable = false,
        Tick.Bid,
        subscribeToTrades = false,
    ),
    "LastPrice" to ScannerClientConfig.LastPrice(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        false,
        "",
    ),
    "VWEMA" to ScannerClientConfig.VWEMA(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        matchByEntityId = false,
        tickerListName = "",
        includeUnShortable = false,
        Tick.Bid,
    ),
    "Custom" to ScannerClientConfig.Custom(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        setOf(
            ExchangePair(
                Exchange.SPBEX,
                Exchange.NASDAQ,
            ),
        ),
        matchByEntityId = false,
        tickerListName = "",
        includeUnShortable = false,
        ScannerTick.Stat(PriceStatType.LAST),
        ScannerTick.Indicator(
            Indicator.LookBack(
                PriceTick.Stat(PriceStatType.LAST),
                5.minutes,
            ),
        ),
    ),
    "Spread" to ScannerClientConfig.Spread(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        160,
        2.seconds,
        5.seconds,
        emptySet(),
        false,
        "",
        true,
        Exchange.NASDAQ,
        PriceStatType.POST_CLOSE,
        SpreadScannerOperatorMode.SUBSCTRACTION,
    ),
    "recorder" to ScannerClientConfig.OrderBookRecorder(
        0,
        false,
        ScannerExecutorId("Pair"),
        Currency.USD,
        setOf(),
        false,
        "nasdaq-eu",
        120,
        2.seconds,
        "history.sqlite",
        5.seconds,
        1.minutes,
    ),
)

private val scannerTypes = scannerClientOptions.values.map {
    it.displayName()
}.toSet()

val scannerClientConnectorsPanel = fcWithScope<Props> { _, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val clickWatcher = getContext<CommonContext>().clickWatcher
    val toaster = getContext<CommonContext>().toaster

    var selectedScannerServer: String by useState("local")
    var scannerClientTypeToCreate: String by useState(scannerClientOptions.keys.first())
    val (shownScannerClients, setShownScannerClients) = useState(emptySet<ScannerId>())
    var scannerClients: Map<ScannerId, ScannerConnectorStatus> by useState(emptyMap())
    val (confirmDialog, setConfirmDialog) = useState<Confirm?>(null)
    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)

    var searchInput by useState("")
    var filterScannerType: String? by useState(null)
    var filterConnectorStatus: StatusFilter? by useState(null)

    useEffect(selectedScannerServer) {
        val client = connectorServersManager.getServerConnection(selectedScannerServer)?.client ?: return@useEffect
        val handle = client.connectionManager.subscribeToScannerClientsUpdate {
            scannerClients = it.scannerClients
        }
        cleanup {
            handle.unsubscribe()
        }
    }

    fun saveScannerClientSettings(
        scannerId: ScannerId,
        settings: Settings,
        prevConfig: ScannerClientConfig,
    ) {
        val autoStart = settings.getBoolean("autoStart")
        val executorId = settings.getScannerExecutorId("executor")
        val newScannerClientSettings = when (prevConfig) {
            is ScannerClientConfig.Level1 -> {
                ScannerClientConfig.Level1(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getBoolean("includeUnShortable"),
                )
            }

            is ScannerClientConfig.VWAP -> {
                ScannerClientConfig.VWAP(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getBoolean("includeUnShortable"),
                    settings.getBoolean("subscribeToTrades"),
                )
            }

            is ScannerClientConfig.LastPriceVWAP -> {
                ScannerClientConfig.LastPriceVWAP(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                )
            }

            is ScannerClientConfig.LastPrice -> {
                ScannerClientConfig.LastPrice(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                )
            }

            is ScannerClientConfig.Bid -> {
                ScannerClientConfig.Bid(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                )
            }

            is ScannerClientConfig.ClosePrice -> {
                ScannerClientConfig.ClosePrice(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getClosePriceScannerMode("mode"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getBoolean("includeUnShortable"),
                )
            }

            is ScannerClientConfig.VWAPPlus -> {
                ScannerClientConfig.VWAPPlus(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getBoolean("includeUnShortable"),
                    settings.getTick("tick1"),
                    settings.getBoolean("subscribeToTrades"),
                )
            }

            is ScannerClientConfig.VWEMA -> {
                ScannerClientConfig.VWEMA(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getBoolean("includeUnShortable"),
                    settings.getTick("tick1"),
                )
            }

            is ScannerClientConfig.Custom -> {
                ScannerClientConfig.Custom(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getBoolean("includeUnShortable"),
                    settings.getScannerTick("tick1"),
                    settings.getScannerTick("tick2"),
                )
            }

            is ScannerClientConfig.Spread -> {
                ScannerClientConfig.Spread(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getBoolean("includeUnShortable"),
                    settings.getString("statExchange").let { exchangeId ->
                        Exchange.fromId(exchangeId)
                    } ?: Exchange.NASDAQ,
                    settings.getString("statType").let { name ->
                        PriceStatType.entries.find { it.name == name } ?: PriceStatType.POST_CLOSE
                    },
                    settings.getSpreadScannerOperator("diffOperator"),
                )
            }

            is ScannerClientConfig.OrderBookRecorder -> {
                ScannerClientConfig.OrderBookRecorder(
                    prevConfig.createdAt,
                    autoStart,
                    executorId,
                    settings.getCurrency("currency"),
                    settings.getStringPairsList("exchangePairs").mapNotNull { (exchangeId1, exchangeId2) ->
                        Exchange.fromId(exchangeId1)?.let { exchange1 ->
                            Exchange.fromId(exchangeId2)?.let { exchange2 ->
                                ExchangePair(exchange1, exchange2)
                            }
                        }
                    }.toSet(),
                    settings.getBoolean("matchByEntityId"),
                    settings.getString("tickerListName"),
                    settings.getInt("chunkSize"),
                    settings.getDuration("chunkDelay"),
                    settings.getString("storePath"),
                    settings.getDuration("subscriptionTimeout"),
                    settings.getDuration("timeframe"),
                )
            }
        }
        val client = connectorServersManager.getServerConnection(selectedScannerServer)?.client
        if (client != null) {
            scope.launch {
                client.connectionManager.sendUpdateScannerConnector(
                    scannerId,
                    newScannerClientSettings,
                )
            }
        }
    }

    fun resetScannerClientSettings(
        settings: MutableSettings,
        config: ScannerClientConfig,
    ) {
        settings.setBoolean("autoStart", config.autoStart, true)
        settings.setScannerExecutorId("executor", config.executorId ?: ScannerExecutorId(""), true)
        settings.setCurrency("currency", config.currency, true, 125)
        settings.setStringPairsList(
            "exchangePairs",
            config.exchangePairs.map {
                Pair(it.exchange1.id, it.exchange2.id)
            },
            false,
            null,
        )
        settings.setBoolean("matchByEntityId", config.matchByEntityId, true)
        settings.setString("tickerListName", config.tickerListName, false, null)
        when (config) {
            is ScannerClientConfig.Level1 -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
                settings.setBoolean("includeUnShortable", config.includeUnShortable, true)
            }

            is ScannerClientConfig.VWAP -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
                settings.setBoolean("includeUnShortable", config.includeUnShortable, true)
                settings.setBoolean("subscribeToTrades", config.subscribeToTrades, true)
            }

            is ScannerClientConfig.LastPriceVWAP -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
            }

            is ScannerClientConfig.LastPrice -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
            }

            is ScannerClientConfig.Bid -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
            }

            is ScannerClientConfig.ClosePrice -> {
                settings.setClosePriceScannerSetting("mode", config.closePriceMode, true)
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
                settings.setBoolean("includeUnShortable", config.includeUnShortable, true)
            }

            is ScannerClientConfig.VWAPPlus -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
                settings.setBoolean("includeUnShortable", config.includeUnShortable, true)
                settings.setTick("tick1", config.tick1, true)
                settings.setBoolean("subscribeToTrades", config.subscribeToTrades, true)
            }

            is ScannerClientConfig.VWEMA -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
                settings.setBoolean("includeUnShortable", config.includeUnShortable, true)
                settings.setTick("tick1", config.tick1, true)
            }

            is ScannerClientConfig.Custom -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
                settings.setBoolean("includeUnShortable", config.includeUnShortable, true)
                settings.setScannerTick("tick1", config.tick1, true)
                settings.setScannerTick("tick2", config.tick2, true)
            }

            is ScannerClientConfig.Spread -> {
                settings.setInt("chunkSize", config.chunkSize, false, null)
                settings.setDuration("chunkDelay", config.chunkDelay, false, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, false, null)
                settings.setBoolean("includeUnShortable", config.includeUnShortable, true)
                settings.setString("statExchange", config.statExchange.id, false, null)
                settings.setString("statType", config.statType.name, false, null)
                settings.setSpreadScannerOperator("diffOperator", config.diffOperator, true)
            }

            is ScannerClientConfig.OrderBookRecorder -> {
                settings.setString("storePath", config.historicalStorePath, true, null)
                settings.setInt("chunkSize", config.chunkSize, true, null)
                settings.setDuration("chunkDelay", config.chunkDelay, true, null)
                settings.setDuration("subscriptionTimeout", config.subscriptionTimeout, true, null)
                settings.setDuration("timeframe", config.timeFrame, true, null)
            }
        }
    }

    Stack {
        spacing = responsive(10.px)

        h3 {
            +"Scanner"
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(5.px)
            justifyContent = "flex-start"

            autocomplete(
                { null },
                false,
                "server",
                150,
                null,
                { value ->
                    value?.let { newSelectedServer ->
                        selectedScannerServer = newSelectedServer
                        scannerClients = emptyMap()
                    }
                },
                null,
                {
                },
                StringOption(selectedScannerServer),
                connectorServersManager.getServerIds().map { StringOption(it) }.toTypedArray(),
                true,
            )

            val status = connectorServersManager.getServerConnection(selectedScannerServer)?.status
            if (status == ConnectorStatus.Disconnected) {
                Tooltip {
                    title = ReactNode("Connect to the server")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            it.stopPropagation()

                            scope.launch {
                                connectorServersManager.getServerConnection(
                                    selectedScannerServer,
                                )?.client?.connect(
                                    selectedScannerServer,
                                )
                            }
                        }
                        +"Connect"
                    }
                }
            }
            autocomplete(
                { null },
                false,
                "scanner type",
                150,
                null,
                { value ->
                    value?.let { newTypeToCreate ->
                        scannerClientTypeToCreate = newTypeToCreate
                    }
                },
                null,
                {
                },
                StringOption(scannerClientTypeToCreate),
                scannerClientOptions.map { StringOption(it.key) }.toTypedArray(),
                true,
            )
            Tooltip {
                title = ReactNode("Create")

                Button {
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        clickWatcher.click()
                        event.stopPropagation()
                        setPromptDialog(
                            Prompt(
                                "",
                                "Create scanner connector",
                                "Connector name",
                            ) { scannerId ->
                                scannerClientOptions[scannerClientTypeToCreate]?.let { config ->
                                    val client = connectorServersManager.getServerConnection(
                                        selectedScannerServer,
                                    )?.client
                                    if (client != null) {
                                        scope.launch {
                                            client.connectionManager.sendCreateScannerConnector(
                                                ScannerId(scannerId),
                                                config,
                                            )
                                        }
                                    }
                                }
                            },
                        )
                    }
                    AddCircle {
                    }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(5.px)
            justifyContent = "flex-start"

            TextField {
                size = Size.small
                label = ReactNode("Search")
                value = searchInput
                onChange = { event ->
                    searchInput = event.target.asDynamic().value as String
                }
            }

            Select {
                size = Size.small
                value = filterScannerType ?: "All"
                onMouseUp = { event ->
                    event.stopPropagation()
                }
                onMouseDown = { event ->
                    event.stopPropagation()
                }
                onChange = { event, _ ->
                    event.stopPropagation()
                    val newValue = event.target.value.takeIf {
                        it.isNotEmpty() && it != "All"
                    }
                    filterScannerType = newValue
                }

                MenuItem {
                    value = "All"
                    +"All"
                }

                scannerTypes.forEach { scannerType ->
                    val count = scannerClients.values.count {
                        it.scannerClientConfig.displayName() == scannerType
                    }
                    MenuItem {
                        key = scannerType
                        value = scannerType
                        +"$scannerType ($count)"
                    }
                }
            }

            Select {
                size = Size.small
                value = filterConnectorStatus?.name ?: "All"
                onMouseUp = { event ->
                    event.stopPropagation()
                }
                onMouseDown = { event ->
                    event.stopPropagation()
                }
                onChange = { event, _ ->
                    event.stopPropagation()
                    val newValue = event.target.value.takeIf {
                        it.isNotEmpty()
                    }
                    filterConnectorStatus = StatusFilter.entries.find {
                        it.name == newValue
                    }
                }

                MenuItem {
                    value = "All"
                    +"All"
                }

                StatusFilter.entries.forEach { connectorStatus ->
                    MenuItem {
                        key = connectorStatus.name
                        value = connectorStatus.name
                        +connectorStatus.name
                    }
                }
            }
        }

        TableContainer {
            Table {
                sx {
                    width = unset
                }
                size = Size.small

                TableHead {
                    TableRow {
                        TableCell {
                            +"ScannerId"
                        }
                        TableCell {
                            +"Status"
                        }
                        TableCell {
                            +"Actions"
                        }
                    }
                }

                val entries = scannerClients.filter {
                    it.key.id.contains(searchInput, ignoreCase = true)
                }.filter {
                    filterScannerType == null || it.value.scannerClientConfig.displayName() == filterScannerType
                }.filter {
                    filterConnectorStatus == null ||
                        StatusFilter.fromConnectorStatus(it.value.connectorStatus) == filterConnectorStatus
                }

                if (entries.isNotEmpty()) {
                    val sortedEntries = entries.entries.sortedBy { it.key.id }
                    for ((scannerId, status) in sortedEntries) {
                        TableBody {
                            key = "scanner:${scannerId.id}"

                            scannerClientConnector {
                                this.serverId = selectedScannerServer
                                this.scannerId = scannerId
                                this.status = status
                                this.resetSettings = { it ->
                                    resetScannerClientSettings(it, status.scannerClientConfig)
                                }
                                this.saveSettings = {
                                    saveScannerClientSettings(
                                        scannerId,
                                        it,
                                        status.scannerClientConfig,
                                    )
                                    toaster.info(
                                        "Scanner ${scannerId.id} saved",
                                        1900,
                                        ToastPosition.BOTTOM_RIGHT,
                                    )
                                }
                                this.showSettings = shownScannerClients.contains(scannerId)
                                this.toggleShowSettings = {
                                    setShownScannerClients { shownScannerClients ->
                                        if (shownScannerClients.contains(scannerId)) {
                                            shownScannerClients - scannerId
                                        } else {
                                            shownScannerClients + scannerId
                                        }
                                    }
                                }
                                this.removeConnector = {
                                    setConfirmDialog(
                                        Confirm(
                                            "Remove scanner connector",
                                            "Please confirm connector removal",
                                        ) {
                                            val client = connectorServersManager.getServerConnection(
                                                selectedScannerServer,
                                            )?.client
                                            scope.launch {
                                                client?.connectionManager?.sendRemoveScannerConnector(scannerId)
                                            }
                                        },
                                    )
                                }
                                this.duplicateConnector = {
                                    setPromptDialog(
                                        Prompt(
                                            "",
                                            "Duplicate scanner connector",
                                            "Connector name",
                                        ) { newScannerId ->
                                            val client = connectorServersManager.getServerConnection(
                                                selectedScannerServer,
                                            )?.client
                                            scope.launch {
                                                client?.connectionManager?.sendDuplicateScannerConnector(
                                                    scannerId,
                                                    ScannerId(newScannerId),
                                                )
                                            }
                                        },
                                    )
                                }
                            }
                        }
                    }
                } else {
                    TableBody {
                        TableRow {
                            TableCell {
                                colSpan = 4
                                +"No connectors"
                            }
                        }
                    }
                }
            }
        }
        val scannerClient = connectorServersManager.getServerConnection(selectedScannerServer)?.client
        if (scannerClient is ScannerClient) {
            Tooltip {
                title = ReactNode("Restart server")

                Button {
                    size = Size.small
                    className = ClassName("normal")
                    onClick = {
                        clickWatcher.click()
                        it.stopPropagation()

                        scope.launch {
                            val client = connectorServersManager.getServerConnection(selectedScannerServer)?.client
                            if (client is ScannerClient) {
                                client.restartScannerServer()
                            }
                        }
                    }
                    +"Restart server"
                }
            }
        }
    }

    confirm {
        this.dialog = confirmDialog
        this.setDialog = setConfirmDialog
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }
}
