package com.edvorg.trade.backend.frontend.views.widgets.chartscript

import com.edvorg.trade.backend.frontend.FrontendContext
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.views.Prompt
import com.edvorg.trade.common.frontend.views.prompt
import com.edvorg.trade.common.frontend.views.renderStatus
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.chartscript.ChartScriptConfig
import com.edvorg.trade.common.model.chartscript.ScriptId
import mui.icons.material.ContentCopy
import mui.icons.material.Delete
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.icons.material.PlayArrow
import mui.icons.material.Stop
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.FormControlLabel
import mui.material.LabelPlacement
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.create
import react.dom.html.ReactHTML.div
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

external interface ChartScriptSettingsProps : Props {
    var onSelectScriptId: (ScriptId?) -> Unit
    var selectedScriptId: ScriptId?
    var roundingScale: Int
    var setRoundingScale: (Int) -> Unit
}

val chartScriptSettingsPanel = FC<ChartScriptSettingsProps> { props ->
    val backendMainClient = getContext<FrontendContext>().backendMainClient
    val clickWatcher = getContext<CommonContext>().clickWatcher

    var allScripts: Map<ScriptId, Triple<ChartScriptConfig, ConnectorStatus, String?>> by useState(mapOf())
    val (showsSettings, setShowsSettings) = useState(setOf<ScriptId>())

    val (promptDialog, setPromptDialog) = useState<Prompt?>(null)

    useEffectOnce {
        val handle = backendMainClient.subscribeChartScripts {
            allScripts = it.scripts
        }

        cleanup {
            handle.unsubscribe()
        }
    }
    Stack {
        spacing = responsive(10.px)

        Stack {
            direction = responsive(StackDirection.row)
            FormControlLabel {
                control = Select.create {
                    value = props.selectedScriptId?.id ?: ""
                    onChange = { event, _ ->
                        val newScriptId = event.target.value.takeIf {
                            it.isNotBlank()
                        }?.let {
                            ScriptId(it)
                        }
                        props.onSelectScriptId(newScriptId)
                    }

                    allScripts.keys.forEach {
                        MenuItem {
                            value = it.id
                            +it.id
                        }
                    }

                    MenuItem {
                        value = ""
                        +""
                    }
                }

                label = ReactNode("Script ID:")
                labelPlacement = LabelPlacement.start
            }

            Button {
                onClick = {
                    it.stopPropagation()
                    backendMainClient.startMarkedChartScripts()
                }

                +"AUTO START"
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            FormControlLabel {
                control = TextField.create {
                    type = InputType.number
                    value = props.roundingScale.toString()
                    onChange = { event ->
                        val value = (event.target as? HTMLInputElement)?.value?.toIntOrNull()
                        if (value != null) {
                            props.setRoundingScale(value)
                        }
                    }
                }

                label = ReactNode("Rounding scale:")
                labelPlacement = LabelPlacement.start
            }
        }

        Button {
            onClick = {
                setPromptDialog(
                    Prompt(
                        value = "",
                        title = "Add new script",
                        label = "Script ID:",
                    ) { scriptId ->
                        backendMainClient.createChartScript(ScriptId(scriptId))
                    },
                )
            }
            +"Add Script"
        }

        if (allScripts.isNotEmpty()) {
            TableContainer {
                Table {
                    sx {
                        width = 1000.px
                    }
                    size = Size.small

                    TableHead {
                        TableRow {
                            TableCell {
                                +"Script ID"
                            }
                            TableCell {
                                +"Status"
                            }
                            TableCell {
                                +"Actions"
                            }
                        }
                    }

                    allScripts.entries.sortedBy { it.key.id }.forEach { (scriptId, scriptData) ->
                        val (chartScript, status, errorMessage) = scriptData
                        TableBody {
                            key = "script-$scriptId"
                            val showScriptEditor = showsSettings.contains(scriptId)
                            TableRow {
                                TableCell {
                                    +scriptId.id
                                }
                                TableCell {
                                    renderStatus(status)
                                }
                                TableCell {
                                    ButtonGroup {
                                        when {
                                            status.isDisconnected() -> {
                                                Button {
                                                    title = "Start"
                                                    size = Size.small
                                                    className = ClassName("normal")
                                                    onClick = {
                                                        clickWatcher.click()
                                                        it.stopPropagation()
                                                        backendMainClient.startChartScript(scriptId)
                                                    }
                                                    PlayArrow {
                                                    }
                                                }
                                            }
                                            else -> {
                                                Button {
                                                    title = "Stop"
                                                    size = Size.small
                                                    className = ClassName("normal")
                                                    onClick = {
                                                        clickWatcher.click()
                                                        it.stopPropagation()
                                                        backendMainClient.stopChartScript(scriptId)
                                                    }
                                                    Stop {
                                                    }
                                                }
                                            }
                                        }

                                        Button {
                                            title = "Remove"
                                            size = Size.small
                                            className = ClassName("normal")
                                            onClick = {
                                                clickWatcher.click()
                                                it.stopPropagation()
                                                backendMainClient.removeChartScript(scriptId)
                                            }
                                            Delete {
                                            }
                                        }

                                        Button {
                                            title = "Duplicate"
                                            size = Size.small
                                            className = ClassName("normal")
                                            onClick = { event ->
                                                clickWatcher.click()
                                                event.stopPropagation()
                                                setPromptDialog(
                                                    Prompt(
                                                        value = "",
                                                        title = "Add new script",
                                                        label = "Script ID:",
                                                    ) { newScriptId ->
                                                        backendMainClient.duplicateScript(
                                                            scriptId,
                                                            ScriptId(newScriptId),
                                                        )
                                                    },
                                                )
                                            }
                                            ContentCopy {
                                            }
                                        }
                                        Button {
                                            title = "Settings"
                                            size = Size.small
                                            className = ClassName("normal")
                                            onClick = {
                                                clickWatcher.click()
                                                it.stopPropagation()
                                                setShowsSettings {
                                                    if (it.contains(scriptId)) {
                                                        it - scriptId
                                                    } else {
                                                        it + scriptId
                                                    }
                                                }
                                            }
                                            if (showScriptEditor) {
                                                ExpandLess {
                                                }
                                            } else {
                                                ExpandMore {
                                                }
                                            }
                                        }
                                    }
                                }
                                TableCell {
                                    if (errorMessage != null) {
                                        +"Error: $errorMessage"
                                    } else {
                                        +""
                                    }
                                }
                            }
                            if (showScriptEditor) {
                                TableRow {
                                    TableCell {
                                        colSpan = 4
                                        chartScriptEditorPanel {
                                            this.chartScript = chartScript
                                            this.setChartScript = {
                                                backendMainClient.updateChartScript(scriptId, it)
                                            }
                                            this.collapsableScriptText = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            div {
                +"No scripts available"
            }
        }
    }

    prompt {
        this.dialog = promptDialog
        this.setDialog = setPromptDialog
    }
}
