package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.GroupId
import com.edvorg.trade.common.model.HumanReadableDurationSerializer
import com.edvorg.trade.common.model.Indicator
import kotlinx.serialization.Serializable
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds

@Serializable
data class SamuraiPair(
    val name: String,
    val groupId: GroupId,
    val autoStart: Boolean,
    val createdAt: Long,
    val maxRetries: Int,
    val security1: SamuraiSecurity,
    val security2: SamuraiSecurity,
    val limSell: Double,
    val limBuy: Double,
    val vMin: Double,
    val vMax: Double,

    // / if ture - restore original lims when position is fully closed
    val restoreOriginalLims: Boolean,
    // if true, do not use x coefficient
    val disableX: Boolean,

    /**
     К - коэффициент сдвига заявки, что улучшает цену для каждого последующего входа.
     На параметр К будут сдвинуты заявки Lim_Sell в случае продажи или Lim_Buy в случае покупки при наборе позиции.
     Другими словами, на сколько улучшится положение заявки после удара на вход.
     Пример:
     Lim_sell 100
     Lim _buy 90
     K = 5
     V_MIN = 3

     Три уровня продажи, каждый уровень считается как
     1 – lim_sell
     2 – lim_sell + К
     3 – (lim_sell + К) + К

     В третьем шаге указал именно формулу (lim_sell+k) + k,
     для того чтобы в дальнейшем мы могли сделать коэф К более настраиваемым (динамическим)

     https://www.youtube.com/watch?v=2qUPvspTQq4
     */

    val k: Double,

    /**
     ТР - уровень противоположной заявки после входа.
     С помощью параметра ТР вы указываете где будет выставлена противоположная заявка после удара
     (работает только после первого удара).

     Если мы вошли в сделку по Lim_Sell = 100, то при ТР = 5,
     Lim_Buy будет выставлен на 100 – 5 = 95 (игнорируя начальные параметры lim_buy)

     https://www.youtube.com/watch?v=R2nuoUpGq9c
     */
    val tp: Double,

    /**
     К1 - коэффициент указывает на сколько будет сдвинута противоположная заявка после второго удара.
     Lim_Buy = 95 из предыдущего примера, при К1 = 5, будет установлена на 95 + 5 = 100,
     после второго удара по Lim_Sell (который благодаря параметру К был равен 100+5 (второй вход))

     https://www.youtube.com/watch?v=nYSPQR2LFhE
     */
    val k1: Double,

    /**
     К2 - коэффициент сдвига заявки, что улучшает цену заявки каждого последующего выхода.
     На параметр К2 будут сдвинуты заявки Lim_Sell или Lim_Buy в случае продажи или покупки, соответственно,
     при выходе из позиции. Другими словами,
     на сколько улучшится положение следующей заявки на выход после предыдущего удара

     Из примера ранее где Lim_Buy = 100 при К2 = 3. При ударе по Lim_Buy его значение станет равным 100 - 3 = 97.

     https://www.youtube.com/watch?v=9RbN893_eD0
     */
    val k2: Double,

    // / do not cancel quote order faster than this delay even when all other conditions are met
    @Serializable(with = HumanReadableDurationSerializer::class)
    val quoteOrderCancelDelay: Duration,

    // / slippage of the initial hedge order
    val hedgeSlippage: Double,

    // / hedge order expiration time
    @Serializable(with = HumanReadableDurationSerializer::class)
    val hedgeTimeout: Duration,

    // / slippage of the hedge order retry
    val hedgeRetrySlippage: Double,

    // / calculate average price that we can realistically get filled with on our hedge order of security2
    // / and use this price as a base for quoting security1
    val useAveraging: Boolean,

    // / fraction of quantity of the quote order to average over
    val averagingFractionOfQuantity: Double,

    // / maximum number of positions that can remain unhedged, if exceeded
    // / re-attempt hedge with a with current price market price
    val maxNotHedged: Double,

    // / if true - adjust quote based on ema (ma2 - ma1)
    val movingAverageAdjustment: Boolean,

    val limitsUnit: SamuraiLimitsUnit = SamuraiLimitsUnit.Absolute,

    // / if false - never hedge positions
    val hedgePositions: Boolean,

    // / intervals between chart data writes
    @Serializable(with = HumanReadableDurationSerializer::class)
    val historyWriteIntervals: Duration,

    // / if true, only submit quote order when sell >= lim sel - safe quote offset or
    // / buy <= lim buy + safe quote offset
    val safeQuote: Boolean,

    // / safe quote offset
    val safeQuoteOffset: Double,

    // / safe quote activation timer
    @Serializable(with = HumanReadableDurationSerializer::class)
    val safeQuoteTimer: Duration = 0.milliseconds,

    // / number of digits after decimal point for price rounding
    val roundingScale: Int,

    // / minimal price difference between current quote order price and new quote order price
    // / that triggers quote order resubmitting
    val quoteOrderPriceSensitivity: Double,

    val minPercentFill: Double,

    val fillCoefficient: Double,

    @Serializable(with = HumanReadableDurationSerializer::class)
    val limitsTimer: Duration,

    val to0: Boolean,

    val mainCurrency: Currency,

    val quoteOrderHidden: Boolean,

    val movingAverageOpen: Indicator,

    val movingAverageClose: Indicator,

    @Serializable(with = HumanReadableDurationSerializer::class)
    val iterateDelay: Duration,

    val printEvents: Boolean,

    val schedule: SamuraiSchedule,

    val maxQuoteDepth: Double,

    @Serializable(with = HumanReadableDurationSerializer::class)
    val maxQuoteDepthCooldown: Duration,

    val hedgeVolumeRoundingScale: Int = 0,
)
