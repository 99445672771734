package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.MarketDataClientConfig
import com.edvorg.trade.common.model.MarketDataConnectorStatus
import com.edvorg.trade.common.model.MarketDataId
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import com.edvorg.trade.common.serialization.Defaults
import com.edvorg.trade.common.utils.concurrency.concurrentMapOf
import kotlinx.browser.window
import kotlinx.coroutines.launch
import mui.icons.material.ContentCopy
import mui.icons.material.CopyAll
import mui.icons.material.Delete
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.icons.material.PlayArrow
import mui.icons.material.RestartAlt
import mui.icons.material.Stop
import mui.icons.material.SyncProblem
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.Size
import mui.material.TableCell
import mui.material.TableRow
import react.Props
import web.cssom.ClassName

external interface MarketDataClientConnectorProps : Props {
    var serverId: String
    var marketDataId: MarketDataId
    var status: MarketDataConnectorStatus
    var resetSettings: (MutableSettings) -> Unit
    var saveSettings: (Settings) -> Unit
    var showSettings: Boolean
    var toggleShowSettings: () -> Unit
    var removeConnector: () -> Unit
    var duplicateConnector: () -> Unit
    var restartConnector: () -> Unit
}

val marketDataClientConnector = fcWithScope<MarketDataClientConnectorProps> { props, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val clickWatcher = getContext<CommonContext>().clickWatcher

    val marketDataId = props.marketDataId
    val status = props.status

    TableRow {
        TableCell {
            +marketDataId.id
        }
        TableCell {
            renderStatus(status.connectorStatus)
        }
        TableCell {
            ButtonGroup {
                when {
                    status.connectorStatus.isDisconnected() -> {
                        Button {
                            title = "Start"
                            size = Size.small
                            className = ClassName("normal")
                            onClick = {
                                clickWatcher.click()
                                it.stopPropagation()

                                scope.launch {
                                    val client =
                                        connectorServersManager.getServerConnection(props.serverId)?.client
                                    client?.connectionManager?.sendConnectMarketData(marketDataId)
                                }
                            }
                            PlayArrow {
                            }
                        }
                    }

                    else -> {
                        Button {
                            title = "Stop"
                            size = Size.small
                            className = ClassName("normal")
                            onClick = {
                                clickWatcher.click()
                                it.stopPropagation()

                                scope.launch {
                                    val client =
                                        connectorServersManager.getServerConnection(props.serverId)?.client
                                    client?.connectionManager?.sendDisconnectMarketData(marketDataId)
                                }
                            }
                            Stop {
                            }
                        }
                    }
                }
                Button {
                    title = "Remove"
                    size = Size.small
                    className = ClassName("normal")
                    onClick = {
                        clickWatcher.click()
                        it.stopPropagation()
                        props.removeConnector()
                    }
                    Delete {
                    }
                }
                Button {
                    title = "Duplicate"
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        clickWatcher.click()
                        event.stopPropagation()
                        props.duplicateConnector()
                    }
                    ContentCopy {
                    }
                }
                Button {
                    title = "Copy config(json) to clipboard"
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        clickWatcher.click()
                        event.stopPropagation()
                        val config = status.marketDataClientConfig
                        val jsonConfig = Defaults.json.encodeToString(
                            MarketDataClientConfig.serializer(),
                            config,
                        )
                        window.navigator.clipboard.writeText(jsonConfig)

                        getContext<CommonContext>().toaster.info(
                            "Config ${marketDataId.id} copied to clipboard",
                            1000,
                        )
                    }
                    CopyAll {
                    }
                }
                Button {
                    title = "Settings"
                    size = Size.small
                    className = ClassName("normal")
                    onClick = {
                        clickWatcher.click()
                        it.stopPropagation()
                        props.toggleShowSettings()
                    }
                    if (props.showSettings) {
                        ExpandLess {
                        }
                    } else {
                        ExpandMore {
                        }
                    }
                }
                if (props.status.marketDataClientConfig is MarketDataClientConfig.Remote) {
                    Button {
                        title = "Restart MD server"
                        size = Size.small
                        className = ClassName("normal")
                        onClick = { event ->
                            clickWatcher.click()
                            event.stopPropagation()
                            props.restartConnector()
                        }
                        RestartAlt {
                        }
                    }
                    Button {
                        title = "Restart MD service (IB only)"
                        size = Size.small
                        className = ClassName("normal")
                        onClick = { event ->
                            clickWatcher.click()
                            event.stopPropagation()

                            scope.launch {
                                val client = connectorServersManager.getServerConnection(
                                    props.serverId,
                                )?.client
                                client?.connectionManager?.sendRestartServiceMarketDataConnector(
                                    marketDataId,
                                )
                            }
                        }
                        SyncProblem {
                        }
                    }
                }
            }
        }
    }
    if (props.showSettings) {
        TableRow {
            TableCell {
                colSpan = 4
                settingsPanel {
                    resetSettings = props.resetSettings
                    saveSettings = props.saveSettings
                    settings = MutableSettings(concurrentMapOf(emptyMap())).apply {
                        resetSettings(this)
                    }.toImmutable()
                    searchEnabled = false
                }
            }
        }
    }
}
