package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.Indicator
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class SamuraiLimitsUnit {
    @Serializable
    @SerialName("Absolute")
    data object Absolute : SamuraiLimitsUnit()

    @Serializable
    @SerialName("Fractional")
    data object Fractional : SamuraiLimitsUnit()

    @Serializable
    @SerialName("SD")
    data class SD(
        val indicator: Indicator.SD,
    ) : SamuraiLimitsUnit()
}
