package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.chartscript.ScriptId
import com.edvorg.trade.common.model.groupwatchlist.GroupWatchlistSubscription
import com.edvorg.trade.common.model.portfolio.PortfolioWidgetColumn
import com.edvorg.trade.common.model.stats.PriceStatType
import com.edvorg.trade.common.model.stats.VolumeStatType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class BackendSubscription {
    @Serializable
    @SerialName("Legacy")
    sealed class Polling : BackendSubscription() {
        @Serializable
        @SerialName("ScannerDeals")
        data class ScannerDeals(val subscription: ScannerDealsSubscription) : Polling() {
            override fun toString(): String {
                return "ScannerDeals (${subscription.scannerId})"
            }
        }

        @Serializable
        @SerialName("AutoTake")
        data class AutoTake(
            val scannerId: ScannerId,
            val widgetId: String,
        ) : BackendSubscription() {
            override fun toString(): String {
                return "AutoTake ($widgetId, $scannerId)"
            }
        }

        @Serializable
        @SerialName("PaginatedOperations")
        object PaginatedOperations : Polling() {
            override fun toString(): String {
                return "Operations"
            }
        }
    }

    @Serializable
    @SerialName("ScannerDealsLog")
    data class ScannerDeals(val deals: ScannerDealsSubscription) : BackendSubscription() {
        override fun toString(): String {
            return "ScannerSubscription ($deals)"
        }
    }

    @Serializable
    @SerialName("Positions")
    data object Positions : BackendSubscription() {
        override fun toString(): String {
            return "Positions"
        }
    }

    @Serializable
    @SerialName("CurrencyPositions")
    data object CurrencyPositions : BackendSubscription() {
        override fun toString(): String {
            return "CurrencyPositions"
        }
    }

    @Serializable
    @SerialName("Orders")
    data class Orders(
        val workingOnly: Boolean,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "Orders"
        }
    }

    @Serializable
    @SerialName("ExcludedIsins")
    data object ExcludedIsins : BackendSubscription() {
        override fun toString(): String {
            return "ExcludedIsins"
        }
    }

    @Serializable
    @SerialName("HiddenIsins")
    data object HiddenIsins : BackendSubscription() {
        override fun toString(): String {
            return "HiddenIsins"
        }
    }

    @Serializable
    @SerialName("ClosePrice")
    data class ClosePrice(val ticker: TypedTicker) : BackendSubscription() {
        override fun toString(): String {
            return "ClosePrice ($ticker)"
        }
    }

    @Serializable
    @SerialName("MidPoint")
    data class MidPoint(val ticker: TypedTicker) : BackendSubscription() {
        override fun toString(): String {
            return "MidPoint ($ticker)"
        }
    }

    @Serializable
    @SerialName("NewOperations")
    data class NewOperations(val brokerId: BrokerId) : BackendSubscription() {
        override fun toString(): String {
            return "NewOperations ($brokerId)"
        }
    }

    @Serializable
    @SerialName("OrderBook")
    data class OrderBook(
        val ticker: TypedTicker,
        val currency: Currency?,
        val depth: Int?,
        val priorityExchange: Exchange?,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "OrderBook ($ticker) ($currency) ($depth)"
        }
    }

    @Serializable
    @SerialName("PriceStat")
    data class PriceStat(
        val ticker: TypedTicker,
        val statType: PriceStatType,
        val currency: Currency?,
        val priorityExchange: Exchange?,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "PriceStat ($ticker) ($statType) ($currency) ($priorityExchange)"
        }
    }

    @Serializable
    @SerialName("VolumeStat")
    data class VolumeStat(
        val ticker: TypedTicker,
        val statType: VolumeStatType,
        val priorityExchange: Exchange?,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "VolumeStat ($ticker) ($statType) ($priorityExchange)"
        }
    }

    @Serializable
    @SerialName("VWAP")
    data class VWAP(val ticker: TypedTicker) : BackendSubscription() {
        override fun toString(): String {
            return "VWAP ($ticker)"
        }
    }

    @Serializable
    @SerialName("VWEMA")
    data class VWEMA(val ticker: TypedTicker) : BackendSubscription() {
        override fun toString(): String {
            return "VWEMA ($ticker)"
        }
    }

    @Serializable
    @SerialName("Trades")
    data class Trades(
        val ticker: TypedTicker,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "Trades ($ticker)"
        }
    }

    @Serializable
    @SerialName("AvailableOrders")
    data class AvailableOrderLots(val ticker: TypedTicker, val price: Double?) : BackendSubscription() {
        override fun toString(): String {
            return "AvailableOrders ($ticker) ($price)"
        }
    }

    @Serializable
    @SerialName("Candles")
    data class Candles(val ticker: TypedTicker, val barSize: BarSize) : BackendSubscription() {
        override fun toString(): String {
            return "Candles ($ticker)"
        }
    }

    @Serializable
    @SerialName("TradingStatus")
    data class TradingStatus(val ticker: TypedTicker) : BackendSubscription() {
        override fun toString(): String {
            return "TradingStatus ($ticker)"
        }
    }

    @Serializable
    @SerialName("CurrencyRate")
    data class CurrencyRateSubscription(
        @SerialName("c1")
        val currency1: Currency,
        @SerialName("c2")
        val currency2: Currency,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "CurrencyRate ($currency1:$currency2)"
        }
    }

    @Serializable
    @SerialName("ChannelNews")
    data class ChannelNewsSubscription(
        val marketDataId: MarketDataId,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "ChannelNews ($marketDataId)"
        }
    }

    @Serializable
    @SerialName("News")
    data class NewsSubscription(
        val marketDataId: MarketDataId,
        val channels: Set<String>,
        val ticker: TypedTicker?,
        val searchString: String,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "News ($marketDataId, $ticker, $searchString)"
        }
    }

    @Serializable
    @SerialName("Indicator")
    data class IndicatorSubscription(
        val ticker: TypedTicker,
        val indicator: Indicator,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "Indicator ($ticker, $indicator)"
        }
    }

    @Serializable
    @SerialName("Watchlist")
    data class GroupWatchlist(
        val data: GroupWatchlistSubscription,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "Watchlist ($data)"
        }
    }

    @Serializable
    @SerialName("ChartScript")
    data class ChartScriptPlot(
        val scriptId: ScriptId,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "ChartScript ($scriptId)"
        }
    }

    @Serializable
    @SerialName("ChartScripts")
    data object ChartScripts : BackendSubscription() {
        override fun toString(): String {
            return "ChartScripts"
        }
    }

    @Serializable
    @SerialName("ChartScriptSettings")
    data class ChartScriptConfig(
        val scriptId: ScriptId,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "ChartScriptSettings $scriptId"
        }
    }

    @Serializable
    @SerialName("ChartScriptIds")
    data object ChartScriptIds : BackendSubscription() {
        override fun toString(): String {
            return "ChartScriptIds"
        }
    }

    @Serializable
    @SerialName("ScheduledDeals")
    data class ScheduledDeals(val id: Long) : BackendSubscription()

    @Serializable
    @SerialName("GroupPortfolioSubscription")
    data class GroupedPortfolioSubscription(
        val groupBy: PortfolioGroupMode,
        val sortType: SortType,
        val columnSort: PortfolioWidgetColumn?,
        val hideExcluded: Boolean,
        var profitCurrency: Currency,
        val selectedExchange: Exchange?,
        val selectedBroker: BrokerId?,
        val routingExchanges: Map<Exchange, Exchange>,
    ) : BackendSubscription() {
        override fun toString(): String {
            return "GroupPortfolioSubscription"
        }
    }
}
