package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class MarketDataControlResponse {
    @Serializable
    @SerialName("ConnectorsUpdate")
    data class ConnectorsUpdate(
        val connectors: Connectors,
    ) : MarketDataControlResponse()
}
