package com.edvorg.trade.backend.frontend.services

import com.edvorg.trade.common.frontend.services.AbstractClickWatcher
import com.edvorg.trade.common.utils.sound.BackendSoundPlayer
import kotlin.js.Date
import kotlin.math.min

class ClickWatcher(private val soundPlayer: BackendSoundPlayer) : AbstractClickWatcher {
    private var maxClicks = 3
    private var clicks = listOf<Long>()

    override fun click() {
        val now = Date.now().toLong()
        clicks = (clicks + now).filter {
            (now - it) < 1000
        }
        if (maxClicks <= clicks.size) {
            soundPlayer.playOnLotsOfClicks()
            maxClicks = min(6, maxClicks + 1)
            clicks = listOf()
        }
    }
}
