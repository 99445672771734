package com.edvorg.trade.common.utils.sound

import kotlinx.browser.document
import mu.KotlinLogging
import org.w3c.dom.Audio

class JsSoundPlayer : SoundPlayer {
    companion object {
        private val logger = KotlinLogging.logger { }
    }

    override fun play(soundId: String) {
        val elementId = "audio-$soundId"
        val audio = document.getElementById(elementId) as? Audio
        if (audio == null) {
            logger.warn { "sound $elementId not found" }
            return
        }
        audio.play()
    }
}
