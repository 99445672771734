package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.SetGroupIdHandler
import com.edvorg.trade.common.frontend.services.SetGroupTickerHandler
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.TypedTicker
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.ButtonGroupVariant
import mui.material.Popper
import mui.material.Size
import popper.core.Placement
import react.ChildrenBuilder
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.table
import react.dom.html.ReactHTML.tbody
import react.dom.html.ReactHTML.td
import react.dom.html.ReactHTML.tr
import react.useEffect
import react.useState
import web.cssom.ClassName
import web.dom.Element
import web.dom.document
import web.html.HTMLInputElement
import web.html.InputType

private const val enterKey = 13

external interface TickerInputProps : PropsWithChildren {
    var selectedTicker: TypedTicker?
    var setGroupId: SetGroupIdHandler
    var setTicker: SetGroupTickerHandler
    var groupId: Group
    var alignRight: Boolean
    var tickerSearchId: String
}

val tickerInputView = fcWithScope<TickerInputProps> { props, scope ->
    val tickersInfoProvider = getContext<CommonContext>().tickersInfoProvider

    var tickerToSearch: String by useState(props.selectedTicker?.ticker?.id ?: "")
    var tickerType: TickerType by useState(props.selectedTicker?.tickerType ?: TickerType.STOCK)
    var tickerSelectAnchor by useState<Element?>(null)
    var tickersToShowPopup: List<Pair<TypedTicker, String>> by useState(listOf())
    var tickerTypeSelectAnchor by useState<Element?>(null)

    useEffect(props.selectedTicker) {
        scope.launch {
            tickerToSearch = props.selectedTicker?.toExchangeAndTicker().toString()
            tickerType = props.selectedTicker?.tickerType ?: TickerType.STOCK
        }
    }

    fun ChildrenBuilder.renderSelectGroup() {
        select {
            className = ClassName(props.groupId.styleClass)
            value = props.groupId.id
            onMouseUp = { it.stopPropagation() }
            onMouseDown = { it.stopPropagation() }
            onChange = { event ->
                event.stopPropagation()
                event.target.value.let { groupIdStr ->
                    props.setGroupId.invoke(
                        Group.entries.find { it.id == groupIdStr }
                            ?: throw Error("unexpected"),
                    )
                }
            }
            Group.entries.forEach {
                option {
                    className = ClassName(it.styleClass)
                    key = it.id
                    value = it.id
                    +"${it.ordinal + 1}"
                }
            }
        }
    }

    span {
        className = if (props.alignRight) {
            ClassName("header-ticker-select-right")
        } else {
            ClassName("header-ticker-select-left")
        }

        renderSelectGroup()

        Popper {
            open = tickerSelectAnchor != null
            anchorEl = tickerSelectAnchor
            placement = Placement.bottom

            div {
                className = ClassName("search-ticker")
                table {
                    tbody {
                        if (tickersToShowPopup.isNotEmpty()) {
                            tickersToShowPopup.forEach { (ticker, description) ->
                                tr {
                                    key = ticker.toString()
                                    onClick = {
                                        props.setTicker.invoke(
                                            props.groupId,
                                            ticker.toExchangeAndTicker().toString(),
                                            ticker.tickerType,
                                            null,
                                        )
                                        tickerSelectAnchor = null
                                    }
                                    td {
                                        +ticker.toString()
                                    }
                                    td {
                                        +description
                                    }
                                }
                            }
                        } else {
                            tr {
                                td {
                                    +"Not found"
                                }
                            }
                        }
                    }
                }
            }
        }

        input {
            type = InputType.text
            className = ClassName("ticker-header")
            id = props.tickerSearchId
            value = tickerToSearch
            onFocus = { event ->
                document.getElementById(props.tickerSearchId)?.let { element ->
                    (element as? HTMLInputElement)?.let { input ->
                        input.setSelectionRange(0, input.value.length)
                        input.focus()
                    }
                }
                tickerTypeSelectAnchor = event.target
            }
            onBlur = {
                window.setTimeout(
                    {
                        tickerTypeSelectAnchor = null
                        tickerSelectAnchor = null
                    },
                    200,
                )
            }
            onClick = { event ->
                (event.target as? HTMLInputElement)?.let { element ->
                    element.setSelectionRange(0, element.value.length)
                }
            }
            onChange = { event ->
                tickerSelectAnchor = null
                tickerToSearch = event.target.value
            }
            onKeyUp = { event ->
                val keyCode = event.asDynamic().keyCode as Int
                val altKey = event.asDynamic().altKey as Boolean
                if (keyCode == enterKey) {
                    if (altKey) {
                        scope.launch {
                            val searchString = tickerToSearch
                            if (searchString.isNotEmpty()) {
                                val tickers = tickersInfoProvider.getSearchTickers(
                                    props.selectedTicker,
                                    searchString,
                                )
                                tickerSelectAnchor = event.target as? HTMLInputElement
                                tickersToShowPopup = tickers
                            }
                        }
                    } else {
                        props.setTicker.invoke(
                            props.groupId,
                            tickerToSearch,
                            tickerType,
                            null,
                        )

                        tickerSelectAnchor = null
                        tickerTypeSelectAnchor = null

                        scope.launch {
                            delay(200)
                            (event.target as? HTMLInputElement)?.let { element ->
                                element.setSelectionRange(0, element.value.length)
                            }
                        }
                    }
                }
            }
        }
        Popper {
            this.open = tickerTypeSelectAnchor != null
            this.anchorEl = tickerTypeSelectAnchor
            this.placement = Placement.top

            // buttons for every ticker type
            ButtonGroup {
                variant = ButtonGroupVariant.contained
                className = ClassName("ticker-type")
                // buttons for every ticker type
                TickerType.entries.forEach { newTickerType ->
                    Button {
                        size = Size.small
                        key = newTickerType.toString()
                        if (tickerType == newTickerType) {
                            className = ClassName("selected-ticker-type")
                        }
                        onClick = {
                            tickerType = newTickerType

                            props.setTicker.invoke(
                                props.groupId,
                                tickerToSearch,
                                newTickerType,
                                null,
                            )
                        }
                        +newTickerType.name.lowercase()
                    }
                }
            }
        }
    }
}
