package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.TypedTicker
import kotlinx.serialization.Serializable

@Serializable
data class SamuraiSecurity(
    val brokerId: BrokerId?,
    val ticker: TypedTicker,
    val count: Double,
    val ratio: Double,
    val pos: Double,
    val openTick: SamuraiTick,
    val closeTick: SamuraiTick,
    val currency: Currency,
    val smart: Boolean,
)
