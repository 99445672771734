package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class PriceFormulaComponets(
    val ratio: Double,
    val addend: Double,
) {
    companion object {
        val emptyFormula = PriceFormulaComponets(1.0, 0.0)
    }
    fun calcPrice(price: Double): Double {
        return (price + addend) * ratio
    }
}
