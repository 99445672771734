package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class ParsedTickerNews(
    val title: String,
    val parsedContent: List<String>,
    val timestamp: Long,
    val channelId: String,
) {
    companion object {
        fun parseNewsContent(tickerNews: TickerNews): ParsedTickerNews {
            val parsedContent = mutableListOf<String>()
            var current = tickerNews.content
            while (current.isNotEmpty()) {
                val index1 = current.indexOf("\$")
                val index2 = current.indexOf("#")

                if (index1 == -1 && index2 == -1) {
                    parsedContent.add(current)
                    break
                }

                val index = when {
                    index1 == -1 -> index2
                    index2 == -1 -> index1
                    else -> minOf(index1, index2)
                }

                parsedContent.add(current.substring(0, index))

                current = current.substring(index)

                val nextIndex = current.indexOfFirst {
                    !it.isLetter() && it != '$' && it != '#'
                }.takeIf { it > 0 } ?: current.length

                val ticker = current.substring(0, nextIndex)
                parsedContent.add(ticker)

                current = current.substring(nextIndex)
            }

            return ParsedTickerNews(
                tickerNews.title,
                parsedContent,
                tickerNews.timestamp,
                tickerNews.channelId,
            )
        }
    }
}
