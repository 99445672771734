package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.config.SamuraiPair
import com.edvorg.trade.common.model.config.SamuraiPauseState
import kotlinx.serialization.Serializable

@Serializable
data class SamuraiState(
    val status: SamuraiStatus,
    val pair: SamuraiPair,
    val pauseState: SamuraiPauseState?,
)
