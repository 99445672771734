package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.BrokerConnectorStatus
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import com.edvorg.trade.common.utils.concurrency.concurrentMapOf
import kotlinx.coroutines.launch
import mui.icons.material.ContentCopy
import mui.icons.material.Delete
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.icons.material.PlayArrow
import mui.icons.material.Stop
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.Size
import mui.material.TableCell
import mui.material.TableRow
import mui.material.Tooltip
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.span
import web.cssom.ClassName

external interface BrokerClientConnectorProps : Props {
    var serverId: String
    var brokerId: BrokerId
    var status: BrokerConnectorStatus
    var resetSettings: (MutableSettings) -> Unit
    var saveSettings: (Settings) -> Unit
    var showSettings: Boolean
    var toggleShowSettings: () -> Unit
    var removeConnector: () -> Unit
    var duplicateConnector: () -> Unit
}

val brokerClientConnector = fcWithScope<BrokerClientConnectorProps> { props, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val clickWatcher = getContext<CommonContext>().clickWatcher

    val brokerId = props.brokerId
    val status = props.status

    TableRow {
        TableCell {
            +brokerId.id
        }
        TableCell {
            renderStatus(status.connectorStatus)
        }
        TableCell {
            ButtonGroup {
                when {
                    status.connectorStatus.isDisconnected() -> {
                        Tooltip {
                            title = ReactNode("connect")

                            Button {
                                size = Size.small
                                className = ClassName("normal")
                                onClick = {
                                    clickWatcher.click()
                                    it.stopPropagation()

                                    scope.launch {
                                        val client = connectorServersManager.getServerConnection(
                                            props.serverId,
                                        )?.client
                                        client?.connectionManager?.sendConnectBroker(brokerId)
                                    }
                                }
                                PlayArrow {
                                }
                            }
                        }
                    }
                    else -> {
                        Tooltip {
                            title = ReactNode("disconnect")

                            Button {
                                size = Size.small
                                className = ClassName("normal")
                                onClick = {
                                    clickWatcher.click()
                                    it.stopPropagation()

                                    scope.launch {
                                        val client = connectorServersManager.getServerConnection(
                                            props.serverId,
                                        )?.client
                                        client?.connectionManager?.sendDisconnectBroker(brokerId)
                                    }
                                }
                                Stop {
                                }
                            }
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("delete")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            clickWatcher.click()
                            it.stopPropagation()
                            props.removeConnector()
                        }
                        Delete {
                        }
                    }
                }
                Tooltip {
                    title = ReactNode("Duplicate")

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = { event ->
                            clickWatcher.click()
                            event.stopPropagation()
                            props.duplicateConnector()
                        }
                        ContentCopy {
                        }
                    }
                }
                Tooltip {
                    title = if (props.showSettings) {
                        ReactNode("Hide settings")
                    } else {
                        ReactNode("Show settings")
                    }

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            clickWatcher.click()
                            it.stopPropagation()
                            props.toggleShowSettings()
                        }
                        if (props.showSettings) {
                            ExpandLess {
                            }
                        } else {
                            ExpandMore {
                            }
                        }
                    }
                }
            }
        }
        TableCell {
            span {
                className = ClassName("broker-lasterror")
                +(props.status.lastError ?: "")
            }
        }
    }
    if (props.showSettings) {
        TableRow {
            TableCell {
                colSpan = 5
                settingsPanel {
                    resetSettings = props.resetSettings
                    saveSettings = props.saveSettings
                    settings = MutableSettings(concurrentMapOf(emptyMap())).apply {
                        resetSettings(this)
                    }.toImmutable()
                    searchEnabled = false
                }
            }
        }
    }
}
