package com.edvorg.trade.common.utils

object CollectionUtils {
    fun <K, V> Map<K, V>.updated(key: K, updater: ((K, V?) -> V?)): Map<K, V> {
        return when (val newValue = updater(key, this[key])) {
            null -> this - key
            else -> this + Pair(key, newValue)
        }
    }
}
