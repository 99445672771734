package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.Exchange
import kotlinx.coroutines.launch
import react.Props
import react.useEffectOnce
import react.useState

external interface PriorityBrokerSelectProps : Props {
    var exchange: Exchange
}

data class BrokerIdOption(
    override val value: BrokerId,
) : SelectOption<BrokerId> {
    override val label: String = value.id
}

val priorityBrokerSelect = fcWithScope<PriorityBrokerSelectProps> { props, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val connectionManager = connectorServersManager.getServerConnection("local")?.client?.connectionManager

    var broker: BrokerId? by useState(null)
    var brokerOptions: Set<BrokerId> by useState(emptySet())

    useEffectOnce {
        val brokerClientsHandle = connectionManager?.subscribeToBrokerClientsUpdate { update ->
            broker = update.priorityBrokers[props.exchange]
            brokerOptions = update.brokerClientsByExchange[props.exchange] ?: emptySet()
        }

        cleanup {
            brokerClientsHandle?.unsubscribe()
        }
    }

    autocomplete(
        { null },
        false,
        props.exchange.id,
        150,
        null,
        { value ->
            value?.let { brokerId ->
                scope.launch {
                    connectionManager?.sendSetPriorityBroker(props.exchange, brokerId)
                }
                broker = brokerId
            }
        },
        null,
        {
        },
        broker?.let { BrokerIdOption(it) },
        brokerOptions.map { BrokerIdOption(it) }.toTypedArray(),
        true,
    )
}
