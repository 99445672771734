package com.edvorg.trade.backend.frontend.views

import com.benasher44.uuid.uuid4
import com.edvorg.trade.backend.frontend.FrontendContext
import com.edvorg.trade.backend.frontend.model.WidgetType
import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.OnDuplicateWidgetStateHandler
import com.edvorg.trade.common.frontend.services.OnSaveWidgetStateTemplateHandler
import com.edvorg.trade.common.frontend.services.OnUpdateGroupWatchedTickersHandler
import com.edvorg.trade.common.frontend.services.OnUpdateWidgetStateSettingsHandler
import com.edvorg.trade.common.frontend.services.ScannerClient
import com.edvorg.trade.common.frontend.services.SetGroupPriceHandler
import com.edvorg.trade.common.frontend.services.SetGroupTickerHandler
import com.edvorg.trade.common.frontend.services.SetGroupVolumeHandler
import com.edvorg.trade.common.frontend.services.SetWidgetGroupIdHandler
import com.edvorg.trade.common.frontend.services.UpdateWidgetGeometryHandler
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.frontend.views.fcWithScope
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.model.GroupState
import com.edvorg.trade.common.model.TabState
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.WidgetGeometry
import com.edvorg.trade.common.model.WidgetGeometry.Companion.makeDefaultGeometry
import com.edvorg.trade.common.model.WidgetSettings
import com.edvorg.trade.common.model.WidgetState
import com.edvorg.trade.common.model.config.GuiConfig.Companion.defaultTab
import com.edvorg.trade.common.model.config.GuiConfigChange
import com.edvorg.trade.common.serialization.Defaults.json
import com.edvorg.trade.common.utils.CollectionUtils.updated
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import react.Props
import react.useEffectOnce
import react.useState
import kotlin.time.Duration.Companion.seconds

val snackbar = fcWithScope<Props> { _, scope ->
    val toaster = getContext<FrontendContext>().snackbarToaster
    toaster.run {
        this@fcWithScope.renderSnackbar(scope)
    }
}

data class LocalGroupState(
    val priceStr: String,
    val volumeStr: String,
    val state: GroupState,
)

data class LocalTabState(
    val widgets: Map<String, WidgetState>,
)

private fun createDefaultWidgetState(
    widgetType: WidgetType,
    geometry: WidgetGeometry,
): WidgetState {
    return when (widgetType) {
        WidgetType.CHART -> {
            WidgetState(
                Group.BLACK,
                geometry,
                WidgetSettings.Chart(
                    barSize = BarSize.MINUTE,
                    appendLast = true,
                ),
                Group.BLACK,
            )
        }
        WidgetType.CHART_SCRIPT -> {
            WidgetState(
                Group.BLACK,
                geometry,
                WidgetSettings.ChartScript(
                    null,
                    5,
                ),
                Group.BLACK,
            )
        }
    }
}

val app = fcWithScope<Props> { _, scope ->
    val backendMainClient = getContext<FrontendContext>().backendMainClient
    val soundPlayer = getContext<CommonContext>().soundPlayer
    val toaster = getContext<CommonContext>().toaster
    val candlesClient = getContext<FrontendContext>().candlesClient
    val tradesClient = getContext<FrontendContext>().tradesClient
    val connectorServersManager = getContext<CommonContext>().connectorServersManager

    val (widgetTemplates, setWidgetTemplates) = useState<Map<String, WidgetState>>(emptyMap())
    val (watchedTickers, setWatchedTickers) = useState<Map<Group, Set<Ticker>>>(emptyMap())
    val (groups, setGroups) = useState<Map<Group, LocalGroupState>>(emptyMap())
    var selectedTab by useState(defaultTab)
    var activeSubscriptionSize by useState(0)
    var prioritySuggestExchange by useState<Exchange?>(null)
    var userSpaceList by useState<List<String>?>(null)
    var roundingScale by useState(2)
    val (tabs, setTabs) = useState<Map<String, LocalTabState>>(
        hashMapOf(
            defaultTab to LocalTabState(
                hashMapOf(),
            ),
        ),
    )

    useEffectOnce {
        val handle1 = backendMainClient.subscribeToGuiConfigSnapshotUpdate { update ->
            val currentGroups = groups
            selectedTab = update.selectedTab ?: selectedTab
            setTabs { tabs ->
                (tabs.keys + update.tabs.keys).map { tab ->
                    val stateTab = tabs[tab] ?: LocalTabState(mapOf())
                    val newTab = update.tabs[tab] ?: TabState(mapOf())
                    val mergedWidgets = (stateTab.widgets.keys + newTab.widgets.keys).mapNotNull { widgetId ->
                        val newState = newTab.widgets[widgetId]
                            ?: stateTab.widgets[widgetId]
                            ?: return@mapNotNull null
                        Pair(widgetId, newState)
                    }.toMap()
                    Pair(tab, LocalTabState(mergedWidgets))
                }.toMap()
            }
            setWidgetTemplates { widgetTemplates ->
                widgetTemplates + update.widgetTemplates
            }
            setWatchedTickers { watchedTickers ->
                watchedTickers + update.watchedTickers
            }
            setGroups { groups ->
                groups + update.groups.mapValues { (group, newGroup) ->
                    val priceStr = currentGroups[group]?.priceStr ?: ""
                    val volumeStr = currentGroups[group]?.volumeStr ?: ""
                    LocalGroupState(priceStr, volumeStr, newGroup)
                }
            }
            prioritySuggestExchange = update.prioritySuggestExchange
            roundingScale = update.priceRoundingScale
        }

        val handle2 = backendMainClient.subscribeToUserSpaceListUpdate { newUserSpaceList ->
            userSpaceList = newUserSpaceList
        }

        val handle3 = backendMainClient.subscribeToGuiConfigChanges { change ->
            when (change) {
                is GuiConfigChange.AddNewTab -> {
                    setTabs { tabs ->
                        tabs + Pair(change.newTab, LocalTabState(mapOf()))
                    }
                }
                is GuiConfigChange.AddWidget -> {
                    setTabs { tabs ->
                        tabs.updated(change.tab) { _, oldTab ->
                            val newTabs = oldTab ?: LocalTabState(mapOf())
                            newTabs.copy(
                                widgets = newTabs.widgets.updated(change.newWidgetId) { _, w ->
                                    w ?: change.widgetState
                                },
                            )
                        }
                    }
                }
                is GuiConfigChange.DuplicateTab -> {
                    val existingTab = tabs[selectedTab]
                    setTabs { tabs ->
                        tabs.updated(change.newTab) { _, oldWidgets ->
                            oldWidgets ?: existingTab ?: LocalTabState(mapOf())
                        }
                    }
                }
                is GuiConfigChange.RemoveTab -> {
                    setTabs { tabs ->
                        tabs - change.tab
                    }
                    if (selectedTab == change.tab) {
                        val newSelectedTab = tabs.entries.firstOrNull()?.key ?: defaultTab
                        selectedTab = newSelectedTab
                    }
                }
                is GuiConfigChange.RemoveWidgetStateTemplate -> {
                    setWidgetTemplates { widgetTemplates ->
                        widgetTemplates - change.name
                    }
                }
                is GuiConfigChange.SaveWidgetStateTemplate -> {
                    setWidgetTemplates { widgetTemplates ->
                        widgetTemplates.updated(change.name) { _, _ ->
                            change.widgetState
                        }
                    }
                }
                is GuiConfigChange.SetGroupTicker -> {
                    setGroups { groups ->
                        groups.updated(change.group) { _, oldGroup ->
                            oldGroup?.copy(
                                state = oldGroup.state.copy(
                                    selectedTicker = change.ticker,
                                ),
                            ) ?: LocalGroupState("", "", GroupState(change.ticker))
                        }
                    }
                }
                is GuiConfigChange.SetPriceRoundingScale -> {
                    roundingScale = change.scale
                }
                is GuiConfigChange.SetSuggestExchange -> {
                    prioritySuggestExchange = change.exchange
                }
                is GuiConfigChange.SetWidgetGroupId -> {
                    setTabs { tabs ->
                        tabs.updated(change.tab) { _, oldTab ->
                            val newTabs = oldTab ?: LocalTabState(mapOf())
                            newTabs.copy(
                                widgets = newTabs.widgets.updated(change.widgetId) { _, oldWidget ->
                                    oldWidget?.copy(
                                        groupId = change.groupId,
                                    )
                                },
                            )
                        }
                    }
                }
                is GuiConfigChange.SetWidgetGroupId2 -> {
                    setTabs { tabs ->
                        tabs.updated(change.tab) { _, oldTab ->
                            val newTabs = oldTab ?: LocalTabState(mapOf())
                            newTabs.copy(
                                widgets = newTabs.widgets.updated(change.widgetId) { _, oldWidget ->
                                    oldWidget?.copy(
                                        groupId2 = change.groupId,
                                    )
                                },
                            )
                        }
                    }
                }

                is GuiConfigChange.RemoveWidget -> {
                    setTabs { tabs ->
                        tabs.updated(change.tab) { _, oldTab ->
                            val newTabs = oldTab ?: LocalTabState(mapOf())
                            newTabs.copy(
                                widgets = newTabs.widgets - change.widgetId,
                            )
                        }
                    }
                }

                is GuiConfigChange.SetWidgetGeometry -> {
                    setTabs { tabs ->
                        tabs.updated(change.tab) { _, oldTab ->
                            val newTabs = oldTab ?: LocalTabState(mapOf())
                            newTabs.copy(
                                widgets = newTabs.widgets.updated(change.widgetId) { _, oldWidget ->
                                    oldWidget?.copy(
                                        geometry = change.geometry,
                                    )
                                },
                            )
                        }
                    }
                }

                is GuiConfigChange.UpdateGroupWatchedTickers -> {
                    setWatchedTickers { watchedTickers ->
                        watchedTickers.updated(change.group) { _, oldTickers ->
                            oldTickers ?: emptySet()
                        }
                    }
                }
                is GuiConfigChange.UpdateWidgetSettings -> {
                    setTabs { tabs ->
                        tabs.updated(change.tab) { _, oldTab ->
                            val newTabs = oldTab ?: LocalTabState(mapOf())
                            newTabs.copy(
                                widgets = newTabs.widgets.updated(change.widgetId) { _, oldWidget ->
                                    oldWidget?.copy(
                                        typedSettings = change.settings,
                                    )
                                },
                            )
                        }
                    }
                }
                is GuiConfigChange.ClearPriorityBroker -> {
                    // handled in BackendMainClient
                }
                is GuiConfigChange.SetPriorityBroker -> {
                    // handled in BackendMainClient
                }
                is GuiConfigChange.SetSelectedTab -> {
                    // ignore
                }
            }
        }

        val job1 = scope.launch {
            while (isActive) {
                val subscriptionSize = connectorServersManager.getServerIds().mapNotNull {
                    val client = connectorServersManager.getServerConnection(it)?.client as? ScannerClient
                    client?.activeSubscriptionSize()
                }.sum() + candlesClient.activeSubscriptionSize() + tradesClient.activeSubscriptionSize()
                activeSubscriptionSize = subscriptionSize
                delay(3.seconds)
            }
        }

        cleanup {
            handle1.unsubscribe()
            handle2.unsubscribe()
            handle3.unsubscribe()
            job1.cancel()
        }
    }

    appBar {
        this.tabs = tabs.keys.toList()
        this.widgetTemplates = widgetTemplates.keys.toList()
        this.removeWidgetTemplate = { it ->
            setWidgetTemplates { widgetTemplates ->
                widgetTemplates - it
            }
        }
        this.selectedTab = selectedTab
        this.setSelectedTab = { tab ->
            selectedTab = tab
            setTabs { tabs ->
                tabs.updated(tab) { _, oldWidgets ->
                    oldWidgets ?: LocalTabState(mapOf())
                }
            }
        }
        this.removeTab = { tab ->
            setTabs { tabs ->
                tabs - tab
            }
            val newSelectedTab = tabs.entries.firstOrNull()?.key ?: defaultTab
            selectedTab = newSelectedTab
        }
        this.duplicateTab = { tab ->
            val existingTab = tabs[selectedTab]
            selectedTab = tab
            setTabs { tabs ->
                tabs.updated(tab) { _, oldWidgets ->
                    oldWidgets ?: existingTab ?: LocalTabState(mapOf())
                }
            }
            backendMainClient.sendTabsChange(GuiConfigChange.DuplicateTab(selectedTab, tab))
            soundPlayer.playLetsDoSomeChemistry()
        }
        this.userSpaceList = userSpaceList
        this.roundingScale = roundingScale
        this.setRoundingScale = { scale ->
            roundingScale = scale
        }
        this.prioritySuggestExchange = prioritySuggestExchange
        this.setPrioritySuggestExchange = { exchange ->
            prioritySuggestExchange = exchange
        }
        this.createWidget = { selectedWidget ->
            val newWidgetId = uuid4().toString()
            val geometry = makeDefaultGeometry(
                tabs[selectedTab]?.widgets?.map {
                    it.value.geometry.index
                }?.maxOrNull() ?: 0,
            )

            val widgetState = createDefaultWidgetState(
                WidgetType.entries.find { it.id == selectedWidget } ?: throw Exception("unexpected"),
                geometry,
            )

            setTabs { tabs ->
                tabs.updated(selectedTab) { _, oldTab ->
                    val newTabs = oldTab ?: LocalTabState(mapOf())
                    newTabs.copy(
                        widgets = newTabs.widgets.updated(newWidgetId) { _, w ->
                            w ?: widgetState
                        },
                    )
                }
            }
            backendMainClient.sendTabsChange(
                GuiConfigChange.AddWidget(
                    selectedTab,
                    newWidgetId,
                    widgetState,
                ),
            )
            soundPlayer.playOnWidgetAdded()
        }
        this.createWidgetFromState = { stateStr ->
            val newWidgetId = uuid4().toString()
            val geometry = makeDefaultGeometry(
                tabs[selectedTab]?.widgets?.map {
                    it.value.geometry.index
                }?.maxOrNull() ?: 0,
            )

            val widgetState = json.decodeFromString(
                WidgetState.serializer(),
                stateStr,
            ).copy(geometry = geometry)

            setTabs { tabs ->
                tabs.updated(selectedTab) { _, oldTab ->
                    val newTabs = oldTab ?: LocalTabState(mapOf())
                    newTabs.copy(
                        widgets = newTabs.widgets.updated(newWidgetId) { _, w ->
                            w ?: widgetState
                        },
                    )
                }
            }
            backendMainClient.sendTabsChange(
                GuiConfigChange.AddWidget(
                    selectedTab,
                    newWidgetId,
                    widgetState,
                ),
            )
            soundPlayer.playOnWidgetAdded()
        }
        this.createWidgetFromTemplate = { selectedWidgetTemplate ->
            val widgetState = selectedWidgetTemplate.let { widgetTemplates[it] }
            if (widgetState != null) {
                val geometry = makeDefaultGeometry(
                    tabs[selectedTab]?.widgets?.map {
                        it.value.geometry.index
                    }?.maxOrNull() ?: 0,
                )
                val newWidgetId = uuid4().toString()
                val newWidgetState = widgetState.copy(geometry = geometry)
                setTabs { tabs ->
                    tabs.updated(selectedTab) { _, oldTab ->
                        val newTabs = oldTab ?: LocalTabState(mapOf())
                        newTabs.copy(
                            widgets = newTabs.widgets.updated(newWidgetId) { _, _ ->
                                widgetState.copy(geometry = geometry)
                            },
                        )
                    }
                }

                backendMainClient.sendTabsChange(
                    GuiConfigChange.AddWidget(
                        selectedTab,
                        newWidgetId,
                        newWidgetState,
                    ),
                )
                soundPlayer.playOnWidgetAdded()
            }
        }
        this.activeSubscriptionSize = activeSubscriptionSize
    }

    frame {
        this.updateWidgetGeometry = UpdateWidgetGeometryHandler { widgetId, geometry ->
            setTabs { tabs ->
                tabs.updated(selectedTab) { _, oldState ->
                    oldState?.copy(
                        widgets = oldState.widgets.updated(widgetId) { _, oldWidget ->
                            oldWidget?.copy(
                                geometry = geometry,
                            )
                        },
                    )
                }
            }

            backendMainClient.sendTabsChange(
                GuiConfigChange.SetWidgetGeometry(
                    selectedTab,
                    widgetId,
                    geometry,
                ),
            )
        }
        this.onRemoveWidget = { widgetId ->
            setTabs { tabs ->
                tabs.updated(selectedTab) { _, oldState ->
                    oldState?.copy(
                        widgets = oldState.widgets - widgetId,
                    )
                }
            }

            backendMainClient.sendTabsChange(
                GuiConfigChange.RemoveWidget(
                    selectedTab,
                    widgetId,
                ),
            )
        }
        this.tab = tabs[selectedTab] ?: LocalTabState(mapOf())
        this.groups = groups
        this.watchedTickers = watchedTickers
        this.setTicker = SetGroupTickerHandler { groupId, newTickerInput, tickerType, brokerId ->
            val suggestExchange = prioritySuggestExchange ?: backendMainClient.config.masterExchange
            val tickerHelper = backendMainClient.getTickerInputHelper()
            val newSelectedTicker = tickerHelper.processInput(
                newTickerInput.uppercase(),
                suggestExchange,
            ).copy(
                tickerType = tickerType,
            )
            if (brokerId != null) {
                scope.launch {
                    backendMainClient.setPriorityBroker(newSelectedTicker.exchange, brokerId)
                }
            }
            setGroups { groups ->
                groups.updated(groupId) { _, group ->
                    group?.copy(
                        state = group.state.copy(selectedTicker = newSelectedTicker),
                    ) ?: LocalGroupState(
                        "",
                        "",
                        GroupState(selectedTicker = newSelectedTicker),
                    )
                }
            }

            backendMainClient.sendTabsChange(GuiConfigChange.SetGroupTicker(groupId, newSelectedTicker))
        }
        this.setWidgetGroupId = SetWidgetGroupIdHandler { widgetId, groupId ->
            setTabs { tabs ->
                tabs.updated(selectedTab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets.updated(widgetId) { _, widgetState ->
                            widgetState?.copy(groupId = groupId)
                        },
                    )
                }
            }

            backendMainClient.sendTabsChange(GuiConfigChange.SetWidgetGroupId(selectedTab, widgetId, groupId))
        }
        this.setWidgetGroupId2 = SetWidgetGroupIdHandler { widgetId, groupId ->
            setTabs { tabs ->
                tabs.updated(selectedTab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets.updated(widgetId) { _, widgetState ->
                            widgetState?.copy(groupId2 = groupId)
                        },
                    )
                }
            }

            backendMainClient.sendTabsChange(GuiConfigChange.SetWidgetGroupId2(selectedTab, widgetId, groupId))
        }
        this.setGroupVolume = SetGroupVolumeHandler { groupId, newVolumeStr ->
            setGroups { groups ->
                groups.updated(groupId) { _, oldGroup ->
                    oldGroup?.copy(volumeStr = newVolumeStr)
                }
            }
        }
        this.setGroupPrice = SetGroupPriceHandler { groupId, newPriceStr ->
            setGroups { groups ->
                groups.updated(groupId) { _, oldGroup ->
                    oldGroup?.copy(priceStr = newPriceStr)
                }
            }
        }
        this.onSaveWidgetStateTemplate = OnSaveWidgetStateTemplateHandler { name, widgetState ->
            setWidgetTemplates { widgetTemplates ->
                widgetTemplates.updated(name) { _, _ ->
                    widgetState
                }
            }
            backendMainClient.sendTabsChange(
                GuiConfigChange.SaveWidgetStateTemplate(
                    name,
                    widgetState,
                ),
            )
        }
        this.onDuplicateWidgetState = OnDuplicateWidgetStateHandler { widgetState ->
            scope.launch {
                delay(0.1.seconds)
                val geometry = makeDefaultGeometry(
                    tabs[selectedTab]?.widgets?.map {
                        it.value.geometry.index
                    }?.maxOrNull() ?: 0,
                )
                val widgetId = uuid4().toString()
                val newWidgetState = widgetState.copy(geometry = geometry)
                setTabs { tabs ->
                    tabs.updated(selectedTab) { _, oldTab ->
                        val newTabs = oldTab ?: LocalTabState(mapOf())
                        newTabs.copy(
                            widgets = newTabs.widgets.updated(widgetId) { _, w ->
                                w ?: newWidgetState
                            },
                        )
                    }
                }

                backendMainClient.sendTabsChange(
                    GuiConfigChange.AddWidget(
                        selectedTab,
                        widgetId,
                        newWidgetState,
                    ),
                )
            }
            soundPlayer.playOnWidgetAdded()
        }
        this.onUpdateWidgetStateSettings = OnUpdateWidgetStateSettingsHandler { widgetId, updater ->
            val newTypedSettings = updater()
            setTabs { tabs ->
                tabs.updated(selectedTab) { _, oldTab ->
                    oldTab?.copy(
                        widgets = oldTab.widgets.updated(widgetId) { _, oldWidget ->
                            oldWidget?.copy(
                                typedSettings = newTypedSettings,
                            )
                        },
                    )
                }
            }
            backendMainClient.sendTabsChange(
                GuiConfigChange.UpdateWidgetSettings(
                    selectedTab,
                    widgetId,
                    newTypedSettings,
                ),
            )

            toaster.info(
                "Widget settings saved",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        this.onUpdateGroupWatchedTickers = OnUpdateGroupWatchedTickersHandler { groupId, updater ->
            setWatchedTickers { watchedTickers ->
                watchedTickers.updated(groupId) { _, old ->
                    (old?.toMutableSet() ?: mutableSetOf()).also(updater)
                }
            }

            backendMainClient.sendTabsChange(
                GuiConfigChange.UpdateGroupWatchedTickers(
                    groupId,
                    watchedTickers[groupId] ?: emptySet(),
                ),
            )
        }
        this.roundingScale = roundingScale
    }

    snackbar {
    }
}
