package com.edvorg.trade.common.utils.concurrency

actual fun <K, V> concurrentMapOf(map: Map<K, V>): MutableMap<K, V> {
    return map.toMutableMap()
}

actual fun <E> concurrentSetOf(set: Set<E>): MutableSet<E> {
    return set.toMutableSet()
}

actual fun <K, V> concurrentMapOf(vararg pairs: Pair<K, V>): MutableMap<K, V> {
    return mutableMapOf(*pairs)
}

actual fun <K, V> MutableMap<K, V>.compute(key: K, block: (K, V?) -> V?): V? {
    val newValue = block(key, this[key])
    return if (newValue != null) {
        this[key] = newValue
        newValue
    } else {
        remove(key)
        null
    }
}
