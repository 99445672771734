package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("BrokerManagementRequest")
sealed class BrokerManagementRequest {
    @Serializable
    @SerialName("DisconnectBroker")
    data class DisconnectBroker(
        val brokerId: BrokerId,
    ) : BrokerManagementRequest()

    @Serializable
    @SerialName("ConnectBroker")
    data class ConnectBroker(
        val brokerId: BrokerId,
    ) : BrokerManagementRequest()

    @Serializable
    @SerialName("CreateBrokerClient")
    data class CreateBrokerClient(
        val brokerId: BrokerId,
        val config: BrokerClientConfig,
    ) : BrokerManagementRequest()

    @Serializable
    @SerialName("RemoveBrokerClient")
    data class RemoveBrokerClient(
        val brokerId: BrokerId,
    ) : BrokerManagementRequest()

    @Serializable
    @SerialName("UpdateBrokerClient")
    data class UpdateBrokerClient(
        val brokerId: BrokerId,
        val config: BrokerClientConfig,
    ) : BrokerManagementRequest()

    @Serializable
    @SerialName("DuplicateBrokerConnector")
    data class DuplicateBrokerConnector(
        val brokerId: BrokerId,
        val newBrokerId: BrokerId,
    ) : BrokerManagementRequest()

    @Serializable
    @SerialName("SetPriorityBroker")
    data class SetPriorityBroker(
        val exchange: Exchange,
        val brokerId: BrokerId,
    ) : BrokerManagementRequest()
}
