package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

enum class TickerType(val index: Int) {
    STOCK(1),
    CRYPTO(2),
    FUTURE(3),
    CURRENCY(4),
    OPTION(5),
    ;

    companion object {
        private val indexToTickerType = entries.associateBy {
            it.index
        }.also { map ->
            map.entries.groupBy {
                it.value
            }.entries.find {
                1 < it.value.size
            }?.let { entry ->
                throw Error("2 or more tickerType cases have the same index $entry")
            }
        }

        fun fromIndex(index: Int): TickerType? {
            return indexToTickerType[index]
        }
    }
}

object TickerTypeIndexSerializer : KSerializer<TickerType> {
    override fun deserialize(decoder: Decoder): TickerType {
        val index = decoder.decodeInt()
        return TickerType.fromIndex(index) ?: throw Error("tickerType not supported: $index")
    }

    override fun serialize(encoder: Encoder, value: TickerType) {
        encoder.encodeInt(value.index)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "TickerTypeIndexIdentifier",
        PrimitiveKind.INT,
    )
}
