package com.edvorg.trade.common.model

import com.edvorg.trade.common.utils.format
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.nanoseconds
import kotlin.time.Duration.Companion.seconds

fun String.asHumanReadableToDuration(): Duration {
    val regex = Regex("([0-9.]+)\\s?([A-Za-z]+)?")
    val result = regex.matchEntire(this) ?: throw Exception("regex match error")
    val value = result.groups[1]?.value?.toDouble() ?: throw Exception("value group not found")
    val unit = result.groups[2]?.value ?: "ms"
    return when (unit.lowercase()) {
        "nanos", "nano", "n" -> value.nanoseconds
        "milliseconds", "millisecond", "millis", "milli", "ms" -> value.milliseconds
        "seconds", "second", "secs", "sec", "s" -> value.seconds
        "minutes", "minute", "mins", "min", "m" -> value.minutes
        "hours", "hour", "hrs", "hr", "h" -> value.hours
        "days", "day", "d" -> value.days
        else -> throw Exception("unable to parse duration from '$this'")
    }
}

private val millisInDay = 1.days.inWholeMilliseconds

private val millisInHour = 1.hours.inWholeMilliseconds

private val millisInMinute = 1.minutes.inWholeMilliseconds

private val millisInSecond = 1.seconds.inWholeMilliseconds

fun Duration.toHumanReadableString(): String {
    val milliseconds = inWholeMilliseconds
    return when {
        millisInDay <= milliseconds -> {
            "${(milliseconds.toDouble() / millisInDay).format(2)} days"
        }
        millisInHour <= milliseconds -> {
            "${(milliseconds.toDouble() / millisInHour).format(2)} hours"
        }
        millisInMinute <= milliseconds -> {
            "${(milliseconds.toDouble() / millisInMinute).format(2)} minutes"
        }
        millisInSecond <= milliseconds -> {
            "${(milliseconds.toDouble() / millisInSecond).format(2)} seconds"
        }
        else -> "$milliseconds millis"
    }
}

object HumanReadableDurationSerializer : KSerializer<Duration> {
    override fun deserialize(decoder: Decoder): Duration {
        return decoder.decodeString().asHumanReadableToDuration()
    }

    override fun serialize(encoder: Encoder, value: Duration) {
        return encoder.encodeString(value.toHumanReadableString())
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "HumanReadableDurationSerializer",
        PrimitiveKind.STRING,
    )
}
