package com.edvorg.trade.backend.frontend.views.widgets.connectors

import com.edvorg.trade.backend.frontend.FrontendContext
import com.edvorg.trade.common.frontend.services.getContext
import io.ktor.http.Url
import mui.material.Button
import mui.material.Size
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableContainer
import mui.material.TableRow
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.label
import react.dom.onChange
import react.useEffectOnce
import react.useState
import web.cssom.ClassName
import web.cssom.Globals.Companion.unset
import web.html.HTMLInputElement
import web.html.InputType

val scannerServersPanel = FC<Props> {
    val backendMainClient = getContext<FrontendContext>().backendMainClient

    var scannerServers: Map<String, Url> by useState(emptyMap())
    var lastUpdatedScannerServers: Map<String, Url> by useState(emptyMap())
    val (editableScannerServers, setEditableScannerServers) = useState<List<Pair<String, String>>?>(null)

    useEffectOnce {
        val handle = backendMainClient.subscribeScannerServersUpdate { update ->
            if (editableScannerServers == null) {
                scannerServers = update.scannerServers
                lastUpdatedScannerServers = update.scannerServers
                setEditableScannerServers(
                    update.scannerServers.map {
                        Pair(it.key, it.value.toString())
                    },
                )
            } else {
                val servers = editableScannerServers.associate {
                    Pair(it.first, Url(it.second))
                }
                if (lastUpdatedScannerServers == servers) {
                    scannerServers = update.scannerServers
                    lastUpdatedScannerServers = update.scannerServers
                    setEditableScannerServers(
                        update.scannerServers.map {
                            Pair(it.key, it.value.toString())
                        },
                    )
                } else {
                    scannerServers = update.scannerServers
                }
            }
        }
        cleanup {
            handle.unsubscribe()
        }
    }

    val outdated = scannerServers != lastUpdatedScannerServers
    h3 {
        if (!outdated) {
            +"Scanner servers"
        } else {
            +"Scanner servers !OUTDATED!"
        }
    }
    div {
        if (editableScannerServers != null) {
            TableContainer {
                Table {
                    sx {
                        width = unset
                    }
                    size = Size.small

                    TableBody {
                        editableScannerServers.forEachIndexed { index, server ->
                            val name = server.first
                            val url = server.second
                            TableRow {
                                key = index.toString()
                                TableCell {
                                    TextField {
                                        size = Size.small
                                        label = ReactNode("name")
                                        type = InputType.text
                                        value = name
                                        onChange = { event ->
                                            (event.target as HTMLInputElement).value.trim().let {
                                                setEditableScannerServers { editableScannerServers ->
                                                    if (editableScannerServers == null) {
                                                        return@setEditableScannerServers editableScannerServers
                                                    }
                                                    val mutableServers = editableScannerServers.toMutableList()
                                                    mutableServers[index] = Pair(it, url)
                                                    mutableServers
                                                }
                                            }
                                        }
                                    }
                                }
                                TableCell {
                                    TextField {
                                        size = Size.small
                                        label = ReactNode("url")
                                        type = InputType.text
                                        value = url
                                        onChange = { event ->
                                            (event.target as HTMLInputElement).value.trim().let {
                                                setEditableScannerServers { editableScannerServers ->
                                                    if (editableScannerServers == null) {
                                                        return@setEditableScannerServers editableScannerServers
                                                    }
                                                    val mutableServers = editableScannerServers.toMutableList()
                                                    mutableServers[index] = Pair(name, it)
                                                    mutableServers
                                                }
                                            }
                                        }
                                    }
                                }
                                TableCell {
                                    Button {
                                        size = Size.small
                                        className = ClassName("normal")
                                        onClick = {
                                            setEditableScannerServers { editableScannerServers ->
                                                if (editableScannerServers == null) {
                                                    return@setEditableScannerServers editableScannerServers
                                                }
                                                val mutableServers = editableScannerServers.toMutableList()
                                                mutableServers.removeAt(index)
                                                mutableServers
                                            }
                                        }
                                        +"Remove"
                                    }
                                }
                            }
                        }
                        TableRow {
                            TableCell {
                                Button {
                                    size = Size.small
                                    className = ClassName("normal")
                                    onClick = {
                                        setEditableScannerServers { editableScannerServers ->
                                            val last = editableScannerServers?.lastOrNull()
                                            val newName = last?.let { it.first + "-1" } ?: "scanner-1"
                                            val servers = editableScannerServers ?: listOf()
                                            servers + Pair(newName, "")
                                        }
                                    }
                                    +"Add"
                                }
                            }
                        }
                    }
                }
            }
            Button {
                size = Size.small
                className = ClassName("normal")
                disabled = outdated
                onClick = { event ->
                    event.stopPropagation()
                    setEditableScannerServers { editableScannerServers ->
                        val servers = editableScannerServers?.associate {
                            Pair(it.first, Url(it.second))
                        }
                        if (servers != null) {
                            backendMainClient.sendScannerClients(servers)
                            scannerServers = servers
                            lastUpdatedScannerServers = servers
                            servers.map {
                                Pair(it.key, it.value.toString())
                            }
                        } else {
                            editableScannerServers
                        }
                    }
                }
                +"Apply"
            }
            if (outdated) {
                Button {
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        event.stopPropagation()
                        lastUpdatedScannerServers = scannerServers
                        setEditableScannerServers(
                            scannerServers.map {
                                Pair(it.key, it.value.toString())
                            },
                        )
                    }
                    +"Update"
                }
            }
        } else {
            label {
                +"Not found servers"
            }
        }
    }
}
