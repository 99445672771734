package com.edvorg.trade.common.frontend.views

import com.edvorg.trade.common.frontend.services.CommonContext
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.model.ScannerConnectorStatus
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.settings.MutableSettings
import com.edvorg.trade.common.model.settings.Settings
import com.edvorg.trade.common.utils.concurrency.concurrentMapOf
import kotlinx.coroutines.launch
import mui.icons.material.ContentCopy
import mui.icons.material.Delete
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.icons.material.PlayArrow
import mui.icons.material.Stop
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.Size
import mui.material.TableCell
import mui.material.TableRow
import react.Props
import web.cssom.ClassName

external interface ScannerClientConnectorProps : Props {
    var serverId: String
    var scannerId: ScannerId
    var status: ScannerConnectorStatus
    var resetSettings: (MutableSettings) -> Unit
    var saveSettings: (Settings) -> Unit
    var showSettings: Boolean
    var toggleShowSettings: () -> Unit
    var removeConnector: () -> Unit
    var duplicateConnector: () -> Unit
}

val scannerClientConnector = fcWithScope<ScannerClientConnectorProps> { props, scope ->
    val connectorServersManager = getContext<CommonContext>().connectorServersManager
    val clickWatcher = getContext<CommonContext>().clickWatcher

    val scannerId = props.scannerId
    val status = props.status
    val client = connectorServersManager.getServerConnection(props.serverId)?.client
    val scannerType = status.scannerClientConfig.displayName()

    TableRow {
        TableCell {
            +"${scannerId.id} ($scannerType)"
        }
        TableCell {
            renderStatus(status.connectorStatus)
        }
        TableCell {
            ButtonGroup {
                when {
                    status.connectorStatus.isDisconnected() -> {
                        Button {
                            title = "Start"
                            size = Size.small
                            className = ClassName("normal")
                            onClick = {
                                clickWatcher.click()
                                it.stopPropagation()

                                scope.launch {
                                    client?.connectionManager?.sendConnectScanner(scannerId)
                                }
                            }
                            PlayArrow {
                            }
                        }
                    }

                    else -> {
                        Button {
                            title = "Stop"
                            size = Size.small
                            className = ClassName("normal")
                            onClick = {
                                clickWatcher.click()
                                it.stopPropagation()

                                scope.launch {
                                    client?.connectionManager?.sendDisconnectScanner(scannerId)
                                }
                            }
                            Stop {
                            }
                        }
                    }
                }
                Button {
                    title = "Remove"
                    size = Size.small
                    className = ClassName("normal")
                    onClick = {
                        clickWatcher.click()
                        it.stopPropagation()
                        props.removeConnector()
                    }
                    Delete {
                    }
                }
                Button {
                    title = "Duplicate"
                    size = Size.small
                    className = ClassName("normal")
                    onClick = { event ->
                        clickWatcher.click()
                        event.stopPropagation()
                        props.duplicateConnector()
                    }
                    ContentCopy {
                    }
                }
                Button {
                    title = "Settings"
                    size = Size.small
                    className = ClassName("normal")
                    onClick = {
                        clickWatcher.click()
                        it.stopPropagation()
                        props.toggleShowSettings()
                    }
                    if (props.showSettings) {
                        ExpandLess {
                        }
                    } else {
                        ExpandMore {
                        }
                    }
                }
            }
        }
    }
    if (props.showSettings) {
        TableRow {
            TableCell {
                colSpan = 4
                settingsPanel {
                    resetSettings = props.resetSettings
                    saveSettings = props.saveSettings
                    settings = MutableSettings(concurrentMapOf(emptyMap())).apply {
                        resetSettings(this)
                    }.toImmutable()
                    searchEnabled = false
                }
            }
        }
    }
}
