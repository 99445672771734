package com.edvorg.trade.backend.frontend.views.widgets.chartscript

import com.edvorg.trade.common.frontend.views.BarSizeSelectOption
import com.edvorg.trade.common.frontend.views.autocomplete
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.Indicator
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import mui.material.TextField
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.ReactNode
import react.dom.onChange
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

external interface IndicatorTickEditorPanelProps : PropsWithChildren {
    var indicator: Indicator
    var onUpdateIndicator: (Indicator) -> Unit
}

val indicatorTickEditorPanel = FC<IndicatorTickEditorPanelProps> { props ->
    val indicators = listOf("MA", "SD")
    val currentIndicatorName = when (props.indicator) {
        is Indicator.MA -> "MA"
        is Indicator.SD -> "SD"
        is Indicator.LookBack -> TODO()
    }
    val indicator = props.indicator
    Select {
        sx {
            this.width = 100.px
        }
        size = Size.small
        value = currentIndicatorName
        onChange = { event, _ ->
            event.stopPropagation()

            val newIndicatorName = event.target.value

            val barInterval: BarSize
            val windowDataPoints: Int
            val count: Int
            when (indicator) {
                is Indicator.MA -> {
                    barInterval = indicator.barInterval
                    windowDataPoints = indicator.windowDataPoints
                    count = indicator.count
                }
                is Indicator.SD -> {
                    barInterval = indicator.barInterval
                    windowDataPoints = indicator.windowDataPoints
                    count = 1
                }
                is Indicator.LookBack -> TODO()
            }

            val newIndicator = when (newIndicatorName) {
                "MA" -> Indicator.MA(windowDataPoints, barInterval, count)
                "SD" -> Indicator.SD(windowDataPoints, barInterval)
                else -> null
            }

            if (newIndicator != null) {
                props.onUpdateIndicator(newIndicator)
            }
        }

        indicators.forEach {
            MenuItem {
                key = it
                value = it
                +it
            }
        }
    }

    when (indicator) {
        is Indicator.MA -> {
            autocomplete(
                { null },
                false,
                "barSize",
                100,
                null,
                { value ->
                    if (value != null) {
                        props.onUpdateIndicator(indicator.copy(barInterval = value))
                    }
                },
                null,
                {
                },
                BarSizeSelectOption(indicator.barInterval),
                BarSize.entries.map { BarSizeSelectOption(it) }.toTypedArray(),
                true,
            )

            TextField {
                sx {
                    this.width = 100.px
                }
                size = Size.small
                type = InputType.number
                value = indicator.windowDataPoints
                label = ReactNode("DataPoints")

                onChange = { event ->
                    event.stopPropagation()
                    val value =
                        (event.target as? HTMLInputElement)?.value?.toIntOrNull()

                    if (value != null) {
                        props.onUpdateIndicator(indicator.copy(windowDataPoints = value))
                    }
                }
            }

            TextField {
                sx {
                    this.width = 100.px
                }
                size = Size.small
                type = InputType.number
                value = indicator.count
                label = ReactNode("Count")

                onChange = { event ->
                    event.stopPropagation()
                    val value =
                        (event.target as? HTMLInputElement)?.value?.toIntOrNull()

                    if (value != null) {
                        props.onUpdateIndicator(indicator.copy(count = value))
                    }
                }
            }
        }
        is Indicator.SD -> {
            autocomplete(
                { null },
                false,
                "barSize",
                100,
                null,
                { value ->
                    if (value != null) {
                        props.onUpdateIndicator(indicator.copy(barInterval = value))
                    }
                },
                null,
                {
                },
                BarSizeSelectOption(indicator.barInterval),
                BarSize.entries.map { BarSizeSelectOption(it) }.toTypedArray(),
                true,
            )

            TextField {
                sx {
                    this.width = 100.px
                }
                size = Size.small
                type = InputType.number
                value = indicator.windowDataPoints
                label = ReactNode("DataPoints")

                onChange = { event ->
                    event.stopPropagation()
                    val value =
                        (event.target as? HTMLInputElement)?.value?.toIntOrNull()

                    if (value != null) {
                        props.onUpdateIndicator(indicator.copy(windowDataPoints = value))
                    }
                }
            }
        }
        is Indicator.LookBack -> TODO()
    }
}
