package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.MatchResult
import kotlinx.serialization.Serializable

@Serializable
data class OptionalLongRange(
    val min: Long? = null,
    val max: Long? = null,
    val enabled: Boolean = true,
) {
    fun match(value: Long): MatchResult {
        if (!enabled) {
            return MatchResult.Disabled
        }
        if (min != null && value < min) {
            return MatchResult.TooLow(value.toString(), min.toString())
        }
        if (max != null && max < value) {
            return MatchResult.TooHigh(value.toString(), max.toString())
        }
        return MatchResult.Matches
    }
}
