package com.edvorg.trade.common.model.portfolio

import kotlinx.serialization.Serializable

@Serializable
data class GroupPositionKey(
    val key: String?,
    val title: String,
    val volume: Double?,
    val discrepancyVolume: Boolean,
    val profit: Double?,
    val profitPercent: Double?,
    val averageSpread: Double?,
)
