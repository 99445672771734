package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
enum class BinanceFutureMarket(val value: String) {
    COIN("COIN"),
    USDT("USDT"),
    ;
    companion object {
        private val nameToBinanceFutureMarket = entries.associateBy {
            it.name
        }

        fun fromName(name: String): BinanceFutureMarket? {
            return nameToBinanceFutureMarket[name]
        }
    }
}
