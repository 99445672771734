package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class ConnectorStatus(
    val total: Int,
    val connecting: Int = 0,
    val connected: Int = 0,
    val failed: Int = 0,
    val disconnecting: Int = 0,
) {
    companion object {
        val Connecting = ConnectorStatus(total = 1, connecting = 1)
        val Connected = ConnectorStatus(total = 1, connected = 1)
        val Failed = ConnectorStatus(total = 1, failed = 1)
        val Disconnecting = ConnectorStatus(total = 1, disconnecting = 1)
        val Disconnected = ConnectorStatus(total = 1, connected = 0)
    }

    init {
        if (total < 1) {
            throw Error("total connections can't be less than [1], but [$total] provided")
        }
    }

    fun isPartiallyConnected() = connected != 0

    fun isDisconnecting() = disconnecting != 0

    fun isFailed() = failed != 0

    fun isConnecting() = connecting != 0

    fun isDisconnected() = connected == 0

    fun isConnected() = connected == total
}
