package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.AlpacaDataApiType
import com.edvorg.trade.common.model.AlpacaEndPoint
import com.edvorg.trade.common.model.BinanceFutureMarket
import com.edvorg.trade.common.model.BrokerClientConfig
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.CurrencyPair
import com.edvorg.trade.common.model.ExanteLevel2Mode
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.MarketDataClientConfig
import com.edvorg.trade.common.model.PostCloseMethod
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.TickerPricePrefill
import com.edvorg.trade.common.model.TickerType
import com.edvorg.trade.common.model.TypedTicker
import io.ktor.http.Url
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.milliseconds
import kotlin.time.Duration.Companion.minutes
import kotlin.time.Duration.Companion.seconds

val interactiveBrokersDefaultExchanges = setOf(
    Exchange.NASDAQ,
    Exchange.NYSE,
    Exchange.AMEX,
    Exchange.FWB,
    Exchange.FWB2,
    Exchange.SWB,
    Exchange.SWB2,
    Exchange.TGATE,
    Exchange.SEHK,
    Exchange.IBIS,
    Exchange.TSEJ,
    Exchange.LSE,
    Exchange.MEXI,
)

val interactiveBrokersDefaultExchangeCurrencies = mapOf(
    Exchange.NASDAQ to Currency.USD,
    Exchange.NYSE to Currency.USD,
    Exchange.AMEX to Currency.USD,
    Exchange.FWB to Currency.EUR,
    Exchange.FWB2 to Currency.EUR,
    Exchange.SWB to Currency.EUR,
    Exchange.SWB2 to Currency.EUR,
    Exchange.TGATE to Currency.EUR,
    Exchange.SEHK to Currency.HKD,
    Exchange.IBIS to Currency.EUR,
    Exchange.TSEJ to Currency.JPY,
    Exchange.LSE to Currency.GBP,
    Exchange.MEXI to Currency.MXN,
)

val interactiveBrokersDefaultCurrencyPairs = listOf(
    CurrencyPair(Currency.GBP, Currency.USD),
    CurrencyPair(Currency.EUR, Currency.USD),
    CurrencyPair(Currency.USD, Currency.RUB),
    CurrencyPair(Currency.USD, Currency.HKD),
    CurrencyPair(Currency.USD, Currency.JPY),
    CurrencyPair(Currency.USD, Currency.CNH),
    CurrencyPair(Currency.USD, Currency.MXN),
)

val interactiveBrokersDefaultDirectlyRoutedExchanges = setOf(
    Exchange.FWB,
    Exchange.FWB2,
    Exchange.SWB,
    Exchange.SWB2,
    Exchange.TGATE,
    Exchange.GETTEX,
    Exchange.GETTEX2,
    Exchange.IBIS,
    Exchange.IBIS2,
)

val brokerClientOptions = mapOf(
    "Interactive Brokers" to BrokerClientConfig.InteractiveBrokers(
        0,
        false,
        interactiveBrokersDefaultExchanges,
        0.025,
        7496,
        interactiveBrokersDefaultExchangeCurrencies,
        interactiveBrokersDefaultCurrencyPairs,
        interactiveBrokersDefaultDirectlyRoutedExchanges,
        50,
        1.seconds,
        2,
        5.seconds,
        correctPriceByStep = false,
        skipEmptyQuote = false,
        genericTicks = setOf(),
        caching = true,
        errorCodesPush = setOf(101),
    ),
    "Tinkoff" to BrokerClientConfig.Tinkoff(
        0,
        false,
        setOf(Exchange.SPBEX),
        0.0,
        false,
        "issue token at https://www.tinkoff.ru/invest",
        PollConfig(
            500.milliseconds,
            2500.milliseconds,
            true,
        ),
        PollConfig(
            500.milliseconds,
            2500.milliseconds,
            true,
        ),
        PollConfig(
            2000.milliseconds,
            2500.milliseconds,
            true,
        ),
    ),
    "Tinkoff (Sandbox)" to BrokerClientConfig.Tinkoff(
        0,
        false,
        setOf(Exchange.SPBEX),
        0.0,
        true,
        "t.tv6xI_lHvQbFOHN8ourURdwZ0KhqvUBv-gAr2hNKzstNxdPDIy6CyXQMU3ceEo_BOr818sV-JpksLZBQQJcpMg",
        PollConfig(
            500.milliseconds,
            2500.milliseconds,
            true,
        ),
        PollConfig(
            500.milliseconds,
            2500.milliseconds,
            true,
        ),
        PollConfig(
            2000.milliseconds,
            2500.milliseconds,
            true,
        ),
    ),
    "Relay" to BrokerClientConfig.Relay(
        0,
        false,
        emptySet(),
        0.025,
        Url("http://localhost:7000"),
        10.seconds,
    ),
    "Alor" to BrokerClientConfig.Alor(
        0,
        false,
        setOf(Exchange.SPBEX),
        0.0025,
        false,
        "",
        "",
        PollConfig(
            2000.milliseconds,
            2500.milliseconds,
            true,
        ),
        false,
        "7",
        PollConfig(
            300.milliseconds,
            3.seconds,
            true,
        ),
        subscribeOperations = true,
        subscribePortfolioSummary = true,
        subscribeRisk = true,
        subscribeStopOrders = true,
    ),
    "Simulator" to BrokerClientConfig.Simulator(
        0,
        false,
        emptySet(),
        0.0,
        1000.0,
        emptyMap(),
        2.seconds,
        null,
        true,
    ),
    "Exante" to BrokerClientConfig.Exante(
        0,
        false,
        setOf(Exchange.NASDAQ),
        0.0,
        "account id",
        "issue token at https://exante.eu/ru/clientsarea/dashboard",
        PollConfig(
            500.milliseconds,
            2500.milliseconds,
            true,
        ),
        PollConfig(
            500.milliseconds,
            2500.milliseconds,
            true,
        ),
        false,
    ),
    "Finam" to BrokerClientConfig.Finam(
        0,
        false,
        emptySet(),
        0.0,
        "TOKEN",
        "CLIETNID",
        10.seconds,
        3.minutes,
    ),
    "Binance Futures" to BrokerClientConfig.FutureBinance(
        0,
        false,
        emptySet(),
        0.0,
        "API_KEY",
        "SECRET_KEY",
        BinanceFutureMarket.USDT,
    ),
    "Mexc" to BrokerClientConfig.Mexc(
        0,
        false,
        setOf(Exchange.MEXC),
        0.0,
        "ACCESS_KEY",
        "SECRET",
        10.minutes,
    ),
    "MexcFut" to BrokerClientConfig.MexcFut(
        0,
        false,
        emptySet(),
        0.0,
        "ACCESS_KEY",
        "SECRET",
    ),
)
val marketDataClientOptions = mapOf(
    "Relay" to MarketDataClientConfig.Relay(
        0,
        false,
        setOf(
            Exchange.SPBEX,
            Exchange.MOEX,
        ),
        emptySet(),
        emptySet(),
        emptySet(),
        BrokerId(""),
    ),
    "InteractiveBrokers" to MarketDataClientConfig.InteractiveBrokers(
        0,
        false,
        interactiveBrokersDefaultExchanges,
        setOf(
            Exchange.NYMEX,
            Exchange.GLOBEX,
        ),
        emptySet(),
        emptySet(),
        BrokerId(""),
    ),
    "Alor" to MarketDataClientConfig.Alor(
        0,
        false,
        setOf(Exchange.SPBEX),
        emptySet(),
        emptySet(),
        setOf(Exchange.SPBEX),
        false,
        "8ce7278d-ef9d-4c79-9e79-b3035a15e33e",
        2,
        1,
        1,
        1,
        1000,
        50,
        1.days,
        1.days,
        30.days,
        365.days,
        0,
        20,
        PollConfig(
            2000.milliseconds,
            5000.milliseconds,
            true,
        ),
        500.milliseconds,
        true,
    ),
    "Remote" to MarketDataClientConfig.Remote(
        0,
        false,
        setOf(Exchange.NASDAQ),
        emptySet(),
        setOf(Exchange.NASDAQ),
        emptySet(),
        10.seconds,
        Url("wss://test.com/ws"),
        1.0,
        false,
        false,
    ),
    "IQFeed" to MarketDataClientConfig.IQFeed(
        0,
        false,
        setOf(Exchange.NASDAQ),
        emptySet(),
        setOf(Exchange.NASDAQ),
        emptySet(),
        "6.1",
        5009,
        9100,
        90,
        4,
        1000,
        5,
    ),
    "Exante" to MarketDataClientConfig.Exante(
        0,
        false,
        setOf(Exchange.NASDAQ),
        emptySet(),
        emptySet(),
        emptySet(),
        "issue application id at https://exante.eu/ru/clientsarea/dashboard",
        "issue access key id at https://exante.eu/ru/clientsarea/dashboard",
        false,
        10,
        100000,
        10,
        ExanteLevel2Mode.BEST_PRICE,
    ),
    "Sk" to MarketDataClientConfig.Sk(
        0,
        false,
        setOf(
            Exchange.TGATE,
            Exchange.GETTEX,
        ),
        emptySet(),
        emptySet(),
        emptySet(),
        2,
        2,
        3000,
    ),
    "TradingView" to MarketDataClientConfig.TradingView(
        0,
        false,
        emptySet(),
        emptySet(),
        emptySet(),
        emptySet(),
        "auth token",
        1,
        2,
        3000,
    ),
    "Nasdaq" to MarketDataClientConfig.Nasdaq(
        0,
        false,
        emptySet(),
        emptySet(),
        setOf(Exchange.NASDAQ),
        emptySet(),
    ),
    "Alpaca" to MarketDataClientConfig.Alpaca(
        0,
        false,
        setOf(Exchange.NYSE),
        emptySet(),
        emptySet(),
        emptySet(),
        "key id",
        "secret key",
        AlpacaEndPoint.LIVE,
        AlpacaDataApiType.SIP,
        1,
        100000,
        0.seconds,
        tradeGroupRequests = false,
        withoutLive = false,
        postCloseMethod = PostCloseMethod.VWAP,
        wildcardSubscription = false,
        orderBookUpdatePeriod = 0.seconds,
        true,
    ),
    "OrderBooksSimulation" to MarketDataClientConfig.OrderBooksSimulation(
        0,
        false,
        setOf(Exchange.SPBEX),
        emptySet(),
        emptySet(),
        emptySet(),
        BrokerId(""),
        mapOf(
            TypedTicker(TickerType.STOCK, Exchange.SPBEX, Ticker("TSLA")) to
                TickerPricePrefill.MidPointPrefill(100.0),
        ),
        null,
        null,
    ),
    "TelegramNews" to MarketDataClientConfig.TelegramNews(
        0,
        false,
        emptySet(),
        emptySet(),
        emptySet(),
        emptySet(),
        "66628211604",
    ),
    "Okx" to MarketDataClientConfig.Okx(
        0,
        false,
        setOf(Exchange.OKX),
        setOf(Exchange.OKX),
        emptySet(),
        emptySet(),
    ),
    "Mexc" to MarketDataClientConfig.Mexc(
        0,
        false,
        setOf(Exchange.MEXC),
        emptySet(),
        emptySet(),
        emptySet(),
        1.seconds,
        5,
        5,
        5.seconds,
    ),
    "CSV" to MarketDataClientConfig.Csv(
        0,
        false,
        setOf(Exchange.BLUE),
        setOf(Exchange.BLUE),
        emptySet(),
        emptySet(),
        "blue.csv",
    ),
    "Gateio" to MarketDataClientConfig.GateIO(
        0,
        false,
        setOf(Exchange.GATEIO),
        emptySet(),
        emptySet(),
        emptySet(),
        5,
        5,
    ),
    "Freedom" to MarketDataClientConfig.Freedom(
        0,
        false,
        setOf(Exchange.FREEDOM),
        emptySet(),
        emptySet(),
        emptySet(),
        "",
        "",
        "",
        5,
        100,
    ),
    "RobinHood" to MarketDataClientConfig.RobinHood(
        0,
        false,
        setOf(Exchange.ROBINHOOD),
        emptySet(),
        emptySet(),
        emptySet(),
        "",
        "",
        PollConfig(
            2.seconds,
            5.seconds,
            true,
        ),
        60,
        2.hours,
    ),
    "Binance" to MarketDataClientConfig.Binance(
        0,
        false,
        setOf(Exchange.BINANCE),
        emptySet(),
        emptySet(),
        emptySet(),
        5,
        5,
    ),
    "BitPanda" to MarketDataClientConfig.Bitpanda(
        0,
        false,
        setOf(Exchange.BITPANDA),
        emptySet(),
        emptySet(),
        emptySet(),
        setOf("stocks"), // , "etfs", "cryptocoins", "indexes"),
        setOf("stock"),
        PollConfig(
            10.seconds,
            10.seconds,
            true,
        ),
        5,
        10000,
    ),
    "StreetInsider" to MarketDataClientConfig.StreetInsider(
        0,
        false,
        emptySet(),
        emptySet(),
        emptySet(),
        emptySet(),
        PollConfig(
            10.seconds,
            5.seconds,
            true,
        ),
    ),
    "DxFeed" to MarketDataClientConfig.DxFeed(
        0,
        false,
        setOf(Exchange.NASDAQ),
        emptySet(),
        emptySet(),
        emptySet(),
        "demo.dxfeed.com:7300",
        1,
        5,
        false,
        1,
        ":OCEA",
        1.hours,
    ),
    "Iress" to MarketDataClientConfig.Iress(
        0,
        false,
        setOf(Exchange.SEHK),
        setOf(),
        setOf(),
        setOf(),
        "",
        "",
        "",
        6051,
        20,
        0,
        mapOf(
            Exchange.OVERNIGHT to "OCEA",
            Exchange.TGATE to "TGAT",
            Exchange.TGATE_SK to "TGAT",
        ),
        false,
        false,
        false,
        setOf(Exchange.TGATE),
        setOf(Exchange.TGATE),
        mapOf(),
        false,
        10.seconds,
    ),
    "MexcFut" to MarketDataClientConfig.MexcFut(
        0,
        false,
        setOf(Exchange.MEXC_FUT),
        emptySet(),
        emptySet(),
        emptySet(),
        5,
        5,
        20,
        5.seconds,
    ),
    "BinanceFut" to MarketDataClientConfig.BinanceFut(
        0,
        false,
        setOf(Exchange.BINANCE_FUT),
        emptySet(),
        emptySet(),
        emptySet(),
        BinanceFutureMarket.USDT,
    ),
    "MooMoo" to MarketDataClientConfig.MooMoo(
        0,
        false,
        setOf(),
        setOf(),
        setOf(),
        setOf(),
        PollConfig(
            10.seconds,
            5.seconds,
            true,
        ),
        50,
    ),
)
