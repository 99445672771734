package com.edvorg.trade.common.frontend.services.toast

import react.ReactNode

interface AbstractToaster {
    fun info(
        message: String,
        autoCloseMs: Int,
        position: ToastPosition = ToastPosition.DEFAULT,
        icon: ReactNode? = null,
    )

    fun error(
        message: String,
        autoCloseMs: Int,
        position: ToastPosition = ToastPosition.DEFAULT,
    )
}
