package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class ExchangePair(
    val exchange1: Exchange,
    val exchange2: Exchange,
) {
    fun reversed(): ExchangePair {
        return ExchangePair(exchange2, exchange1)
    }
}
