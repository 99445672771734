package com.edvorg.trade.backend.frontend.views.widgets.chartscript

import com.edvorg.trade.backend.frontend.FrontendContext
import com.edvorg.trade.common.charts.Chart
import com.edvorg.trade.common.charts.ChartOptions
import com.edvorg.trade.common.charts.LineData
import com.edvorg.trade.common.charts.LineSeries
import com.edvorg.trade.common.charts.LineStyleOptions
import com.edvorg.trade.common.charts.PriceFormat
import com.edvorg.trade.common.charts.PriceScaleOptions
import com.edvorg.trade.common.charts.TimeScaleOptions
import com.edvorg.trade.common.charts.lightWeightCharts
import com.edvorg.trade.common.frontend.services.getContext
import com.edvorg.trade.common.frontend.views.StringOption
import com.edvorg.trade.common.frontend.views.WidgetProps
import com.edvorg.trade.common.frontend.views.autocomplete
import com.edvorg.trade.common.frontend.views.widget
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.WidgetSettings
import com.edvorg.trade.common.model.chartscript.ChartScriptConfig
import com.edvorg.trade.common.model.chartscript.DataField
import com.edvorg.trade.common.model.chartscript.ScriptId
import com.edvorg.trade.common.utils.format
import js.core.jso
import mui.icons.material.PlayArrow
import mui.icons.material.Settings
import mui.icons.material.Stop
import mui.material.Button
import mui.material.ButtonGroup
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableRow
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.ReactNode
import react.dom.aria.ariaReadOnly
import react.dom.html.ReactHTML.div
import react.useEffect
import react.useState
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.Visibility
import web.cssom.pct
import web.cssom.px
import web.dom.document
import kotlin.math.max
import kotlin.math.pow

external interface ChartScriptWidgetProps : WidgetProps<WidgetSettings.ChartScript>

private const val heightOffset = -25 - 22.5
private const val widthOffset = -5

val chartScriptWidget = widget<ChartScriptWidgetProps, WidgetSettings.ChartScript>(
    { "Chart Script" },
    isStretchable = true,
    isPair = false,
) { props, showSettings, _, _ ->

    val backendMainClient = getContext<FrontendContext>().backendMainClient

    var chart by useState<Chart?>(null)

    val idStr = "chartscript_${props.id}"

    val (dataFields, setDataFields) = useState<Map<String, DataField>>(mapOf())

    var allChartScripts by useState<Set<ScriptId>>(setOf())
    var scriptConfig by useState<Triple<ChartScriptConfig, ConnectorStatus, String?>?>(null)
    var scriptShowSettings by useState(false)

    useEffect {
        val handle = backendMainClient.subscribeChartScriptIds {
            allChartScripts = it.scriptIds
        }

        cleanup {
            handle.unsubscribe()
        }
    }

    useEffect(props.settings.scriptId) {
        document.getElementById(idStr)?.let { chartContainer ->
            while (true) {
                val firstChild = chartContainer.firstChild ?: break
                chartContainer.removeChild(firstChild)
            }
        }

        val scriptId = props.settings.scriptId
        if (scriptId == null) {
            return@useEffect
        }

        val series = mutableMapOf<Int, LineSeries>()

        val options = ChartOptions(
            autoSize = false,
            width = props.width + widthOffset,
            height = max((props.height + heightOffset - 100), 10.0),
            timeScale = TimeScaleOptions(
                timeVisible = true,
            ),
            leftPriceScale = PriceScaleOptions(
                visible = true,
            ),
            rightPriceScale = PriceScaleOptions(
                visible = true,
            ),
        )

        val newChart = lightWeightCharts().createChart(
            idStr,
            options,
        )

        chart = newChart

        val handle = backendMainClient.subscribeToChartScriptPlot(scriptId) { plotData ->
            val pints = plotData.chartScriptData.plots.entries.firstOrNull()?.value?.data?.size
            console.log("Got $pints points")
            if (plotData.chartScriptData.flush) {
                series.values.forEach { lineSeries ->
                    newChart.removeSeries(lineSeries)
                }
                series.clear()
                setDataFields {
                    mapOf()
                }
            }
            plotData.chartScriptData.plots.forEach { (plotIndex, plot) ->
                val lineSeries = series[plotIndex]
                if (lineSeries == null) {
                    val newLineSeries = newChart.addLineSeries(
                        LineStyleOptions(
                            color = plot.color,
                            lineWidth = 2.0,
                            plot.name,
                            priceFormat = PriceFormat(
                                "price",
                                props.settings.roundingScale.toDouble(),
                                10.0.pow(-props.settings.roundingScale),
                            ),
                        ),
                    )
                    series[plotIndex] = newLineSeries
                    newLineSeries.setData(
                        plot.data.map {
                            LineData(it.time, it.value)
                        }.toTypedArray(),
                    )
                } else {
                    plot.data.map {
                        LineData(it.time, it.value)
                    }.forEach { lineData ->
                        lineSeries.update(lineData)
                    }
                }
            }

            setDataFields {
                if (it != plotData.chartScriptData.dataFields) {
                    plotData.chartScriptData.dataFields
                } else {
                    it
                }
            }
        }

        val handle2 = backendMainClient.subscribeToChartScriptConfig(scriptId) {
            scriptConfig = it.scriptState
        }

        cleanup {
            handle.unsubscribe()
            handle2.unsubscribe()
        }
    }

    useEffect(props.width, props.height) {
        chart?.resize(
            props.width + widthOffset,
            max((props.height + heightOffset - 100), 10.0),
        )
    }

    div {
        if (showSettings) {
            chartScriptSettingsPanel {
                this.selectedScriptId = props.settings.scriptId
                this.onSelectScriptId = { newScriptId ->
                    props.onUpdateSettings.invoke {
                        props.settings.copy(scriptId = newScriptId)
                    }
                }
                this.roundingScale = props.settings.roundingScale
                this.setRoundingScale = { newRoundingScale ->
                    props.onUpdateSettings.invoke {
                        props.settings.copy(roundingScale = newRoundingScale)
                    }
                }
            }
        }
    }

    div {
        style = jso {
            visibility = if (showSettings) Visibility.hidden else Visibility.visible
            width = 100.pct
        }

        Stack {
            sx {
                margin = 10.px
            }
            direction = responsive(StackDirection.row)
            spacing = responsive(10.px)

            autocomplete(
                { null },
                false,
                "current script",
                250,
                null,
                { newScriptIdStr ->
                    val newScriptId = newScriptIdStr?.let { ScriptId(it) }
                    props.onUpdateSettings.invoke {
                        props.settings.copy(scriptId = newScriptId)
                    }
                },
                null,
                {},
                props.settings.scriptId?.let {
                    StringOption(it.id)
                },
                allChartScripts.map { StringOption(it.id) }.toTypedArray(),
                false,
            )

            val scriptId = props.settings.scriptId
            if (scriptConfig != null && scriptId != null) {
                val (_, status, lastError) = scriptConfig ?: return@Stack

                ButtonGroup {
                    size = Size.small
                    when {
                        status.isDisconnected() -> {
                            Button {
                                title = "Start"
                                size = Size.small
                                className = ClassName("normal")
                                onClick = {
                                    it.stopPropagation()
                                    backendMainClient.startChartScript(scriptId)
                                }
                                PlayArrow {
                                }
                            }
                        }

                        else -> {
                            Button {
                                title = "Stop"
                                size = Size.small
                                className = ClassName("normal")
                                onClick = {
                                    it.stopPropagation()
                                    backendMainClient.stopChartScript(scriptId)
                                }
                                Stop {
                                }
                            }
                        }
                    }

                    Button {
                        size = Size.small
                        className = ClassName("normal")
                        onClick = {
                            it.stopPropagation()
                            scriptShowSettings = !scriptShowSettings
                        }
                        Settings {
                        }
                    }
                }

                div {
                    +(lastError ?: "")
                }
            }
        }

        div {
            val config = scriptConfig?.first
            val scriptId = props.settings.scriptId
            if (scriptShowSettings && config != null && scriptId != null) {
                chartScriptEditorPanel {
                    this.chartScript = config
                    this.setChartScript = { newConfig ->
                        backendMainClient.updateChartScript(scriptId, newConfig)
                    }
                    this.collapsableScriptText = true
                }
            }
        }
    }

    div {
        style = jso {
            visibility = if (showSettings) Visibility.hidden else Visibility.visible
        }
        id = idStr
    }

    div {
        Table {
            sx {
                maxHeight = 70.px
                width = 100.pct
            }

            TableBody {
                TableRow {
                    dataFields.forEach { (dataFieldName, dataField) ->
                        TableCell {
                            key = dataFieldName
                            align = TableCellAlign.center
                            size = Size.small
                            TextField {
                                sx {
                                    val dataColor = dataField.color
                                    if (dataColor != null) {
                                        backgroundColor = Color(dataColor)
                                    }
                                }
                                this.ariaReadOnly = true
                                this.label = ReactNode(dataFieldName)
                                this.value = dataField.value.format(props.settings.roundingScale)
                            }
                        }
                    }
                }
            }
        }
    }
}
