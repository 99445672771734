package com.edvorg.trade.common.model.chartscript

import com.edvorg.trade.common.model.DataPoint
import kotlinx.serialization.Serializable

@Serializable
data class PlotData(
    val name: String,
    val color: String,
    val data: List<DataPoint>,
)
