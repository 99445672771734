package com.edvorg.trade.common.model.groupwatchlist

import com.edvorg.trade.common.model.stats.ImmutableTickerStats
import kotlinx.serialization.Serializable

@Serializable
data class GroupTickerData(
    val stats: ImmutableTickerStats,
    val ask: Double?,
    val askSize: Double?,
    val askDiff: Double?,
    val bid: Double?,
    val bidSize: Double?,
    val bidDiff: Double?,
    val statsDiff: ImmutableTickerStats,
    val correlation: Double?,
    val industry: Int?,
    val sector: Int?,
    val volume: Double?,
)
