package com.edvorg.trade.backend.frontend.services

import com.edvorg.trade.common.client.WebSocketClient
import com.edvorg.trade.common.frontend.services.ConnectionManagement
import com.edvorg.trade.common.frontend.services.ConnectionManager
import com.edvorg.trade.common.model.ConnectionManagementRequest
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Connectors
import com.edvorg.trade.common.model.MarketDataControlRequest
import com.edvorg.trade.common.model.MarketDataControlResponse
import com.edvorg.trade.common.serialization.Defaults
import io.ktor.http.Url
import io.ktor.websocket.Frame
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.coroutines.launch
import mu.KotlinLogging

class RemoteMarketDataControlClient(
    url: Url,
) : WebSocketClient("MarketDataControlClient($url)", url, false, null, 5, null), ConnectionManagement {
    companion object {
        private val logger = KotlinLogging.logger { }
    }

    private val scope = CoroutineScope(Dispatchers.Default)
    override val connectionManager = ConnectionManager("MarketDataControlClient", this)

    private val updateConnectorsMutableFlow = MutableSharedFlow<Connectors>(0, 10000)
    override val updateConnectorsFlow = updateConnectorsMutableFlow.asSharedFlow()

    override suspend fun onConnect() {
    }

    override fun sendConnectorUpdate(message: ConnectionManagementRequest) {
        send(MarketDataControlRequest.UpdateConnectors(message))
    }

    override fun onDisconnect() {
    }

    override fun processFrame(frame: Frame) {
        val m = ResponseDecoders.decodeMarketDataControlResponse(frame) ?: return

        scope.launch {
            when (m) {
                is MarketDataControlResponse.ConnectorsUpdate -> {
                    if (!updateConnectorsMutableFlow.tryEmit(m.connectors)) {
                        logger.info { "Unable emit connector configs update $m" }
                    }
                }
            }
        }
    }

    private fun send(message: MarketDataControlRequest) {
        send(
            Frame.Binary(
                true,
                Defaults.cbor.encodeToByteArray(MarketDataControlRequest.serializer(), message),
            ),
        )
    }

    override suspend fun connect(selectedServer: String) {
    }

    override suspend fun start(onStarted: (ConnectorStatus) -> Unit) {
        startConnection(
            {},
            onStarted,
        )
    }
}
