package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class CurrencyPosition(
    val currentPos: Double,
    val status: String,
    val portfolio: Double,
    val initialMargin: Double,
    val minimalMargin: Double,
    val free: Double,
)
