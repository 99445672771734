package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class CalculatedScannerEntry(
    val operation1: OperationType,
    val tickerPair: TickerPair,
    val formula1: PriceFormulaComponets,
    val bid1: OrderBookEntryPair?,
    val ask1: OrderBookEntryPair?,
    val formula2: PriceFormulaComponets,
    val bid2: OrderBookEntryPair?,
    val ask2: OrderBookEntryPair?,
    val priceDifferencePercent: Double,
    val futurePriceDifferencePercent: Double,
    val availableLots1: Double,
    val potentialProfit: Double,
    val potentialSpreadProfit: Double,
    val time: Long, // in milliseconds
    val ticker1ExtraInfo: TickerExtraInfo?,
    val ticker2ExtraInfo: TickerExtraInfo?,
    val shortableTick1: Double?,
    val shortableTick2: Double?,
    val averageSpread: Double?,
) {
    fun scannerEntry(): ScannerEntry? {
        return when (operation1) {
            OperationType.BUY -> {
                if (ask1 != null && bid2 != null) {
                    ScannerEntry.LongEntry(
                        tickerPair,
                        formula1,
                        bid1,
                        ask1,
                        formula2,
                        bid2,
                        ask2,
                        time,
                        priceDifferencePercent,
                        null,
                        null,
                        null,
                    )
                } else {
                    null
                }
            }
            OperationType.SELL -> {
                if (bid1 != null && ask2 != null) {
                    ScannerEntry.ShortEntry(
                        tickerPair,
                        formula1,
                        bid1,
                        ask1,
                        formula2,
                        bid2,
                        ask2,
                        time,
                        priceDifferencePercent,
                        null,
                        null,
                        null,
                    )
                } else {
                    null
                }
            }
        }
    }
}
