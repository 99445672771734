@file:UseSerializers(UrlSerializer::class)

package com.edvorg.trade.common.model

import com.edvorg.trade.common.model.chartscript.ChartScriptConfig
import com.edvorg.trade.common.model.chartscript.ChartScriptPlotData
import com.edvorg.trade.common.model.chartscript.ScriptId
import com.edvorg.trade.common.model.config.GuiConfig
import com.edvorg.trade.common.model.config.GuiConfigChange
import com.edvorg.trade.common.model.config.ScheduledDealsConfig
import com.edvorg.trade.common.model.groupwatchlist.GroupWatchlistSnapshot
import com.edvorg.trade.common.model.market2.Market2PreparedOrdersCache
import com.edvorg.trade.common.model.portfolio.GroupedPositions
import com.edvorg.trade.common.utils.BuildInfo
import io.ktor.http.Url
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

@Serializable
sealed class BackendResponse {
    @Serializable
    @SerialName("ConnectorsUpdate")
    data class ConnectorsUpdate(
        val connectors: Connectors,
    ) : BackendResponse()

    @Serializable
    @SerialName("DealsUpdate")
    data class DealsUpdate(
        val subscriptionIds: Set<Long>,
        val deals: Deals,
    ) : BackendResponse()

    @Serializable
    @SerialName("LogDealsUpdate")
    data class LogDealsUpdate(
        val subscriptionIds: Set<Long>,
        val entries: List<CalculatedScannerEntry>,
    ) : BackendResponse()

    @Serializable
    data class CurrencyPositionsUpdate(
        val subscriptionIds: Set<Long>,
        val currencyPositions: Map<BrokerId, Map<Pair<Exchange, Currency>, CurrencyPosition>>,
    ) : BackendResponse()

    @Serializable
    @SerialName("ExcludedTickersUpdate")
    data class ExcludedTickersUpdate(
        val tickers: Map<ScannerId, Map<TypedTicker, Long>>,
    ) : BackendResponse()

    @Serializable
    @SerialName("HiddenTickersUpdate")
    data class HiddenTickersUpdate(
        val tickers: Map<ScannerId, Map<TypedTicker, Long>>,
    ) : BackendResponse()

    @Serializable
    @SerialName("OperationsUpdate")
    data class PaginatedOperationsUpdate(
        val operations: List<Operation>,
        val continuation: String?,
        val requestId: String,
    ) : BackendResponse()

    @Serializable
    @SerialName("NewOperationsUpdate")
    data class NewOperationsUpdate(
        val subscriptionIds: Set<Long>,
        val operations: List<Operation>,
    ) : BackendResponse()

    @Serializable
    @SerialName("OrderBookUpdate")
    data class OrderBookUpdate(
        val subscriptionIds: Set<Long>,
        val orderBook: OrderBook?,
    ) : BackendResponse()

    @Serializable
    @SerialName("ConfigUpdate")
    data class ConfigUpdate(
        val masterExchange: Exchange,
        val maxTradesStorage: Int,
        val buildInfo: BuildInfo,
    ) : BackendResponse()

    @Serializable
    @SerialName("GuiConfigUpdate")
    data class GuiConfigUpdate(
        val userSpaceName: String,
        val guiConfig: GuiConfig,
    ) : BackendResponse()

    @Serializable
    @SerialName("GuiConfigChanges")
    data class GuiConfigChangeUpdate(
        val change: GuiConfigChange,
    ) : BackendResponse()

    @Serializable
    @SerialName("Su")
    data class SuggestExchangesUpdate(
        val tickers: Map<Exchange, Set<Ticker>>,
    ) : BackendResponse()

    @Serializable
    @SerialName("TradesUpdate")
    data class TradesUpdate(
        val subscriptionIds: Set<Long>,
        val trades: List<Trade>,
        val snapshot: Boolean,
    ) : BackendResponse()

    @Serializable
    @SerialName("NewsUpdate")
    data class NewsUpdate(
        val subscriptionIds: Set<Long>,
        val news: List<ParsedTickerNews>,
        val snapshot: Boolean,
    ) : BackendResponse()

    @Serializable
    @SerialName("ChannelsUpdate")
    data class ChannelsUpdate(
        val subscriptionIds: Set<Long>,
        val channels: List<NewsChannel>,
    ) : BackendResponse()

    @Serializable
    @SerialName("CandlesUpdate")
    data class CandlesUpdate(
        val subscriptionIds: Set<Long>,
        val candles: List<Candle>,
    ) : BackendResponse()

    @Serializable
    @SerialName("ClosePriceUpdate")
    data class ClosePriceUpdate(
        val subscriptionIds: Set<Long>,
        val closePrice: MidPoint,
    ) : BackendResponse()

    @Serializable
    @SerialName("MidPointUpdate")
    data class MidPointUpdate(
        val subscriptionIds: Set<Long>,
        val midPoint: MidPoint,
    ) : BackendResponse()

    @Serializable
    @SerialName("CurrencyRateUpdate")
    data class CurrencyRateUpdate(
        val subscriptionIds: Set<Long>,
        val rate: Double,
    ) : BackendResponse()

    @Serializable
    @SerialName("ScannerOpenOrderPlacedUpdate")
    data object ScannerOpenOrderPlacedUpdate : BackendResponse()

    @Serializable
    @SerialName("ScannerOpenPositionUpdate")
    data class ScannerOpenPositionUpdate(
        val ticker: TypedTicker,
        val groupId: Group?,
        val widgetId: String?,
    ) : BackendResponse()

    @Serializable
    @SerialName("OnError")
    data class OnError(val message: String?) : BackendResponse()

    @Serializable
    @SerialName("ScannerServers")
    data class ScannerServersUpdate(
        val scannerServers: Map<String, Url>,
    ) : BackendResponse()

    @Serializable
    @SerialName("RequestConfirmationCode")
    data class RequestConfirmationCode(
        val phoneNumber: String,
    ) : BackendResponse()

    @Serializable
    @SerialName("AutoTakeUpdate")
    data class AutoTakeUpdate(
        val subscriptionIds: Set<Long>,
        val autoTake: Boolean,
    ) : BackendResponse()

    @Serializable
    @SerialName("TradingStatusUpdate")
    data class TradingStatusUpdate(
        val subscriptionIds: Set<Long>,
        val tradingStatus: TradingStatus,
    ) : BackendResponse()

    @Serializable
    @SerialName("IndicatorUpdate")
    data class IndicatorUpdate(
        val subscriptionIds: Set<Long>,
        val value: Double,
    ) : BackendResponse()

    @Serializable
    @SerialName("UserSpaces")
    data class UserSpaces(
        val userSpaces: List<String>,
    ) : BackendResponse()

    @Serializable
    data class GroupWatchlistUpdate(
        val subscriptionIds: Set<Long>,
        val groupWatchlistSnapshot: GroupWatchlistSnapshot,
    ) : BackendResponse()

    @Serializable
    @SerialName("TradingUpdate")
    data class TradingUpdate(
        val response: TradingResponse,
    ) : BackendResponse()

    @Serializable
    @SerialName("AllSectorsAndIndustriesUpdate")
    data class AllSectorsAndIndustriesUpdate(
        val sectors: Map<Int, String>,
        val industries: Map<Int, String>,
        val subtypes: Map<Int, String>,
    ) : BackendResponse()

    @Serializable
    @SerialName("ExchangesOne")
    data class ExchangesOneCurrencyUpdate(
        val exchanges: Map<Exchange, Currency>,
    ) : BackendResponse()

    @Serializable
    @SerialName("ChartScriptDataUpdate")
    data class ChartScriptPlotUpdate(
        val subscriptionIds: Set<Long>,
        val chartScriptData: ChartScriptPlotData,
    ) : BackendResponse()

    @Serializable
    @SerialName("ChartScripts")
    data class ChartScripts(
        val subscriptionIds: Set<Long>,
        val scripts: Map<ScriptId, Triple<ChartScriptConfig, ConnectorStatus, String?>>,
    ) : BackendResponse()

    @Serializable
    @SerialName("ChartScriptConfig")
    data class ChartScriptConfigUpdate(
        val subscriptionIds: Set<Long>,
        val scriptState: Triple<ChartScriptConfig, ConnectorStatus, String?>,
    ) : BackendResponse()

    @Serializable
    @SerialName("ChartScriptIds")
    data class ChartScriptIds(
        val subscriptionIds: Set<Long>,
        val scriptIds: Set<ScriptId>,
    ) : BackendResponse()

    @Serializable
    @SerialName("ToasterMessage")
    data class ToasterMessage(
        val messageType: ToasterMessageType,
        val message: String,
    ) : BackendResponse()

    @Serializable
    @SerialName("GroupedPositionsUpdate")
    data class GroupedPositionsUpdate(
        val subscriptionIds: Set<Long>,
        val positions: GroupedPositions,
    ) : BackendResponse()

    @Serializable
    @SerialName("ScheduledDealsUpdate")
    data class ScheduledDealsUpdate(
        val ids: Set<Long>,
        val config: ScheduledDealsConfig,
    ) : BackendResponse()

    @Serializable
    @SerialName("Market2PreparedOrdersUpdate")
    data class Market2PreparedOrdersUpdate(
        val orders: Market2PreparedOrdersCache,
    ) : BackendResponse()
}
