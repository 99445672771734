package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(SamuraiIdSerializer::class)
data class SamuraiId(val id: String)

object SamuraiIdSerializer : KSerializer<SamuraiId> {
    override fun deserialize(decoder: Decoder): SamuraiId {
        return SamuraiId(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: SamuraiId) {
        encoder.encodeString(value.id)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "SamuraiIdIdentifier",
        PrimitiveKind.STRING,
    )
}
