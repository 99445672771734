package com.edvorg.trade.common.model.chartscript

import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.TypedTicker
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class ChartScriptTick {

    @Serializable
    @SerialName("OrderBookTick")
    data class OrderBookTick(
        val ticker: TypedTicker,
        val currency: Currency?,
    ) : ChartScriptTick()

    @Serializable
    @SerialName("DaysToExpiryTick")
    data class DaysToExpiryTick(
        val ticker: TypedTicker,
    ) : ChartScriptTick()

    @Serializable
    @SerialName("IndicatorTick")
    data class IndicatorTick(
        val ticker: TypedTicker,
        val indicator: Indicator,
    ) : ChartScriptTick()

    @Serializable
    @SerialName("SpreadIndicatorTick")
    data class SpreadIndicatorTick(
        val ticker1: TypedTicker,
        val ratio1: Double,
        val ticker2: TypedTicker,
        val ratio2: Double,
        val indicator: Indicator,
    ) : ChartScriptTick()

    @Serializable
    @SerialName("CandleTick")
    data class CandleTick(
        val ticker: TypedTicker,
        val barSize: BarSize,
    ) : ChartScriptTick()
}
