package com.edvorg.trade.common.model.stats

import kotlinx.serialization.Serializable

@Serializable
enum class PriceStatType {
    MIN,
    MAX,
    LOW,
    HIGH,
    LAST,
    REG_CLOSE,
    POST_CLOSE,
    TURNOVER,
}
