package com.edvorg.trade.backend.frontend.services

import com.edvorg.trade.backend.frontend.utils.TickerInputHelper
import com.edvorg.trade.common.client.WebSocketClient
import com.edvorg.trade.common.frontend.services.ConnectionManagement
import com.edvorg.trade.common.frontend.services.ConnectionManager
import com.edvorg.trade.common.frontend.services.ConnectorServersManager
import com.edvorg.trade.common.frontend.services.ScannerClient
import com.edvorg.trade.common.frontend.services.TickersInfoProvider
import com.edvorg.trade.common.frontend.services.TradingClient
import com.edvorg.trade.common.frontend.services.toast.AbstractToaster
import com.edvorg.trade.common.frontend.services.toast.ToastPosition
import com.edvorg.trade.common.frontend.utils.UrlUtils
import com.edvorg.trade.common.model.ArbitrageConfig
import com.edvorg.trade.common.model.AutoTakePriorityBrokers
import com.edvorg.trade.common.model.BackendRequest
import com.edvorg.trade.common.model.BackendResponse
import com.edvorg.trade.common.model.BackendSubscription
import com.edvorg.trade.common.model.BarSize
import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.ConnectionManagementRequest
import com.edvorg.trade.common.model.ConnectorStatus
import com.edvorg.trade.common.model.Connectors
import com.edvorg.trade.common.model.CorrelationResponse
import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.Deals
import com.edvorg.trade.common.model.DividendInfo
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.model.GroupWatchlist
import com.edvorg.trade.common.model.Indicator
import com.edvorg.trade.common.model.Isin
import com.edvorg.trade.common.model.Level2WidgetSupplements
import com.edvorg.trade.common.model.LogLevel
import com.edvorg.trade.common.model.MarketDataClientConfig
import com.edvorg.trade.common.model.MarketDataControlResponse
import com.edvorg.trade.common.model.MarketDataId
import com.edvorg.trade.common.model.OperationType
import com.edvorg.trade.common.model.OrderType
import com.edvorg.trade.common.model.PriceFormulaComponets
import com.edvorg.trade.common.model.PriceSteps
import com.edvorg.trade.common.model.ScannerDealsSubscription
import com.edvorg.trade.common.model.ScannerEntry
import com.edvorg.trade.common.model.ScannerExecutorId
import com.edvorg.trade.common.model.ScannerId
import com.edvorg.trade.common.model.ScannerResponse
import com.edvorg.trade.common.model.SectorAndIndustry
import com.edvorg.trade.common.model.SubscriptionHandle
import com.edvorg.trade.common.model.TickerDetailsFilter
import com.edvorg.trade.common.model.TickerInfo
import com.edvorg.trade.common.model.TickerPair
import com.edvorg.trade.common.model.ToasterMessageType
import com.edvorg.trade.common.model.TradingRequest
import com.edvorg.trade.common.model.TradingResponse
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.chartscript.ChartScriptConfig
import com.edvorg.trade.common.model.chartscript.ChartScriptManagementRequest
import com.edvorg.trade.common.model.chartscript.ScriptId
import com.edvorg.trade.common.model.config.GuiConfig
import com.edvorg.trade.common.model.config.GuiConfigChange
import com.edvorg.trade.common.model.config.OptionalDoubleRange
import com.edvorg.trade.common.model.config.ScheduledDealsConfig
import com.edvorg.trade.common.model.groupwatchlist.GroupWatchlistSubscription
import com.edvorg.trade.common.model.market2.Market2PreparedOrder
import com.edvorg.trade.common.model.market2.Market2PreparedOrdersCache
import com.edvorg.trade.common.model.stats.PriceStatType
import com.edvorg.trade.common.model.stats.VolumeStatType
import com.edvorg.trade.common.serialization.Defaults.cbor
import com.edvorg.trade.common.serialization.Defaults.json
import com.edvorg.trade.common.utils.BuildInfo
import com.edvorg.trade.common.utils.CollectionUtils.updated
import com.edvorg.trade.common.utils.Subscriber
import com.edvorg.trade.common.utils.concurrency.compute
import com.edvorg.trade.common.utils.concurrency.concurrentMapOf
import com.edvorg.trade.common.utils.sound.BackendSoundPlayer
import io.github.reactivecircus.cache4k.Cache
import io.ktor.client.HttpClient
import io.ktor.client.plugins.HttpRequestTimeoutException
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.delete
import io.ktor.client.request.get
import io.ktor.client.request.parameter
import io.ktor.client.request.post
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.bodyAsText
import io.ktor.http.HttpStatusCode
import io.ktor.http.URLBuilder
import io.ktor.http.URLProtocol
import io.ktor.http.Url
import io.ktor.http.encodedPath
import io.ktor.websocket.Frame
import io.ktor.websocket.readBytes
import io.ktor.websocket.readText
import kotlinx.atomicfu.atomic
import kotlinx.atomicfu.getAndUpdate
import kotlinx.atomicfu.updateAndGet
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.asSharedFlow
import kotlinx.coroutines.launch
import kotlinx.serialization.encodeToString
import mu.KotlinLogging
import react.create
import react.dom.html.ReactHTML.img
import web.cssom.ClassName
import kotlin.time.Duration

object ResponseDecoders {
    private val logger = KotlinLogging.logger { }

    fun decodeBackendResponse(frame: Frame): BackendResponse? {
        return when (frame) {
            is Frame.Text -> {
                json.decodeFromString(BackendResponse.serializer(), frame.readText())
            }
            is Frame.Binary -> {
                cbor.decodeFromByteArray(BackendResponse.serializer(), frame.readBytes())
            }
            is Frame.Close -> {
                logger.info { "connection closed" }
                throw Error("connection closed by server")
            }
            is Frame.Ping -> {
                logger.info { "ping" }
                null
            }
            is Frame.Pong -> {
                logger.info { "Pong" }
                null
            }
        }
    }

    fun decodeScannerResponse(frame: Frame): ScannerResponse? {
        return when (frame) {
            is Frame.Text -> {
                json.decodeFromString(ScannerResponse.serializer(), frame.readText())
            }
            is Frame.Binary -> {
                cbor.decodeFromByteArray(ScannerResponse.serializer(), frame.readBytes())
            }
            is Frame.Close -> {
                logger.info { "connection closed" }
                throw Error("connection closed by server")
            }
            is Frame.Ping -> {
                logger.info { "ping" }
                null
            }
            is Frame.Pong -> {
                logger.info { "Pong" }
                null
            }
        }
    }

    fun decodeMarketDataControlResponse(frame: Frame): MarketDataControlResponse? {
        return when (frame) {
            is Frame.Text -> {
                json.decodeFromString(MarketDataControlResponse.serializer(), frame.readText())
            }
            is Frame.Binary -> {
                cbor.decodeFromByteArray(MarketDataControlResponse.serializer(), frame.readBytes())
            }
            is Frame.Close -> {
                logger.info { "connection closed" }
                throw Error("connection closed by server")
            }
            is Frame.Ping -> {
                logger.info { "ping" }
                null
            }
            is Frame.Pong -> {
                logger.info { "Pong" }
                null
            }
        }
    }
}

fun makeWsBackendUrl(
    path: String,
    params: Map<String, String>,
): Url {
    val urlProtocol = if (window.location.protocol == "https:") URLProtocol.WSS else URLProtocol.WS
    return makeBackendUrl(urlProtocol, path, params)
}

fun makeHttpBackendUrl(
    path: String,
    params: Map<String, String>,
): Url {
    return makeHttpBackendUrl(window.location.hostname, path, params)
}

fun makeHttpBackendUrl(
    hostName: String,
    path: String,
    params: Map<String, String>,
): Url {
    val urlProtocol = if (window.location.protocol == "https:") URLProtocol.HTTPS else URLProtocol.HTTP
    return makeBackendUrl(hostName, urlProtocol, path, params)
}

private fun makeBackendUrl(
    urlProtocol: URLProtocol,
    path: String,
    params: Map<String, String>,
): Url {
    return makeBackendUrl(window.location.hostname, urlProtocol, path, params)
}

private fun makeBackendUrl(
    hostName: String,
    urlProtocol: URLProtocol,
    path: String,
    params: Map<String, String>,
): Url {
    val mode = window.asDynamic().MODE as? String
    val url = URLBuilder().apply {
        protocol = urlProtocol
        host = hostName
        if (mode == "dev") {
            port = 3005 // standard dev port for websocket
        } else {
            val explicitPort = window.location.port.toIntOrNull()
            if (explicitPort != null) {
                port = explicitPort
            }
        }
        encodedPath = path
        for ((key, value) in params) {
            parameters.append(key, value)
        }
    }.build()
    return url
}

class BackendMainClient(
    cachedUserSpace: String?,
    private val player: BackendSoundPlayer,
    private val connectorServersManager: ConnectorServersManager,
    url: Url,
    private val toaster: AbstractToaster,
    private val onConfigReceived: () -> Unit,
) : WebSocketClient("BackendMainClient", url, false),
    ScannerClient,
    ConnectionManagement,
    TickersInfoProvider,
    TradingClient {
    companion object {
        private val logger = KotlinLogging.logger { }
    }

    private val subscriptionId = atomic(0L)

    private val httpClient = HttpClient {
        expectSuccess = true
    }

    private val scannerDealSubscribers =
        mutableMapOf<ScannerDealsSubscription, Map<Long, Subscriber<Deals>>>()
    private val scannerDealSubscribersFlattened =
        mutableMapOf<Long, Subscriber<Deals>>()
    private val scannerLogSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.LogDealsUpdate>>()
    private val orderBookSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.OrderBookUpdate>>()
    private val newsSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.NewsUpdate>>()
    private val channelsSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ChannelsUpdate>>()
    private val currencyRateSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.CurrencyRateUpdate>>()
    private val closePriceSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ClosePriceUpdate>>()
    private val midPointSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.MidPointUpdate>>()
    private val positionSubscribers =
        mutableMapOf<Long, Subscriber<TradingResponse.PositionsUpdate>>()
    private val scheduledDealSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ScheduledDealsUpdate>>()
    private val currencyPositionSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.CurrencyPositionsUpdate>>()
    private val groupedPositionsSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.GroupedPositionsUpdate>>()
    private val autoTakeSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.AutoTakeUpdate>>()
    private val paginatedOperationsSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.PaginatedOperationsUpdate>>()
    private val newOperationSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.NewOperationsUpdate>>()
    private val guiConfigSubscribers =
        mutableMapOf<Long, Subscriber<GuiConfig>>()
    private val guiConfigChangeSubscribers =
        mutableMapOf<Long, Subscriber<GuiConfigChange>>()
    private val scannerOpenPositionSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ScannerOpenPositionUpdate>>()
    private val ordersSubscribers = mutableMapOf<Long, Subscriber<TradingResponse.OrdersUpdate>>()
    private val priceStatSubscribers =
        mutableMapOf<Long, Subscriber<TradingResponse.PriceStatUpdate>>()
    private val volumeStatSubscribers = mutableMapOf<Long, Subscriber<TradingResponse.VolumeStatUpdate>>()
    private val vwapSubscribers = mutableMapOf<Long, Subscriber<TradingResponse.VWAPUpdate>>()
    private val vwemaSubscribers = mutableMapOf<Long, Subscriber<TradingResponse.VWEMAUpdate>>()
    private val excludedIsinSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ExcludedTickersUpdate>>()
    private val hiddenIsinSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.HiddenTickersUpdate>>()
    private val availableOrderLotSubscribers =
        mutableMapOf<Long, Subscriber<TradingResponse.AvailableOrderLotsUpdate>>()
    private val scannerServersSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ScannerServersUpdate>>()
    private val tradingStatusSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.TradingStatusUpdate>>()
    private val indicatorSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.IndicatorUpdate>>()
    private val userSpaceListSubscribers = mutableMapOf<Long, Subscriber<List<String>>>()
    private val market2PreparedOrdersSubscribers =
        mutableMapOf<Long, Subscriber<Market2PreparedOrdersCache>>()
    private val candleSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.CandlesUpdate>>()
    private val groupWatchlistSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.GroupWatchlistUpdate>>()
    private val tradeSubscribers = mutableMapOf<Long, Subscriber<BackendResponse.TradesUpdate>>()

    private val chartScriptsUpdateSubscribers = mutableMapOf<Long, Subscriber<BackendResponse.ChartScripts>>()
    private val chartScriptPlotUpdatesSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ChartScriptPlotUpdate>>()
    private val chartScriptIdsSubscribers = mutableMapOf<Long, Subscriber<BackendResponse.ChartScriptIds>>()
    private val chartScriptConfigSubscribers =
        mutableMapOf<Long, Subscriber<BackendResponse.ChartScriptConfigUpdate>>()

    private val priorityBrokerSubscribers = mutableMapOf<Long, Subscriber<Map<Exchange, BrokerId>>>()

    private val activeSubscriptions = mutableMapOf<Long, BackendSubscription>()
    lateinit var config: BackendResponse.ConfigUpdate
    private var market2PreparedOrders: Market2PreparedOrdersCache? = null
    private var guiConfig: GuiConfig? = null
    private val scope = CoroutineScope(Dispatchers.Default)
    private val subscriptionsMutableFlow = MutableSharedFlow<Unit>(0, 1000)
    private val subscriptionsFlow = subscriptionsMutableFlow.asSharedFlow()
    private val subscriptionsHandle = atomic<Int?>(null)
    private var buildInfo: BuildInfo? = null
    private var scannerServers: BackendResponse.ScannerServersUpdate? = null
    private var marketDataServers: Map<String, MarketDataClientConfig.Remote> = emptyMap()
    private var allSectorsAndIndustries: BackendResponse.AllSectorsAndIndustriesUpdate? = null
    private var exchangesOneCurrency: Map<Exchange, Currency> = emptyMap()

    private var priorityBrokers = concurrentMapOf<Exchange, BrokerId>()

    override val connectionManager = ConnectionManager("BackendMainClient", this)

    private val updateConnectorsMutableFlow = MutableSharedFlow<Connectors>(0, 10000)
    override val updateConnectorsFlow = updateConnectorsMutableFlow.asSharedFlow()

    private val tickerInputHelper = atomic<TickerInputHelper?>(null)
    private var userSpacesList: List<String> = listOf()
    var currentUserSpace: String? = cachedUserSpace

    private val currencyCache = Cache.Builder()
        .maximumCacheSize(500)
        .build<TypedTicker, Currency>()
    private val additionalTickersCache = Cache.Builder()
        .maximumCacheSize(100)
        .build<Pair<TypedTicker, Set<Exchange>>, Set<TypedTicker>>()

    val allSectors get() = (allSectorsAndIndustries?.sectors?.values ?: setOf())
    val allIndustries get() = (allSectorsAndIndustries?.industries?.values ?: setOf())
    val allSubTypes get() = (allSectorsAndIndustries?.subtypes?.values ?: setOf())

    override fun activeSubscriptionSize(): Int =
        activeSubscriptions.size + connectionManager.activeSubscriptionSize()

    override fun HttpRequestBuilder.requestSetup() {
        if (currentUserSpace != null) {
            parameter("user_space", currentUserSpace)
        }
    }

    override suspend fun onConnect() {
        scope.launch {
            sendActiveSubscriptions()

            subscriptionsFlow.collect {
                subscriptionsHandle.getAndUpdate {
                    window.setTimeout(
                        {
                            sendActiveSubscriptions()
                        },
                        150,
                    )
                }?.let { window.clearTimeout(it) }
            }
        }
    }

    private fun sendActiveSubscriptions() {
        send(
            BackendRequest.Subscribe(
                activeSubscriptions.toMap(),
            ),
        )
    }

    override fun sendConnectorUpdate(message: ConnectionManagementRequest) {
        send(BackendRequest.UpdateConnectors(message))
    }

    override fun onDisconnect() {
    }

    override fun processFrame(frame: Frame) {
        val m = ResponseDecoders.decodeBackendResponse(frame) ?: return
        scope.launch {
            when (m) {
                is BackendResponse.ConnectorsUpdate -> {
                    if (!updateConnectorsMutableFlow.tryEmit(m.connectors)) {
                        logger.info { "Unable emit connectors update $m" }
                    }

                    updateMarketDataClients(m.connectors)
                }
                is BackendResponse.DealsUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        scannerDealSubscribersFlattened[subscriptionId]?.onUpdate(m.deals)
                    }
                }
                is BackendResponse.CurrencyPositionsUpdate -> {
                    for ((_, subscriber) in currencyPositionSubscribers) {
                        subscriber.onUpdate(m)
                    }
                }
                is BackendResponse.AutoTakeUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        autoTakeSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.OrderBookUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        orderBookSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.ExcludedTickersUpdate -> {
                    for ((_, subscriber) in excludedIsinSubscribers) {
                        subscriber.onUpdate(m)
                    }
                }
                is BackendResponse.HiddenTickersUpdate -> {
                    for ((_, subscriber) in hiddenIsinSubscribers) {
                        subscriber.onUpdate(m)
                    }
                }
                is BackendResponse.PaginatedOperationsUpdate -> {
                    for ((_, subscriber) in paginatedOperationsSubscribers) {
                        subscriber.onUpdate(m)
                    }
                }
                is BackendResponse.NewOperationsUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        newOperationSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.ConfigUpdate -> {
                    this@BackendMainClient.config = m
                    if (buildInfo != null && buildInfo != m.buildInfo) {
                        window.location.reload()
                    }
                    buildInfo = m.buildInfo

                    onConfigReceived()
                }
                is BackendResponse.TradesUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        tradeSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.CandlesUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        candleSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.GroupWatchlistUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        groupWatchlistSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.ClosePriceUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        closePriceSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.MidPointUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        midPointSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.GuiConfigUpdate -> {
                    this@BackendMainClient.guiConfig = m.guiConfig
                    if (currentUserSpace != m.userSpaceName) {
                        this@BackendMainClient.currentUserSpace = m.userSpaceName
                    }

                    for ((_, subscriber) in guiConfigSubscribers) {
                        subscriber.onUpdate(m.guiConfig)
                    }

                    priorityBrokers.clear()
                    priorityBrokers.putAll(m.guiConfig.priorityBrokers)
                    for ((_, subscriber) in priorityBrokerSubscribers) {
                        subscriber.onUpdate(priorityBrokers)
                    }
                }
                is BackendResponse.GuiConfigChangeUpdate -> {
                    this@BackendMainClient.guiConfig = this@BackendMainClient.guiConfig?.applyChange(m.change)

                    when (val change = m.change) {
                        is GuiConfigChange.ClearPriorityBroker -> {
                            priorityBrokers.remove(change.exchange)
                            for ((_, subscriber) in priorityBrokerSubscribers) {
                                subscriber.onUpdate(priorityBrokers)
                            }
                        }
                        is GuiConfigChange.SetPriorityBroker -> {
                            priorityBrokers[change.exchange] = change.brokerId
                            for ((_, subscriber) in priorityBrokerSubscribers) {
                                subscriber.onUpdate(priorityBrokers)
                            }
                        }
                        else -> {}
                    }

                    for ((_, subscriber) in guiConfigChangeSubscribers) {
                        subscriber.onUpdate(m.change)
                    }
                }
                BackendResponse.ScannerOpenOrderPlacedUpdate -> {
                    player.playPewSound()
                }
                is BackendResponse.ScannerOpenPositionUpdate -> {
                    for ((_, subscriber) in scannerOpenPositionSubscribers) {
                        subscriber.onUpdate(m)
                    }
                }
                is BackendResponse.OnError -> {
                    val errorMessage = m.message ?: "Unknown error"
                    toaster.error(
                        errorMessage,
                        3500,
                    )
                    player.playError()
                }
                is BackendResponse.CurrencyRateUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        currencyRateSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.LogDealsUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        scannerLogSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.NewsUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        newsSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.ChannelsUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        channelsSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.TradingStatusUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        tradingStatusSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.IndicatorUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        indicatorSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.ScannerServersUpdate -> {
                    updateScannerClients(m)
                }
                is BackendResponse.RequestConfirmationCode -> {
                    window.prompt("Confirm ${m.phoneNumber}")?.takeIf { it.isNotBlank() }?.let {
                        sendConfirmationCode(m.phoneNumber, it)
                    }
                }
                is BackendResponse.SuggestExchangesUpdate -> {
                    tickerInputHelper.updateAndGet {
                        TickerInputHelper(m.tickers)
                    }
                }
                is BackendResponse.UserSpaces -> {
                    userSpacesList = m.userSpaces
                    for ((_, subscriber) in userSpaceListSubscribers) {
                        subscriber.onUpdate(userSpacesList)
                    }
                }
                is BackendResponse.ScheduledDealsUpdate -> {
                    for ((_, subscriber) in scheduledDealSubscribers) {
                        subscriber.onUpdate(m)
                    }
                }
                is BackendResponse.TradingUpdate -> {
                    when (val r = m.response) {
                        is TradingResponse.PositionsUpdate -> {
                            for ((_, subscriber) in positionSubscribers) {
                                subscriber.onUpdate(r)
                            }
                        }
                        is TradingResponse.OrdersUpdate -> {
                            for (subscriptionId in r.subscriptionIds) {
                                ordersSubscribers[subscriptionId]?.onUpdate(r)
                            }
                        }
                        is TradingResponse.PriceStatUpdate -> {
                            for (subscriptionId in r.subscriptionIds) {
                                priceStatSubscribers[subscriptionId]?.onUpdate(r)
                            }
                        }
                        is TradingResponse.VolumeStatUpdate -> {
                            for (subscriptionId in r.subscriptionIds) {
                                volumeStatSubscribers[subscriptionId]?.onUpdate(r)
                            }
                        }
                        is TradingResponse.VWAPUpdate -> {
                            for (subscriptionId in r.subscriptionIds) {
                                vwapSubscribers[subscriptionId]?.onUpdate(r)
                            }
                        }
                        is TradingResponse.VWEMAUpdate -> {
                            for (subscriptionId in r.subscriptionIds) {
                                vwemaSubscribers[subscriptionId]?.onUpdate(r)
                            }
                        }
                        is TradingResponse.AvailableOrderLotsUpdate -> {
                            for (subscriptionId in r.subscriptionIds) {
                                availableOrderLotSubscribers[subscriptionId]?.onUpdate(r)
                            }
                        }
                        is TradingResponse.OrderPlaced -> {
                            val o = r.order
                            toaster.info(
                                "Placed Order ${o.operationType} ${o.requestedLots} ${o.ticker} " +
                                    "by ${o.price}\$",
                                1900,
                                ToastPosition.BOTTOM_RIGHT,
                                icon = img.create {
                                    className = ClassName("preview")
                                    src = UrlUtils.getStockIconUrl(o.ticker)
                                },
                            )
                        }
                    }
                }
                is BackendResponse.ChartScripts -> {
                    for (subscriptionId in m.subscriptionIds) {
                        chartScriptsUpdateSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }
                is BackendResponse.ChartScriptPlotUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        chartScriptPlotUpdatesSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }

                is BackendResponse.ChartScriptConfigUpdate -> {
                    for (subscriptionId in m.subscriptionIds) {
                        chartScriptConfigSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }

                is BackendResponse.ChartScriptIds -> {
                    for (subscriptionId in m.subscriptionIds) {
                        chartScriptIdsSubscribers[subscriptionId]?.onUpdate(m)
                    }
                }

                is BackendResponse.AllSectorsAndIndustriesUpdate -> {
                    allSectorsAndIndustries = m
                }

                is BackendResponse.ExchangesOneCurrencyUpdate -> {
                    exchangesOneCurrency = m.exchanges
                }

                is BackendResponse.GroupedPositionsUpdate -> {
                    for ((_, subscriber) in groupedPositionsSubscribers) {
                        subscriber.onUpdate(m)
                    }
                }

                is BackendResponse.ToasterMessage -> {
                    when (m.messageType) {
                        ToasterMessageType.ERROR -> {
                            toaster.error(m.message, 3000, ToastPosition.BOTTOM_RIGHT)
                        }
                        ToasterMessageType.INFO -> {
                            toaster.info(m.message, 3000, ToastPosition.BOTTOM_RIGHT)
                        }
                    }
                }

                is BackendResponse.Market2PreparedOrdersUpdate -> {
                    market2PreparedOrders = m.orders
                    for ((_, subscriber) in market2PreparedOrdersSubscribers) {
                        subscriber.onUpdate(m.orders)
                    }
                }
            }
        }
    }

    fun getTickerInputHelper(): TickerInputHelper {
        return tickerInputHelper.value ?: TickerInputHelper(mapOf())
    }

    private fun updateScannerClients(update: BackendResponse.ScannerServersUpdate) {
        val latestServers = update.scannerServers

        latestServers.forEach { (serverId, url) ->
            connectorServersManager.updateServer(
                serverId,
                url,
                scope,
                RemoteScannerClient(
                    URLBuilder().apply {
                        protocol = url.protocol
                        host = url.host
                        port = url.port
                        encodedPath = "/client"
                    }.build(),
                ),
            )
        }

        val removedClients = ((scannerServers?.scannerServers?.keys) ?: setOf()) - latestServers.keys

        removedClients.forEach {
            connectorServersManager.deleteServer(it)
        }

        scannerServers = update
        scannerServersSubscribers.forEach {
            it.value.onUpdate(update)
        }
    }

    private fun updateMarketDataClients(connectors: Connectors) {
        if (connectors !is Connectors.MarketDataClients) {
            return
        }

        val latestServers = connectors.marketDataClients.mapNotNull { (k, v) ->
            (v.marketDataClientConfig as? MarketDataClientConfig.Remote)?.let { config ->
                Pair(k.id, config)
            }
        }.toMap()

        latestServers.forEach { (serverId, config) ->
            connectorServersManager.updateServer(
                serverId,
                config.endpoint,
                scope,
                RemoteMarketDataControlClient(
                    URLBuilder().apply {
                        protocol = config.endpoint.protocol
                        host = config.endpoint.host
                        port = config.endpoint.port
                        encodedPath = config.endpoint.encodedPath + "/control"
                    }.build(),
                ),
            )
        }

        val removedClients = marketDataServers.keys - latestServers.keys
        marketDataServers = latestServers

        removedClients.forEach {
            connectorServersManager.deleteServer(it)
        }
    }

    fun sendScannerClients(scannerServers: Map<String, Url>) {
        send(BackendRequest.UpdateScannerServers(scannerServers))
    }

    fun subscribeScannerServersUpdate(
        subscriber: Subscriber<BackendResponse.ScannerServersUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scannerServersSubscribers[id] = subscriber

        val update = scannerServers
        if (update != null) {
            subscriber.onUpdate(update)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeScannerServersUpdate(id)
            }
        }
    }

    private fun unsubscribeScannerServersUpdate(id: Long) {
        scannerServersSubscribers.remove(id)
    }

    fun sendExecuteDeals(
        scannerEntries: List<ScannerEntry>,
        subscription: ScannerDealsSubscription,
        ignoreExclusionFilter: Boolean,
        budgetFraction: Double,
        limit: Boolean,
        maxBudgetUsd: Double,
        minProfitUsd: Double,
        maxProfitUsd: Double,
        priceDiffPercentFilterRange: OptionalDoubleRange,
        priceDiffUsdFilterRange: OptionalDoubleRange,
        pair: Boolean,
        openVolumeCapPercent: Double?,
        arbitrageConfig: ArbitrageConfig,
        scannerExecutorId: ScannerExecutorId?,
        scannerId: ScannerId,
        groupId: Group?,
        widgetId: String?,
        priorityBrokers1: Map<Exchange, BrokerId>,
        priorityBrokers2: Map<Exchange, BrokerId>,
    ) {
        send(
            BackendRequest.ExecuteDeals(
                scannerExecutorId,
                scannerId,
                scannerEntries,
                ignoreExclusionFilter,
                subscription,
                budgetFraction,
                limit,
                maxBudgetUsd,
                minProfitUsd,
                maxProfitUsd,
                priceDiffPercentFilterRange,
                priceDiffUsdFilterRange,
                pair,
                openVolumeCapPercent,
                arbitrageConfig,
                groupId,
                widgetId,
                priorityBrokers1,
                priorityBrokers2,
            ),
        )
    }

    fun findAndExecuteDeal(
        tickerPair: TickerPair,
        budgetFraction: Double,
        limit: Boolean,
        maxBudgetUsd: Double,
        subscription: ScannerDealsSubscription,
        arbitrageConfig: ArbitrageConfig,
        executorId: ScannerExecutorId?,
        groupId: Group?,
        widgetId: String?,
    ) {
        send(
            BackendRequest.FindAndExecuteDeal(
                tickerPair,
                executorId,
                budgetFraction,
                limit,
                maxBudgetUsd,
                subscription,
                arbitrageConfig,
                groupId,
                widgetId,
            ),
        )
    }

    override fun sendCancelOrder(brokerId: BrokerId, exchange: Exchange, id: String) {
        send(
            BackendRequest.ExecTradingRequest(
                TradingRequest.CancelOrder(
                    brokerId,
                    exchange,
                    id,
                ),
            ),
        )
    }

    fun sendUnExcludeTickers(tickers: Set<TypedTicker>, scannerId: ScannerId) {
        send(
            BackendRequest.UnExcludeTickers(
                tickers,
                scannerId,
            ),
        )
    }

    fun sendExcludeTickers(tickers: Set<TypedTicker>, scannerId: ScannerId) {
        send(
            BackendRequest.ExcludeTickers(
                tickers,
                scannerId,
            ),
        )
        if (2 < tickers.size) {
            player.playStrangeSmell()
        } else {
            player.playWeCapturedWitch()
        }
    }

    fun sendHideTickers(tickers: Set<TypedTicker>, scannerId: ScannerId) {
        send(
            BackendRequest.HideTickers(
                tickers,
                scannerId,
            ),
        )
        if (2 < tickers.size) {
            player.playStrangeSmell()
        } else {
            player.playWeCapturedWitch()
        }
    }

    fun sendUnHideTickers(tickers: Set<TypedTicker>, scannerId: ScannerId) {
        send(
            BackendRequest.UnHideTickers(
                tickers,
                scannerId,
            ),
        )
    }

    override fun subscribeToDealsUpdate(
        subscription: ScannerDealsSubscription,
        subscriber: Subscriber<Deals>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scannerDealSubscribers.compute(subscription) { _, oldSubscribers ->
            oldSubscribers?.plus(id to subscriber)
                ?: mapOf(id to subscriber)
        }
        scannerDealSubscribersFlattened[id] = subscriber
        addSubscription(id, BackendSubscription.Polling.ScannerDeals(subscription))
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromDealsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromDealsUpdate(id: Long) {
        val subscriptionKeys = scannerDealSubscribers.keys.toSet()
        subscriptionKeys.forEach {
            scannerDealSubscribers.compute(it) { _, oldSubscribers ->
                oldSubscribers?.minus(id)?.takeIf { subscribers -> subscribers.isNotEmpty() }
            }
            removeSubscription(id)
        }
        scannerDealSubscribersFlattened.remove(id)
    }

    override fun subscribeToPositionsUpdate(
        subscriber: Subscriber<TradingResponse.PositionsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        positionSubscribers[id] = subscriber
        addSubscription(id, BackendSubscription.Positions)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromPositionsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromPositionsUpdate(id: Long) {
        positionSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToScheduledDealsUpdate(
        subscriber: Subscriber<BackendResponse.ScheduledDealsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scheduledDealSubscribers[id] = subscriber
        addSubscription(id, BackendSubscription.ScheduledDeals(id))
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromScheduledDealsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromScheduledDealsUpdate(id: Long) {
        scheduledDealSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToGroupedPositions(
        subscription: BackendSubscription.GroupedPortfolioSubscription,
        subscriber: Subscriber<BackendResponse.GroupedPositionsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        groupedPositionsSubscribers[id] = subscriber
        addSubscription(id, subscription)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                groupedPositionsSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeToScannerLogDeals(
        subscription: BackendSubscription.ScannerDeals,
        subscriber: Subscriber<BackendResponse.LogDealsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scannerLogSubscribers[id] = subscriber
        addSubscription(id, subscription)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromScannerLogDeals(id)
            }
        }
    }

    private fun unsubscribeFromScannerLogDeals(id: Long) {
        scannerLogSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToCurrencyPositionsUpdate(
        subscriber: Subscriber<BackendResponse.CurrencyPositionsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        currencyPositionSubscribers[id] = subscriber
        addSubscription(id, BackendSubscription.CurrencyPositions)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromCurrencyPositionsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromCurrencyPositionsUpdate(id: Long) {
        currencyPositionSubscribers.remove(id)
        removeSubscription(id)
    }

    override fun subscribeToAutoTakeUpdate(
        widgetId: String,
        scannerId: ScannerId,
        subscriber: Subscriber<BackendResponse.AutoTakeUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.Polling.AutoTake(scannerId, widgetId)
            autoTakeSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromAutoTakeUpdate(id)
            }
        }
    }

    private fun unsubscribeFromAutoTakeUpdate(id: Long) {
        scope.launch {
            autoTakeSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    override fun subscribeToOrdersUpdate(
        workingOnly: Boolean,
        subscriber: Subscriber<TradingResponse.OrdersUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        ordersSubscribers[id] = subscriber
        addSubscription(id, BackendSubscription.Orders(workingOnly))
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromOrdersUpdate(id)
            }
        }
    }

    private fun unsubscribeFromOrdersUpdate(id: Long) {
        ordersSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToOrderBookUpdate(
        ticker: TypedTicker,
        currency: Currency?,
        depth: Int?,
        priorityExchange: Exchange?,
        subscriber: Subscriber<BackendResponse.OrderBookUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.OrderBook(ticker, currency, depth, priorityExchange)
            orderBookSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromOrderBookUpdate(id)
            }
        }
    }

    private fun unsubscribeFromOrderBookUpdate(id: Long) {
        scope.launch {
            orderBookSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    fun subscribeToUserSpaceListUpdate(
        subscriber: Subscriber<List<String>>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        subscriber.onUpdate(userSpacesList)
        userSpaceListSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                userSpaceListSubscribers.remove(id)
            }
        }
    }

    fun subscribeToMarket2PreparedOrdersUpdate(
        subscriber: Subscriber<Market2PreparedOrdersCache>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        market2PreparedOrdersSubscribers[id] = subscriber
        val update = market2PreparedOrders
        if (update != null) {
            subscriber.onUpdate(update)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                market2PreparedOrdersSubscribers.remove(id)
            }
        }
    }

    fun updateMarket2PreparedOrder(
        ticker: TypedTicker,
        localOrderId: String,
        preparedOrder: Market2PreparedOrder?,
    ) {
        val update = BackendRequest.UpdateMarket2PreparedOrder(
            ticker,
            localOrderId,
            preparedOrder,
        )

        val currentOrders = market2PreparedOrders?.orders ?: mapOf()
        market2PreparedOrders = Market2PreparedOrdersCache(
            currentOrders.updated(ticker) { _, oldTickerOrders ->
                if (oldTickerOrders == null && preparedOrder != null) {
                    mapOf(localOrderId to preparedOrder)
                } else {
                    oldTickerOrders?.updated(localOrderId) { _, _ ->
                        preparedOrder
                    }
                }
            },
        )

        if (market2PreparedOrders != null) {
            market2PreparedOrdersSubscribers.forEach {
                it.value.onUpdate(market2PreparedOrders!!)
            }
        }

        scope.launch {
            send(update)
        }
    }

    fun forceResubscribeOrderBook(ticker: TypedTicker, priorityExchange: Exchange?) {
        scope.launch {
            send(BackendRequest.ForceResubscribeOrderBook(ticker, priorityExchange))
        }
    }

    fun forceResubscribeClosePrice(ticker: TypedTicker) {
        scope.launch {
            send(BackendRequest.ForceResubscribeClosePrice(ticker))
        }
    }

    fun subscribeNews(
        subscription: BackendSubscription.NewsSubscription,
        subscriber: Subscriber<BackendResponse.NewsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            newsSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                scope.launch {
                    newsSubscribers.remove(id)
                    removeSubscription(id)
                }
            }
        }
    }

    fun subscribeToCandles(
        ticker: TypedTicker,
        barSize: BarSize,
        subscriber: Subscriber<BackendResponse.CandlesUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.Candles(ticker, barSize)
            candleSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                candleSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeToTrades(
        ticker: TypedTicker,
        subscriber: Subscriber<BackendResponse.TradesUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.Trades(ticker)
            tradeSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                tradeSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeToGroupWatchlist(
        data: GroupWatchlistSubscription,
        subscriber: Subscriber<BackendResponse.GroupWatchlistUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.GroupWatchlist(data)
            groupWatchlistSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                groupWatchlistSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeChannels(
        marketDataId: MarketDataId,
        subscriber: Subscriber<BackendResponse.ChannelsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.ChannelNewsSubscription(
                marketDataId,
            )
            channelsSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                scope.launch {
                    channelsSubscribers.remove(id)
                    removeSubscription(id)
                }
            }
        }
    }

    fun subscribeToExcludedIsinsUpdate(
        subscriber: Subscriber<BackendResponse.ExcludedTickersUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        excludedIsinSubscribers[id] = subscriber
        addSubscription(id, BackendSubscription.ExcludedIsins)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromExcludedIsinsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromExcludedIsinsUpdate(id: Long) {
        excludedIsinSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToHiddenIsinsUpdate(
        subscriber: Subscriber<BackendResponse.HiddenTickersUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        hiddenIsinSubscribers[id] = subscriber
        addSubscription(id, BackendSubscription.HiddenIsins)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromHiddenIsinsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromHiddenIsinsUpdate(id: Long) {
        hiddenIsinSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToPaginatedOperationsUpdate(
        subscriber: Subscriber<BackendResponse.PaginatedOperationsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        paginatedOperationsSubscribers[id] = subscriber
        val subscription = BackendSubscription.Polling.PaginatedOperations
        addSubscription(id, subscription)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromPaginatedOperationsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromPaginatedOperationsUpdate(id: Long) {
        paginatedOperationsSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToNewOperationsUpdate(
        brokerId: BrokerId,
        subscriber: Subscriber<BackendResponse.NewOperationsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        newOperationSubscribers[id] = subscriber
        val subscription = BackendSubscription.NewOperations(brokerId)
        addSubscription(id, subscription)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromNewOperationsUpdate(id)
            }
        }
    }

    private fun unsubscribeFromNewOperationsUpdate(id: Long) {
        newOperationSubscribers.remove(id)
        removeSubscription(id)
    }

    fun subscribeToGuiConfigSnapshotUpdate(
        subscriber: Subscriber<GuiConfig>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        guiConfig?.let {
            subscriber.onUpdate(it)
        }
        guiConfigSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                guiConfigSubscribers.remove(id)
            }
        }
    }

    fun subscribeToGuiConfigChanges(
        subscriber: Subscriber<GuiConfigChange>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        guiConfigChangeSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                guiConfigChangeSubscribers.remove(id)
            }
        }
    }

    fun subscribeToScannerOpenPositionUpdate(
        subscriber: Subscriber<BackendResponse.ScannerOpenPositionUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scannerOpenPositionSubscribers[id] = subscriber
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromScannerOpenPositionUpdate(id)
            }
        }
    }

    private fun unsubscribeFromScannerOpenPositionUpdate(id: Long) {
        scannerOpenPositionSubscribers.remove(id)
    }

    private fun addSubscription(id: Long, subscription: BackendSubscription) {
        activeSubscriptions[id] = subscription
        if (!subscriptionsMutableFlow.tryEmit(Unit)) {
            logger.info { "unable to emit subscriptions update" }
        }
    }

    private fun removeSubscription(id: Long) {
        activeSubscriptions.remove(id)
        if (!subscriptionsMutableFlow.tryEmit(Unit)) {
            logger.info { "unable to emit subscriptions update" }
        }
    }

    private fun send(message: BackendRequest) {
        send(
            Frame.Binary(
                true,
                cbor.encodeToByteArray(BackendRequest.serializer(), message),
            ),
        )
    }

    fun sendClosePosition(brokerId: BrokerId, ticker: TypedTicker, closeVolume: Double?) {
        send(BackendRequest.ClosePosition(brokerId, ticker, closeVolume))
        player.playNeedMoreGold()
    }

    fun sendTabsChange(
        change: GuiConfigChange,
    ) {
        send(
            BackendRequest.TabsChange(
                change,
            ),
        )
    }

    fun sendGetOperations(brokerId: BrokerId, continuation: String?, requestId: String) {
        send(BackendRequest.GetPaginatedOperations(brokerId, continuation, requestId))
    }

    override fun sendPlaceBridge(
        operationType: OperationType,
        ticker: TypedTicker,
        volume: Double,
        price: Double,
        delay: Duration?,
    ) {
        val brokerId1 = getPriorityBroker(ticker.exchange)
        val brokerId2 = getPriorityBroker(Exchange.NASDAQ)
        send(
            BackendRequest.ExecTradingRequest(
                TradingRequest.PlaceBridgeOrder(
                    operationType,
                    ticker,
                    volume,
                    price,
                    delay,
                    brokerId1,
                    brokerId2,
                ),
            ),
        )
    }

    override fun sendPlaceOrder(
        brokerId: BrokerId?,
        operationType: OperationType,
        orderType: OrderType,
        ticker: TypedTicker,
        volume: Double,
        price: Double,
        currency: Currency,
        smartRoute: Boolean,
        hidden: Boolean,
        trail: Pair<Int, Double>?,
        triggerPrice: Double?,
    ) {
        when (operationType) {
            OperationType.BUY -> send(
                BackendRequest.ExecTradingRequest(
                    TradingRequest.PlaceBuyOrder(
                        brokerId,
                        orderType,
                        ticker,
                        volume,
                        price,
                        currency,
                        smartRoute,
                        hidden,
                        trail,
                        triggerPrice,
                    ),
                ),
            )
            OperationType.SELL -> send(
                BackendRequest.ExecTradingRequest(
                    TradingRequest.PlaceSellOrder(
                        brokerId,
                        orderType,
                        ticker,
                        volume,
                        price,
                        currency,
                        smartRoute,
                        hidden,
                        trail,
                        triggerPrice,
                    ),
                ),
            )
        }
    }

    fun sendSetScheduledDealsConfig(
        newConfig: ScheduledDealsConfig,
    ) {
        send(BackendRequest.SetScheduledDealsConfig(newConfig))
    }

    fun sendExecuteScheduledOrders() {
        send(BackendRequest.ExecuteScheduledOrders)
    }

    fun subscribeToPriceStatUpdate(
        ticker: TypedTicker,
        statType: PriceStatType,
        currency: Currency?,
        priorityExchange: Exchange?,
        subscriber: Subscriber<TradingResponse.PriceStatUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.PriceStat(ticker, statType, currency, priorityExchange)
            priceStatSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromPriceStatUpdate(id)
            }
        }
    }

    private fun unsubscribeFromPriceStatUpdate(id: Long) {
        scope.launch {
            priceStatSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    override fun subscribeToPriceStatsUpdate(
        ticker: TypedTicker,
        statTypes: Set<PriceStatType>,
        currency: Currency?,
        priorityExchange: Exchange?,
        subscriber: Subscriber<Pair<PriceStatType, TradingResponse.PriceStatUpdate>>,
    ): SubscriptionHandle<Set<Long>> {
        val typesWithHandles = statTypes.associateWith { subscriptionId.getAndIncrement() }
        scope.launch {
            typesWithHandles.forEach { (statType, id) ->
                val subscription = BackendSubscription.PriceStat(ticker, statType, currency, priorityExchange)
                priceStatSubscribers[id] = Subscriber { response ->
                    subscriber.onUpdate(statType to response)
                }

                addSubscription(id, subscription)
            }
        }

        return object : SubscriptionHandle<Set<Long>>(typesWithHandles.values.toSet()) {
            override fun unsubscribe() {
                typesWithHandles.values.forEach { unsubscribeFromPriceStatUpdate(it) }
            }
        }
    }

    override fun subscribeToVolumeStatsUpdate(
        ticker: TypedTicker,
        statTypes: Set<VolumeStatType>,
        priorityExchange: Exchange?,
        subscriber: Subscriber<Pair<VolumeStatType, TradingResponse.VolumeStatUpdate>>,
    ): SubscriptionHandle<Set<Long>> {
        val typesWithHandles = statTypes.associateWith { subscriptionId.getAndIncrement() }
        scope.launch {
            typesWithHandles.forEach { (statType, id) ->
                val subscription = BackendSubscription.VolumeStat(ticker, statType, priorityExchange)
                volumeStatSubscribers[id] = Subscriber { response ->
                    subscriber.onUpdate(statType to response)
                }

                addSubscription(id, subscription)
            }
        }

        return object : SubscriptionHandle<Set<Long>>(typesWithHandles.values.toSet()) {
            override fun unsubscribe() {
                typesWithHandles.values.forEach { unsubscribeFromVolumeStatUpdate(it) }
            }
        }
    }

    fun setUnchangeablePriceStat(
        ticker: TypedTicker,
        priorityExchange: Exchange?,
        priceStatType: PriceStatType,
        value: Double,
    ) {
        send(BackendRequest.SetUnchangeablePriceStat(ticker, priorityExchange, priceStatType, value))
    }

    fun setUnchangeableVWAP(
        ticker: TypedTicker,
        priorityExchange: Exchange?,
        value: Double,
    ) {
        send(BackendRequest.SetUnchangeableVWAP(ticker, priorityExchange, value))
    }

    fun subscribeToVolumeStatUpdate(
        ticker: TypedTicker,
        statType: VolumeStatType,
        priorityExchange: Exchange?,
        subscriber: Subscriber<TradingResponse.VolumeStatUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.VolumeStat(ticker, statType, priorityExchange)
            volumeStatSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromVolumeStatUpdate(id)
            }
        }
    }

    private fun unsubscribeFromVolumeStatUpdate(id: Long) {
        scope.launch {
            volumeStatSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    override fun subscribeToVWAPUpdate(
        ticker: TypedTicker,
        subscriber: Subscriber<TradingResponse.VWAPUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.VWAP(ticker)
            vwapSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromVWAPUpdate(id)
            }
        }
    }

    private fun unsubscribeFromVWAPUpdate(id: Long) {
        scope.launch {
            vwapSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    override fun subscribeToVWEMAUpdate(
        ticker: TypedTicker,
        subscriber: Subscriber<TradingResponse.VWEMAUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.VWEMA(ticker)
            vwemaSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                vwemaSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeToTradingStatusUpdate(
        ticker: TypedTicker,
        subscriber: Subscriber<BackendResponse.TradingStatusUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.TradingStatus(ticker)
            tradingStatusSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                tradingStatusSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeToIndicator(
        ticker: TypedTicker,
        indicator: Indicator,
        subscriber: Subscriber<BackendResponse.IndicatorUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.IndicatorSubscription(ticker, indicator)
            indicatorSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                indicatorSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeCurrencyRate(
        currency1: Currency,
        currency2: Currency,
        subscriber: Subscriber<BackendResponse.CurrencyRateUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.CurrencyRateSubscription(
                currency1,
                currency2,
            )
            currencyRateSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromCurrencyRate(id)
            }
        }
    }

    private fun unsubscribeFromCurrencyRate(id: Long) {
        scope.launch {
            currencyRateSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    override fun subscribeToPriorityBrokersUpdate(
        subscriber: Subscriber<Map<Exchange, BrokerId>>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        priorityBrokerSubscribers[id] = subscriber
        subscriber.onUpdate(priorityBrokers)
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                priorityBrokerSubscribers.remove(id)
            }
        }
    }

    fun subscribeToClosePrice(
        ticker: TypedTicker,
        subscriber: Subscriber<BackendResponse.ClosePriceUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.ClosePrice(ticker)
            closePriceSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromClosePrice(id)
            }
        }
    }

    private fun unsubscribeFromClosePrice(id: Long) {
        scope.launch {
            closePriceSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    fun subscribeToMidPoint(
        ticker: TypedTicker,
        subscriber: Subscriber<BackendResponse.MidPointUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.MidPoint(ticker)
            midPointSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromMidPoint(id)
            }
        }
    }

    private fun unsubscribeFromMidPoint(id: Long) {
        scope.launch {
            midPointSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    override fun subscribeToAvailableOrderLots(
        ticker: TypedTicker,
        price: Double?,
        subscriber: Subscriber<TradingResponse.AvailableOrderLotsUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.AvailableOrderLots(ticker, price)
            availableOrderLotSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }
        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                unsubscribeFromAvailableOrderLots(id)
            }
        }
    }

    fun subscribeChartScripts(
        subscriber: Subscriber<BackendResponse.ChartScripts>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.ChartScripts
            chartScriptsUpdateSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                chartScriptsUpdateSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeChartScriptIds(
        subscriber: Subscriber<BackendResponse.ChartScriptIds>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.ChartScriptIds
            chartScriptIdsSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                chartScriptIdsSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeToChartScriptConfig(
        scriptId: ScriptId,
        subscriber: Subscriber<BackendResponse.ChartScriptConfigUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.ChartScriptConfig(scriptId)
            chartScriptConfigSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                chartScriptConfigSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun subscribeToChartScriptPlot(
        scriptId: ScriptId,
        subscriber: Subscriber<BackendResponse.ChartScriptPlotUpdate>,
    ): SubscriptionHandle<Long> {
        val id = subscriptionId.getAndIncrement()
        scope.launch {
            val subscription = BackendSubscription.ChartScriptPlot(scriptId)
            chartScriptPlotUpdatesSubscribers[id] = subscriber
            addSubscription(id, subscription)
        }

        return object : SubscriptionHandle<Long>(id) {
            override fun unsubscribe() {
                chartScriptPlotUpdatesSubscribers.remove(id)
                removeSubscription(id)
            }
        }
    }

    fun createChartScript(
        scriptId: ScriptId,
    ) {
        send(
            BackendRequest.UpdateChartScripts(
                ChartScriptManagementRequest.CreateChartScript(
                    scriptId,
                ),
            ),
        )
    }

    fun startMarkedChartScripts() {
        send(
            BackendRequest.UpdateChartScripts(
                ChartScriptManagementRequest.StartMarkedScripts,
            ),
        )
    }

    fun removeChartScript(
        scriptId: ScriptId,
    ) {
        send(
            BackendRequest.UpdateChartScripts(
                ChartScriptManagementRequest.RemoveChartScript(
                    scriptId,
                ),
            ),
        )
    }

    fun duplicateScript(
        originalScriptId: ScriptId,
        newScriptId: ScriptId,
    ) {
        send(
            BackendRequest.UpdateChartScripts(
                ChartScriptManagementRequest.DuplicateChartScript(
                    originalScriptId,
                    newScriptId,
                ),
            ),
        )
    }

    fun updateChartScript(
        scriptId: ScriptId,
        script: ChartScriptConfig,
    ) {
        send(
            BackendRequest.UpdateChartScripts(
                ChartScriptManagementRequest.UpdateChartScript(
                    scriptId,
                    script,
                ),
            ),
        )
    }

    fun startChartScript(
        scriptId: ScriptId,
    ) {
        send(
            BackendRequest.UpdateChartScripts(
                ChartScriptManagementRequest.StartChartScript(
                    scriptId,
                ),
            ),
        )
    }

    fun stopChartScript(
        scriptId: ScriptId,
    ) {
        send(
            BackendRequest.UpdateChartScripts(
                ChartScriptManagementRequest.StopChartScript(
                    scriptId,
                ),
            ),
        )
    }

    private fun unsubscribeFromAvailableOrderLots(id: Long) {
        scope.launch {
            availableOrderLotSubscribers.remove(id)
            removeSubscription(id)
        }
    }

    fun sendLog(message: String) {
        send(BackendRequest.Log(message))
    }

    suspend fun getTickerInfo(ticker: TypedTicker): TickerInfo {
        val url = makeHttpBackendUrl("/tickers/info?ticker=$ticker", emptyMap())
        return try {
            json.decodeFromString(httpClient.get(url).bodyAsText())
        } catch (e: Throwable) {
            TickerInfo("", emptySet(), DividendInfo(mapOf()), mapOf(), null, null, null)
        }
    }

    suspend fun getShortableTick(ticker: TypedTicker): Double? {
        val url = makeHttpBackendUrl("/tickers/shortable-tick?ticker=$ticker", emptyMap())
        return try {
            httpClient.get(url).bodyAsText().toDoubleOrNull()
        } catch (e: Throwable) {
            null
        }
    }

    suspend fun getPriceSteps(ticker: TypedTicker): PriceSteps {
        val attempts = 5
        val url = makeHttpBackendUrl("/tickers/price-steps?ticker=$ticker", emptyMap())
        try {
            repeat(attempts) {
                val response = httpClient.get(url)
                if (response.status == HttpStatusCode.OK) {
                    return json.decodeFromString(response.bodyAsText())
                }
            }
        } catch (e: Throwable) {
            logger.error(e) { "Error: get price steps for $ticker, return defaults steps" }
        }
        return PriceSteps.defaultSteps()
    }

    suspend fun getFormula(
        tickerPair: TickerPair,
        scannerId: ScannerId,
    ): PriceFormulaComponets {
        val url = makeHttpBackendUrl("/tickers/formula", emptyMap())
        return try {
            json.decodeFromString(
                httpClient.get(url) {
                    parameter("tickerPair", json.encodeToString(tickerPair))
                    parameter("scannerId", scannerId.id)
                }.bodyAsText(),
            )
        } catch (e: Throwable) {
//            toaster.error(e.toString(), 300, ToastPosition.BOTTOM_RIGHT, true)
            PriceFormulaComponets.emptyFormula
        }
    }

    override suspend fun getSearchTickers(
        ticker: TypedTicker?,
        searchString: String,
    ): List<Pair<TypedTicker, String>> {
        val url = makeHttpBackendUrl("/search-tickers?searchString=$searchString&ticker=$ticker", emptyMap())
        val response: HttpResponse = httpClient.get(url)
        return try {
            json.decodeFromString(response.bodyAsText())
        } catch (e: Throwable) {
            listOf()
        }
    }

    override suspend fun getCurrencyForTicker(ticker: TypedTicker): Currency? {
        val currencyForExchange = exchangesOneCurrency[ticker.exchange]
        if (currencyForExchange != null) {
            return currencyForExchange
        }

        val cachedCurrency = currencyCache.get(ticker)
        if (cachedCurrency != null) {
            return cachedCurrency
        }

        val currency = try {
            val url = makeHttpBackendUrl("/tickers/currency?ticker=$ticker", emptyMap())
            val response = httpClient.get(url)
            Currency.fromName(response.bodyAsText())
        } catch (e: HttpRequestTimeoutException) {
            toaster.error(
                "Timeout error: get currency for $ticker",
                300,
                ToastPosition.BOTTOM_RIGHT,
            )
            null
        } catch (e: Throwable) {
            null
        }

        if (currency != null) {
            currencyCache.put(ticker, currency)
        }

        return currency
    }

    suspend fun getAdditionalExchanges(
        ticker: TypedTicker,
        exchanges: Set<Exchange>,
    ): Set<TypedTicker> {
        val url = makeHttpBackendUrl("/additional-tickers", emptyMap())
        return additionalTickersCache.get(ticker to exchanges) {
            try {
                val response: HttpResponse = httpClient.get(url) {
                    parameter("ticker", ticker)
                    parameter(
                        "exchanges",
                        exchanges.joinToString(",") {
                            it.id
                        },
                    )
                }
                json.decodeFromString(response.bodyAsText())
            } catch (e: Throwable) {
                emptySet()
            }
        }
    }

    suspend fun getLevel2WidgetSupplements(
        ticker: TypedTicker,
        allExchanges: Set<Exchange>,
    ): Level2WidgetSupplements? {
        val url = makeHttpBackendUrl("/level2-widget-supplements", emptyMap())
        return try {
            val response: HttpResponse = httpClient.get(url) {
                parameter("ticker", ticker)
                parameter(
                    "allExchanges",
                    allExchanges.joinToString(",") {
                        it.id
                    },
                )
            }
            val supplements = json.decodeFromString(Level2WidgetSupplements.serializer(), response.bodyAsText())
            additionalTickersCache.put(ticker to allExchanges, supplements.tickers)
            supplements
        } catch (e: Throwable) {
            null
        }
    }

    fun getAdditionalTickersFromCache(
        ticker: TypedTicker,
        exchanges: Set<Exchange>,
    ): Set<TypedTicker>? {
        return additionalTickersCache.get(ticker to exchanges)
    }

    suspend fun getFormulas(
        tickers: Set<TypedTicker>,
        scannerId: ScannerId,
    ): Map<TypedTicker, PriceFormulaComponets> {
        val url = makeHttpBackendUrl("/tickers/formulas", emptyMap())
        return try {
            val response: HttpResponse = httpClient.get(url) {
                parameter("tickers", json.encodeToString<Set<TypedTicker>>(tickers))
                parameter("scannerId", scannerId.id)
            }
            json.decodeFromString(response.bodyAsText())
        } catch (e: Throwable) {
            emptyMap()
        }
    }

    suspend fun getBestCorrelationTickers(
        ticker: TypedTicker,
        min: Double,
        max: Double,
        count: Int,
        checkStatus: Boolean,
        filter: TickerDetailsFilter,
    ): CorrelationResponse {
        val url = makeHttpBackendUrl("/best-correlation", emptyMap())
        return try {
            val response: HttpResponse = httpClient.get(url) {
                parameter("ticker", ticker)
                parameter("count", count)
                parameter("max", max)
                parameter("min", min)
                parameter("checkStatus", checkStatus)
                parameter("filter", json.encodeToString(filter))
            }
            json.decodeFromString(response.bodyAsText())
        } catch (e: Throwable) {
            toaster.error(
                e.toString(),
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
            CorrelationResponse(listOf())
        }
    }

    suspend fun getSectorsAndIndustries(
        tickers: Set<TypedTicker>,
    ): Map<TypedTicker, Pair<String, String>> {
        val url = makeHttpBackendUrl("/sector-industry", emptyMap())
        return try {
            val response: HttpResponse = httpClient.get(url) {
                parameter("tickers", json.encodeToString(tickers))
            }
            json.decodeFromString(response.bodyAsText())
        } catch (e: Throwable) {
            toaster.error(
                e.toString(),
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
            emptyMap()
        }
    }

    suspend fun getScannerSnapshots(): List<String> {
        val url = makeHttpBackendUrl("/scanner-snapshots", emptyMap())
        val response: HttpResponse = httpClient.get(url)
        return try {
            json.decodeFromString(response.bodyAsText())
        } catch (e: Throwable) {
            logger.error(e) { }
            listOf()
        }
    }

    override suspend fun getIsin(ticker: TypedTicker): Isin? {
        val url = makeHttpBackendUrl("/ticker-isin?ticker=$ticker", emptyMap())
        return try {
            json.decodeFromString(httpClient.get(url).bodyAsText())
        } catch (e: Throwable) {
            null
        }
    }

    suspend fun startRecordScanner(startRecord: BackendRequest.StartRecordScanner) {
        val params = json.encodeToString(startRecord)
        val url = makeHttpBackendUrl("/scanner-record?record-params=$params", emptyMap())
        try {
            val response = httpClient.post(url).bodyAsText()
            toaster.info(
                response,
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
    }

    suspend fun getGroupWatchlists(): Map<String, TypedTicker?> {
        val url = makeHttpBackendUrl("/all-group-watchlists", emptyMap())
        try {
            val response = httpClient.get(url)
            return json.decodeFromString(response.bodyAsText())
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        return mapOf()
    }

    suspend fun getGroupWatchlist(name: String): GroupWatchlist? {
        val url = makeHttpBackendUrl("/group-watchlist", emptyMap())
        try {
            val response = httpClient.get(url) {
                parameter("name", name)
            }
            return json.decodeFromString(response.bodyAsText())
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        return null
    }

    suspend fun removeGroupWatchlist(name: String): Boolean {
        val url = makeHttpBackendUrl("/remove-group-watchlist", emptyMap())
        try {
            httpClient.delete(url) {
                parameter("name", name)
            }
            return true
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        return false
    }

    suspend fun removeTickerFromWatchList(name: String, ticker: TypedTicker): Boolean {
        val url = makeHttpBackendUrl("/remove-ticker-watchlists", emptyMap())
        try {
            httpClient.delete(url) {
                parameter("name", name)
                parameter("ticker", ticker.toString())
            }
            return true
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        return false
    }

    suspend fun addTickerToWatchList(name: String, ticker: TypedTicker): Boolean {
        val url = makeHttpBackendUrl("/add-ticker-watchlists", emptyMap())
        try {
            httpClient.post(url) {
                parameter("name", name)
                parameter("ticker", ticker.toString())
            }
            return true
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        return false
    }

    suspend fun setMainTickerWatchlist(name: String, mainTicker: TypedTicker?): Boolean {
        val url = makeHttpBackendUrl("/set-main-ticker-watchlist", emptyMap())
        try {
            httpClient.post(url) {
                parameter("name", name)
                parameter("main_ticker", mainTicker.toString())
            }
            return true
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        return false
    }

    suspend fun createNewGroupWatchlist(name: String): Boolean {
        val url = makeHttpBackendUrl("/create-group-watchlist", emptyMap())
        try {
            httpClient.post(url) {
                parameter("name", name)
            }
            return true
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }
        return false
    }

    suspend fun generateGroupWatchlist(
        name: String,
        exchange: Exchange,
        targetExchange: Exchange?,
        priceRange: OptionalDoubleRange,
        sectors: List<String>,
        industries: List<String>,
        subTypes: List<String>,
        maxCount: Int,
    ): GroupWatchlist? {
        val url = makeHttpBackendUrl("/generate-group-watchlist", emptyMap())
        try {
            val result = httpClient.post(url) {
                parameter("name", name)
                parameter("exchange", exchange.id)
                if (targetExchange != null) {
                    parameter("targetExchange", targetExchange.id)
                }
                parameter("priceRange", json.encodeToString(priceRange))
                parameter("sectors", json.encodeToString(sectors))
                parameter("industries", json.encodeToString(industries))
                parameter("subTypes", json.encodeToString(subTypes))
                parameter("maxCount", maxCount)
            }
            return json.decodeFromString(result.bodyAsText())
        } catch (e: Throwable) {
            toaster.error(
                e.message ?: "",
                1900,
                ToastPosition.BOTTOM_RIGHT,
            )
        }

        return null
    }

    fun sendRestart() {
        send(BackendRequest.Restart)
    }

    override fun restartScannerServer() {
        // Not need implementing for backend server
    }

    override fun sendEditLimitOrder(
        brokerId: BrokerId,
        orderId: String,
        operation: OperationType,
        ticker: TypedTicker,
        price: Double,
        volume: Double,
        smartRoute: Boolean,
        hidden: Boolean,
    ) {
        send(
            BackendRequest.ExecTradingRequest(
                TradingRequest.EditLimitOrder(
                    brokerId,
                    orderId,
                    operation,
                    ticker,
                    price,
                    volume,
                    smartRoute,
                    hidden,
                ),
            ),
        )
    }

    private fun sendConfirmationCode(phoneNumber: String, code: String) {
        send(
            BackendRequest.ConfirmCode(
                phoneNumber,
                code,
            ),
        )
    }

    fun sendStartAutoTake(
        widgetId: String,
        scannerId: ScannerId,
        subscription: ScannerDealsSubscription,
        arbitrageConfig: ArbitrageConfig,
        priorityBrokers1: Map<Exchange, BrokerId>,
        priorityBrokers2: Map<Exchange, BrokerId>,
    ) {
        send(
            BackendRequest.StartAutoTake(
                widgetId,
                scannerId,
                subscription,
                arbitrageConfig,
                AutoTakePriorityBrokers(
                    priorityBrokers1,
                    priorityBrokers2,
                ),
            ),
        )
    }

    fun sendStopAutoTake(
        widgetId: String,
        scannerId: ScannerId,
    ) {
        send(
            BackendRequest.StopAutoTake(
                widgetId,
                scannerId,
            ),
        )
    }

    fun sendSetLogLevel(logLevel: LogLevel) {
        send(BackendRequest.SetLogLevel(logLevel))
    }

    override suspend fun connect(selectedServer: String) {
    }

    override suspend fun start(onStarted: (ConnectorStatus) -> Unit) {
    }

    suspend fun getAllSectorAndIndustry(): List<SectorAndIndustry> {
        val url = makeHttpBackendUrl("/tickers/all-sectors-industries", emptyMap())
        return try {
            json.decodeFromString(httpClient.get(url).bodyAsText())
        } catch (e: Throwable) {
            listOf()
        }
    }

    suspend fun getAllSubTickerTypes(): List<String> {
        val url = makeHttpBackendUrl("/tickers/all-subtypes", emptyMap())
        return try {
            json.decodeFromString(httpClient.get(url).bodyAsText())
        } catch (e: Throwable) {
            listOf()
        }
    }

    fun sendAddUserSpace(userSpace: String) {
        send(BackendRequest.CreateNewUserSpace(userSpace))
    }

    fun sendDuplicateUserSpace(userSpace: String, sourceUserName: String) {
        send(BackendRequest.DuplicateUserSpace(userSpace, sourceUserName))
    }

    fun sendRemoveUserSpace(userSpace: String) {
        send(BackendRequest.RemoveUserSpace(userSpace))
    }

    override fun getPriorityBroker(exchange: Exchange): BrokerId? {
        return priorityBrokers[exchange]
    }

    override fun setPriorityBroker(exchange: Exchange, brokerId: BrokerId) {
        priorityBrokers[exchange] = brokerId
        priorityBrokerSubscribers.forEach {
            it.value.onUpdate(priorityBrokers)
        }

        sendTabsChange(GuiConfigChange.SetPriorityBroker(exchange, brokerId))
    }

    override fun clearPriorityBroker(exchange: Exchange) {
        val currentGuiConfig = guiConfig
        if (currentGuiConfig != null) {
            val priorityBrokers = currentGuiConfig.priorityBrokers.minus(exchange)

            guiConfig = guiConfig?.copy(
                priorityBrokers = priorityBrokers,
            )

            sendTabsChange(GuiConfigChange.ClearPriorityBroker(exchange))
        }
    }

    fun industry(index: Int): String? {
        return allSectorsAndIndustries?.industries?.get(index)
    }

    fun sector(index: Int): String? {
        return allSectorsAndIndustries?.sectors?.get(index)
    }

    fun getSelectedMasterExchange(): Exchange {
        return guiConfig?.prioritySuggestExchange ?: config.masterExchange
    }
}
