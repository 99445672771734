package com.edvorg.trade.common.frontend.views

import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.TextField
import react.FC
import react.Props
import react.ReactNode
import react.StateSetter
import react.dom.onChange
import web.html.HTMLInputElement

private const val enterKey = 13

data class Prompt(val value: String, val title: String, val label: String, val onOk: (String) -> Unit)
external interface PromptProps : Props {
    var dialog: Prompt?
    var setDialog: StateSetter<Prompt?>
}

val prompt = FC<PromptProps> { props ->
    Dialog {
        open = props.dialog != null
        onClose = { _, _ ->
            props.setDialog {
                null
            }
        }

        val v = props.dialog
        if (v != null) {
            DialogTitle {
                +v.title
            }
            DialogContent {
                TextField {
                    size = Size.small
                    label = ReactNode(v.label)
                    value = v.value
                    variant = FormControlVariant.filled
                    autoFocus = true
                    onChange = { event ->
                        (event.target as? HTMLInputElement)?.value?.let { value ->
                            props.setDialog { dialog ->
                                dialog?.copy(value = value)
                            }
                        }
                    }
                    onKeyUp = { event ->
                        val keyCode = event.asDynamic().keyCode as Int
                        if (keyCode == enterKey) {
                            props.setDialog { _ ->
                                null
                            }
                            v.onOk(v.value)
                        }
                    }
                }
            }
            DialogActions {
                Button {
                    size = Size.small
                    onClick = {
                        props.setDialog { _: Prompt? ->
                            null
                        }
                    }
                    +"Cancel"
                }
                Button {
                    size = Size.small
                    onClick = {
                        props.setDialog { _ ->
                            null
                        }
                        v.onOk(v.value)
                    }
                    +"Ok"
                }
            }
        }
    }
}
