package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(BrokerIdSerializer::class)
data class BrokerId(val id: String)

object BrokerIdSerializer : KSerializer<BrokerId> {
    override fun deserialize(decoder: Decoder): BrokerId {
        return BrokerId(decoder.decodeString())
    }

    override fun serialize(encoder: Encoder, value: BrokerId) {
        encoder.encodeString(value.id)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "BrokerIdIdentifier",
        PrimitiveKind.STRING,
    )
}
