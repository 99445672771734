package com.edvorg.trade.common.model.portfolio

import kotlinx.serialization.Serializable

@Serializable
enum class PortfolioWidgetColumn(val displayName: String, val index: Int) {
    Logo("Logo", 0),
    Broker("Broker", 1),
    Symbol("Symbol", 2),
    Direction("Direction", 3),
    Lots("Lots", 4),
    Volume("Volume", 5),
    Blocked("Blocked", 6),
    LastPrice("Last", 7),
    AvgPrice("Avg", 8),
    Amount("Amount", 9),
    Profit("P/L", 10),
    ProfitPercent("P/L %", 11),
    Event("Event", 12),
    Actions("Actions", 13),
    ;

    companion object {
        private val indexToColumn = entries.associateBy { it.index }

        fun fromIndex(index: Int): PortfolioWidgetColumn? = indexToColumn[index]
    }
}
