package com.edvorg.trade.common.model.chartscript

import kotlinx.serialization.Serializable

@Serializable
data class ChartScriptPlotData(
    val plots: Map<Int, PlotData>,
    val dataFields: Map<String, DataField>,
    val flush: Boolean,
) {
    fun addPoints(newPlots: Map<Int, PlotData>, limit: Int): ChartScriptPlotData {
        return (newPlots.keys + plots.keys).mapNotNull {
            val oldPlot = plots[it]
            val newPlot = newPlots[it]
            val color = newPlot?.color ?: oldPlot?.color ?: return@mapNotNull null
            val name = oldPlot?.name ?: newPlot?.name ?: return@mapNotNull null
            val oldPoints = oldPlot?.data ?: listOf()
            val newPoints = newPlot?.data ?: listOf()

            it to PlotData(name, color, (oldPoints + newPoints).takeLast(limit))
        }.let {
            ChartScriptPlotData(it.toMap(), dataFields, flush)
        }
    }
}
