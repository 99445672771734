package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.BrokerId
import com.edvorg.trade.common.model.Exchange
import com.edvorg.trade.common.model.Group
import com.edvorg.trade.common.model.Ticker
import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.WidgetGeometry
import com.edvorg.trade.common.model.WidgetSettings
import com.edvorg.trade.common.model.WidgetState
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class GuiConfigChange {
    @Serializable
    @SerialName("SetWidgetGeometry")
    data class SetWidgetGeometry(
        val tab: String,
        val widgetId: String,
        val geometry: WidgetGeometry,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("UpdateGroupWatchedTickers")
    data class UpdateGroupWatchedTickers(
        val group: Group,
        val tickers: Set<Ticker>,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SetWidgetGroupId")
    data class SetWidgetGroupId(
        val tab: String,
        val widgetId: String,
        val groupId: Group,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SetWidgetGroupId2")
    data class SetWidgetGroupId2(
        val tab: String,
        val widgetId: String,
        val groupId: Group,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("UpdateWidgetSettings")
    data class UpdateWidgetSettings(
        val tab: String,
        val widgetId: String,
        val settings: WidgetSettings,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("AddWidget")
    data class AddWidget(
        val tab: String,
        val newWidgetId: String,
        val widgetState: WidgetState,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("RemoveWidget")
    data class RemoveWidget(
        val tab: String,
        val widgetId: String,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SaveWidgetTemplate")
    data class SaveWidgetStateTemplate(
        val name: String,
        val widgetState: WidgetState,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("RemoveWidgetTemplate")
    data class RemoveWidgetStateTemplate(
        val name: String,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SetGroupTicker")
    data class SetGroupTicker(
        val group: Group,
        val ticker: TypedTicker,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("AddNewTab")
    data class AddNewTab(
        val newTab: String,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("RemoveTab")
    data class RemoveTab(
        val tab: String,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("DuplicateTab")
    data class DuplicateTab(
        val tab: String,
        val newTab: String,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SetSelectedTab")
    data class SetSelectedTab(
        val tab: String,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SetSuggestExchange")
    data class SetSuggestExchange(
        val exchange: Exchange,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SetPriorityBroker")
    data class SetPriorityBroker(
        val exchange: Exchange,
        val brokerId: BrokerId,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("RemovePriorityBroker")
    data class ClearPriorityBroker(
        val exchange: Exchange,
    ) : GuiConfigChange()

    @Serializable
    @SerialName("SetPriceRoundingScale")
    data class SetPriceRoundingScale(
        val scale: Int,
    ) : GuiConfigChange()
}
