package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.TypedTicker
import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.serialization.Serializable

@Serializable
data class ScheduledDealsOrder(
    val ticker: TypedTicker,
    val openTickOverride: PriceStatType?,
    val l3Override: Double?,
    val l2Override: Double?,
    val l1Override: Double?,
    val s1Override: Double?,
    val s2Override: Double?,
    val s3Override: Double?,
    val v1Override: Double? = null,
    val v2Override: Double? = null,
    val v3Override: Double? = null,
)
