package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CurrencyPair(
    @SerialName("first")
    val currency1: Currency,
    @SerialName("second")
    val currency2: Currency,
) {
    fun reversed(): CurrencyPair {
        return CurrencyPair(currency2, currency1)
    }
}
