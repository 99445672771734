package com.edvorg.trade.common.frontend.utils

import com.edvorg.trade.common.model.Currency
import com.edvorg.trade.common.model.TypedTicker
import io.ktor.http.URLBuilder
import io.ktor.http.URLProtocol
import kotlinx.browser.window

object UrlUtils {
    fun getStockIconUrl(ticker: TypedTicker): String {
        val mode = (window.asDynamic().MODE as? String)
        val url = URLBuilder().apply {
            protocol = if (window.location.protocol == "https:") URLProtocol.HTTPS else URLProtocol.HTTP
            host = window.location.hostname
            if (mode == "dev") {
                port = 3005 // standard dev port for websocket
            } else {
                val explicitPort = window.location.port.toIntOrNull()
                if (explicitPort != null) {
                    port = explicitPort
                }
            }
        }.build()
        return "$url/icon?ticker=$ticker"
    }

    fun getCountryIconUrlByCurrency(currency: Currency): String {
        if (currency == Currency.HKD) {
            return "https://invest-brands.cdn-tinkoff.ru/HKDx160.png"
        }
        val countryName = when (currency) {
            Currency.RUB -> "RUS"
            Currency.USD -> "USA"
            Currency.EUR -> "EU"
            Currency.GBP -> "ENG"
            else -> ""
        }
        return "https://static.tinkoff.ru/flags/1/$countryName.svg"
    }
}
