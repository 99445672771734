package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class ScannerTick {
    @Serializable
    @SerialName("Bid")
    data object Bid : ScannerTick()

    @Serializable
    @SerialName("Ask")
    data object Ask : ScannerTick()

    @Serializable
    @SerialName("Mid")
    data object Mid : ScannerTick()

    @Serializable
    @SerialName("Stat")
    data class Stat(
        val priceStatType: PriceStatType,
    ) : ScannerTick()

    @Serializable
    @SerialName("Indicator")
    data class Indicator(
        val indicator: com.edvorg.trade.common.model.Indicator,
    ) : ScannerTick()
}
