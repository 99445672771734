package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class OrderBookEntryPair(
    val entry: OrderBookEntry,
    val currency: Currency,
    val convertedPrice: Double,
    val targetCurrency: Currency,
) {
    constructor(entry: OrderBookEntry) : this(entry, Currency.USD, 0.0, Currency.USD)

    fun getTargetPrice(): Double {
        return if (currency == targetCurrency) {
            entry.price
        } else {
            convertedPrice
        }
    }

    fun targetEntry(): OrderBookEntry {
        return OrderBookEntry(entry.volume, getTargetPrice())
    }
}
