package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(TypedTickerSerializer::class)
data class TypedTicker(
    val tickerType: TickerType,
    val exchange: Exchange,
    val ticker: Ticker,
) {
    constructor(exchangeAndTicker: ExchangeAndTicker, tickerType: TickerType = TickerType.STOCK) :
        this(tickerType, exchangeAndTicker.exchange, exchangeAndTicker.ticker)

    companion object {
        fun parse(tickerStr: String, defaultTickerType: TickerType = TickerType.STOCK): TypedTicker? {
            var components = tickerStr.split(":")

            val tickerType = if (components.size > 2) {
                val t = components[0].toIntOrNull()?.let {
                    TickerType.fromIndex(it)
                } ?: components[0].let { name ->
                    TickerType.entries.firstOrNull {
                        it.name.equals(name, true)
                    }
                } ?: return null
                components = components.drop(1)
                t
            } else {
                defaultTickerType
            }

            if (components.size < 2) {
                return null
            }

            val (exchangeId, ticker) = components

            val exchange = Exchange.fromId(exchangeId)
                ?: return null

            return TypedTicker(tickerType, exchange, Ticker(ticker.trim()))
        }

        fun parse(
            tickerStr: String,
            defaultExchange: Exchange,
            defaultTickerType: TickerType = TickerType.STOCK,
        ): TypedTicker? {
            var components = tickerStr.split(":")

            val tickerType = if (components.size > 2) {
                val t = components[0].toIntOrNull()?.let {
                    TickerType.fromIndex(it)
                } ?: components[0].let { name ->
                    TickerType.entries.firstOrNull {
                        it.name.equals(name, true)
                    }
                } ?: return null
                components = components.drop(1)
                t
            } else {
                defaultTickerType
            }

            val exchange = if (components.size > 1) {
                val exchangeId = components[0]
                components = components.drop(1)
                Exchange.fromId(exchangeId)
            } else {
                defaultExchange
            } ?: return null

            if (components.size < 1) {
                return null
            }

            val ticker = components[0]

            return TypedTicker(tickerType, exchange, Ticker(ticker))
        }
    }

    override fun toString(): String {
        return if (tickerType == TickerType.STOCK) {
            "${exchange.id}:${ticker.id.trim()}"
        } else {
            "${tickerType.name}:${exchange.id}:${ticker.id.trim()}"
        }
    }

    fun toExchangeAndTicker(): ExchangeAndTicker {
        return ExchangeAndTicker(exchange, ticker)
    }
}

object TypedTickerSerializer : KSerializer<TypedTicker> {
    private const val delimiter = ":"

    override val descriptor = PrimitiveSerialDescriptor(
        "TypedTicker",
        PrimitiveKind.STRING,
    )

    override fun deserialize(decoder: Decoder): TypedTicker {
        var components = decoder.decodeString().split(delimiter)

        val tickerType = if (components.size > 2) {
            val t = components[0].toIntOrNull()?.let {
                TickerType.fromIndex(it)
            } ?: components[0].let { name ->
                TickerType.entries.firstOrNull {
                    it.name.equals(name, true)
                }
            } ?: throw Error("tickerType ${components[0]} is not supported")
            components = components.drop(1)
            t
        } else {
            TickerType.STOCK
        }

        if (components.size < 2) {
            throw Error("ticker must be in format TICKER_TYPE:EXCHANGE:TICKER or EXCHANGE:TICKER")
        }

        val (exchangeId, ticker) = components

        val exchange = Exchange.fromId(exchangeId)
            ?: throw Error("exchange $exchangeId is not supported")

        return TypedTicker(tickerType, exchange, Ticker(ticker.trim()))
    }

    override fun serialize(encoder: Encoder, value: TypedTicker) {
        if (value.tickerType == TickerType.STOCK) {
            encoder.encodeString(
                value.exchange.id + delimiter + value.ticker.id.trim(),
            )
        } else {
            encoder.encodeString(
                value.tickerType.index.toString() +
                    delimiter + value.exchange.id +
                    delimiter + value.ticker.id.trim(),
            )
        }
    }
}
