package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class MarketDataControlRequest {
    @Serializable
    @SerialName("UpdateConnectors")
    data class UpdateConnectors(
        val request: ConnectionManagementRequest,
    ) : MarketDataControlRequest()
}
