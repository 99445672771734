package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
sealed class ScannerExecutorConfig {
    abstract val createdAt: Long

    abstract fun withCreatedAt(createdAt: Long): ScannerExecutorConfig
    abstract fun displayName(): String

    @Serializable
    @SerialName("Pair")
    data class Pair(
        override val createdAt: Long,
    ) : ScannerExecutorConfig() {
        override fun withCreatedAt(createdAt: Long): Pair {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Pair"
        }
    }

    @Serializable
    @SerialName("PairCap")
    data class PairCap(
        override val createdAt: Long,
        val price2PercentCap: Double,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val orderTimeout: Duration,
    ) : ScannerExecutorConfig() {
        override fun withCreatedAt(createdAt: Long): PairCap {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "PairCap"
        }
    }

    @Serializable
    @SerialName("Bridge")
    data class Bridge(
        override val createdAt: Long,
        val bridgeConfig: BridgeTradeConfig,
    ) : ScannerExecutorConfig() {
        override fun withCreatedAt(createdAt: Long): Bridge {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "Bridge"
        }
    }

    @Serializable
    @SerialName("vwap")
    data class Vwap(
        override val createdAt: Long,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val orderTimeout: Duration,
    ) : ScannerExecutorConfig() {
        override fun withCreatedAt(createdAt: Long): Vwap {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "vwap"
        }
    }

    @Serializable
    @SerialName("timeout")
    data class Timeout(
        override val createdAt: Long,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val orderTimeout: Duration,
        val minHedgeBudget: Double,
    ) : ScannerExecutorConfig() {
        override fun withCreatedAt(createdAt: Long): Timeout {
            return copy(createdAt = createdAt)
        }

        override fun displayName(): String {
            return "timeout"
        }
    }
}
