package com.edvorg.trade.common.model

enum class SortType(val value: Int) {
    ASC(0),
    DESC(1),
    ;

    fun toEmojiCode() = when (this) {
        ASC -> "↑"
        DESC -> "↓"
    }
}
