package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable

@Serializable
data class Candle(
    val time: Long, // in seconds
    val open: Double,
    val high: Double,
    val low: Double,
    val close: Double,
    val volume: Double = 0.0,
)
