package com.edvorg.trade.common.model

import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

enum class Currency(val index: Int) {
    USD(1),
    RUB(2),
    EUR(3),
    CHF(4),
    CNH(5),
    GBP(6),
    HKD(7),
    JPY(8),
    TRY(9),
    MXN(10),
    CAD(11),
    ILS(12),
    SGD(13),
    SEK(14),
    PLN(15),
    AUD(16),
    NOK(17),
    DKK(18),
    CZK(19),
    HUF(20),
    INR(21),
    KRW(22),
    CNY(23),
    TWD(24),
    USDT(25),
    USDC(26),
    BTC(27),
    ETH(28),
    KZT(29),
    ;

    companion object {
        private val nameToCurrency = entries.associateBy {
            it.name
        }

        fun fromName(name: String): Currency? {
            return nameToCurrency[name]
        }

        private val indexToCurrency = entries.associateBy {
            it.index
        }.also { map ->
            map.entries.groupBy {
                it.value
            }.entries.find {
                1 < it.value.size
            }?.let { entry ->
                throw Error("2 or more currency cases have the same index $entry")
            }
        }

        fun fromIndex(index: Int): Currency? {
            return indexToCurrency[index]
        }
    }
}

object CurrencyIndexSerializer : KSerializer<Currency> {
    override fun deserialize(decoder: Decoder): Currency {
        val index = decoder.decodeInt()
        return Currency.fromIndex(index) ?: throw Error("currency not supported: $index")
    }

    override fun serialize(encoder: Encoder, value: Currency) {
        encoder.encodeInt(value.index)
    }

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor(
        "CurrencyIndexIdentifier",
        PrimitiveKind.INT,
    )
}

fun Currency.toEmojiCode(): String {
    return when (this) {
        Currency.USD -> "\u0024"
        Currency.RUB -> "\u20BD"
        Currency.EUR -> "\u20AC"
        Currency.CHF -> "\u20A3"
        Currency.CNH -> "\u00A5"
        Currency.GBP -> "\u00A3"
        Currency.JPY -> "\u00A3"
        Currency.TRY -> "\u20A4"
        Currency.CNY -> "¥"
        else -> this.name.lowercase()
    }
}
