package com.edvorg.trade.common.utils.sound

import kotlinx.atomicfu.atomic
import kotlinx.atomicfu.getAndUpdate

class BackendSoundPlayer(private val player: SoundPlayer) {
    private val widgetAddSounds = listOf(
        "fresh-meat",
        "my-life-belongs-to-nerzul",
        "my-life-belongs-to-tribe",
        "i-wish-to-serve",
        "we-are-righteous",
        "what-to-bomb",
    ).shuffled().toTypedArray()

    private val clickSounds = listOf(
        "why-clicking",
        "stop-clicking",
        "dont-click",
        "help-me-they-are-pressing",
        "something-clicked",
        "what-the-heck-are-you-doing",
        "im-busy-come-tomorrow",
        "dont-offend-me",
    ).shuffled().toTypedArray()

    fun playPewSound() {
        player.play("purchased")
    }

    fun playReadySound() {
        player.play("ready")
    }

    fun playPissed() {
        player.play("pissed")
    }

    fun playNeedMoreGold() {
        player.play("need-more-gold")
    }

    fun playLetTheProphecyFulfil() {
        player.play("let-prophecy-fulfil")
    }

    fun playWontDo() {
        player.play("wont-do")
    }

    fun playError() {
        player.play("error")
    }

    fun playAddPotatoes() {
        player.play("add-potatoes")
    }

    fun playWorkIsNotWolf() {
        player.play("work-is-not-wolf")
    }

    fun playIsSeeDarkNess() {
        player.play("i-see-darkness")
    }

    fun playPathOfDarkness() {
        player.play("path-of-darkness")
    }

    fun playLetsDoSomeChemistry() {
        player.play("lets-do-some-chemistry")
    }

    fun playWeCapturedWitch() {
        player.play("we-captured-witch")
    }

    fun playStrangeSmell() {
        player.play("strange-smell")
    }

    fun playDeath() {
        player.play("death")
    }

    fun playIDroppedBomb() {
        player.play("i-dropped-bomb")
    }

    fun playILikeItLikeThat() {
        player.play("i-like-it-like-that")
    }

    fun playIGainedPower() {
        player.play("i-gained-power")
    }

    fun playIHitTwoTimes() {
        player.play("i-hit-two-times")
    }

    fun playScannerLogEvent() {
        player.play("scannerlog-event")
    }

    fun playNewsEvent() {
        player.play("news-event")
    }

    private val widgetAddCounter = atomic(0)

    fun playOnWidgetAdded() {
        player.play(widgetAddSounds[widgetAddCounter.getAndUpdate { it + 1 } % widgetAddSounds.size])
    }

    private val clickCounter = atomic(0)

    fun playOnLotsOfClicks() {
        player.play(clickSounds[clickCounter.getAndUpdate { it + 1 } % clickSounds.size])
    }
}
