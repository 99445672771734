package com.edvorg.trade.common.model

import kotlinx.serialization.Serializable
import kotlin.time.Duration

@Serializable
sealed class TradingRequest {
    @Serializable
    data class EditLimitOrder(
        val brokerId: BrokerId,
        val orderId: String,
        val operation: OperationType,
        val ticker: TypedTicker,
        val price: Double,
        val volume: Double,
        val smartRoute: Boolean,
        val hidden: Boolean,
    ) : TradingRequest()

    @Serializable
    data class PlaceBridgeOrder(
        val operationType: OperationType,
        val ticker: TypedTicker,
        val volume: Double,
        val price: Double,
        @Serializable(with = HumanReadableDurationSerializer::class)
        val delay: Duration?,
        val brokerId1: BrokerId?,
        val brokerId2: BrokerId?,
    ) : TradingRequest()

    @Serializable
    data class PlaceBuyOrder(
        val brokerId: BrokerId?,
        val orderType: OrderType,
        val ticker: TypedTicker,
        val volume: Double,
        val price: Double,
        val currency: Currency,
        val smartRoute: Boolean,
        val hidden: Boolean,
        val trail: Pair<Int, Double>?,
        val triggerPrice: Double?,
    ) : TradingRequest()

    @Serializable
    data class PlaceSellOrder(
        val brokerId: BrokerId?,
        val orderType: OrderType,
        val ticker: TypedTicker,
        val volume: Double,
        val price: Double,
        val currency: Currency,
        val smartRoute: Boolean,
        val hidden: Boolean,
        val trail: Pair<Int, Double>?,
        val triggerPrice: Double?,
    ) : TradingRequest()

    @Serializable
    data class CancelOrder(
        val brokerId: BrokerId,
        val exchange: Exchange,
        val id: String,
    ) : TradingRequest()
}
