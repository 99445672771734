package com.edvorg.trade.common.model.config

import kotlinx.serialization.Serializable

@Serializable
data class BridgeConfig(
    val intermediateRangeModes: List<BridgeRange>,
    val finalMode: BridgeMode,
    val finalDelta: Double,
)
