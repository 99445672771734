package com.edvorg.trade.common.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Connectors")
sealed class Connectors {
    @Serializable
    @SerialName("BrokerClients")
    data class BrokerClients(
        val brokerClientsByExchange: Map<Exchange, Set<BrokerId>>,
        val priorityBrokers: Map<Exchange, BrokerId>,
        val brokerClients: Map<BrokerId, BrokerConnectorStatus>,
    ) : Connectors()

    @Serializable
    @SerialName("MarketDataClients")
    data class MarketDataClients(
        val marketDataClients: Map<MarketDataId, MarketDataConnectorStatus>,
        val newsMarketDataClients: Map<MarketDataId, MarketDataConnectorStatus>,
    ) : Connectors()

    @Serializable
    @SerialName("ScannerClients")
    data class ScannerClients(
        val scannerClients: Map<ScannerId, ScannerConnectorStatus>,
    ) : Connectors()

    @Serializable
    @SerialName("ScannerExecutorsUpdate")
    data class ScannerExecutorsUpdate(
        val executors: Map<ScannerExecutorId, ScannerExecutorStatus>,
    ) : Connectors()

    @Serializable
    @SerialName("Samurais")
    data class Samurais(
        val samurais: Map<SamuraiId, SamuraiState>,
    ) : Connectors()
}
