package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.HumanReadableDurationSerializer
import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.datetime.LocalTime
import kotlinx.serialization.Serializable
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

@Serializable
data class ScheduledDealsConfig(
    val enabled: Boolean,
    val time: LocalTime,
    @Serializable(with = HumanReadableDurationSerializer::class)
    val delay: Duration,
    val keepAlive: Boolean,
    val settings: ScheduledDealsListSettings,
    val orders: List<ScheduledDealsOrder>,
    val batchSize: Int = 10,
) {
    companion object {
        val default = ScheduledDealsConfig(
            false,
            LocalTime(10, 0),
            5.seconds,
            true,
            ScheduledDealsListSettings(
                PriceStatType.POST_CLOSE,
                0.8,
                0.9,
                0.95,
                1.05,
                1.1,
                1.2,
                1.0,
                1.0,
                1.0,
            ),
            emptyList(),
            10,
        )
    }
}
