package com.edvorg.trade.common.model.config

import com.edvorg.trade.common.model.stats.PriceStatType
import kotlinx.serialization.Serializable

@Serializable
data class ScheduledDealsListSettings(
    val openTick: PriceStatType,
    val l3: Double?,
    val l2: Double?,
    val l1: Double?,
    val s1: Double?,
    val s2: Double?,
    val s3: Double?,
    val v1: Double = 1.0,
    val v2: Double = 1.0,
    val v3: Double = 1.0,
)
